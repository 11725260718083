import { Button } from "@/app/components/ui/button"
import { Dialog, DialogContent } from "@/app/components/ui/dialog"
import { PackageContext } from "@/app/context/PackageContext"
import { useTranslation } from "next-i18next"
import React, { FC, useContext } from "react"

interface ModalApplyAvatarSuccessProps {
  open: boolean
  handleClose: () => void
}

export const ModalApplyAvatarSuccess: FC<ModalApplyAvatarSuccessProps> = ({
  open,
  handleClose
}) => {
  const { visible, setVisible, setPreview, preview } =
    useContext(PackageContext)
  const { t } = useTranslation()

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className="max-w-[320px] border-0 shadow-[0px_4px_30px_0px_rgba(0,0,0,0.06)] rounded-[24px] p-[24px] sm:rounded-[24px] gap-0 xs:w-[90vw]">
        <p className="text-[16px] Nunito-700 pb-[8px] leading-[22px] text-center">
          Bạn đã áp dụng avatar thành công!
        </p>
        <p className="text-[14px] text-neutral-05 text-center dark:text-neutral-07">
          Truy cập Profile để cập nhật giao diện mới
        </p>
        <Button
          variant="main"
          onClick={() => {
            setVisible({
              ...visible,
              avatarSuccess: false
            })
          }}
          className="text-[14px] rounded-[8px] px-[16px] py-[6px] w-fit h-[30px] mx-auto mt-[32px]"
        >
          {t("common.close")}
        </Button>
      </DialogContent>
    </Dialog>
  )
}
