export const IconGift = ({
  className,
  fill
}: {
  className?: string
  fill?: string
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      className={className}
    >
      <path
        d="M29 11.0007H26.605C26.846 10.5243 26.9808 10.0012 27 9.46767C26.9653 8.52443 26.5615 7.63254 25.8757 6.98408C25.1898 6.33562 24.2767 5.98247 23.333 6.00067C22.2089 6.04909 21.1191 6.40183 20.1799 7.02124C19.2406 7.64066 18.4872 8.5035 18 9.51767C17.518 8.50634 16.7703 7.64495 15.8369 7.0255C14.9034 6.40606 13.8191 6.05183 12.7 6.00067C12.662 6.00067 12.624 6.00067 12.586 6.00067C11.6558 6.00216 10.7624 6.36425 10.0936 7.01081C9.42489 7.65738 9.03287 8.53805 9 9.46767V9.53367C9.01951 10.0442 9.14878 10.5446 9.379 11.0007H7C6.73478 11.0007 6.48043 11.106 6.29289 11.2936C6.10536 11.4811 6 11.7355 6 12.0007V15.0007C6 15.2659 6.10536 15.5202 6.29289 15.7078C6.48043 15.8953 6.73478 16.0007 7 16.0007H29C29.2652 16.0007 29.5196 15.8953 29.7071 15.7078C29.8946 15.5202 30 15.2659 30 15.0007V12.0007C30 11.7355 29.8946 11.4811 29.7071 11.2936C29.5196 11.106 29.2652 11.0007 29 11.0007ZM23.364 8.00067C23.7724 7.99007 24.1695 8.13614 24.4737 8.40891C24.7779 8.68168 24.9662 9.06049 25 9.46767C24.9858 9.87699 24.8141 10.265 24.5206 10.5507C24.2271 10.8363 23.8346 10.9976 23.425 11.0007H19.478C20.1 9.74167 21.337 8.00067 23.364 8.00067ZM11 9.50067C11.0256 9.09146 11.2073 8.7077 11.5077 8.42857C11.808 8.14945 12.204 7.99628 12.614 8.00067C14.683 8.00867 15.895 9.73167 16.524 11.0007H12.624C12.2134 11.0038 11.817 10.8504 11.5154 10.5718C11.2137 10.2932 11.0295 9.91023 11 9.50067Z"
        fill={fill ? fill : "url(#paint0_linear_12722_57509)"}
      />
      <path
        d="M20 18.0006V30.0006H25C25.7956 30.0006 26.5587 29.6845 27.1213 29.1219C27.6839 28.5593 28 27.7963 28 27.0006V18.0006H20Z"
        fill={fill ? fill : "url(#paint1_linear_12722_57509)"}
      />
      <path
        d="M16 18.0006H8V27.0006C8 27.7963 8.31607 28.5593 8.87868 29.1219C9.44129 29.6845 10.2044 30.0006 11 30.0006H16V18.0006Z"
        fill={fill ? fill : "url(#paint2_linear_12722_57509)"}
      />
      <defs>
        <linearGradient
          id="paint0_linear_12722_57509"
          x1="33.6923"
          y1="5.37496"
          x2="5.23446"
          y2="8.14991"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#B6B4F8" />
          <stop offset="0.457045" stopColor="#AEAAF8" />
          <stop offset="1" stopColor="#A39FD1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_12722_57509"
          x1="29.2308"
          y1="17.2506"
          x2="19.6616"
          y2="17.5098"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#B6B4F8" />
          <stop offset="0.457045" stopColor="#AEAAF8" />
          <stop offset="1" stopColor="#A39FD1" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_12722_57509"
          x1="17.2308"
          y1="17.2506"
          x2="7.66165"
          y2="17.5098"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#B6B4F8" />
          <stop offset="0.457045" stopColor="#AEAAF8" />
          <stop offset="1" stopColor="#A39FD1" />
        </linearGradient>
      </defs>
    </svg>
  )
}
