import { IconClose } from "@/app/components/icon-tsx/IconClose"
import { Button } from "@/app/components/ui/button"
import { Dialog, DialogContent } from "@/app/components/ui/dialog"
import { IGift } from "@/app/interfaces/IReward"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import { FC } from "react"
import { ENUM_NORMAL_TAB } from ".."
import { PACKAGE_TYPE, TAB_ITEM_PACKAGE_TYPE } from "@/app/configs"

interface ModalResultSuccessProps {
  open: boolean
  onOpenChange: () => void
  dataSelected?: IGift
}

export const ModalResultSuccess: FC<ModalResultSuccessProps> = ({
  open,
  onOpenChange,
  dataSelected
}) => {
  const { t } = useTranslation()
  const router = useRouter()

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:rounded-[24px] gap-0 rounded-[16px] max-w-[90vw] md:max-w-[461px] p-[24px] border-0 focus-visible:outline-none dark:bg-bg-02">
        <div className="w-full flex">
          <div
            className="w-[32px] h-[32px] rounded-[8px] flex items-center justify-center ml-auto mr-0 bg-bg-button dark:bg-bg-button/10 cursor-pointer"
            onClick={onOpenChange}
          >
            <IconClose />
          </div>
        </div>

        <div
          className={`w-[96px] h-[96px] rounded-[96px] mx-auto flex items-center justify-center mb-[24px] bg-bg-green`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            className="mx-auto my-[20px]"
          >
            <path
              d="M28.1438 5.14404C15.5495 5.14404 5.28662 15.4069 5.28662 28.0012C5.28662 40.5955 15.5495 50.8583 28.1438 50.8583C40.7381 50.8583 51.0009 40.5955 51.0009 28.0012C51.0009 15.4069 40.7381 5.14404 28.1438 5.14404ZM39.0695 22.744L26.1095 35.704C25.7895 36.024 25.3552 36.2069 24.8981 36.2069C24.4409 36.2069 24.0066 36.024 23.6866 35.704L17.2181 29.2355C16.5552 28.5726 16.5552 27.4755 17.2181 26.8126C17.8809 26.1498 18.9781 26.1498 19.6409 26.8126L24.8981 32.0698L36.6466 20.3212C37.3095 19.6583 38.4066 19.6583 39.0695 20.3212C39.7323 20.984 39.7323 22.0583 39.0695 22.744Z"
              fill="#27908C"
            />
          </svg>
        </div>

        <h1 className="text-center Nunito-700 text-[18px] pb-[8px]">
          {t("store.payment_success_title")}
        </h1>
        <h2 className="text-center text-neutral dark:text-neutral-08 Nunito-500 pb-[24px]">
          {t("reward.buy_package_success_desc")}
        </h2>

        <Button
          variant="main"
          className="px-[16px] py-[8px] h-[35px] rounded-[12px] w-fit text-[14px] Nunito-600 mx-auto"
          onClick={() => {
            router.push({
              pathname: router.pathname,
              query: {
                tab: ENUM_NORMAL_TAB.PACKAGE,
                item:
                  dataSelected?.item_type === PACKAGE_TYPE.AVATAR ||
                  dataSelected?.item_type === PACKAGE_TYPE.STICKER
                    ? TAB_ITEM_PACKAGE_TYPE.OTHER
                    : dataSelected?.item_type === PACKAGE_TYPE.VOUCHER
                    ? TAB_ITEM_PACKAGE_TYPE.VOUCHER
                    : dataSelected?.item_type === PACKAGE_TYPE.GIFTCODE
                    ? TAB_ITEM_PACKAGE_TYPE.GIFTCODE
                    : ""
              }
            })
            onOpenChange()
          }}
        >
          {t("reward.go_to_package")}
        </Button>
      </DialogContent>
    </Dialog>
  )
}
