import {
  createSlugNameDetail,
  removeVietnameseTones,
  timeFormatter
} from "@/app/helpers/function"
import { useRouter } from "next/router"
import React from "react"

export const ItemDetailStatistical = ({ detail, activeTab }: any) => {
  const router = useRouter()
  const onNavigateToDetail = () => {
    router.push(
      `/detail/${activeTab}/${createSlugNameDetail(detail?.title, detail?.id)}`
    )
  }
  return (
    <div className="flex flex-col justify-between w-full overflow-auto md:flex-row">
      <div
        className="flex items-start gap-[16px] cursor-pointer"
        onClick={onNavigateToDetail}
      >
        <img
          className="rounded-[12px] w-[120px] aspect-video object-cover"
          src={detail?.thumbnail ?? ""}
          alt={detail?.title}
          title={detail?.title}
        />
        <div className="flex flex-col flex-1 max-w-full lg:max-w-1/2">
          <p className="text-xs Nunito-400 text-neutral dark:tex-neutral-08">
            {timeFormatter(detail?.created_at * 1000)}
          </p>
          <p className="text-bg-01 Nunito-600 text-base mt-[8px] dark:text-main-white">
            {detail?.title}
          </p>
        </div>
      </div>
      <div
        className={`flex items-center gap-[24px] justify-evenly md:justify-start  mt-[16px] pb-[24px] md:mt-0 md:pb-0 border-b border-b/10 md:border-0 dark:border-neutral-03`}
      >
        {activeTab === "news" ? (
          <>
            <div className="flex flex-col items-center justify-center gap-[8px]">
              <p className="text-xs text-neutral Nunito-400 dark:text-neutral-08">
                Lượt thích
              </p>
              <p className="text-base text-bg-01 Nunito-700 dark:text-main-white">
                {detail?.total_like}
              </p>
            </div>
            <div className="h-[30px] w-[0.6px] bg-black/10 " />
            <div className="flex flex-col items-center justify-center gap-[8px]">
              <p className="text-xs text-neutral Nunito-400 dark:text-neutral-08">
                Lượt bình luận
              </p>
              <p className="text-base text-bg-01 Nunito-700 dark:text-main-white">
                {detail?.total_comment}
              </p>
            </div>
          </>
        ) : (
          <div className="flex flex-col items-center justify-center gap-[8px]">
            <p className="text-xs text-neutral Nunito-400 dark:text-neutral-08">
              Lượt tham gia
            </p>
            <p className="text-base text-bg-01 Nunito-700 dark:text-main-white">
              {detail?.total_joined}
            </p>
          </div>
        )}
      </div>
    </div>
  )
}
