import { DropdownMenuItem } from "@/app/components/ui/dropdown-menu"
import { useTranslation } from "next-i18next"
import React from "react"
import { useFormContext } from "react-hook-form"

export const ProfileSortFilter = () => {
  const { t } = useTranslation()
  const { setValue, watch } = useFormContext()
  const watchSort = watch("sort", -1)
  return (
    <>
      <DropdownMenuItem
        className={`${
          watchSort === -1
            ? "bg-bg-disable dark:bg-[#F1F1FC1A] text-main-01"
            : ""
        } focus:bg-bg-disable dark:focus:bg-[#F1F1FC1A] focus:text-main-01 bold-600--700 text-sm text-left flex px-4 py-3 rounded-[8px]`}
        onClick={() => setValue("sort", -1)}
      >
        {t("group.latest")}
      </DropdownMenuItem>
      <DropdownMenuItem
        className={`${
          watchSort === 1
            ? "bg-bg-disable dark:bg-[#F1F1FC1A] text-main-01"
            : ""
        }focus:bg-bg-disable dark:focus:bg-[#F1F1FC1A] focus:text-main-01 bold-600--700 text-sm text-left flex px-4 py-3 rounded-[8px]`}
        onClick={() => setValue("sort", 1)}
      >
        {t("group.oldest")}
      </DropdownMenuItem>
    </>
  )
}
