import React from "react"
interface Iprops {
  className?: string
}

export const IconSelectDown = ({ className }: Iprops) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      className={className}
    >
      <path
        d="M10.5179 4.59753e-07L3.48207 1.52206e-07C1.93849 8.47342e-08 0.976748 1.67443 1.75451 3.00774L5.27244 9.03847C6.0442 10.3615 7.9558 10.3615 8.72756 9.03847L12.2455 3.00774C13.0232 1.67443 12.0615 5.27225e-07 10.5179 4.59753e-07Z"
        fill="#878693"
      />
    </svg>
  )
}
