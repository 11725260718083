import { IconGift } from "@/app/components/icon-tsx/IconGift"
import { IconGold } from "@/app/components/icon-tsx/IconGold"
import { Button } from "@/app/components/ui/button"
import { MISSION_TYPE, QUERY_KEY } from "@/app/configs"
import { AppContext } from "@/app/context"
import { RewardContext } from "@/app/context/RewardContext"
import useGAEventsTracker from "@/app/hooks/useGAEventsTracker"
import RewardService from "@/app/services/services/RewardService"
import { useQueryClient } from "@tanstack/react-query"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import { FC, useContext, useEffect, useState } from "react"

interface IDailyLogin {
  dataReward: any
}

export const DailyLogin: FC<IDailyLogin> = ({ dataReward }) => {
  const { t } = useTranslation()
  const router = useRouter()
  const queryClient = useQueryClient()
  const { userInfo, themeApp } = useContext(AppContext)
  const [listDaily, setListDaily] = useState<any>({})
  const { getPoint, pointReward } = useContext(RewardContext)
  const GAEventsTracker = useGAEventsTracker("Reward")

  const handleClick = () => {
    if (
      Object.keys(listDaily?.my_task?.tasks || []).includes(listDaily.today)
    ) {
      return
    } else {
      GAEventsTracker("Mission_Checkin", "Checkin")
      GAEventsTracker("Mission_Do_success", "Checkin")
      RewardService.confirmTask(listDaily?.id)
        .then(() => {
          getPoint()
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY.LIST_MISSION],
            type: "active",
            exact: true
          })

          queryClient.refetchQueries({
            queryKey: [
              QUERY_KEY.POINT_HISTORY,
              userInfo?.userid,
              {
                type: "earn",
                source: "",
                start_date: "",
                end_date: ""
              }
            ]
          })
        })
        .catch((e) => {
          GAEventsTracker("Mission_Do_failed", "Checkin")
        })
    }
  }

  useEffect(() => {
    setListDaily(
      dataReward?.find((item: any) => {
        return item?.type === MISSION_TYPE.CHECKIN
      })
    )
  }, [dataReward])

  return (
    <div className="w-full md:px-[24px] mt-[16px] md:mt-0">
      <div className="flex items-center">
        <div className="flex gap-[4px] items-center">
          <IconGold className="w-[20px] h-[20px]" />
          <p className="w-full text-[14px] Nunito-700 flex-1">
            {pointReward.toLocaleString()}
          </p>
        </div>
        <div className="bg-black/10 w-[1px] h-[16px] mx-[8px]"></div>
        <p
          className="cursor-pointer text-main-01 Nunito-400"
          onClick={() => {
            router.push(
              `/user/${
                userInfo?.alias ? userInfo?.alias : userInfo?.userid
              }?tab=transaction-manager&gold=earn-all`
            )
          }}
        >
          {t("reward.get_point_history")}
        </p>

        <div className="ml-auto mr-0">
          {!Object.keys(listDaily?.my_task?.tasks || []).includes(
            listDaily?.today
          ) ? (
            <Button
              onClick={() => handleClick()}
              variant="main"
              className="text-[12px] Nunito-700 rounded-[8px] px-[16px] h-[28px] w-[93px]"
            >
              {t("reward.daily_login")}
            </Button>
          ) : (
            <Button
              variant="none"
              className="text-[12px] Nunito-500 cursor-default rounded-[8px] px-[16px] h-[28px] bg-button dark:bg-button/10 text-main-disable min-w-[108px]"
            >
              {t("reward.daily_login_success")}
            </Button>
          )}
        </div>
      </div>

      <div className="sd:mt-[60px] mt-[24px] md:mt-[32px]">
        <div className="mb-[16px] px-[24px]">
          <div className="bg-bg-button dark:bg-white/10 rounded-[10px] flex-1 h-[6px] relative">
            <div
              className="bg-main-disable-02 dark:bg-main-01 rounded-[10px] h-full"
              style={{
                width: `min(${
                  (100 * (listDaily?.my_task?.totalTaskSuccess - 1)) /
                  (listDaily?.sub_tasks?.length - 1)
                }%, 100%)`
              }}
            ></div>

            {listDaily?.sub_tasks?.map((item: any, index: number) => {
              return (
                <div
                  key={index + 10}
                  className="absolute w-[6px] h-[6px] bg-main-01 dark:bg-main-disable-02 top-0 rounded-[8px]"
                  style={{
                    left: `calc(${
                      (100 * index) / (listDaily?.sub_tasks?.length - 1)
                    }% - 3px)`
                  }}
                ></div>
              )
            })}
          </div>
        </div>
        <div className="flex justify-between w-full">
          {listDaily?.sub_tasks?.map((item: any, index: number) => {
            return (
              <div
                key={index}
                className="flex flex-col items-center gap-[16px]"
              >
                <p className="text-neural-03 text-[14px] Nunito-700">
                  {t("reward.day_login", { day: item?.day })}
                </p>

                {listDaily?.my_task?.totalTaskSuccess >= item?.day ? (
                  <div
                    className={`w-[36px] h-[36px] rounded-[36px] flex items-center justify-center bg-bg-green`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="56"
                      height="56"
                      viewBox="0 0 56 56"
                      fill="none"
                      className="w-[20px] h-[20px]"
                    >
                      <path
                        d="M28.1438 5.14404C15.5495 5.14404 5.28662 15.4069 5.28662 28.0012C5.28662 40.5955 15.5495 50.8583 28.1438 50.8583C40.7381 50.8583 51.0009 40.5955 51.0009 28.0012C51.0009 15.4069 40.7381 5.14404 28.1438 5.14404ZM39.0695 22.744L26.1095 35.704C25.7895 36.024 25.3552 36.2069 24.8981 36.2069C24.4409 36.2069 24.0066 36.024 23.6866 35.704L17.2181 29.2355C16.5552 28.5726 16.5552 27.4755 17.2181 26.8126C17.8809 26.1498 18.9781 26.1498 19.6409 26.8126L24.8981 32.0698L36.6466 20.3212C37.3095 19.6583 38.4066 19.6583 39.0695 20.3212C39.7323 20.984 39.7323 22.0583 39.0695 22.744Z"
                        fill="#27908C"
                      />
                    </svg>
                  </div>
                ) : (
                  <IconGift fill={themeApp === "dark" ? "#7673E5" : ""} />
                )}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
