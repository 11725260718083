import { DataEmpty } from "@/app/components/data-empty"
import { IconSearch } from "@/app/components/icon-tsx"
import { IconClose } from "@/app/components/icon-tsx/IconClose"
import { Button } from "@/app/components/ui/button"
import { Dialog, DialogContent, DialogHeader } from "@/app/components/ui/dialog"
import { toast } from "@/app/components/ui/use-toast"
import { OPEN_MODAL, QUERY_KEY } from "@/app/configs"
import { AppContext, MobileContext } from "@/app/context"
import { removeVietnameseTones } from "@/app/helpers/function"
import { IGame } from "@/app/interfaces"
import { removeNullKeyObject } from "@/app/lib/utils"
import { useQueryGetHotTopic } from "@/app/services/query-fn"
import { useQueryGetListGame } from "@/app/services/query-fn/game"
import { useMutationUpdateInterestTopic } from "@/app/services/query-fn/user/setting-mutation"
import { ScrollArea } from "@radix-ui/react-scroll-area"
import { useQueryClient } from "@tanstack/react-query"
import { useTranslation } from "next-i18next"
import Image from "next/image"
import React, { useContext, useMemo, useState } from "react"
import { animated, useSpring } from "@react-spring/web"

interface Props {}

const LIMIT_HASHTAG = 5
export const ModalEditHashtag: React.FC<Props> = () => {
  const { t } = useTranslation()
  const { userInfo, themeApp, openModal, setOpenModal } = useContext(AppContext)
  const { isMobile } = useContext(MobileContext)
  const [searchValue, setSearchValue] = useState<string>("")
  const queryClient = useQueryClient()
  const { data: otherHashtag } = useQueryGetHotTopic()
  const { data: games } = useQueryGetListGame({
    category: "",
    type: "",
    limit: 100,
    page: 1
  })
  const { mutate } = useMutationUpdateInterestTopic()

  const addHashtagList = (withType: "hashtag" | "game", value: string) => {
    const listTopicHashtag =
      withType === "hashtag"
        ? Array.from(new Set([...userInfo?.topic_interest, value]))
        : userInfo?.topic_interest

    const listTopicGameId =
      withType === "game"
        ? Array.from(new Set([...userInfo?.games_interest, value]))
        : userInfo?.games_interest

    return [listTopicHashtag, listTopicGameId]
  }

  const removeHashtagList = (withType: "hashtag" | "game", value: string) => {
    const listTopicHashtag =
      withType === "hashtag"
        ? userInfo?.topic_interest?.filter((item: any) => item !== value)
        : userInfo?.topic_interest

    const listTopicGameId =
      withType === "game"
        ? userInfo?.games_interest?.filter((item: any) => item !== value)
        : userInfo?.games_interest
    return [listTopicHashtag, listTopicGameId]
  }

  const handleClose = () => {
    if (
      userInfo?.topic_interest?.length + userInfo?.games_interest?.length >
      0
    ) {
      setOpenModal(null)
    } else
      toast({
        variant: "error",
        title: t("news.require_favorite_hashtag")
      })
  }

  const updateMyTag = (
    withType: "hashtag" | "game",
    hashtag: string,
    type: "add" | "remove"
  ) => {
    //  Lấy list hashtag sau khi thêm hoặc xóa theo withtype
    const hash =
      type === "add"
        ? addHashtagList(withType, hashtag)
        : removeHashtagList(withType, hashtag)

    // bắt buộc chọn ít nhất 1 sở thích
    if (hash[0]?.length + hash[1]?.length === 0)
      toast({
        variant: "error",
        title: t("news.require_favorite_hashtag")
      })
    else {
      if (
        userInfo?.topic_interest?.length + userInfo?.games_interest?.length ===
          LIMIT_HASHTAG &&
        type === "add"
      ) {
        toast({
          variant: "error",
          title: t("news.limit_favorite_hashtag")
        })
      } else {
        mutate(
          removeNullKeyObject({
            topic_interest: hash[0].toString().replace(" ", ""),
            games_interest: hash[1].toString().replace(" ", "")
          }),
          {
            onSuccess(data, variables, context) {
              queryClient.invalidateQueries({
                queryKey: [QUERY_KEY.PROFILE_INFO],
                refetchType: "active"
              })
            },
            onError(error: any, variables, context) {
              toast({
                variant: "error",
                title: error?.response?.data?.message || error?.data?.message
              })
            }
          }
        )
      }
    }
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e?.target?.value)
  }

  const memoGames = useMemo(() => {
    if (!!searchValue?.trim()) {
      return games
        ? games.filter((item: IGame) =>
            removeVietnameseTones(item?.game_name?.toLowerCase())?.includes(
              removeVietnameseTones(searchValue?.toLowerCase())
            )
          )
        : []
    }
    return games
  }, [userInfo, searchValue, games])

  const memoHashtags = useMemo(() => {
    if (!!searchValue?.trim()) {
      return otherHashtag.filter((item: any) =>
        item?.hashtag?.replaceAll("#", "")?.includes(searchValue)
      )
    }
    return otherHashtag
  }, [userInfo, searchValue, otherHashtag])

  const onAddItem = (withType: "hashtag" | "game", hashtag: string) => {
    if (
      userInfo?.topic_interest?.indexOf(hashtag) !== -1 ||
      userInfo?.games_interest?.indexOf(hashtag) !== -1
    ) {
      updateMyTag(withType, hashtag, "remove")
    } else updateMyTag(withType, hashtag, "add")
  }

  return (
    <Dialog
      open={openModal === OPEN_MODAL.EDIT_HASHTAG}
      onOpenChange={handleClose}
    >
      <DialogContent className="sm:rounded-[16px] rounded-[16px] w-[90vw] md:w-[700px] p-[12px] md:p-[24px] dark:bg-bg-02 max-w-[90vw] md:max-w-[700px] overflow-auto max-h-[95vh] dark:border-0 gap-4 md:gap-6">
        <DialogHeader className="flex flex-row items-center justify-between w-full">
          <p className="Nunito-700 text-[16px] text-bg-01 dark:text-main-white">
            {t("profile.edit_hashtag")}
          </p>
        </DialogHeader>
        <div className="w-full space-y-[8px] md:space-y-[12px]">
          <div className="flex items-center justify-between border-b-[1px] dark:border-0 gap-4 py-3 md:max-w-[700px]">
            <IconSearch
              fill={themeApp === "dark" ? "#fff" : "#100D28"}
              className="w-[16px] h-[16px]"
            />
            <input
              type="text"
              value={searchValue}
              className="w-full text-sm border-0 outline-none dark:bg-transparent"
              placeholder={t("profile.find_hashtag")}
              onChange={onChange}
            />
          </div>

          <SelectedHashtag games={games ?? []} updateMyTag={updateMyTag} />
        </div>

        <div className="w-full space-y-[8px]">
          <p className="text-base text-black Nunito-700 dark:text-main-white">
            Game
          </p>
          <div className="w-full">
            {memoGames?.length ? (
              <ScrollArea className="max-h-[80px] md:max-h-[112px] overflow-auto flex items-center flex-wrap gap-2 md:gap-[12px]">
                {memoGames?.map((item: IGame) => (
                  <Button
                    key={item?.id}
                    className={`flex items-center justify-center p-2 text-base w-fit h-[36px] md:h-[48px] text-bg-01 Nunito-700 gap-[8px] text-start border dark:text-main-white ${
                      userInfo?.games_interest?.indexOf(item?.id) === -1
                        ? "border-black/10 dark:border-neutral-05"
                        : "border-main-01"
                    } rounded-[12px]`}
                    variant="none"
                    onClick={() => onAddItem("game", item?.id)}
                  >
                    <Image
                      src={item?.icon ?? ""}
                      width={isMobile ? 24 : 32}
                      height={isMobile ? 24 : 32}
                      alt={item?.game_name ?? ""}
                      className="rounded-[8px]"
                    />
                    <p className="text-xs md:text-sm Nunito-600 text-bg-01 dark:text-main-white">
                      {item?.game_name}
                    </p>
                  </Button>
                ))}
              </ScrollArea>
            ) : (
              <div className="w-full flex items-center justify-center h-[200px]">
                <DataEmpty title={t("profile.no_data")} />
              </div>
            )}
          </div>
        </div>

        <div className="w-full space-y-[8px]">
          <p className="text-base text-black Nunito-700 dark:text-main-white">
            {t("profile.interest")}
          </p>
          <div className="w-full">
            {memoHashtags?.length ? (
              <ScrollArea className="max-h-[80px] md:max-h-[112px] overflow-auto flex items-center flex-wrap gap-2 md:gap-[12px]">
                {memoHashtags?.map((item: any) => (
                  <Button
                    key={item?.hashtag}
                    className={`flex items-center justify-center px-4 py-2 text-xs md:text-base w-fit h-[36px] md:h-[48px] text-bg-01 Nunito-600 text-start border dark:text-main-white ${
                      userInfo?.topic_interest?.indexOf(item?.hashtag) === -1
                        ? "border-black/10 dark:border-neutral-05"
                        : "border-main-01"
                    }  rounded-[12px]`}
                    variant="none"
                    onClick={() => onAddItem("hashtag", item?.hashtag)}
                  >
                    #{item?.hashtag?.replaceAll("#", "")}
                  </Button>
                ))}
              </ScrollArea>
            ) : (
              <div className="w-full flex items-center justify-center h-[200px]">
                <DataEmpty title={t("profile.no_data")} />
              </div>
            )}
          </div>
        </div>
        <Button
          variant="main"
          disabled={
            userInfo?.topic_interest?.length +
              userInfo?.games_interest?.length ===
            0
          }
          onClick={handleClose}
          className="mx-auto text-sm md:text-base w-fit Nunito-700 rounded-[16px] h-[35px] md:h-[46px] px-[16px] md:px-[24px]"
        >
          {LIMIT_HASHTAG -
            userInfo?.topic_interest?.length -
            userInfo?.games_interest?.length ===
          0 ? (
            <>{t("common.update_btn")}</>
          ) : (
            <>
              {t("common.select_more")}
              <span>
                &nbsp;(
                {LIMIT_HASHTAG -
                  userInfo?.topic_interest?.length -
                  userInfo?.games_interest?.length}
                )
              </span>
            </>
          )}
        </Button>
      </DialogContent>
    </Dialog>
  )
}

const SelectedHashtag = ({
  games,
  updateMyTag
}: {
  games: IGame[]
  updateMyTag: (
    withType: "hashtag" | "game",
    hashtag: string,
    type: "add" | "remove"
  ) => void
}) => {
  const { userInfo } = useContext(AppContext)
  const { isMobile } = useContext(MobileContext)
  const fade = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    delay: 50
  })
  return (
    <>
      {(!!userInfo?.topic_interest?.length ||
        !!userInfo?.games_interest?.length) && (
        <div className="flex flex-wrap items-center w-full max-w-full gap-2 md:gap-[12px]">
          {React.Children.toArray(
            userInfo?.topic_interest?.map((item: any) => (
              <animated.div
                style={fade}
                className="bg-transparent text-main-01 Nunito-700 border border-main-01 text-xs md:text-sm px-3 py-[12px] flex flex-row items-center gap-[8px] h-[36px] md:h-[48px] rounded-[8px] md:rounded-[12px] max-w-full md:max-w-[33%]"
              >
                <p className="line-clamp-1">#{item?.replaceAll("#", "")}</p>
                <div
                  className="cursor-pointer w-[16px] h-[16px]"
                  onClick={() => updateMyTag("hashtag", item, "remove")}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.8287 5.1716L5.17188 10.8285M10.8287 10.8284L5.17188 5.17157"
                      stroke="#DAD9FE"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </animated.div>
            ))
          )}
          {React.Children.toArray(
            userInfo?.games_interest?.map((id: string) => {
              const exactGame = games?.find((game: IGame) => game.id === id)
              if (!!exactGame) {
                return (
                  <animated.div
                    style={fade}
                    className="bg-transparent text-main-01 Nunito-700 border p-[6px] md:p-[8px] border-main-01 text-xs md:text-sm flex flex-row items-center h-[36px] md:h-[48px] gap-[8px] rounded-[8px] md:rounded-[12px] max-w-full md:max-w-[33%]"
                  >
                    <Image
                      src={exactGame?.icon ?? ""}
                      alt={exactGame.icon}
                      width={isMobile ? 24 : 32}
                      height={isMobile ? 24 : 32}
                      className="rounded-[8px]"
                    />
                    <p className="line-clamp-1">{exactGame?.game_name}</p>
                    <div
                      className="cursor-pointer w-[16px] h-[16px]"
                      onClick={() => updateMyTag("game", id, "remove")}
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.8287 5.1716L5.17188 10.8285M10.8287 10.8284L5.17188 5.17157"
                          stroke="#DAD9FE"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </animated.div>
                )
              }
            })
          )}
        </div>
      )}
    </>
  )
}
