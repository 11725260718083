import React, { useState, useRef, useEffect } from "react"
import { useDropzone } from "react-dropzone"

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop
} from "react-image-crop"

import "react-image-crop/dist/ReactCrop.css"
import { canvasPreview } from "./CanvasPreview"
import { useTranslation } from "next-i18next"
import { useDebounceEffect } from "@/app/hooks/useDebounceEffect"
import { IconClose } from "../icon-tsx/IconClose"
import { ZoomSlider } from "./ZoomSlider"

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  )
}

export const MIN_VALUE = 1
export const MAX_VALUE = 3
const STEP = 0.1

export default function CropImage({
  size,
  setImageSmall,
  setImage,
  image
}: any) {
  const [imgSrc, setImgSrc] = useState("")
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const fileInput: any = useRef<HTMLDivElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [aspect, setAspect] = useState<number | undefined>(1 / 1)
  const { t } = useTranslation()
  const [file, setFile] = useState<any>()
  const [files, setFiles] = useState<(File & { preview: string })[]>([])

  const [minVal, setMinVal] = useState(MIN_VALUE)

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setImage(e.target.files[0])
      setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader()
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      )
      reader.readAsDataURL(e.target.files[0])
    }
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, size))
    } else if (imgRef.current) {
      const { width, height } = imgRef.current
      setAspect(1 / 1)
      setCrop(centerAspectCrop(width, height, 1 / 1))
    }
  }
  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        const file = await canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale
        )
        setFile(file)
      }
    },
    100,
    [completedCrop, scale, rotate]
  )

  const onDrop = (acceptedFiles: any) => {
    setFiles(
      acceptedFiles.map((file: any) => {
        setImage(file)
        setCrop(undefined) // Makes crop preview update between images.
        setImgSrc(URL.createObjectURL(file))
      })
    )
  }

  const onDropImage = () => {
    setImgSrc("")
    setImage(null)
    setImageSmall(null)
  }
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [".jpeg", ".png"]
    },
    maxFiles: 1,
    multiple: false,
    onDrop
  })

  useEffect(() => {
    if (file) {
      setImageSmall(new File([file], image?.name, { type: file?.type }))
    }
  }, [file])

  const onChangeScaleImage = (value: number) => {
    if (imgRef && imgRef?.current) {
      imgRef.current.style.transform = `scale(${value})`
    }
    setScale(value)
  }
  return (
    <div>
      <div {...getRootProps()}>
        {!imgSrc && (
          <div className="rounded-[24px] bg-[rgba(0, 0, 0, 0.06)] border border-dashed dark:border-0 border-[#BDBDBD] w-full h-[220px] cursor-pointer flex items-center justify-center bg-bg-disable rounded-[24px] dark:bg-[#DAD9FE1A] opacity-100 active:opacity-60 outline-none">
            <input
              ref={fileInput}
              autoFocus={false}
              type="file"
              accept="image/*"
              onChange={onSelectFile}
              onClick={(event: any) => {
                event.target.value = ""
              }}
              className="hidden"
              multiple={false}
              {...getInputProps()}
            />

            <div
              className="flex items-center justify-center gap-3 "
              onClick={() => fileInput?.current && fileInput?.current?.click()}
            >
              <div className="w-[48px] h-[48px] flex items-center justify-center bg-main-disable-02 rounded-[16px]">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.75 6C2.75 4.20507 4.20507 2.75 6 2.75H14C14.4142 2.75 14.75 2.41421 14.75 2C14.75 1.58579 14.4142 1.25 14 1.25H6C3.37665 1.25 1.25 3.37665 1.25 6V18C1.25 20.6234 3.37665 22.75 6 22.75H18C20.6234 22.75 22.75 20.6234 22.75 18V10C22.75 9.58579 22.4142 9.25 22 9.25C21.5858 9.25 21.25 9.58579 21.25 10V13.5091C21.2049 13.473 21.158 13.4387 21.1094 13.4063L18.7285 11.819C17.142 10.7613 15.0295 10.9705 13.6812 12.3188L10.3188 15.6812C8.9705 17.0295 6.85802 17.2387 5.27153 16.181L2.75 14.5V6ZM19.75 2C19.75 1.58579 19.4142 1.25 19 1.25C18.5858 1.25 18.25 1.58579 18.25 2V4.25H16C15.5858 4.25 15.25 4.58579 15.25 5C15.25 5.41421 15.5858 5.75 16 5.75H18.25V8C18.25 8.41421 18.5858 8.75 19 8.75C19.4142 8.75 19.75 8.41421 19.75 8V5.75H22C22.4142 5.75 22.75 5.41421 22.75 5C22.75 4.58579 22.4142 4.25 22 4.25H19.75V2ZM8.5 11C9.88071 11 11 9.88071 11 8.5C11 7.11929 9.88071 6 8.5 6C7.11929 6 6 7.11929 6 8.5C6 9.88071 7.11929 11 8.5 11Z"
                    fill="#7673E6"
                  />
                </svg>
              </div>
              <div className="flex flex-col justify-center">
                <p className="text-base text-bg-01 Nunito-700 dark:text-white">
                  Tải ảnh lên
                </p>
                <p className="text-base text-bg-01 Nunito-400 dark:text-white">
                  {t("news.drag_drop")}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
      {!!imgSrc && (
        <>
          <div className="w-full bg-black p-3 relative flex justify-center rounded-[24px]">
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={size}
              className="center"
              circularCrop
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                className="object-cover bg-black"
                style={{
                  transform: `scale(${1}) rotate(${0}deg)`,
                  aspectRatio: 1,
                  width: "100%"
                }}
                onLoad={onImageLoad}
              />
            </ReactCrop>
            <div
              className="w-[28px] h-[28px] flex justify-center items-center cursor-pointer z-10 absolute top-[8px] right-[8px] bg-black rounded-[28px]"
              onClick={onDropImage}
            >
              <IconClose />
            </div>
          </div>
          <div>
            <ZoomSlider
              min={MIN_VALUE}
              max={MAX_VALUE}
              step={STEP}
              minVal={minVal}
              setMinVal={setMinVal}
              onChangeScaleImage={onChangeScaleImage}
            />
          </div>
        </>
      )}
      {!!completedCrop && (
        <div className="hidden">
          <canvas
            ref={previewCanvasRef}
            style={{
              objectFit: "contain",
              width: completedCrop.width,
              height: completedCrop.height
            }}
          />
        </div>
      )}
    </div>
  )
}
