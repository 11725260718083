import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import React, { useContext } from "react"

import { Dialog, DialogContent } from "@/app/components/ui/dialog"

import { Button } from "@/app/components/ui/button"
import { isClient } from "@/app/configs"
import { jwtDecode } from "jwt-decode"
import { doNothing } from "@/app/helpers"
import { AppContext } from "@/app/context"

interface RequireVerifiedModalProps {
  open: boolean
}
export const RequireVerifiedModal: React.FC<RequireVerifiedModalProps> = ({
  open
}) => {
  const router = useRouter()
  const { t } = useTranslation()
  const { userInfo } = useContext(AppContext)

  const onNavigate = () => {
    if (isClient) {
      const token = localStorage.getItem("token")
      if (token) {
        const decodeToken: any = jwtDecode(token)

        const _callback = !!userInfo?.email
          ? "/user/verify_email"
          : "/user/update_email"

        router.push(
          `https://id.gamota.com/authorize?access_token=${decodeToken?.data?.id}&callback=${_callback}&redirect=${window.location.href}?update=1`
        )
      }
    }
  }

  return (
    <Dialog open={open} onOpenChange={doNothing}>
      <DialogContent className="sm:rounded-[24px] max-w-[95vw] md:max-w-fit p-[24px] border-0 focus-visible:outline-none w-fit rounded-[24px] min-w-[300px] dark:bg-bg-02">
        <div className="flex flex-col items-center w-full">
          <p className="text-base text-center align-middle w-fit Nunito-700 text-bg-01 dark:text-white">
            {t("profile.account_not_verified")}
          </p>
          <div className="flex items-center justify-center w-full mt-[24px] gap-[16px]">
            <Button
              variant="main"
              className="text-sm text-white Nunito-700 rounded-[8px] outline-none focus:outline-none"
              onClick={onNavigate}
            >
              {t("reward.verify_now")}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
