import React from "react"

export const IconHelpCenter = ({ className }: { className?: string }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3327 9.00033C17.3327 13.6027 13.6017 17.3337 8.99935 17.3337C4.39698 17.3337 0.666016 13.6027 0.666016 9.00033C0.666016 4.39795 4.39698 0.666992 8.99935 0.666992C13.6017 0.666992 17.3327 4.39795 17.3327 9.00033ZM8.99935 4.75033C8.03285 4.75033 7.24935 5.53383 7.24935 6.50033C7.24935 6.91454 6.91356 7.25033 6.49935 7.25033C6.08514 7.25033 5.74935 6.91454 5.74935 6.50033C5.74935 4.7054 7.20442 3.25033 8.99935 3.25033C10.7943 3.25033 12.2493 4.7054 12.2493 6.50033C12.2493 7.84738 11.43 9.00133 10.2654 9.49427C10.0933 9.56713 9.9491 9.66872 9.85714 9.77263C9.76899 9.87224 9.74935 9.94759 9.74935 10.0003V10.667C9.74935 11.0812 9.41356 11.417 8.99935 11.417C8.58514 11.417 8.24935 11.0812 8.24935 10.667V10.0003C8.24935 8.9978 9.06422 8.37389 9.68074 8.11293C10.3101 7.84652 10.7493 7.22386 10.7493 6.50033C10.7493 5.53383 9.96585 4.75033 8.99935 4.75033ZM8.99935 14.0003C9.45959 14.0003 9.83268 13.6272 9.83268 13.167C9.83268 12.7068 9.45959 12.3337 8.99935 12.3337C8.53911 12.3337 8.16602 12.7068 8.16602 13.167C8.16602 13.6272 8.53911 14.0003 8.99935 14.0003Z"
        fill="#7673E6"
      />
    </svg>
  )
}
