import React from "react"

export const IconCopyLink = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1211 9.87866L9.87845 14.1213"
        stroke="#7673E6"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M10.332 7.00003L11.9987 5.33336C13.8396 3.49239 16.8244 3.49239 18.6654 5.33335V5.33335C20.5063 7.17431 20.5063 10.1591 18.6654 12.0001L16.9987 13.6667"
        stroke="#7673E6"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M13.668 17L12.0013 18.6666C10.1604 20.5076 7.17558 20.5076 5.33462 18.6666V18.6666C3.49365 16.8257 3.49365 13.8409 5.33462 11.9999L7.00129 10.3333"
        stroke="#7673E6"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}
