export const IconSelectCreatePost = ({ className }: { className?: string }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 6C3 4.20507 4.45507 2.75 6.25 2.75H12.25C12.6642 2.75 13 2.41421 13 2C13 1.58579 12.6642 1.25 12.25 1.25H6.25C3.62665 1.25 1.5 3.37665 1.5 6V18C1.5 20.6234 3.62665 22.75 6.25 22.75H18.25C20.8734 22.75 23 20.6234 23 18V12C23 11.5858 22.6642 11.25 22.25 11.25C21.8358 11.25 21.5 11.5858 21.5 12V18C21.5 19.7949 20.0449 21.25 18.25 21.25H6.25C4.45507 21.25 3 19.7949 3 18V6ZM16.669 2.67708C17.5718 1.77431 19.0355 1.77431 19.9383 2.67708L21.5729 4.31171C22.4757 5.21449 22.4757 6.67818 21.5729 7.58096L20.1236 9.03028C20.0098 8.97394 19.8901 8.91302 19.766 8.84767C18.9306 8.40786 17.9392 7.79057 17.1993 7.05069C16.4595 6.31082 15.8422 5.31945 15.4024 4.48403C15.337 4.35989 15.2761 4.24018 15.2197 4.12639L16.669 2.67708ZM16.1387 8.11135C17.0142 8.98687 18.1277 9.67594 19.0095 10.1444L13.31 15.8438C12.9564 16.1975 12.4975 16.4269 12.0023 16.4977L8.56963 16.9881C7.8068 17.097 7.15295 16.4432 7.26193 15.6804L7.75231 12.2477C7.82305 11.7525 8.05248 11.2936 8.40616 10.94L14.1056 5.24053C14.5741 6.12234 15.2631 7.23582 16.1387 8.11135Z"
        fill="#7673E6"
      />
    </svg>
  )
}
