import { IconSearch } from "@/app/components/icon-tsx"
import { IconClose } from "@/app/components/icon-tsx/IconClose"
import { Dialog, DialogContent, DialogHeader } from "@/app/components/ui/dialog"
import { Tabs, TabsList, TabsTrigger } from "@/app/components/ui/tabs"
import { AppContext } from "@/app/context"
import { ScrollArea } from "@radix-ui/react-scroll-area"
import { useTranslation } from "next-i18next"
import React, { useContext, useState } from "react"
import { ListFriend } from "./ListFriend"
import { ListProposeFriend } from "./ListProposeFriend"
import { ListRequestFriend } from "./ListRequestFriend"
import { useDebounceEffect } from "@/app/hooks/useDebounceEffect"

interface ModalFollowFriendProps {
  open: boolean
  onClose: () => void
}

const TIME_DEBOUNCE = 700
export const ModalFriend: React.FC<ModalFollowFriendProps> = ({
  open,
  onClose
}) => {
  const { t } = useTranslation()
  const { themeApp } = useContext(AppContext)
  const [tabActive, setTabActive] = useState<"friend" | "propose" | "request">(
    "friend"
  )
  const [keyword, setKeyword] = useState<string>("")
  const [tempSearchValue, setTempSearchValue] = useState<string>("")

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTempSearchValue(e?.target?.value)
  }

  useDebounceEffect(() => setKeyword(tempSearchValue?.trim()), TIME_DEBOUNCE, [
    tempSearchValue
  ])

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="rounded-[16px] sm:rounded-[16px] w-[90vw] md:max-w-[470px] p-0 dark:bg-bg-02 outline-none border-0">
        <DialogHeader className="w-full flex items-center justify-between flex-row border-b-[1px] border-black/10 py-[24px] px-[32px] h-[72px]">
          <p className="Nunito-700 text-[16px] text-bg-01 dark:text-white">
            {t("profile.list_friend")}
          </p>
          <div className="cursor-pointer" onClick={onClose}>
            <IconClose />
          </div>
        </DialogHeader>
        <div className="h-[54px] w-full">
          <Tabs
            value={tabActive}
            className="w-full mt-0 relative px-[24px]"
            style={{
              zIndex: 2
            }}
          >
            <TabsList className="grid w-full grid-cols-3 px-0 border-b-[1px] border-black/10 dark:border-neutral-05">
              <TabsTrigger
                value="friend"
                className="flex items-center gap-[10px] dark:text-main-01 dark:data-[state=active]:text-main-01 dark:data-[state=active]:border-main-01 dark:data-[state=inactive]:text-neutral-05"
                onClick={() => setTabActive("friend")}
              >
                {t("profile.friend")}
              </TabsTrigger>
              <TabsTrigger
                value="propose"
                className="flex items-center gap-[10px] dark:text-main-01 dark:data-[state=active]:text-main-01 dark:data-[state=active]:border-main-01 dark:data-[state=inactive]:text-neutral-05"
                onClick={() => setTabActive("propose")}
              >
                {t("profile.refer")}
              </TabsTrigger>
              <TabsTrigger
                value="request"
                className="flex items-center gap-[10px] dark:text-main-01 dark:data-[state=active]:text-main-01 dark:data-[state=active]:border-main-01 dark:data-[state=inactive]:text-neutral-05"
                onClick={() => setTabActive("request")}
              >
                {t("profile.friend_request")}
              </TabsTrigger>
            </TabsList>
          </Tabs>
        </div>

        <div className="w-full px-[24px] pb-[32px]">
          {tabActive === "friend" && (
            <div className="flex justify-between gap-4 py-3 mb-4 border-0 h-[44px]">
              <IconSearch fill={themeApp === "dark" ? "#fff" : "#100D28"} />
              <input
                type="text"
                value={tempSearchValue}
                className="flex-1 text-sm border-0 outline-none dark:text-white dark:bg-transparent"
                placeholder={t("profile.search")}
                onChange={onChange}
              />
            </div>
          )}
          <ScrollArea
            className={`${
              tabActive === "friend"
                ? "h-[188px] sm:h-[268px] ld:h-[290px]"
                : "h-[248px] sm:h-[328px] ld:h-[350px]"
            } overflow-auto space-y-[24px]`}
          >
            {tabActive === "friend" && (
              <ListFriend
                active={tabActive === "friend" && !!open}
                searchValue={keyword}
              />
            )}
            {tabActive === "propose" && (
              <ListProposeFriend active={tabActive === "propose" && !!open} />
            )}
            {tabActive === "request" && (
              <ListRequestFriend active={tabActive === "request" && !!open} />
            )}
          </ScrollArea>
        </div>
      </DialogContent>
    </Dialog>
  )
}
