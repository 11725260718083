import { isClient } from "@/app/configs"
import { useQueryGetListGameBanner } from "@/app/services/query-fn/game"
import Link from "next/link"
import React from "react"
import { Autoplay, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

export const RewardBanner = () => {
  const { data: listRewardBanner } = useQueryGetListGameBanner({
    type: "reward"
  })
  const token = isClient ? window.localStorage.getItem("token") : null

  const convertLink = (url: string) => {
    const link =
      url?.includes(process.env.NEXT_PUBLIC_EVENT_DOMAIN || "") && token
        ? `${url}?token=${token}`
        : url
    return link
  }
  return (
    <div>
      <Swiper
        modules={[Autoplay, Pagination]}
        slidesPerView={1}
        pagination={{ clickable: true }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false
        }}
      >
        {listRewardBanner?.length
          ? listRewardBanner.map((item: any, i: number) => {
              return (
                <SwiperSlide key={i}>
                  <div className="relative">
                    <Link href={convertLink(item.click_url)} target="_blank">
                      <div className="rounded-[16px] overflow-hidden relative">
                        <img
                          src={item?.banner}
                          alt={item.game_id}
                          className="w-[100%] aspect-[3/1] object-cover"
                        />
                      </div>
                    </Link>
                  </div>
                </SwiperSlide>
              )
            })
          : null}
      </Swiper>
    </div>
  )
}
