import { DataEmpty } from "@/app/components/data-empty"
import { IconEvent } from "@/app/components/icon-tsx/IconEvent"
import { IconNotiIcon } from "@/app/components/icon-tsx/IconNotiIcon"
import { IconStore } from "@/app/components/icon-tsx/IconStore"
import { Skeleton } from "@/app/components/ui/skeleton"
import {
  DATE_FORMAT_FULL,
  NOTIFICATION_TYPE,
  QUERY_KEY,
  REPLY_LIMIT_SIZE
} from "@/app/configs"
import { INotice } from "@/app/interfaces/INotice"
import moment from "moment"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import React, { FC, useContext } from "react"
import { useInView } from "react-intersection-observer"
import { ENUM_NORMAL_TAB } from "../reward-center"
import { ActionFriendNotification } from "./ActionFriendNotification"
import { AppContext } from "@/app/context"
import useGAEventsTracker from "@/app/hooks/useGAEventsTracker"
import {
  createSlugNameDetail,
  removeVietnameseTones
} from "@/app/helpers/function"
import { useQueryClient } from "@tanstack/react-query"

interface ListNotificationProps {
  className?: string
  setNoticeId: (params: any) => void
  listNoti: any
  isFetching: boolean
  fetchNextPage: any
  setIsShowNotices?: (params: any) => void
  handleRefetch: () => void
}

export const ListNotification: FC<ListNotificationProps> = ({
  className,
  setNoticeId,
  listNoti,
  isFetching,
  fetchNextPage,
  setIsShowNotices,
  handleRefetch
}) => {
  const router = useRouter()
  const { ref, inView } = useInView()
  const { t } = useTranslation()
  const { userInfo } = useContext(AppContext)
  const GAEventsTrackerNoti = useGAEventsTracker("Notification")
  const queryClient = useQueryClient()
  React.useEffect(() => {
    if (inView) {
      fetchNextPage()
    }
  }, [fetchNextPage, inView])

  const handleNoti = (noti: INotice) => {
    GAEventsTrackerNoti("Notification_view", noti?.type)

    if (!noti.is_read) {
      setNoticeId(noti.id)

      setTimeout(() => {
        handleRefetch()
      }, 1000)
    }

    if (
      [
        NOTIFICATION_TYPE.COMMENT,
        NOTIFICATION_TYPE.LIKE,
        NOTIFICATION_TYPE.REPLY_COMMENT
      ].includes(noti?.type)
    ) {
      router.push(
        `/detail/news/${createSlugNameDetail(
          noti?.content_id?.post_title,
          noti?.content_id?.post_id
        )}`
      )
    }

    if ([NOTIFICATION_TYPE.TICKET].includes(noti?.type)) {
      router.push({
        pathname: "/help-center/detail",
        query: {
          faqId: noti.content_id?.ticket_id,
          view: "ticket"
        }
      })
    }

    if ([NOTIFICATION_TYPE.PAYMENT_RESULT].includes(noti?.type)) {
      router.push(
        `/user/${
          userInfo?.alias ? userInfo?.alias : userInfo?.userid
        }?tab=transaction-manager`
      )
    }

    if ([NOTIFICATION_TYPE.TOPUP_GAME].includes(noti?.type)) {
      router.push(
        `/user/${
          userInfo?.alias ? userInfo?.alias : userInfo?.userid
        }?tab=transaction-manager&type=topup`
      )
    }

    if ([NOTIFICATION_TYPE.CREATE_ORDER_RESULT].includes(noti?.type)) {
      router.push(
        `/user/${
          userInfo?.alias ? userInfo?.alias : userInfo?.userid
        }?tab=transaction-manager&type=merchandise`
      )
    }

    if (
      [NOTIFICATION_TYPE.SHOP, NOTIFICATION_TYPE?.EVENT].includes(noti?.type)
    ) {
      if (noti?.content_id?.item?.item_type === "sticker") {
        router.push(`/reward-center?item=other&tab=${ENUM_NORMAL_TAB.PACKAGE}`)
      } else if (noti?.content_id?.item?.item_type === "voucher") {
        router.push(
          `/reward-center?tab=${ENUM_NORMAL_TAB.PACKAGE}&item=voucher`
        )
      } else {
        router.push(`/reward-center?tab=${ENUM_NORMAL_TAB.PACKAGE}&item=`)
      }
    }
    if ([NOTIFICATION_TYPE.SPIN].includes(noti?.type)) {
      if (noti?.content_id?.item.type === "point") {
        router.push(
          `/user/${userInfo?.userid}?tab=transaction-manager&gold=earn-all`
        )
      } else if (noti?.content_id?.item.type === "giftcode") {
        router.push(
          `/reward-center?item=giftcode&tab=${ENUM_NORMAL_TAB.PACKAGE}`
        )
      } else if (noti?.content_id?.item.type === "gift_voucher") {
        router.push(
          `/reward-center?item=voucher&tab=${ENUM_NORMAL_TAB.PACKAGE}`
        )
      } else if (noti?.content_id?.item.type === "appota_card") {
        router.push(
          `/reward-center?item=appota_card&tab=${ENUM_NORMAL_TAB.PACKAGE}`
        )
      } else {
        router.push(`/reward-center?item=other&tab=${ENUM_NORMAL_TAB.PACKAGE}`)
      }
    }
    if ([NOTIFICATION_TYPE.TASK].includes(noti?.type)) {
      if (noti?.content_id?.return === "point") {
        router.push(
          `/user/${userInfo?.userid}?tab=transaction-manager&gold=earn-all`
        )
      } else if (noti?.content_id?.return === "spin_game") {
        router.push(`/reward-center/lucky-spin`)
        // router.push(
        //   `/event/landing/${process.env.NEXT_PUBLIC_ID_LANDING_EVENT}`
        // )
      }
      //fix redirect
      else if (
        noti?.content_id?.return === "giftcode" ||
        noti?.content_id?.return === "gc_task"
      ) {
        router.push(
          `/reward-center?item=giftcode&tab=${ENUM_NORMAL_TAB.PACKAGE}`
        )
      } else if (noti?.content_id?.return === "gift_voucher") {
        router.push(
          `/reward-center?item=voucher&tab=${ENUM_NORMAL_TAB.PACKAGE}`
        )
      } else {
        router.push(`/reward-center?item=other&tab=${ENUM_NORMAL_TAB.PACKAGE}`)
      }
    }

    if (
      [
        NOTIFICATION_TYPE?.PURCHASE,
        NOTIFICATION_TYPE?.TOPUP_GAME_EVENT
      ].includes(noti?.type)
    ) {
      router.push(`/event/landing/${process.env.NEXT_PUBLIC_ID_LANDING_EVENT}`)
    }

    if ([NOTIFICATION_TYPE?.EVENT_MERCHANDISE].includes(noti?.type)) {
      if (
        noti?.content_id?.return_type === "giftcode" ||
        noti?.content_id?.return_type === "gc"
      ) {
        router.push(
          `/reward-center?item=giftcode&tab=${ENUM_NORMAL_TAB.PACKAGE}`
        )
      } else if (
        noti?.content_id?.return_type === "gift_voucher" ||
        noti?.content_id?.return_type === "voucher"
      ) {
        router.push(
          `/reward-center?item=voucher&tab=${ENUM_NORMAL_TAB.PACKAGE}`
        )
      }
    }

    if ([NOTIFICATION_TYPE?.SEND_GIFT_FRIEND].includes(noti?.type)) {
      router.push(`/reward-center?tab=${ENUM_NORMAL_TAB.PACKAGE}`)
    }

    if ([NOTIFICATION_TYPE?.REG_NOTI_EVEMT].includes(noti?.type)) {
      router.push(
        `/detail/event/${createSlugNameDetail(
          noti?.content_id?.event?.title,
          noti?.content_id?.event?.event_id
        )}`
      )

      // router.push(`/detail/event/${noti.content_id?.event?.event_id}`)
    }

    if (noti?.type === NOTIFICATION_TYPE.ACCEPT_FRIEND) {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.PROFILE_INFO],
        refetchType: "active"
      })
    }

    if ([NOTIFICATION_TYPE.BAG_EXPIRED].includes(noti?.type)) {
      router.push(`/reward-center?item=voucher&tab=${ENUM_NORMAL_TAB.PACKAGE}`)
    }
  }

  const NotiItem = ({ noti }: { noti: INotice }) => {
    return (
      <div
        key={noti.id}
        className="py-[12px] flex gap-[8px] cursor-pointer active:opacity-60 dark:border-b-[1px] dark:border-neutral-03 dark:last:border-0"
        onClick={() => {
          setIsShowNotices && setIsShowNotices(false)
          handleNoti(noti)
        }}
      >
        <div className="flex items-center h-fit gap-[4px]">
          <div
            className={`w-[4px] h-[4px] rounded-[4px] ${
              !!noti.is_read ? "bg-transparent" : "bg-main-01"
            } `}
          ></div>

          <div className="w-[32px] h-[32px] rounded-[12px] bg-button dark:bg-button/10 flex items-center justify-center">
            {[
              NOTIFICATION_TYPE.PAYMENT_RESULT,
              NOTIFICATION_TYPE.TOPUP_GAME,
              NOTIFICATION_TYPE.CREATE_ORDER_RESULT,
              NOTIFICATION_TYPE.SHOP,
              NOTIFICATION_TYPE?.EVENT,
              NOTIFICATION_TYPE.SPIN,
              NOTIFICATION_TYPE.TASK,
              NOTIFICATION_TYPE?.PURCHASE,
              NOTIFICATION_TYPE?.TOPUP_GAME_EVENT
            ].includes(noti?.type) ? (
              <IconStore className="w-[16px] h-[16px]" fill="#7673E5" />
            ) : noti.type === NOTIFICATION_TYPE.REG_NOTI_EVEMT ? (
              <IconEvent className="w-[16px] h-[16px]" />
            ) : (
              <IconNotiIcon className="w-[16px] h-[16px]" />
            )}
          </div>
        </div>
        <div>
          <div
            className={`Nunito-600 text-[14px] pb-[12px] [&_strong]:text-main-01`}
            dangerouslySetInnerHTML={{
              __html: noti.message || ""
            }}
          ></div>
          <p className="text-[12px] text-main-01">
            {moment(noti.created_at * 1000).format(DATE_FORMAT_FULL)}
          </p>
          {noti.type === NOTIFICATION_TYPE.REQUEST_FRIEND && (
            <ActionFriendNotification noti={noti} handleNoti={handleNoti} />
          )}
        </div>
      </div>
    )
  }

  return (
    <div className={`${className} overflow-auto`}>
      {!!listNoti && !!listNoti?.pages?.flat()?.length && (
        <div className="w-full">
          {React.Children.toArray(
            listNoti?.pages
              ?.flat()
              ?.map((item: INotice, index: number) => <NotiItem noti={item} />)
          )}
        </div>
      )}

      {isFetching &&
        Array(3)
          .fill("")
          .map((index: number) => {
            return (
              <div key={index} className="py-[12px] flex gap-[8px]">
                <Skeleton className="w-[4px] mt-[14px] h-[4px] rounded-[12px] bg-[#EAEAEA] dark:bg-white/10" />
                <Skeleton className="w-[32px] h-[32px] rounded-[12px] bg-[#EAEAEA] dark:bg-white/10" />
                <div className="flex-1">
                  <Skeleton className="w-full h-[12px] rounded-[6px] bg-[#EAEAEA] dark:bg-white/10 mb-2" />
                  <Skeleton className="w-full h-[12px] rounded-[6px] bg-[#EAEAEA] dark:bg-white/10 mb-4" />

                  <Skeleton className="w-[100px] h-[12px] rounded-[8px] bg-[#EAEAEA] dark:bg-white/10" />
                </div>
              </div>
            )
          })}

      {!listNoti?.pages?.flat()?.length && !isFetching && (
        <div className="h-[300px] flex items-center justify-center">
          <DataEmpty title={t("profile.no_data")} />
        </div>
      )}
      {Number(listNoti?.pages?.at(-1)?.length) >= REPLY_LIMIT_SIZE && (
        <button ref={ref} className="opacity-0">
          Load more
        </button>
      )}
    </div>
  )
}
