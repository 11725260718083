import { IconClose } from "@/app/components/icon-tsx/IconClose"
import { IconCopied } from "@/app/components/icon-tsx/IconCopied"
import { IconNonCopy } from "@/app/components/icon-tsx/IconNonCopy"
import { Button } from "@/app/components/ui/button"
import { Dialog, DialogContent } from "@/app/components/ui/dialog"
import { useToast } from "@/app/components/ui/use-toast"
import { DATE_FORMAT, GIFTCODE_TYPE, QUERY_KEY } from "@/app/configs"
import { PackageContext } from "@/app/context/PackageContext"
import useGAEventsTracker from "@/app/hooks/useGAEventsTracker"
import { useMutationUseItem } from "@/app/services/query-fn/reward-center/package/package-mutation"
import { useQueryClient } from "@tanstack/react-query"
import moment from "moment"
import { useTranslation } from "next-i18next"
import { FC, useContext, useEffect, useState } from "react"
import ImagePackage from "public/img/reward/package_default.png"

interface ModalViewStickerProps {
  open: boolean
  handleClose: () => void
}

export const ModalViewSticker: FC<ModalViewStickerProps> = ({
  open,
  handleClose
}) => {
  const { visible, setVisible, dataSelected, setDataSelected } =
    useContext(PackageContext)
  const { t } = useTranslation()

  const onClose = () => {
    setVisible({
      ...visible,
      viewSticker: false
    })

    setDataSelected()
  }

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className="max-w-[520px] rounded-[24px] p-[24px] border-0 shadow-[0px_4px_30px_0px_rgba(0,0,0,0.06)] sm:rounded-[24px] gap-0 space-y-[24px] xs:w-[90vw]">
        <div className="flex items-center justify-between">
          <p className="text-[16px] Nunito-700">{t("reward.detail")}</p>
          <IconClose className="cursor-pointer" onClick={() => onClose()} />
        </div>
        <div className="flex gap-[24px]">
          <img
            src={dataSelected?.image ? dataSelected?.image : ImagePackage.src}
            className="w-[152px] rounded-[8px] aspect-square h-fit object-cover"
          />
          <div className="flex-1 space-y-[16px]">
            <div>
              <p className="text-[14px] text-neutral-05 dark:text-neutral-07">
                {t("reward.exchange_package")}
              </p>
              <p className="text-[16px] Nunito-500">{dataSelected?.name}</p>
            </div>

            <div className="border border-dashed border-black/10 dark:border-neutral-07 text-neutral-03 dark:text-neutral-08 rounded-[12px] p-[12px] text-[14px]">
              {t("reward.sticker_guide_desc")}
            </div>
          </div>
        </div>

        <div className="flex gap-[16px] justify-center items-center">
          <Button
            variant="main"
            className="rounded-[16px] px-[24px] py-[12px] w-fit h-[46px]"
            onClick={() => {
              window.open(
                "https://playee.vn/help-center/detail?faqId=649ebf2da6fc952f1342566f"
              )
            }}
          >
            {t("reward.sticker_guide")}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
