import CropImage from "@/app/components/crop-image"
import { IconClose } from "@/app/components/icon-tsx/IconClose"
import { Button } from "@/app/components/ui/button"
import { Dialog, DialogContent, DialogHeader } from "@/app/components/ui/dialog"
import { QUERY_KEY } from "@/app/configs"
import { useMutationUploadAvatar } from "@/app/services/query-fn/user/setting-mutation"
import { useQueryClient } from "@tanstack/react-query"
import { useTranslation } from "next-i18next"
import { useState } from "react"

export const ModalUploadImage = (props: {
  open: boolean
  onClose: () => void
}) => {
  const { open, onClose } = props
  const { t } = useTranslation()
  const [image, setImage] = useState<any>()
  const [imageSmall, setImageSmall] = useState<any>()
  const queryClient = useQueryClient()
  const { mutate: mutateUploadAvatar, isPending } = useMutationUploadAvatar()
  const onUpload = () => {
    const formData = new FormData()

    formData.append("avatar", image)
    formData.append("small_avatar", imageSmall)
    mutateUploadAvatar(formData, {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.PROFILE_INFO],
          refetchType: "active"
        })
        onClose()
      }
    })
  }
  const onCancelUpload = () => {
    setImage(undefined)
    setImageSmall(undefined)
    onClose()
  }

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="rounded-[16px] sm:rounded-[16px] w-[90vw] md:w-[528px] p-[24px] outline-none">
        <DialogHeader className="flex flex-row items-center justify-between w-full">
          <p className="Nunito-700 text-[16px] text-bg-01 dark:text-white">
            {t("profile.avatar")}
          </p>
          <div className="cursor-pointer" onClick={onClose}>
            <IconClose />
          </div>
        </DialogHeader>
        <div className="outline-none">
          <CropImage
            size={1}
            setImageSmall={setImageSmall}
            setImage={setImage}
            image={image}
          />
          {image && (
            <div className="mt-[32px] flex justify-end gap-4">
              <Button
                variant="none"
                className="bg-bg-button rounded-[16px] text-bg-01 Nunito-700"
                onClick={onCancelUpload}
              >
                {t("common.cancel")}
              </Button>
              <Button
                variant={"none"}
                disabled={isPending}
                onClick={onUpload}
                className="bg-main-01 rounded-[16px] text-white Nunito-700"
              >
                {t("profile.save")}
              </Button>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
}
