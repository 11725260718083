export const IconFilter = ({ className }: { className?: string }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
    >
      <path
        d="M5.8335 17.5L5.8335 13.3333M5.8335 4.16667V2.5M5.8335 4.16667C7.21421 4.16667 8.3335 5.28595 8.3335 6.66667V8.33333C8.3335 9.71404 7.21421 10.8333 5.8335 10.8333C4.45278 10.8333 3.3335 9.71404 3.3335 8.33333V6.66667C3.3335 5.28595 4.45278 4.16667 5.8335 4.16667ZM14.1668 6.66667V2.5M14.1668 17.5V15.8333M14.1668 15.8333C15.5475 15.8333 16.6668 14.714 16.6668 13.3333V11.6667C16.6668 10.286 15.5475 9.16667 14.1668 9.16667C12.7861 9.16667 11.6668 10.286 11.6668 11.6667V13.3333C11.6668 14.714 12.7861 15.8333 14.1668 15.8333Z"
        stroke="#7673E6"
        strokeLinecap="round"
      />
    </svg>
  )
}
