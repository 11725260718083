export const LoadingOutside = ({
  className,
  style
}: {
  className?: string
  style: any
}) => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        d="M0.75 9.5C0.75 14.3325 4.66751 18.25 9.5 18.25C14.3325 18.25 18.25 14.3325 18.25 9.5C18.25 4.66751 14.3325 0.75 9.5 0.75"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
