import { IconBack } from "@/app/components/icon-tsx/IconBack"
import { IconCopied } from "@/app/components/icon-tsx/IconCopied"
import { IconNonCopy } from "@/app/components/icon-tsx/IconNonCopy"
import { DATE_FORMAT, DATE_FORMAT_FULL } from "@/app/configs"
import useGAEventsTracker from "@/app/hooks/useGAEventsTracker"
import StoreService from "@/app/services/services/StoreServices"
import { IPaymentMethod, IProductItem } from "@interfaces/IStore"
import { AppContext } from "app/context/AppContext"
import moment from "moment"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import { FC, useContext, useEffect, useState } from "react"
import * as XLSX from "xlsx"

interface OrderDetailProps {
  dataSelected: any
  setDataSelected: (param: any) => void
}

interface CardItemProps {
  card: any
  key: number
}

const CardItem: FC<CardItemProps> = ({ card, key }) => {
  const { t } = useTranslation()
  const { themeApp } = useContext(AppContext)
  const [copyCode, setCopyCode] = useState(false)
  const [copySerial, setCopySerial] = useState(false)

  const CopyCode = (code: string) => {
    if (!copyCode) {
      navigator.clipboard.writeText(code)
      setCopyCode(true)
    }
  }

  const CopySerial = (serial: string) => {
    if (!copySerial) {
      navigator.clipboard.writeText(serial)
      setCopySerial(true)
    }
  }

  useEffect(() => {
    if (copySerial) {
      setTimeout(() => {
        setCopySerial(false)
      }, 2000)
    }
    if (copyCode) {
      setTimeout(() => {
        setCopyCode(false)
      }, 2000)
    }
    return () => {
      clearTimeout(
        setTimeout(() => {
          setCopyCode(false)
        }, 2000)
      )
      clearTimeout(
        setTimeout(() => {
          setCopySerial(false)
        }, 2000)
      )
    }
  }, [copyCode, copySerial])

  return (
    <div
      key={key}
      className="border-b-[1px] border-[#0000001A] dark:border-white border-dashed pt-[17px] first:pt-0 last:border-0"
    >
      {card.card_code && (
        <div className="flex justify-between mb-2">
          <p className="text-[14px] text-neutral-05 dark:text-neutral-08">
            {t("store.card_code")}
          </p>
          <span
            onClick={() => CopyCode(card.card_code)}
            className={`text-[#757575] text-[14px] flex items-center gap-2 cursor-pointer`}
          >
            <span
              className={`border-r border-[#e5e5e5] pr-[5px] text-bg-01 dark:text-main-white`}
            >
              {card.card_code}
            </span>
            {!card.is_used && (
              <>{copyCode ? <IconCopied /> : <IconNonCopy />}</>
            )}
          </span>
        </div>
      )}
      {card.card_serial && (
        <div className="flex justify-between mb-2">
          <p className="text-[14px] text-neutral-05 dark:text-neutral-08">
            {t("store.serial_code")}
          </p>
          <span
            onClick={() => CopySerial(card.card_serial)}
            className={`text-neutral-05 dark:text-neutral-08 cursor-pointer
            text-[14px] flex items-center gap-2`}
          >
            <span
              className={`border-r border-[#e5e5e5] pr-[5px] dark:text-main-white text-bg-01`}
            >
              {card.card_serial}
            </span>
            {!card.is_used && (
              <>{copySerial ? <IconCopied /> : <IconNonCopy />}</>
            )}
          </span>
        </div>
      )}

      {card.expired && !card?.is_used && (
        <div className="flex justify-between mb-[16px]">
          <p className="text-[14px] text-neutral-05 dark:text-neutral-08">
            {t("store.expired_date")}
          </p>
          <span className="cursor-pointer text-[14px] flex items-center gap-2 dark:text-main-white text-bg-01">
            {moment(card?.expired).format(DATE_FORMAT)}
          </span>
        </div>
      )}
      {!!card?.is_used && (
        <div className="flex justify-between mb-[16px]">
          <p className="text-[14px]">{t("reward.status")}</p>
          <button className="Nunito-600 text-xs text-bg-01 dark:text-white text-[14px] flex items-center gap-2 bg-[#F5F5F5] dark:bg-white/10 px-[10px] py-[2px] rounded-[8px]">
            {t("reward.used")}
          </button>
        </div>
      )}
    </div>
  )
}

export const CardDetail: FC<OrderDetailProps> = ({
  dataSelected,
  setDataSelected
}) => {
  const { t } = useTranslation()
  const router = useRouter()
  const [dataMethod, setDataMethod] = useState<IPaymentMethod>()
  const [copyTransaction, setCopyTransaction] = useState(false)
  const GAEventTracker = useGAEventsTracker("OrderHistory")
  const onCopyTransaction = () => {
    if (!copyTransaction) {
      navigator.clipboard.writeText(dataSelected?.transaction_id)
      setCopyTransaction(true)
    }
  }

  useEffect(() => {
    if (copyTransaction) {
      setTimeout(() => {
        setCopyTransaction(false)
      }, 2000)
    }
    return () => {
      clearTimeout(
        setTimeout(() => {
          setCopyTransaction(false)
        }, 2000)
      )
    }
  }, [copyTransaction])

  const onBack = () => {
    router.back()
    setDataSelected("")
  }

  const getDataMethod = async (item: IProductItem) => {
    try {
      const { data }: any = await StoreService.getListItemDetail(item.item_code)

      setDataMethod(
        data?.data?.methods.find(
          (item: any) => item.method === dataSelected.payment_method
        )
      )
    } catch (e) {}
  }

  const exportFile = () => {
    if (!!dataSelected?.items?.length) {
      const result = dataSelected?.items?.map(
        ({ is_used, ...rest }: any) => rest
      )
      GAEventTracker("Export_log")
      const worksheet = XLSX.utils.json_to_sheet(result)
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
      //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
      //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workbook, "export_card.xlsx")
    }
  }

  useEffect(() => {
    if (dataSelected?.item_code) {
      getDataMethod(dataSelected)
    }
  }, [dataSelected])

  const renderOrderDetail = () => {
    return (
      <div className="pb-[24px] before:mb-4 border-b border-dashed">
        <div className="flex items-center mb-4 gap-[12px] xs:justify-between">
          <div className="bg-bg-button dark:bg-white/10 px-[10px] rounded-[8px] flex h-[26px] items-center gap-[10px]">
            <p className="text-bg-01 dark:text-white text-[12px] Nunito-500 ">
              {t("profile.item_code_summary")}:&nbsp;
              <span className="Nunito-700">{dataSelected?.transaction_id}</span>
            </p>
            <div className="w-[1px] h-[17px] bg-neutral-08" />
            <span className="cursor-pointer" onClick={onCopyTransaction}>
              {copyTransaction ? <IconCopied /> : <IconNonCopy />}
            </span>
          </div>

          {!dataSelected?.items?.length && (
            <p className="text-white bg- text-label-02 bg-bg-button dark:bg-white/10 text-[12px] Nunito-600 px-[10px] rounded-[8px] flex h-[24px] items-center">
              {t("store.wait_export_card")}
            </p>
          )}

          {dataSelected?.items?.[0]?.is_used === true && (
            <p className="text-white bg- text-label-02 bg-bg-button dark:bg-white/10 text-[12px] Nunito-600 px-[10px] rounded-[8px] flex h-[24px] items-center">
              {t("reward.used")}
            </p>
          )}
        </div>
        <div className="flex items-center gap-[16px]">
          <img
            src={dataSelected?.item_icon}
            alt={dataSelected?.item_name}
            className="min-w-[48px] w-[48px] h-[48px] rounded-[12px] object-cover border border-black/10"
          />
          <div className="flex justify-between w-full">
            <div className="flex flex-col">
              <p className="text-[14px] Nunito-600 text-bg-01 dark:text-white">
                {dataSelected?.item_name}
              </p>
              <p className="text-[12px] Nunito-400 text-text-grey-03 dark:text-neutral-08">
                {t("store.buy_date")}{" "}
                {moment.unix(dataSelected?.created_at).format(DATE_FORMAT_FULL)}
              </p>
            </div>

            <span className="ml-auto mr-0 text-main-01 text-[16px] Nunito-600 dark:text-main-01">
              {dataSelected?.amount?.toLocaleString()} đ
            </span>
          </div>
        </div>
      </div>
    )
  }

  const renderOrderInfo = () => {
    return (
      <div className="pt-[24px] border-b border-dashed">
        <div className="rounded-[5px]">
          <div className="flex items-center justify-between">
            <p className="text-base text-bg-01 dark:text-white Nunito-700">
              {t("store.order_infor")}
            </p>
            {!!dataSelected?.items?.length && (
              <div
                className="flex items-center bg-button dark:bg-white/10 text-main-01 gap-[5px] rounded-[30px] px-[10px] py-[2px] cursor-pointer"
                onClick={exportFile}
              >
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.60156 8.27344L8.4149 10.0868L10.2282 8.27344"
                    stroke="#7673E5"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.41504 2.83398V10.0377"
                    stroke="#7673E5"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14.1668 8.62695C14.1668 11.7578 12.0418 14.2936 8.50016 14.2936C4.9585 14.2936 2.8335 11.7578 2.8335 8.62695"
                    stroke="#7673E5"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <span className="text-xs NunitoSans-600 dark:text-white">
                  Xuất file
                </span>
              </div>
            )}
          </div>

          {dataSelected?.items?.length ? (
            <div className="mt-[16px]">
              {dataSelected?.items?.map((card: any, index: number) => {
                return <CardItem card={card} key={index} />
              })}
            </div>
          ) : (
            <p className="py-[8px] text-neutral dark:text-white text-[14px]">
              {t("store.pending_text")}
            </p>
          )}
        </div>
      </div>
    )
  }

  const _amountBeforeFee =
    dataSelected?.items?.[0]?.amount * dataSelected?.item_quantity -
    (dataSelected?.items?.[0]?.amount *
      dataSelected?.item_quantity *
      dataSelected?.item_sale_off) /
      100
  const amount =
    _amountBeforeFee + (_amountBeforeFee * (dataMethod?.fee || 0)) / 100

  const renderOrderAmount = () => {
    return (
      <div className="mb-[64px] md:mb-4 py-[24px] rounded-[5px]">
        <div className="w-full md:items-right ml-auto flex flex-col gap-[12px]">
          {!!dataSelected?.items?.length && (
            <>
              <div className="flex justify-between">
                <p className="text-[14px] text-neutral-05 dark:text-neutral-08">
                  {t("store.total_amount")}
                </p>
                <p className="text-base text-bg-01 dark:text-white Nunito-600">
                  đ{" "}
                  {(
                    dataSelected?.items?.[0]?.amount *
                    dataSelected?.item_quantity
                  )?.toLocaleString()}
                </p>
              </div>
              <div className="flex justify-between">
                <p className="text-[14px] text-neutral-05 dark:text-neutral-08">
                  {t("store.fee")}
                </p>
                <p className="text-base text-bg-01 dark:text-white Nunito-600">
                  đ{" "}
                  {(
                    (_amountBeforeFee * (dataMethod?.fee || 0)) /
                    100
                  )?.toLocaleString()}
                </p>
              </div>
              <div className="flex justify-between">
                <p className="text-[14px] text-neutral-05 dark:text-neutral-08">
                  {t("store.sale_off")}
                </p>
                <p className="text-base text-bg-01 dark:text-white Nunito-600">
                  đ{" "}
                  {(
                    (dataSelected.items?.[0]?.amount *
                      dataSelected?.item_quantity *
                      dataSelected?.item_sale_off) /
                    100
                  )?.toLocaleString()}
                </p>
              </div>
            </>
          )}
          <div className="flex justify-between">
            <p className="text-[14px] text-neutral-05 dark:text-neutral-08">
              {t("store.payment_method")}
            </p>
            <p className="text-base text-bg-01 dark:text-white Nunito-600">
              {dataMethod?.name}
            </p>
          </div>
          <div className="flex justify-between">
            <p className="text-[14px] text-neutral-05 dark:text-neutral-08">
              {t("store.total")}
            </p>
            <p className="text-[18px] text-main-01 Nunito-700 ">
              đ {(amount || dataSelected?.amount)?.toLocaleString()}
            </p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="w-full m-auto">
      {renderOrderDetail()}
      {renderOrderInfo()}
      {renderOrderAmount()}
    </div>
  )
}
