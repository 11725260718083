/**
 * Cho phép chọn thời gian đăng bài, nhưng sẽ ẩn nếu về mobile,
 * Hẹn thời gian đăng bài ở mobile sẽ chuyển sang phần submit của editor
 */
import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "next-i18next"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger
} from "@/app/components/ui/dropdown-menu"
import { Calendar } from "@/app/components/ui/calendar"
import { Button } from "@/app/components/ui/button"
import { useFormContext } from "react-hook-form"
import { CustomSelectMonthYear } from "./CustomSelectMontYear"
import { END_YEAR, FROM_YEAR } from "@/app/configs"

interface TimePublicProps {}
export const TimePublic: React.FC<TimePublicProps> = ({}) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)
  const [publicNow, setPublicNow] = useState<string>("true")
  const [timeValue, setTimeValue] = React.useState<string>("00:00")
  const { setValue, watch } = useFormContext()
  const selectedPublicAt = watch("public_at", undefined)
  const startTime = watch("start_time", undefined)
  useEffect(() => {
    if (!!selectedPublicAt) {
      setPublicNow("false")
    }
  }, [selectedPublicAt])
  const handleChangeValue = (e: any) => {
    setPublicNow(e?.target?.value)
    if (e?.target?.value === "false") {
      setOpen(true)
    } else setValue("public_at", undefined)
  }
  const onSubmitSelectTime = (value: "false" | "true") => {
    setPublicNow(value)
    if (!!selectedPublicAt && value === "false") {
      setOpen(false)
    }
    if (value === "true") {
      setValue("public_at", undefined)
      setOpen(false)
    }
  }

  const handleTimeChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const time = e.target.value
    if (!selectedPublicAt) {
      setTimeValue(time)
      return
    }
    const [hours, minutes] = time.split(":").map((str) => parseInt(str, 10))
    const newSelectedDate = new Date(
      selectedPublicAt.getFullYear(),
      selectedPublicAt.getMonth(),
      selectedPublicAt.getDate(),
      hours,
      minutes
    )
    setValue("public_at", newSelectedDate)
    setTimeValue(time)
  }

  const handleDaySelect = (date: Date | undefined) => {
    if (!timeValue || !date) {
      setValue("public_at", date)
      return
    }
    const [hours, minutes] = timeValue
      .split(":")
      .map((str) => parseInt(str, 10))
    const newDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      hours,
      minutes
    )
    setValue("public_at", newDate)
  }

  const calendar = useMemo(() => {
    return (
      <Calendar
        mode="single"
        selected={selectedPublicAt}
        onSelect={handleDaySelect}
        className="p-0 rounded-md"
        showOutsideDays
        captionLayout="dropdown"
        fromYear={new Date().getFullYear()}
        toYear={END_YEAR}
        components={{
          Head: () => <CustomSelectMonthYear />
        }}
        footer={
          <div className="space-y-[24px]">
            <div className="flex items-center justify-between mt-2">
              <span className="text-base Nunito-600 text-main-01">
                {t("news.post_time")}:
              </span>
              <input
                type="time"
                value={timeValue}
                onChange={handleTimeChange}
                className="text-base"
              />
            </div>
            <div className="flex items-center justify-end gap-4">
              <Button
                variant="none"
                className="bg-bg-disable text-neutral rounded-[12px] max-h-[34px] "
                onClick={() => onSubmitSelectTime("true")}
              >
                {t("common.cancel")}
              </Button>

              <Button
                variant="none"
                disabled={
                  !selectedPublicAt ||
                  selectedPublicAt < new Date() ||
                  (!!startTime && startTime < selectedPublicAt)
                }
                className="text-white bg-disable bg-main-01 rounded-[12px] max-h-[34px] py-[6px] px-[24px]"
                onClick={() => onSubmitSelectTime("false")}
              >
                {t("news.set_time")}
              </Button>
            </div>
            {selectedPublicAt < new Date() && (
              <p className="mt-1 text-xs Nunito-400 text-main-red">
                {t("news.please_select_future_date")}
              </p>
            )}
            {!!startTime && startTime < selectedPublicAt && (
              <p className="mt-1 text-xs Nunito-400 text-main-red">
                {t("news.please_select_smaller_than_start_time")}
              </p>
            )}
          </div>
        }
      />
    )
  }, [open, publicNow, timeValue, selectedPublicAt, startTime])

  return (
    <div className="hidden md:block">
      <div className="flex items-center gap-3">
        <span className="text-bg-01 text-[18px] Nunito-700 dark:text-white">
          {t("news.public_time")}
        </span>
      </div>
      <div className="space-y-[18px] mt-6">
        <label className="flex w-full text-base cursor-pointer Nunito-400 text-neutral dark:text-white">
          <input
            type="radio"
            value="true"
            checked={publicNow === "true"}
            onChange={handleChangeValue}
            className="w-6 h-6 mr-4 border-gray-300 accent-main-01"
            aria-labelledby="mod"
            aria-describedby="mod"
          />
          {t("news.public_now")}
        </label>
        <DropdownMenu open={open}>
          <DropdownMenuTrigger asChild>
            <label
              className="relative flex w-full text-base cursor-pointer Nunito-400 text-bg-01 dark:text-white"
              onClick={() => setOpen(true)}
            >
              <input
                type="radio"
                value="false"
                checked={publicNow === "false"}
                onChange={handleChangeValue}
                className="w-6 h-6 mr-4 border-gray-300 accent-main-01"
                aria-labelledby="mod"
                aria-describedby="mod"
              />
              {!!selectedPublicAt
                ? selectedPublicAt.toLocaleString()
                : t("news.select_time")}
            </label>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-[276px]" align="start" side="top">
            {calendar}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  )
}
