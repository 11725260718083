import React from "react"

export const IconTime = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        d="M2 6.83349C2 4.62435 3.79086 2.8335 6 2.8335H10C12.2091 2.8335 14 4.62436 14 6.8335V11.1668C14 13.376 12.2091 15.1668 10 15.1668H6C3.79086 15.1668 2 13.376 2 11.1668V6.83349Z"
        stroke="#7673E6"
      />
      <path d="M2 6.5H14" stroke="#7673E6" strokeLinecap="round" />
      <path
        d="M5.33325 1.8335L5.33325 3.8335"
        stroke="#7673E6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6667 1.8335V3.8335"
        stroke="#7673E6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx="7.99992"
        cy="10.5002"
        rx="0.666667"
        ry="0.666667"
        fill="#7673E6"
      />
      <ellipse
        cx="10.6667"
        cy="10.5002"
        rx="0.666667"
        ry="0.666667"
        fill="#7673E6"
      />
      <ellipse
        cx="5.33342"
        cy="10.5002"
        rx="0.666667"
        ry="0.666667"
        fill="#7673E6"
      />
    </svg>
  )
}
