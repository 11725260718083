import { DataEmpty } from "@/app/components/data-empty"
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious
} from "@/app/components/ui/carousel"
import { useQueryGetUserMerchandiseHistory } from "@/app/services/query-fn/user/user-tab-query"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import React, { Ref, useMemo } from "react"
import { ItemMerchandiseTransaction } from "./ItemMerchandiseTransaction"

const LIMIT_CAROUSEL = 8

export const MerchandiseTransaction = ({
  prevRef,
  nextRef,
  onSelecteData
}: {
  prevRef: Ref<HTMLButtonElement>
  nextRef: Ref<HTMLButtonElement>
  onSelecteData: (item: any) => void
}) => {
  const { t } = useTranslation()
  const router = useRouter()
  const userId = useMemo(() => router?.query?.id, [router])
  const type = useMemo(() => router?.query?.type, [router])
  const { data: merchandises } = useQueryGetUserMerchandiseHistory(
    {},
    String(userId),
    String(type)
  )
  return (
    <div>
      <div className="relative flex">
        {merchandises?.pages?.flat()?.length ? (
          <Carousel
            className="w-full"
            opts={{
              align: "start"
            }}
          >
            <div className="overflow-auto">
              <CarouselContent className="-ml-0 md:-ml-[24px] min-h-[159px]">
                {merchandises?.pages
                  ?.flat()
                  ?.slice(0, LIMIT_CAROUSEL)
                  .map((item: any, i: number) => {
                    return (
                      <CarouselItem
                        className={`md:min-w-0 ${
                          i === 0 ? "basis-4/5 pl-0" : "pl-[8px]"
                        } md:basis-1/2 lg:basis-1/3 2xl:basis-1/3 md:pl-[24px] flex`}
                        key={i}
                      >
                        <ItemMerchandiseTransaction
                          item={item}
                          key={i}
                          onSelecteData={onSelecteData}
                        />
                      </CarouselItem>
                    )
                  })}
              </CarouselContent>
            </div>
            <CarouselNext ref={nextRef} className="hidden" />
            <CarouselPrevious ref={prevRef} className="hidden" />
          </Carousel>
        ) : (
          <div className="w-full min-h-[160px] flex items-center justify-center">
            <DataEmpty title={t("profile.no_data")} />
          </div>
        )}
      </div>
    </div>
  )
}
