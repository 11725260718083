import { IconSearch } from "@/app/components/icon-tsx"
import { PostEvent } from "@/app/components/post-event/PostEvent"
import { IPostCard } from "@/app/interfaces/IPost"
import { useQueryGetListEventByType } from "@/app/services/query-fn/event"
import { useTranslation } from "next-i18next"
import React, { useContext, useState } from "react"
import { useInView } from "react-intersection-observer"
import { FilterPostEvent } from "../../common/filter-post/FilterPostEvent"
import { AppContext } from "@/app/context"
import { FormProvider, useForm } from "react-hook-form"
import { removeNullKeyObject } from "@/app/lib/utils"
import { useDebounceEffect } from "@/app/hooks/useDebounceEffect"
import { DataEmpty } from "@/app/components/data-empty"

export const initialFilterEvent = {
  keyword: "",
  sort: -1
}

// eslint-disable-next-line react/display-name
export const JoinedEvent = () => {
  const { t } = useTranslation()
  const { themeApp } = useContext(AppContext)
  const [tempSearch, setTempSearch] = useState<string>("")

  const methods = useForm<typeof initialFilterEvent>({
    defaultValues: initialFilterEvent
  })

  const watchKeyword = methods.watch("keyword", "")
  const watchSort = methods.watch("sort", -1)
  const { data: listEvent, fetchNextPage } = useQueryGetListEventByType(
    removeNullKeyObject({
      type: "joined",
      keyword: watchKeyword,
      sort: watchSort
    }),
    true
  )

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTempSearch(e?.target?.value)
  }

  useDebounceEffect(() => methods.setValue("keyword", tempSearch), 300, [
    tempSearch
  ])

  const { ref, inView } = useInView()

  React.useEffect(() => {
    if (inView) {
      fetchNextPage()
    }
  }, [fetchNextPage, inView])

  return (
    <div className="mt-4 md:mt-[32px] relative z-10">
      <div className="flex items-center justify-between w-full">
        <FormProvider {...methods}>
          <div className="max-w-[320px]">
            <div className="flex items-center justify-between border-b-[1px] dark:border-neutral-04 gap-4 py-3">
              <IconSearch
                fill={themeApp === "dark" ? "#7673E5" : "#100D28"}
                className="w-[20px] h-[20px]"
              />
              <input
                type="text"
                value={tempSearch}
                className="flex-1 text-sm bg-transparent border-0 outline-none"
                placeholder={t("profile.find_event_placeholder")}
                onChange={onChange}
              />
            </div>
          </div>
          <FilterPostEvent />
        </FormProvider>
      </div>
      {!!listEvent && !!listEvent?.pages?.flat()?.length ? (
        <div className="w-full mt-[24px]">
          {React.Children.toArray(
            listEvent?.pages
              ?.flat()
              ?.map((item: IPostCard) => <PostEvent event={item} />)
          )}
        </div>
      ) : (
        <div className="w-full flex items-center justify-center h-[200px]">
          <DataEmpty title={t("profile.no_data")} />
        </div>
      )}
      <button ref={ref} className="opacity-0">
        Load more
      </button>
    </div>
  )
}
