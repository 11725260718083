import React from "react"

export const IconSetting = ({ className }: { className?: string }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.20454 2.5H10.7955C11.6741 2.5 12.3864 3.17157 12.3864 4C12.3864 4.94801 13.4007 5.55074 14.2333 5.09748L14.3179 5.05145C15.0788 4.63723 16.0518 4.88305 16.4911 5.60049L17.2866 6.89952C17.7259 7.61696 17.4652 8.53435 16.7043 8.94856C15.8712 9.40203 15.8712 10.598 16.7043 11.0514C17.4652 11.4657 17.7259 12.383 17.2866 13.1005L16.4911 14.3995C16.0518 15.117 15.0788 15.3628 14.3179 14.9486L14.2333 14.9025C13.4007 14.4493 12.3864 15.052 12.3864 16C12.3864 16.8284 11.6741 17.5 10.7955 17.5H9.20454C8.3259 17.5 7.61363 16.8284 7.61363 16C7.61363 15.052 6.5993 14.4493 5.76667 14.9025L5.6821 14.9486C4.92117 15.3628 3.94818 15.117 3.50887 14.3995L2.71341 13.1005C2.27409 12.383 2.5348 11.4657 3.29572 11.0514C4.12876 10.598 4.12876 9.40202 3.29572 8.94855C2.5348 8.53434 2.27409 7.61695 2.71341 6.89951L3.50887 5.60048C3.94819 4.88304 4.92117 4.63722 5.6821 5.05144L5.76667 5.09747C6.5993 5.55073 7.61363 4.94801 7.61363 4C7.61363 3.17157 8.3259 2.5 9.20454 2.5ZM10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.6193 7.5 7.50001 8.61929 7.50001 10C7.50001 11.3807 8.6193 12.5 10 12.5Z"
        fill="#7673E6"
      />
    </svg>
  )
}
