import { useTranslation } from "next-i18next"
import React, { useContext } from "react"
import { Button } from "../ui/button"
import {
  useMutationFollowPartner,
  useQueryGetProfileById
} from "@/app/services/query-fn"
import { AppContext } from "@/app/context"
import { useQueryClient } from "@tanstack/react-query"
import { QUERY_KEY } from "@/app/configs"

export const GroupFollowAction = ({ id }: { id: string }) => {
  const { t } = useTranslation()
  const { data, isFetching } = useQueryGetProfileById(id)

  const { userInfo } = useContext(AppContext)
  const queryClient = useQueryClient()
  const { mutate: mutateFollow } = useMutationFollowPartner()
  const onFollowPartner = () => {
    mutateFollow(String(data?.userid), {
      onSuccess(_data, variables, context) {
        queryClient.refetchQueries({
          queryKey: [QUERY_KEY.GET_PROFILE_BY_ID, id]
        })
      }
    })
  }

  if (isFetching && !data) return <></>

  if (userInfo?.authentic || !userInfo?.userid) return <></>
  if (!data?.authentic) return <></>

  return (
    <>
      {data?.followed ? (
        <Button
          variant="none"
          className="outline-none bg-transparent text-neutral-06 h-[28px] py-[6px] md:py-2 px-[16px] flex items-center justify-center border-[1px] border-neutral-06 rounded-[8px] Nunito-400 text-[14px]"
          onClick={(e) => {
            e.stopPropagation()
            onFollowPartner()
          }}
        >
          {t("profile.unfollow")}
        </Button>
      ) : (
        <Button
          variant="main"
          className="outline-none bg-main-01 h-[28px] px-[16px] py-[6px] md:py-2 flex items-center justify-center text-white rounded-[8px] Nunito-400 text-[14px]"
          onClick={(e) => {
            e.stopPropagation()
            onFollowPartner()
          }}
        >
          {t("profile.follow")}
        </Button>
      )}
    </>
  )
}
