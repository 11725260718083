import { IconFilter } from "@/app/components/icon-tsx/IconFilter"
import { IconGold } from "@/app/components/icon-tsx/IconGold"
import Pagination from "@/app/components/pagination"
import { Button } from "@/app/components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from "@/app/components/ui/dropdown-menu"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger
} from "@/app/components/ui/select"
import { AppContext } from "@/app/context"
import { RewardContext } from "@/app/context/RewardContext"
import { IBuyItemParams, IGift } from "@/app/interfaces/IReward"
import { useMutationBuyItem } from "@/app/services/query-fn/reward-center/shop/shop-mutation"
import { useQueryGetListShopItem } from "@/app/services/query-fn/reward-center/shop/shop-query"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import { useContext, useState } from "react"
import { ModalConfirmBuy } from "./ModalConfirmBuy"
import { ModalResultFail } from "./ModalResultFail"
import { ModalResultSuccess } from "./ModalResultSuccess"
import { ModalViewItem } from "./ModalViewItem"
import { QUERY_KEY } from "@/app/configs"
import { useQueryClient } from "@tanstack/react-query"

const listFilter = (t: any) => [
  {
    title: t("reward.ascending"),
    value: "1"
  },
  {
    title: t("reward.decrease"),
    value: "-1"
  }
]

const listTab = (t: any) => [
  {
    title: t("common.all"),
    value: ""
  },
  {
    title: "Voucher",
    value: "voucher"
  },
  {
    title: t("reward.tab_other"),
    value: "avatar,sticker,giftcode"
  }
]

export const GoldShopContainer = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const { pointReward, getPoint } = useContext(RewardContext)
  const { themeApp } = useContext(AppContext)
  const [textFail, setTextFail] = useState<string>("")
  const [dataSelected, setDataSelected] = useState<IGift>()
  const [params, setParams] = useState({
    item_type: "",
    order_price: "",
    page: 1,
    limit: 12
  })
  const [visible, setVisible] = useState({
    view: false,
    confirm: false,
    result: false,
    resultFail: false
  })
  const { data, isLoading } = useQueryGetListShopItem(params)
  const { mutate } = useMutationBuyItem()
  const { userInfo } = useContext(AppContext)
  const [isNotEnoughPoint, setIsNotEnoughPoint] = useState(false)
  const queryClient = useQueryClient()

  const GoldShopCard = ({ cardItem }: { cardItem: IGift }) => {
    return (
      <div
        key={cardItem.id}
        onClick={() => {
          setDataSelected(cardItem)
          setVisible({ ...visible, view: true })
        }}
        className="flex cursor-pointer flex-col p-[8px] bg-white dark:bg-white/10 rounded-[12px] xs:rounded-[8px] border border-black/10 cursor-pointer md:hover:shadow-[0px_3px_30px_0px_rgba(0,0,0,0.15)] transition duration-300 active:bg-bg-button active:border-bg-button dark:active:border-black/10 md:active:bg-white dark:md:active:bg-white/10 md:active:border-black/10 "
      >
        <img
          src={cardItem.image}
          alt={cardItem.item_name}
          className="w-full object-cover aspect-square rounded-[12px] xs:rounded-[8px]"
        />
        <div className="mt-[8px] p-[8px] flex flex-col h-full flex-1">
          <p className="text-[14px] xs:text-[12px] text-center md:text-left xs:leading-[16px] Nunito-700 leading-[19px] pb-[8px]">
            {cardItem.item_name}
          </p>
          <div className="text-[14px] text-neutral-05 dark:text-neutral-08 flex items-center gap-[3px] Nunito-500 mb-[16px]">
            <p className="leading-[14px]">{cardItem.price}</p>
            <IconGold />
          </div>
          <div className="flex justify-betweem gap-[6px] mt-auto mb-0">
            <Button
              onClick={() => {
                setDataSelected(cardItem)
                setVisible({ ...visible, view: true })
              }}
              variant="grey"
              className="h-[24px] text-[12px] text-neutral-05 dark:text-neutral-08 Nunito-400 rounded-[8px] w-full px-0"
            >
              {t("reward.detail")}
            </Button>
            <Button
              onClick={(e) => {
                e.stopPropagation()
                setDataSelected(cardItem)
                setVisible({ ...visible, confirm: true })
              }}
              variant="main"
              className="h-[24px] text-[12px] rounded-[8px] Nunito-400 w-full px-0"
            >
              {t("store.buy_now")}
            </Button>
          </div>
        </div>
      </div>
    )
  }

  const handleBuy = (_data: IBuyItemParams) => {
    if (dataSelected?.price && pointReward < dataSelected?.price) {
      setTextFail(t("reward.buy_gift_fail_desc"))
      setVisible({ ...visible, confirm: false, resultFail: true })
      setIsNotEnoughPoint(true)
    } else {
      mutate(_data, {
        onSuccess(data) {
          if (data.data.errorCode === 0) {
            setVisible({ ...visible, confirm: false, result: true })
            getPoint()

            queryClient.refetchQueries({
              queryKey: [
                QUERY_KEY.POINT_HISTORY,
                userInfo?.userid,
                {
                  type: "spent",
                  source: "",
                  start_date: "",
                  end_date: ""
                }
              ]
            })
            // GAEventsTracker("Shop_Buy_Success")
          } else {
            // GAEventsTracker("Shop_Buy_Failed")
            setTextFail(t("message.DEFAULT_ERROR"))
            setVisible({ ...visible, confirm: false, resultFail: true })
          }
        },
        onError(error: any) {
          // GAEventsTracker("Shop_Buy_Failed")
          setTextFail(
            error?.data?.errorCode === 400
              ? t("message.max_buy_giftcode")
              : t("message.DEFAULT_ERROR")
          )
          setVisible({ ...visible, confirm: false, resultFail: true })
        }
      })
    }
  }

  return (
    <div>
      <div className="flex justify-between items-center mt-[16px]">
        <div className="md:flex gap-[8px] hidden">
          {listTab(t).map((item) => {
            return (
              <div
                key={item.value}
                className={`px-[16px] py-[4px] border text-[14px] leading-[19px] rounded-[8px] cursor-pointer ${
                  params.item_type === item.value
                    ? "bg-main-01 text-white border-main-01 Nunito-500"
                    : "text-neutral border-black/10 dark:border-neutral-08 dark:text-neutral-08 Nunito-400"
                }`}
                onClick={() => {
                  setParams({
                    ...params,
                    item_type: item.value
                  })
                }}
              >
                {item.title}
              </div>
            )
          })}
        </div>

        <Select
          value={params.item_type}
          onValueChange={(value: string) =>
            setParams({
              ...params,
              item_type: value === "all" ? "" : value
            })
          }
        >
          <SelectTrigger
            asChild={false}
            className="md:hidden w-[36px] h-[36px] rounded-[12px] bg-bg-disable dark:bg-white/10 flex items-center justify-center p-0"
          >
            <div className="w-[36px] h-[36px] rounded-[12px] bg-bg-disable dark:bg-white/10 flex items-center justify-center p-0">
              <IconFilter />
            </div>
          </SelectTrigger>
          <SelectContent className="p-1 rounded-[16px]">
            {listTab(t).map((item: Record<string, string>) => (
              <SelectItem
                value={item?.value === "" ? "all" : item?.value}
                key={item?.value}
                className="p-3 rounded-[8px] hover:!bg-bg-disable hover:!text-main-01 text-sm Nunito-600 focus:bg-bg-disable focus:text-main-01 dark:hover:!bg-[#F1F1FC]/[.1] dark:focus:!bg-[#F1F1FC]/[.1]"
              >
                {item?.title}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        <DropdownMenu>
          <DropdownMenuTrigger asChild className={`outline-0 mt-0`}>
            <div
              className={`cursor-pointer h-[36px] px-[12px] rounded-[12px] bg-bg-disable dark:bg-white/10 text-main-01 dark:text-main-white text-[14px] Nunito-600 flex items-center gap-[8px]`}
            >
              {t("profile.filter_by")}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M10 5H17.5M10 10H15M10 15H12.5M2.5 13.0208L3.75432 14.4319C4.4173 15.1778 5.5827 15.1778 6.24568 14.4319L7.5 13.0208M5 15V5"
                  stroke={themeApp === "dark" ? "#FAF8FF" : "#7673E6"}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="text-left p-[12px] space-y-[4px]"
            align="end"
          >
            {listFilter(t).map((item) => {
              return (
                <DropdownMenuItem
                  key={item.value}
                  className={`justify-start ${
                    params.order_price === item.value
                      ? "text-main-01 bg-bg-disable"
                      : "dark:text-neutral-08"
                  } items-center justify-between focus:bg-bg-disable dark:focus:bg-bg-disable/10 dark focus:text-main-01 dark:focus:text-main-01 Nunito-600 text-[16px] text-center flex px-4 py-3 rounded-[8px] w-[210px]`}
                  onClick={() => {
                    if (params.order_price !== item.value) {
                      setParams({
                        ...params,
                        order_price: item.value
                      })
                    } else {
                      setParams({
                        ...params,
                        order_price: ""
                      })
                    }
                  }}
                >
                  {item.title}
                  {params.order_price === item.value && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="11"
                      viewBox="0 0 16 11"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M15.5018 0.442536C15.8096 0.71963 15.8346 1.19385 15.5575 1.50173L7.8199 10.0991C6.84536 11.1819 5.19549 11.3168 4.05793 10.4068L0.531506 7.58566C0.20806 7.3269 0.155619 6.85493 0.414376 6.53149C0.673133 6.20804 1.1451 6.1556 1.46855 6.41436L4.99497 9.23549C5.51204 9.64915 6.26199 9.5878 6.70496 9.09561L14.4426 0.498283C14.7197 0.1904 15.1939 0.165442 15.5018 0.442536Z"
                        fill="#7673E6"
                      />
                    </svg>
                  )}
                </DropdownMenuItem>
              )
            })}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      <div className="mt-[24px] grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 pc:grid-cols-6 gap-[16px] pc:gap-[24px]">
        {data?.data?.map((item: IGift, index: number) => {
          return <GoldShopCard cardItem={item as IGift} key={item?.id} />
        })}

        <div className="col-span-2 sm:col-span-3 md:col-span-4 lg:col-span-5 pc:col-span-6 flex justify-end mt-[8px]">
          <Pagination
            totalCount={(data as any)?.totalItems || 0}
            onPageChange={(page) => setParams({ ...params, page: page })}
            pageSize={12}
            currentPage={params.page}
          />
        </div>
      </div>
      {dataSelected && (
        <ModalViewItem
          open={visible.view}
          onOpenChange={() => {
            setVisible({ ...visible, view: !visible.view })
          }}
          dataSelected={dataSelected}
          onConfirmBuy={() => {
            setVisible({ ...visible, view: false, confirm: true })
          }}
        />
      )}

      {dataSelected && (
        <ModalConfirmBuy
          open={visible.confirm}
          onOpenChange={() => {
            setVisible({ ...visible, confirm: !visible.confirm })
          }}
          dataSelected={dataSelected}
          onBuy={() =>
            handleBuy({
              item_id: dataSelected.id
            })
          }
        />
      )}
      <ModalResultSuccess
        open={visible.result}
        onOpenChange={() => {
          setVisible({ ...visible, result: !visible.result })
        }}
        dataSelected={dataSelected}
      />
      <ModalResultFail
        open={visible.resultFail}
        onOpenChange={() => {
          setVisible({ ...visible, resultFail: !visible.resultFail })
          setIsNotEnoughPoint(false)
        }}
        textFail={textFail}
        notEnoughPoint={isNotEnoughPoint}
      />
    </div>
  )
}
