export const IconFacebook = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.75 12.0729C24.75 5.44545 19.3774 0.072876 12.75 0.072876C6.12258 0.072876 0.75 5.44545 0.75 12.0729C0.75 18.0623 5.1382 23.0269 10.875 23.9271V15.5416H7.82812V12.0729H10.875V9.42913C10.875 6.42163 12.6666 4.76038 15.4076 4.76038C16.7201 4.76038 18.0938 4.99475 18.0938 4.99475V7.94788H16.5806C15.09 7.94788 14.625 8.87295 14.625 9.82288V12.0729H17.9531L17.4211 15.5416H14.625V23.9271C20.3618 23.0269 24.75 18.0623 24.75 12.0729Z"
        fill="white"
      />
    </svg>
  )
}
