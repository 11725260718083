import React from "react"

interface Iprops {
  className?: string
  fill?: string
}

const IconLight = ({ className, fill }: Iprops) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
    >
      <g clipPath="url(#clip0_7860_36345)">
        <path
          d="M9.29124 14.3535C11.6957 14.3535 13.645 12.4042 13.645 9.99974C13.645 7.59523 11.6957 5.646 9.29124 5.646C6.88674 5.646 4.9375 7.59523 4.9375 9.99974C4.9375 12.4042 6.88674 14.3535 9.29124 14.3535Z"
          fill={fill ? fill : "#907ED9"}
        />
        <path
          d="M17.1243 10.8705H16.2536C16.0226 10.8705 15.8011 10.7787 15.6378 10.6154C15.4746 10.4521 15.3828 10.2307 15.3828 9.99972C15.3828 9.76878 15.4746 9.5473 15.6378 9.384C15.8011 9.22071 16.0226 9.12897 16.2536 9.12897H17.1243C17.3552 9.12897 17.5767 9.22071 17.74 9.384C17.9033 9.5473 17.9951 9.76878 17.9951 9.99972C17.9951 10.2307 17.9033 10.4521 17.74 10.6154C17.5767 10.7787 17.3552 10.8705 17.1243 10.8705Z"
          fill={fill ? fill : "#907ED9"}
        />
        <path
          d="M2.32743 10.8705H1.45669C1.22575 10.8705 1.00427 10.7787 0.840974 10.6154C0.677677 10.4521 0.585938 10.2307 0.585938 9.99972C0.585938 9.76878 0.677677 9.5473 0.840974 9.384C1.00427 9.22071 1.22575 9.12897 1.45669 9.12897H2.32743C2.55837 9.12897 2.77985 9.22071 2.94315 9.384C3.10644 9.5473 3.19818 9.76878 3.19818 9.99972C3.19818 10.2307 3.10644 10.4521 2.94315 10.6154C2.77985 10.7787 2.55837 10.8705 2.32743 10.8705Z"
          fill={fill ? fill : "#907ED9"}
        />
        <path
          d="M14.2185 5.94205C14.0022 5.93226 13.7973 5.84223 13.6438 5.68954C13.4817 5.52639 13.3906 5.3057 13.3906 5.07566C13.3906 4.84562 13.4817 4.62493 13.6438 4.46178L14.2621 3.84355C14.3401 3.7524 14.4362 3.67837 14.5442 3.6261C14.6522 3.57384 14.7699 3.54447 14.8898 3.53983C15.0097 3.5352 15.1293 3.55541 15.2411 3.59918C15.3528 3.64296 15.4543 3.70936 15.5391 3.79422C15.624 3.87908 15.6904 3.98056 15.7342 4.0923C15.778 4.20404 15.7982 4.32362 15.7935 4.44354C15.7889 4.56346 15.7595 4.68113 15.7073 4.78916C15.655 4.89718 15.581 4.99324 15.4898 5.0713L14.8716 5.68954C14.7861 5.77453 14.684 5.84086 14.5716 5.88433C14.4591 5.92779 14.3389 5.94745 14.2185 5.94205Z"
          fill={fill ? fill : "#907ED9"}
        />
        <path
          d="M3.75425 16.4084C3.63965 16.4091 3.52605 16.3871 3.41997 16.3438C3.31388 16.3005 3.21738 16.2366 3.13602 16.1559C2.97384 15.9928 2.88281 15.7721 2.88281 15.5421C2.88281 15.312 2.97384 15.0913 3.13602 14.9282L3.75425 14.3535C3.92083 14.2108 4.1351 14.1363 4.35424 14.1448C4.57339 14.1532 4.78127 14.2441 4.93635 14.3991C5.09142 14.5542 5.18227 14.7621 5.19073 14.9812C5.1992 15.2004 5.12466 15.4147 4.98201 15.5812L4.36377 16.1995C4.1948 16.3445 3.97667 16.4193 3.75425 16.4084Z"
          fill={fill ? fill : "#907ED9"}
        />
        <path
          d="M9.29262 3.90448C9.06169 3.90448 8.84021 3.81274 8.67691 3.64944C8.51361 3.48615 8.42188 3.26467 8.42188 3.03373V2.16298C8.42188 1.93205 8.51361 1.71057 8.67691 1.54727C8.84021 1.38398 9.06169 1.29224 9.29262 1.29224C9.52356 1.29224 9.74504 1.38398 9.90833 1.54727C10.0716 1.71057 10.1634 1.93205 10.1634 2.16298V3.03373C10.1634 3.26467 10.0716 3.48615 9.90833 3.64944C9.74504 3.81274 9.52356 3.90448 9.29262 3.90448Z"
          fill={fill ? fill : "#907ED9"}
        />
        <path
          d="M9.29262 18.7072C9.06169 18.7072 8.84021 18.6155 8.67691 18.4522C8.51361 18.2889 8.42188 18.0674 8.42188 17.8365V16.9657C8.42188 16.7348 8.51361 16.5133 8.67691 16.35C8.84021 16.1867 9.06169 16.095 9.29262 16.095C9.52356 16.095 9.74504 16.1867 9.90833 16.35C10.0716 16.5133 10.1634 16.7348 10.1634 16.9657V17.8365C10.1634 18.0674 10.0716 18.2889 9.90833 18.4522C9.74504 18.6155 9.52356 18.7072 9.29262 18.7072Z"
          fill={fill ? fill : "#907ED9"}
        />
        <path
          d="M4.35901 5.94204C4.13058 5.94107 3.91167 5.85038 3.74948 5.68952L3.13125 5.07129C2.9886 4.90471 2.91406 4.69044 2.92252 4.4713C2.93099 4.25215 3.02184 4.04427 3.17691 3.88919C3.33199 3.73412 3.53987 3.64327 3.75902 3.6348C3.97816 3.62634 4.19243 3.70088 4.35901 3.84353L4.97724 4.46176C5.13942 4.62491 5.23045 4.8456 5.23045 5.07564C5.23045 5.30568 5.13942 5.52637 4.97724 5.68952C4.89588 5.77022 4.79938 5.83407 4.69329 5.8774C4.5872 5.92073 4.4736 5.9427 4.35901 5.94204Z"
          fill={fill ? fill : "#907ED9"}
        />
        <path
          d="M14.8331 16.4084C14.6046 16.4075 14.3857 16.3168 14.2236 16.1559L13.6489 15.5377C13.5568 15.3722 13.5212 15.1813 13.5474 14.9938C13.5737 14.8064 13.6602 14.6325 13.7941 14.4987C13.928 14.3648 14.1018 14.2782 14.2893 14.252C14.4767 14.2258 14.6676 14.2614 14.8331 14.3534L15.4513 14.9717C15.6135 15.1348 15.7045 15.3555 15.7045 15.5856C15.7045 15.8156 15.6135 16.0363 15.4513 16.1994C15.2801 16.3466 15.0585 16.4215 14.8331 16.4084Z"
          fill={fill ? fill : "#907ED9"}
        />
      </g>
      <defs>
        <clipPath id="clip0_7860_36345">
          <rect
            width="18.2857"
            height="18.2857"
            fill={fill ? fill : "#907ED9"}
            transform="translate(0.148438 0.856873)"
          />
        </clipPath>
      </defs>
    </svg>
    // <svg
    //   width="16"
    //   height="16"
    //   viewBox="0 0 16 16"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   className={className}
    // >
    //   <g clipPath="url(#clip0_2561_10490)">
    //     <path
    //       d="M7.99995 11.8095C10.1039 11.8095 11.8095 10.1039 11.8095 7.99995C11.8095 5.89601 10.1039 4.19043 7.99995 4.19043C5.89601 4.19043 4.19043 5.89601 4.19043 7.99995C4.19043 10.1039 5.89601 11.8095 7.99995 11.8095Z"
    //       fill={fill ? fill : "#907ED9"}
    //     />
    //     <path
    //       d="M14.8568 8.76185H14.0949C13.8928 8.76185 13.699 8.68157 13.5562 8.53869C13.4133 8.3958 13.333 8.20201 13.333 7.99994C13.333 7.79787 13.4133 7.60408 13.5562 7.46119C13.699 7.31831 13.8928 7.23804 14.0949 7.23804H14.8568C15.0589 7.23804 15.2527 7.31831 15.3956 7.46119C15.5385 7.60408 15.6187 7.79787 15.6187 7.99994C15.6187 8.20201 15.5385 8.3958 15.3956 8.53869C15.2527 8.68157 15.0589 8.76185 14.8568 8.76185Z"
    //       fill={fill ? fill : "#907ED9"}
    //     />
    //     <path
    //       d="M1.90467 8.76185H1.14276C0.940694 8.76185 0.746901 8.68157 0.604016 8.53869C0.461131 8.3958 0.380859 8.20201 0.380859 7.99994C0.380859 7.79787 0.461131 7.60408 0.604016 7.46119C0.746901 7.31831 0.940694 7.23804 1.14276 7.23804H1.90467C2.10674 7.23804 2.30053 7.31831 2.44342 7.46119C2.5863 7.60408 2.66657 7.79787 2.66657 7.99994C2.66657 8.20201 2.5863 8.3958 2.44342 8.53869C2.30053 8.68157 2.10674 8.76185 1.90467 8.76185Z"
    //       fill={fill ? fill : "#907ED9"}
    //     />
    //     <path
    //       d="M12.3123 4.44943C12.123 4.44086 11.9438 4.36209 11.8094 4.22848C11.6675 4.08573 11.5879 3.89262 11.5879 3.69134C11.5879 3.49005 11.6675 3.29695 11.8094 3.1542L12.3504 2.61324C12.4187 2.53349 12.5028 2.46871 12.5973 2.42298C12.6918 2.37724 12.7948 2.35154 12.8997 2.34749C13.0046 2.34344 13.1093 2.36112 13.207 2.39942C13.3048 2.43773 13.3936 2.49583 13.4678 2.57008C13.5421 2.64433 13.6002 2.73313 13.6385 2.8309C13.6768 2.92867 13.6945 3.03331 13.6904 3.13824C13.6864 3.24316 13.6607 3.34612 13.615 3.44065C13.5692 3.53517 13.5044 3.61923 13.4247 3.68753L12.8837 4.22848C12.809 4.30285 12.7196 4.36089 12.6212 4.39892C12.5228 4.43696 12.4177 4.45416 12.3123 4.44943Z"
    //       fill={fill ? fill : "#907ED9"}
    //     />
    //     <path
    //       d="M3.15411 13.6075C3.05384 13.6081 2.95444 13.5889 2.86161 13.551C2.76878 13.513 2.68435 13.4572 2.61316 13.3866C2.47125 13.2438 2.3916 13.0507 2.3916 12.8494C2.3916 12.6481 2.47125 12.455 2.61316 12.3123L3.15411 11.8094C3.29986 11.6846 3.48735 11.6194 3.6791 11.6268C3.87086 11.6342 4.05275 11.7137 4.18844 11.8494C4.32414 11.9851 4.40363 12.167 4.41103 12.3587C4.41844 12.5505 4.35322 12.738 4.2284 12.8837L3.68744 13.4247C3.53959 13.5516 3.34873 13.617 3.15411 13.6075Z"
    //       fill={fill ? fill : "#907ED9"}
    //     />
    //     <path
    //       d="M8.00019 2.66657C7.79812 2.66657 7.60432 2.5863 7.46144 2.44342C7.31855 2.30053 7.23828 2.10674 7.23828 1.90467V1.14276C7.23828 0.940694 7.31855 0.746901 7.46144 0.604016C7.60432 0.461131 7.79812 0.380859 8.00019 0.380859C8.20226 0.380859 8.39605 0.461131 8.53893 0.604016C8.68182 0.746901 8.76209 0.940694 8.76209 1.14276V1.90467C8.76209 2.10674 8.68182 2.30053 8.53893 2.44342C8.39605 2.5863 8.20226 2.66657 8.00019 2.66657Z"
    //       fill={fill ? fill : "#907ED9"}
    //     />
    //     <path
    //       d="M8.00019 15.619C7.79812 15.619 7.60432 15.5387 7.46144 15.3958C7.31855 15.2529 7.23828 15.0591 7.23828 14.8571V14.0952C7.23828 13.8931 7.31855 13.6993 7.46144 13.5564C7.60432 13.4135 7.79812 13.3333 8.00019 13.3333C8.20226 13.3333 8.39605 13.4135 8.53893 13.5564C8.68182 13.6993 8.76209 13.8931 8.76209 14.0952V14.8571C8.76209 15.0591 8.68182 15.2529 8.53893 15.3958C8.39605 15.5387 8.20226 15.619 8.00019 15.619Z"
    //       fill={fill ? fill : "#907ED9"}
    //     />
    //     <path
    //       d="M3.68718 4.44957C3.4873 4.44873 3.29576 4.36938 3.15385 4.22862L2.61289 3.68767C2.48807 3.54191 2.42285 3.35443 2.43026 3.16267C2.43766 2.97092 2.51715 2.78902 2.65284 2.65333C2.78854 2.51764 2.97043 2.43815 3.16219 2.43074C3.35394 2.42334 3.54143 2.48856 3.68718 2.61338L4.22813 3.15433C4.37004 3.29709 4.44969 3.49019 4.44969 3.69148C4.44969 3.89276 4.37004 4.08587 4.22813 4.22862C4.15694 4.29923 4.07251 4.3551 3.97968 4.39302C3.88685 4.43093 3.78745 4.45015 3.68718 4.44957Z"
    //       fill={fill ? fill : "#907ED9"}
    //     />
    //     <path
    //       d="M12.8461 13.6075C12.6463 13.6067 12.4547 13.5274 12.3128 13.3866L11.8099 12.8456C11.7294 12.7009 11.6983 12.5338 11.7212 12.3698C11.7441 12.2058 11.8199 12.0537 11.937 11.9365C12.0541 11.8194 12.2062 11.7436 12.3703 11.7207C12.5343 11.6978 12.7014 11.7289 12.8461 11.8095L13.3871 12.3504C13.529 12.4932 13.6086 12.6863 13.6086 12.8875C13.6086 13.0888 13.529 13.2819 13.3871 13.4247C13.2373 13.5535 13.0433 13.619 12.8461 13.6075Z"
    //       fill={fill ? fill : "#907ED9"}
    //     />
    //   </g>
    //   <defs>
    //     <clipPath id="clip0_2561_10490">
    //       <rect width="16" height="16" fill="white" />
    //     </clipPath>
    //   </defs>
    // </svg>
  )
}

export default IconLight
