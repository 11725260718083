import React, { useMemo, useState } from "react"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@/app/components/ui/select"
import { useTranslation } from "next-i18next"
import {
  useQueryGetTotalCommentNews,
  useQueryGetTotalLikeNews,
  useQueryGetTotalNews,
  useQueryGetTotalUserFollowPartner,
  useQueryGetTotalUserJoinedEvent
} from "@/app/services/query-fn/user/user-analytic-query"
import { getTimeBySubtract } from "@/app/helpers/function"

export const Statistical = () => {
  const { t } = useTranslation()
  const FilterTime = [
    { label: t("profile.seven_days"), value: "7 days" },
    { label: t("profile._30_days"), value: "30 days" },
    { label: t("profile._45_days"), value: "45 days" },
    { label: t("profile._60_days"), value: "60 days" },
    { label: t("profile._90_days"), value: "90 days" }
  ]
  const [time, setTime] = useState<
    "7 days" | "30 days" | "45 days" | "60 days" | "90 days"
  >("7 days")
  const timeFilter = useMemo(() => {
    return getTimeBySubtract(time)
  }, [time])

  const { data: totalNews } = useQueryGetTotalNews({ ...timeFilter })
  const { data: totalLikeNews } = useQueryGetTotalLikeNews({ ...timeFilter })
  const { data: totalCommentNews } = useQueryGetTotalCommentNews({
    ...timeFilter
  })
  // const { data: totalEvent } = useQueryGetTotalEvent({ ...timeFilter })
  const { data: totalUserJoinedEvent } = useQueryGetTotalUserJoinedEvent({
    ...timeFilter
  })

  const onValueChange = (
    value: "7 days" | "30 days" | "45 days" | "60 days" | "90 days"
  ) => {
    setTime(value)
  }

  const formatNumber = (num: number) => {
    return Intl.NumberFormat("en-US", {
      notation: "compact",
      maximumFractionDigits: 1
    }).format(num)
  }

  return (
    <div className="p-[16px] bg-white dark:bg-white/10 min-h-[400px] col-span-12 md:col-span-5 lg:col-span-4 rounded-[16px] space-y-[24px]">
      <div>
        <p className="border-neutral-05 text-sm Nunito-400 leading-5 mb-[12px] opacity-0 dark:text-neutral-08">
          {t("profile.total_view")}
        </p>
        <div className="flex justify-between items-center border-b border-main-01 pb-[12px]">
          <p className="text-[16px] text-bg-01 Nunito-600 leading-10 dark:text-white">
            {t("profile.statistical")}
          </p>

          <div>
            <Select onValueChange={onValueChange}>
              <SelectTrigger className="w-full max-w-[130px] max-h-[28px] file:m-0 text-xs Nunito-400 text-main-01 dark:text-white dark:bg-white/10 dark:backdrop-blur-[20px] border-0 bg-bg-disable text-left">
                <SelectValue
                  placeholder={t("profile.seven_days")}
                  defaultValue={t("profile.seven_days")}
                />
              </SelectTrigger>
              <SelectContent className="p-1 rounded-[16px]" align="end">
                {FilterTime.map((item: { label: string; value: string }) => (
                  <SelectItem
                    value={item?.value}
                    key={item?.value}
                    className="py-3 px-4 rounded-[8px] hover:!bg-bg-disable hover:!text-main-01 text-base Nunito-600 focus:bg-bg-disable focus:text-main-01 dark:hover:!bg-[#F1F1FC]/[.1] dark:focus:!bg-[#F1F1FC]/[.1]"
                  >
                    {item?.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </div>
      </div>
      <div className="w-full grid grid-cols-1 gap-[8px] p-[8px]">
        {/* total like */}
        <div className="flex justify-between items-center pb-[20px] border-b border-black/10 dark:border-neutral-05">
          <div className="flex gap-[16px] items-center flex-1">
            <div className="w-[24px] h-[24px] rounded-[8px] bg-bg-button flex items-center justify-center">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.334 4.33353C12.0703 4.33353 12.6673 4.93049 12.6673 5.66687M8.00065 3.80189L8.45736 3.33354C9.87797 1.87668 12.1812 1.87668 13.6019 3.33353C14.9843 4.75127 15.0268 7.03607 13.698 8.50681L9.88046 12.7323C8.86628 13.8549 7.13501 13.8549 6.12082 12.7323L2.30327 8.50683C0.974506 7.03609 1.01698 4.75128 2.39945 3.33354C3.82007 1.87668 6.12334 1.87669 7.54396 3.33355L8.00065 3.80189Z"
                  stroke="#585669"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <p className="text-sm text-neutral Nunito-400 dark:text-neutral-08">
              {t("profile.total_like")}
            </p>
          </div>
          <p className="text-base text-bg-01 Nunito-600 dark:text-main-white">
            {formatNumber(Number(totalLikeNews ?? 0))}
          </p>
        </div>

        {/* total comment */}
        <div className="flex justify-between items-center py-[20px] border-b border-black/10 dark:border-neutral-05">
          <div className="flex gap-[16px] items-center flex-1">
            <div className="w-[24px] h-[24px] rounded-[8px] bg-bg-button flex items-center justify-center">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.66732 2H7.33398C4.02028 2 1.33398 4.68629 1.33398 8V11.3333C1.33398 12.8061 2.52789 14 4.00065 14H8.66732C11.981 14 14.6673 11.3137 14.6673 8C14.6673 4.68629 11.981 2 8.66732 2Z"
                  stroke="#585669"
                  strokeWidth="1.5"
                  strokeLinejoin="round"
                />
                <circle cx="8.00065" cy="8.00004" r="0.666667" fill="#585669" />
                <ellipse
                  cx="10.6667"
                  cy="8.00004"
                  rx="0.666667"
                  ry="0.666667"
                  fill="#585669"
                />
                <circle cx="5.33268" cy="8.00004" r="0.666667" fill="#585669" />
              </svg>
            </div>
            <p className="text-sm text-neutral Nunito-400 dark:text-neutral-08">
              {t("profile.total_comment")}
            </p>
          </div>
          <p className="text-base text-bg-01 Nunito-600 dark:text-main-white">
            {formatNumber(Number(totalCommentNews ?? 0))}
          </p>
        </div>

        {/* total join event */}
        <div className="flex justify-between items-center py-[20px] border-b border-black/10 dark:border-neutral-05">
          <div className="flex gap-[16px] items-center flex-1">
            <div className="w-[24px] h-[24px] rounded-[8px] bg-bg-button flex items-center justify-center">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.6667 1.33334V3.33334M5.33333 1.33334V3.33334M8 6.66668V10.6667M10 8.66668H6M4.66667 14.6667H11.3333C12.8061 14.6667 14 13.4728 14 12V5.00001C14 3.52725 12.8061 2.33334 11.3333 2.33334H4.66667C3.19391 2.33334 2 3.52725 2 5.00001V12C2 13.4728 3.19391 14.6667 4.66667 14.6667Z"
                  stroke="#585669"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
            </div>
            <p className="text-sm text-neutral Nunito-400 dark:text-neutral-08">
              {t("profile.total_joined")}
            </p>
          </div>
          <p className="text-base text-bg-01 Nunito-600 dark:text-main-white">
            {formatNumber(Number(totalUserJoinedEvent ?? 0))}
          </p>
        </div>

        {/*  Total news */}
        <div className="flex justify-between items-center py-[20px]">
          <div className="flex gap-[16px] items-center flex-1">
            <div className="w-[24px] h-[24px] rounded-[8px] bg-bg-button flex items-center justify-center">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_9890_62737)">
                  <rect
                    x="1.33398"
                    y="1.33334"
                    width="13.3333"
                    height="13.3333"
                    rx="4"
                    stroke="#585669"
                    strokeWidth="1.5"
                  />
                  <path
                    d="M5.33398 1.33334H10.6673V5.5858C10.6673 6.4767 9.59018 6.92287 8.96021 6.2929L8.70776 6.04045C8.31723 5.64993 7.68407 5.64993 7.29354 6.04045L7.04109 6.2929C6.41113 6.92287 5.33398 6.4767 5.33398 5.5858V1.33334Z"
                    stroke="#585669"
                    strokeWidth="1.5"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_9890_62737">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <p className="text-sm text-neutral Nunito-400 dark:text-neutral-08">
              {t("profile.total_news")}
            </p>
          </div>
          <p className="text-base text-bg-01 Nunito-600 dark:text-main-white">
            {formatNumber(Number(totalNews ?? 0))}
          </p>
        </div>
      </div>
    </div>
  )
}
