import { Button } from "@/app/components/ui/button"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import React, { useMemo, useState } from "react"
import { useFormContext } from "react-hook-form"
import { PublicAtMobile } from "./PublicAtMobile"
import moment from "moment"

interface SubmitProps {
  disable: boolean
  onSubmit: any
}
export const Submit: React.FC<SubmitProps> = ({ disable, onSubmit }) => {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState<boolean>(false)
  const { watch, handleSubmit } = useFormContext()
  const selectedPublicAt = watch("public_at", undefined)
  const onChangeShowModal = () => {
    setShowModal(!showModal)
  }
  const router = useRouter()
  const editId = useMemo(() => router?.query?.editId, [router])
  const type = useMemo(() => router?.query?.type, [router])
  return (
    <>
      <Button
        variant="none"
        className="hidden md:flex text-white bg-main-01 text-[18px] Nunito-700 rounded-xl py-2 px-3"
        onClick={handleSubmit(onSubmit)}
        disabled={disable}
      >
        {editId
          ? t("profile.save")
          : t("news.create_title", {
              field: type?.includes("event") ? t("news.event") : t("news.post")
            })}
      </Button>

      <div className="w-full flex md:hidden justify-between items-center gap-[8px]">
        <Button
          variant="none"
          className="text-white bg-main-01 text-[18px] Nunito-700 rounded-xl h-[44px] w-full items-center justify-center flex flex-col"
          onClick={handleSubmit(onSubmit)}
          disabled={disable}
        >
          <p className="text-sm Nunito-700">
            {editId
              ? t("profile.save")
              : t("news.create_title", {
                  field: type?.includes("event")
                    ? t("news.event")
                    : t("news.post")
                })}
          </p>
          {selectedPublicAt && (
            <p className="text-xs Nunito-400">
              ({moment(selectedPublicAt).format("HH:mm DD/MM/YYYY")})
            </p>
          )}
        </Button>
        <PublicAtMobile
          showModal={showModal}
          onChangeShowModal={onChangeShowModal}
        />
      </div>
    </>
  )
}
