import React from "react"

interface Iprops {
  className?: string
  fill?: string
}

const IconDark = ({ className, fill }: Iprops) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M9.99208 17.6111C12.8035 17.733 15.3178 16.4225 16.8264 14.3578C17.4511 13.5121 17.1159 12.9483 16.0721 13.1387C15.5616 13.2302 15.0359 13.2683 14.4873 13.2454C10.7616 13.093 7.71398 9.97683 7.69874 6.29683C7.69112 5.30636 7.89684 4.36922 8.27017 3.51588C8.6816 2.57112 8.18636 2.1216 7.23398 2.52541C4.21684 3.79779 2.15208 6.83779 2.40351 10.3197C2.67779 14.2435 6.00731 17.4359 9.99208 17.6111Z"
        fill={fill ? "" : fill}
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
    // <svg
    //   width="16"
    //   height="16"
    //   viewBox="0 0 16 16"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   className={className}
    // >
    //   <path
    //     d="M7.99307 14.66C10.4531 14.7666 12.6531 13.62 13.9731 11.8133C14.5197 11.0733 14.2264 10.58 13.3131 10.7466C12.8664 10.8266 12.4064 10.86 11.9264 10.84C8.6664 10.7066 5.99973 7.97997 5.9864 4.75997C5.97973 3.89331 6.15973 3.07331 6.4864 2.32664C6.8464 1.49997 6.41307 1.10664 5.57973 1.45997C2.93973 2.57331 1.13307 5.23331 1.35307 8.27997C1.59307 11.7133 4.5064 14.5066 7.99307 14.66Z"
    //     fill={fill ? fill : ""}
    //     stroke={fill}
    //     strokeLinecap="round"
    //     strokeLinejoin="round"
    //   />
    // </svg>
  )
}

export default IconDark
