import { CreateType } from "@/app/configs"
import React, { SetStateAction, useMemo } from "react"
import { useFormContext } from "react-hook-form"
import { GameMention } from "./GameMention"
import { TagMention } from "./TagMention"
import { LinkEvent } from "./LinkEvent"
import { EventDate } from "./EventDate"
import { TimePublic } from "./TimePublic"
import { Button } from "@/app/components/ui/button"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import { Submit } from "./Submit"

interface AddonChildProps {
  disable: boolean
  onSubmit: any
  hidden: boolean
  setHidden: React.Dispatch<SetStateAction<boolean>>
  hiddenContent?: boolean
}
export const AddonChild: React.FC<AddonChildProps> = ({
  hidden,
  setHidden,
  disable,
  onSubmit,
  hiddenContent
}) => {
  const router = useRouter()
  const type = useMemo(() => router?.query?.type, [router])
  return (
    <>
      <div
        className={`w-[40px] h-[32px] flex items-center justify-center bg-bg-disable dark:bg-[#efefff]/10 rounded-[8px] cursor-pointer ${
          hidden ? "rotate-0" : "rotate-180"
        }`}
        onClick={() => setHidden(!hidden)}
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.168 2.33268L15.168 13.666C15.168 13.9393 14.9413 14.166 14.668 14.166C14.3946 14.166 14.168 13.9393 14.168 13.666L14.168 2.33268C14.168 2.05935 14.3946 1.83268 14.668 1.83268C14.9413 1.83268 15.168 2.05935 15.168 2.33268Z"
            fill="#7673E6"
          />
          <path
            d="M14.6209 8.28715C14.5313 8.37672 14.4088 8.43329 14.2674 8.43329L1.06803 8.43328C0.794616 8.43328 0.568342 8.20701 0.568342 7.93359C0.568342 7.66018 0.794615 7.43391 1.06803 7.43391L14.2674 7.43391C14.5408 7.43391 14.767 7.66018 14.767 7.9336C14.767 8.07502 14.7105 8.19758 14.6209 8.28715Z"
            fill="#7673E6"
          />
          <path
            d="M6.22435 13.2065C6.02729 13.4036 5.70048 13.4036 5.50342 13.2065L0.567493 8.27062C0.370441 8.07357 0.370441 7.74675 0.567494 7.54969L5.50342 2.61377C5.70048 2.41671 6.0273 2.41671 6.22435 2.61377C6.4214 2.81082 6.4214 3.13764 6.22435 3.33469L1.64888 7.91016L6.22435 12.4856C6.4214 12.6827 6.4214 13.0095 6.22435 13.2065Z"
            fill="#7673E6"
          />
        </svg>
      </div>

      {!hiddenContent && (
        <>
          <div className="w-full">
            <div
              className={`${
                type === CreateType.CREATE_EVENT
                  ? "space-y-[48px]"
                  : "space-y-[56px]"
              } py-4`}
            >
              <GameMention />
              <TagMention />
              {type === CreateType.CREATE_EVENT && <LinkEvent />}
              {type === CreateType.CREATE_EVENT && <EventDate />}
              <TimePublic />
            </div>
          </div>

          <Submit disable={disable} onSubmit={onSubmit} />
        </>
      )}
    </>
  )
}
