import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query"
import { UserService } from "../../services"
import { QUERY_KEY, isClient } from "@/app/configs"
import { GameServices } from "../../services/GameServices"

const getReloadProfileInfoFn = () => {
  return UserService.getReloadProfile()
}

const getProfileInfoFn = () => {
  return UserService.getProfile()
}

const getHotTopicListFn = async () => {
  const res = await UserService.getListHashtag({})
  return res?.data?.data
}

const getListGameFollow = async (other_userid?: string) => {
  const res = await GameServices.getListFollowGame(other_userid)
  return {
    game: res?.data?.data,
    totalFollow: res?.data?.totalFollow
  }
}

const followPartnerFn = (follower_id: string) => {
  return UserService.followUser(follower_id)
}

const getListFollowFn = async (pageParam: number, query?: string) => {
  const res = await UserService.getListFollow(pageParam, query)
  return res?.data?.data
}

export const useQueryGetMyFollowing = (
  userid: string,
  enable: boolean,
  query?: string
) => {
  return useInfiniteQuery({
    queryKey: [QUERY_KEY.LIST_FOLLOWING, userid, query],
    queryFn: ({ pageParam }) => getListFollowFn(pageParam, query),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.length > 9 ? pages?.length + 1 : undefined
    },
    refetchOnMount: "always",
    enabled: !!enable
  })
}

export const useMutationFollowPartner = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.FOLLOW_PARTNER],
    mutationFn: followPartnerFn
  })
}

export const useQueryGetProfileById = (id: string | undefined) => {
  return useQuery({
    queryFn: async () => {
      const res = await UserService.getProfileInfoById(String(id))
      return res?.data?.data
    },
    queryKey: [QUERY_KEY.GET_PROFILE_BY_ID, id],
    enabled: !!id,
    refetchOnMount: "always"
  })
}

export const useQueryGetGameFollow = (
  enable?: boolean,
  other_userid?: string
) => {
  return useQuery({
    queryFn: () => getListGameFollow(other_userid),
    queryKey: [QUERY_KEY.LIST_GAME_FOLLOW],
    enabled: !!enable
  })
}

export const useQueryProfileInfo = () => {
  const token = isClient ? window.localStorage.getItem("token") : null

  return {
    ...useQuery({
      queryFn: getProfileInfoFn,
      queryKey: [QUERY_KEY.PROFILE_INFO],
      enabled: !!token,
      refetchOnMount: "always"
    })
  }
}

export const useQueryGetHotTopic = () => {
  return {
    ...useQuery({
      queryFn: getHotTopicListFn,
      queryKey: [QUERY_KEY.HOT_TOPIC]
    })
  }
}
