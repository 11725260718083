import { PostCard } from "@/app/components/PostCard"
import { IconSearch } from "@/app/components/icon-tsx"
import { IPostCard } from "@/app/interfaces/IPost"
import {
  useQueryGetListNewsByUser,
  useQueryGetMyNewsByFilter
} from "@/app/services/query-fn/news"
import { useTranslation } from "next-i18next"
import React, { useContext, useState } from "react"
import { useInView } from "react-intersection-observer"
import { FilterPostEvent } from "../../../common"
import { FormProvider, useForm } from "react-hook-form"
import { LIMIT_PAGE_POST } from "@/app/configs"
import { removeNullKeyObject } from "@/app/lib/utils"
import { useDebounceEffect } from "@/app/hooks/useDebounceEffect"
import { AppContext } from "@/app/context"
import { DataEmpty } from "@/app/components/data-empty"

export const initialFilterPost = {
  keyword: "",
  sort: -1
}

// eslint-disable-next-line react/display-name
export const PartnerPost = () => {
  const { t } = useTranslation()
  const { themeApp } = useContext(AppContext)
  const methods = useForm<typeof initialFilterPost>({
    defaultValues: initialFilterPost
  })
  const watchKeyword = methods.watch("keyword", undefined)
  const watchSort = methods.watch("sort", -1)
  const [tempSearch, setTempSearch] = useState<string>("")
  const {
    data: listNewsWithoutSearch,
    fetchNextPage: fetchNextPageWithoutSearch
  } = useQueryGetListNewsByUser(watchKeyword, watchSort)
  const { data: listNewsWithSearch, fetchNextPage: fetchNextPageWithSearch } =
    useQueryGetMyNewsByFilter(
      removeNullKeyObject({
        keyword: watchKeyword,
        sort: watchSort,
        limit: LIMIT_PAGE_POST
      })
    )

  const listNews = !!watchKeyword ? listNewsWithSearch : listNewsWithoutSearch

  const { ref, inView } = useInView()
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTempSearch(e?.target?.value)
  }
  useDebounceEffect(() => methods.setValue("keyword", tempSearch), 300, [
    tempSearch
  ])

  React.useEffect(() => {
    if (inView) {
      if (!watchKeyword) {
        fetchNextPageWithoutSearch()
      } else fetchNextPageWithSearch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchNextPageWithoutSearch, fetchNextPageWithSearch, inView])

  return (
    <div className="mt-4 md:mt-[32px] relative z-10 w-full">
      <div className="flex items-center justify-between w-full">
        <FormProvider {...methods}>
          <div className="max-w-full flex-1 md:max-w-[320px]">
            <div className="flex items-center justify-between border-b-[1px] dark:border-neutral-04 gap-4 py-3">
              <IconSearch
                fill={themeApp === "dark" ? "#7673E5" : "#100D28"}
                className="w-[20px] h-[20px]"
              />
              <input
                type="text"
                value={tempSearch}
                className="flex-1 text-sm bg-transparent border-0 outline-none"
                placeholder={t("profile.find_news_placeholder")}
                onChange={onChange}
              />
            </div>
          </div>
          <FilterPostEvent />
        </FormProvider>
      </div>
      {!!listNews && !!listNews?.pages?.flat()?.length ? (
        <div className="w-full mt-[24px] space-y-[24px]">
          {React.Children.toArray(
            listNews?.pages
              ?.flat()
              ?.map((item: IPostCard) => (
                <PostCard postcard={item} isCheckHashtag />
              ))
          )}
        </div>
      ) : (
        <div className="w-full flex items-center justify-center h-[200px]">
          <DataEmpty title={t("profile.partner_empty_data")} />
        </div>
      )}
      <button ref={ref} className="opacity-0">
        Load more
      </button>
    </div>
  )
}
