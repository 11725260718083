import { http } from "@/app/api"
import IApiResponse from "@/app/interfaces/IApiResponse"
import {
  IFacebookLoginParams,
  IPointHistoryParams,
  ISocialLoginResponse,
  IUser,
  IUserRequest,
  InviteHistoryResult,
  PointHistoryResult,
  TAuth,
  TLoginBody,
  TotalPointResult
} from "@/app/interfaces/IUser"
import { AxiosResponse } from "axios"

const resource = "/api/users"

export class UserService {
  static login(body: TLoginBody): Promise<AxiosResponse<IApiResponse<TAuth>>> {
    return http.post(`${resource}/login`, body)
  }

  static getReloadProfile(): Promise<AxiosResponse<IApiResponse>> {
    return http.get(`${resource}/reload`)
  }

  static getProfile(): Promise<AxiosResponse<IApiResponse<IUser>>> {
    return http.get(`${resource}/me`)
  }

  static loginByGoogle(
    code?: string
  ): Promise<AxiosResponse<ISocialLoginResponse>> {
    const params = {}
    if (code) {
      Object.assign(params, { code })
    }
    return http.get(`${resource}/loginGoogle`, { params })
  }

  static getUrlFacebook(): Promise<AxiosResponse<ISocialLoginResponse>> {
    return http.get(`${resource}/loginFacebook`)
  }

  static loginByFacebook(
    params: IFacebookLoginParams
  ): Promise<AxiosResponse<IApiResponse<TAuth>>> {
    return http.get(`${resource}/loginFacebook/auth`, { params })
  }

  static updateLanguage(params: {
    lang: string
  }): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`${resource}/updateLanguage`, params)
  }

  static uploadImage(params: FormData): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`${resource}/updateAvatar`, params, {
      headers: { "Content-Type": "multipart/form-data" }
    })
  }

  static updateProfile(params: any): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`${resource}/updateProfile`, params)
  }

  static getListHashtag(params: {
    get_top?: string
  }): Promise<AxiosResponse<IApiResponse<any>>> {
    return http.get(`${resource}/hashtags`, {
      params: params
    })
  }
  static updateInterestTopic(params: {
    topic_interest?: string
    games_interest?: string
  }): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`${resource}/updateInterest`, params)
  }

  static getUserPointHistory(
    params: IPointHistoryParams
  ): Promise<AxiosResponse<PointHistoryResult>> {
    return http.get(`${resource}/pointHistory`, {
      params: params
    })
  }
  static updateInviteCode(params: {
    invite_code: string
  }): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`${resource}/updateInviteCode`, params)
  }

  static getUserPoint(): Promise<AxiosResponse<TotalPointResult>> {
    return http.get(`${resource}/point`)
  }

  static getUserInviteHistory(
    params: IPointHistoryParams
  ): Promise<AxiosResponse<InviteHistoryResult>> {
    return http.get(`${resource}/inviteHistory`, {
      params: params
    })
  }

  static getProfileInfoById(
    id: string
  ): Promise<AxiosResponse<IApiResponse<IUser>>> {
    return http.get(`${resource}/profile`, {
      params: { id }
    })
  }

  static getListSuggestByGame(
    game_id: string,
    pageParam: number
  ): Promise<AxiosResponse<IApiResponse<IUserRequest[]>>> {
    return http.get(`${resource}/suggestFriendByGame`, {
      params: {
        game_id,
        page: pageParam
      }
    })
  }

  static followUser(follower_id: string): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`${resource}/follow`, { follower_id: follower_id })
  }

  static sendGift(
    user_id: string,
    item_id: string
  ): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`/api/bag/sendGift`, { user_id, item_id })
  }

  static getListFollow(
    pageParam: number,
    query?: string
  ): Promise<AxiosResponse<IApiResponse<any>>> {
    return http.get(`${resource}/listFollowMe`, {
      params: { keyword: query, page: pageParam }
    })
  }
}
