import { AppContext } from "@/app/context"
import { useContext } from "react"

export const IconStore = ({
  fill,
  className
}: {
  fill?: string
  className?: string
}) => {
  const { themeApp } = useContext(AppContext)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      className={className}
    >
      <path
        d="M22.6445 8.25V6C22.6445 3.79086 20.8537 2 18.6445 2H6.64453C4.43539 2 2.64453 3.79086 2.64453 6V8.25C2.64453 10.3211 4.13692 12 5.97786 12C7.81881 12 9.3112 10.3211 9.3112 8.25C9.3112 10.3211 10.8036 12 12.6445 12C14.4855 12 15.9779 10.3211 15.9779 8.25C15.9779 10.3211 17.4703 12 19.3112 12C21.1521 12 22.6445 10.3211 22.6445 8.25Z"
        stroke={fill ? fill : "#65676B"}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M9.64453 18C12.0007 19.3404 13.2921 19.3263 15.6445 18"
        stroke={fill ? fill : "#65676B"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.6445 11V18C21.6445 20.2091 19.8537 22 17.6445 22H7.64453C5.43539 22 3.64453 20.2091 3.64453 18V11"
        stroke={fill ? fill : "#65676B"}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  )
}
