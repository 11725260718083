import { useToast } from "@/app/components/ui/use-toast"
import { CreateType, MAX_LENGTH_TITLE, QUERY_KEY } from "@/app/configs"
import { useTranslation } from "next-i18next"
import React, {
  ComponentType,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react"
import { Controller, FormProvider, useForm } from "react-hook-form"
import { CreatePostAddon } from "./add-on"
import { IGame } from "@/app/interfaces"
import moment from "moment"
import {
  useMutationCreateNews,
  useMutationDeleteDraft,
  useMutationEditNews,
  useMutationSaveDraft,
  useQueryCheckDraft,
  useQueryGetNewsDetailForEdit
} from "@/app/services/query-fn/news"
import { useRouter } from "next/router"
import { Dialog, DialogContent } from "@/app/components/ui/dialog"
import { Button } from "@/app/components/ui/button"
import { useQueryClient } from "@tanstack/react-query"
import dynamic from "next/dynamic"
import {
  useMutationCreateEvent,
  useMutationDeleteDraftEvent,
  useMutationEditEvent,
  useMutationSaveDraftEvent
} from "@/app/services/query-fn/event/create-event-mutation"
import { IPostContent } from "@/app/interfaces/IPost"
import { useQueryCheckDraftEvent } from "@/app/services/query-fn/event"
import { AppContext } from "@/app/context"
import { useQueryGetEventDetailForEdit } from "@/app/services/query-fn/event/event-detail-query"
import useGAEventsTracker from "@/app/hooks/useGAEventsTracker"
import {
  createSlugNameDetail,
  removeVietnameseTones
} from "@/app/helpers/function"

const Editor: ComponentType<any> = dynamic(() => import("./editor/Editor"), {
  ssr: false
})

export const initialCreatePostValues = {
  title: "",
  content: "",
  thumbnail: "",
  selectedGames: [] as IGame[],
  public_at: undefined as unknown as any,
  tags: [] as string[],
  link_event: "",
  start_time: undefined as unknown as any,
  end_time: undefined as unknown as any
}

export const CreatePostContainer = () => {
  const methods = useForm<typeof initialCreatePostValues>({
    defaultValues: initialCreatePostValues
  })
  const { themeApp } = useContext(AppContext)
  const { control, setValue, watch, getValues } = methods
  const { t } = useTranslation()
  const { toast } = useToast()
  const { userInfo } = useContext(AppContext)
  const router = useRouter()
  const type = useMemo(() => router?.query?.type, [router])
  const editId = useMemo(() => router?.query?.editId, [router])
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [showAlertDraft, setShowAlertDraft] = useState<boolean>(false)
  const [hidden, setHidden] = useState<boolean>(false)
  const [navigateUrl, setNavigateUrl] = useState<string>("")
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const titleRef = useRef<HTMLHeadingElement>(null)
  const queryClient = useQueryClient()

  const dataDraftNews = useQueryCheckDraft(String(type))
  const dataDraftEvent = useQueryCheckDraftEvent(String(type))

  const dataDraft = useMemo(() => {
    const data =
      type === CreateType.CREATE_EVENT ? dataDraftEvent : dataDraftNews
    const errorData = data?.error as unknown as any
    return data?.data ?? errorData?.data?.data
  }, [dataDraftEvent, dataDraftNews, type])

  // news
  const { data: detailEditNews } = useQueryGetNewsDetailForEdit(
    String(router?.query?.editId),
    String(type)?.replace("create-", "")
  )
  const { mutate: mutateCreatePost, isPending: isPendingCreatePost } =
    useMutationCreateNews()
  const { mutate: mutateSaveDraft } = useMutationSaveDraft()
  const { mutate: mutateDeleteDraft, isPending: isPendingDeleteDraft } =
    useMutationDeleteDraft()
  const { mutate: mutateEditNews, isPending: isPendingEditNews } =
    useMutationEditNews()
  const GAEventsTracker = useGAEventsTracker("User_Post_Action")

  // event
  const { data: detailEditEvent } = useQueryGetEventDetailForEdit(
    String(router?.query?.editId),
    String(type)?.replace("create-", "")
  )
  const { mutate: mutateCreateEvent, isPending: isPendingCreateEvent } =
    useMutationCreateEvent()
  const { mutate: mutateSaveDraftEvent } = useMutationSaveDraftEvent()
  const {
    mutate: mutateDeleteDraftEvent,
    isPending: isPendingDeleteDraftEvent
  } = useMutationDeleteDraftEvent()
  const { mutate: mutateEditEvent, isPending: isPendingEditEvent } =
    useMutationEditEvent()

  const fillDataIfExist = (data: IPostContent) => {
    setValue("content", data?.content)
    if (data?.game?.id) setValue("selectedGames", [data?.game])
    if (data?.hashtag) {
      if (Array.isArray(data?.hashtag)) {
        setValue("tags", data?.hashtag)
      } else {
        const result = []
        for (let tag in data?.hashtag) {
          result.push(data?.hashtag[tag])
        }
        setValue("tags", result)
      }
    }
    if (!!titleRef && !!titleRef?.current) {
      titleRef.current.innerText = data?.title
      setValue("title", data?.title)
    }
    setValue("thumbnail", data?.thumbnail)
    if (data?.link_event) setValue("link_event", data?.link_event)
    if (!!data?.start_time) {
      setValue("start_time", moment.unix(data?.start_time).toDate())
    }
    if (!!data?.end_time) {
      setValue("end_time", moment.unix(data?.end_time).toDate())
    }
    const date = new Date()?.getTime()
    if (!!data?.public_at && data?.public_at * 1000 > date) {
      setValue("public_at", moment.unix(data?.public_at).toDate())
    }

    if (showAlertDraft) setShowAlertDraft(!showAlertDraft)
  }

  useEffect(() => {
    if (dataDraft?.id && !showAlertDraft) {
      setShowAlertDraft(true)
    }
  }, [dataDraft])

  useEffect(() => {
    if (!!detailEditEvent || detailEditNews) {
      const detail =
        type === CreateType.CREATE_POST ? detailEditNews : detailEditEvent
      if (detail) {
        fillDataIfExist(detail?.post)
      }
    }
  }, [detailEditNews, detailEditEvent])

  useEffect(() => {
    if (!!navigateUrl && !!router) {
      setIsLoading(true)
      router.push(navigateUrl)
    }
    return () => {
      setIsLoading(false)
    }
  }, [navigateUrl, router])

  const tranformDataBeforeSubmit = (data: typeof initialCreatePostValues) => {
    const {
      selectedGames,
      tags,
      public_at,
      link_event,
      start_time,
      end_time,
      ...dataWithoutTranform
    } = data

    const result: any = {
      ...dataWithoutTranform,
      hashtag: data?.tags,
      game_id: data?.selectedGames?.map((item) => item.id)[0]
    }

    if (!!public_at) {
      result.public_at = moment(public_at).format("YYYY-MM-DD HH:mm:ss")
    }

    if (!!link_event && type === CreateType.CREATE_EVENT) {
      result.link_event = link_event
    }
    if (!!start_time && type === CreateType.CREATE_EVENT) {
      result.start_time = moment(start_time).format("YYYY-MM-DD HH:mm:ss")
    }
    if (!!end_time && type === CreateType.CREATE_EVENT) {
      result.end_time = moment(end_time).format("YYYY-MM-DD HH:mm:ss")
    }
    return result
  }

  const isDisable = () =>
    isPendingCreatePost ||
    isPendingCreateEvent ||
    isPendingEditNews ||
    isPendingCreateEvent ||
    !watchTitle?.trim() ||
    !watchContent?.trim() ||
    !watchThumbnail ||
    watchTitle?.trim()?.length > MAX_LENGTH_TITLE ||
    (type === CreateType.CREATE_EVENT && !watchEndTime) ||
    (type === CreateType.CREATE_EVENT && !watchStartTime) ||
    (type === CreateType.CREATE_EVENT && !watchLinkEvent?.trim()) ||
    isLoading

  const onSubmit = (data: typeof initialCreatePostValues) => {
    const result = tranformDataBeforeSubmit(data)
    setIsLoading(true)
    if (type === CreateType.CREATE_POST) {
      mutateCreatePost(result, {
        async onSuccess(data: any, variables, context) {
          GAEventsTracker("Post_Created")

          await Promise.all([
            queryClient.invalidateQueries({
              queryKey: [QUERY_KEY.PROFILE_INFO]
            }),
            queryClient.invalidateQueries({
              queryKey: [QUERY_KEY.LIST_NEWS]
            }),
            queryClient.invalidateQueries({
              queryKey: [QUERY_KEY.LIST_NEWS_BY_USER, 1]
            }),
            queryClient.invalidateQueries({
              queryKey: [QUERY_KEY.LIST_NEWS_BY_USER, -1]
            }),
            queryClient.invalidateQueries({
              queryKey: [QUERY_KEY.LIST_NEWS_LATEST]
            })
          ])
        },
        onError(error, variables, context) {},
        onSettled(data: any, error, variables, context) {
          setIsLoading(false)
          if (
            Number(data?.data?.data?.post?.public_at) * 1000 >
            new Date().getTime()
          ) {
            setNavigateUrl(`/preview/news/${data?.data?.data?.post?.id}`)
          } else
            setNavigateUrl(
              `/detail/news/${createSlugNameDetail(
                data?.data?.data?.post?.title,
                data?.data?.data?.post?.id
              )}`
            )
        }
      })
    } else {
      mutateCreateEvent(result, {
        async onSuccess(data: any, variables, context) {
          GAEventsTracker("Post_Created")
          await Promise.all([
            queryClient.invalidateQueries({
              queryKey: [QUERY_KEY.PROFILE_INFO]
            }),
            queryClient.invalidateQueries({
              queryKey: [QUERY_KEY.LIST_EVENT, ""]
            }),
            queryClient.invalidateQueries({
              queryKey: [QUERY_KEY.LIST_EVENT_BY_TYPE, "all__"]
            }),
            queryClient.invalidateQueries({
              queryKey: [QUERY_KEY.MY_EVENT_BY_FILTER, "1-10"]
            }),
            queryClient.invalidateQueries({
              queryKey: [QUERY_KEY.MY_EVENT_BY_FILTER, "-1-10"]
            })
          ])
        },
        onError(error, variables, context) {},
        onSettled(data: any, error, variables, context) {
          setIsLoading(false)
          if (
            Number(data?.data?.data?.post?.public_at) * 1000 >
            new Date().getTime()
          ) {
            setNavigateUrl(`/preview/event/${data?.data?.data?.post?.id}`)
          } else
            setNavigateUrl(
              `/detail/event/${createSlugNameDetail(
                data?.data?.data?.post?.title,
                data?.data?.data?.post?.id
              )}`
            )
        }
      })
    }
  }

  const onEdit = (data: typeof initialCreatePostValues) => {
    const result = tranformDataBeforeSubmit(data)
    let id: string = String(editId)
    if (typeof editId === "undefined" && !!dataDraft?.id) {
      id = dataDraft?.id
    }
    setIsLoading(true)
    if (type === CreateType.CREATE_POST) {
      mutateEditNews(
        { ...result, id: id },
        {
          async onSuccess(data: any, variables, context) {
            GAEventsTracker("Post_Edited")

            await Promise.all([
              queryClient.invalidateQueries({
                queryKey: [QUERY_KEY.PROFILE_INFO]
              }),
              queryClient.invalidateQueries({
                queryKey: [QUERY_KEY.NEWS_DETAIL, id]
              }),
              queryClient.invalidateQueries({
                queryKey: [QUERY_KEY.NEWS_DETAIL_FOR_EDIT, id]
              }),
              queryClient.invalidateQueries({
                queryKey: [QUERY_KEY.LIST_NEWS]
              }),
              queryClient.invalidateQueries({
                queryKey: [QUERY_KEY.CHECK_DRAFT]
              }),
              queryClient.invalidateQueries({
                queryKey: [QUERY_KEY.LIST_NEWS_LATEST]
              }),
              queryClient.invalidateQueries({
                queryKey: [QUERY_KEY.LIST_NEWS_BY_USER, 1]
              }),
              queryClient.invalidateQueries({
                queryKey: [QUERY_KEY.LIST_NEWS_BY_USER, -1]
              })
            ])
          },
          onError(error, variables, context) {},
          onSettled(data: any, error, variables, context) {
            setIsLoading(false)
            if (
              Number(data?.data?.data?.post?.public_at) * 1000 >
              new Date().getTime()
            ) {
              setNavigateUrl(`/preview/news/${data?.data?.data?.post?.id}`)
            } else
              setNavigateUrl(
                `/detail/news/${createSlugNameDetail(
                  data?.data?.data?.post?.title,
                  data?.data?.data?.post?.id
                )}`
              )
          }
        }
      )
    } else {
      mutateEditEvent(
        { ...result, id: id },
        {
          async onSuccess(data: any, variables, context) {
            GAEventsTracker("Post_Edited")
            await Promise.all([
              queryClient.invalidateQueries({
                queryKey: [QUERY_KEY.PROFILE_INFO]
              }),
              queryClient.invalidateQueries({
                queryKey: [QUERY_KEY.EVENT_DETAIL, id]
              }),
              queryClient.invalidateQueries({
                queryKey: [QUERY_KEY.EVENT_DETAIL_FOR_EDIT, id]
              }),
              queryClient.invalidateQueries({
                queryKey: [QUERY_KEY.LIST_EVENT, ""]
              }),
              queryClient.invalidateQueries({
                queryKey: [QUERY_KEY.MY_EVENT_BY_FILTER, "1-10"]
              }),
              queryClient.invalidateQueries({
                queryKey: [QUERY_KEY.MY_EVENT_BY_FILTER, "-1-10"]
              }),
              queryClient.invalidateQueries({
                queryKey: [QUERY_KEY.CHECK_DRAFT_EVENT]
              })
            ])
          },
          onError(error, variables, context) {},
          onSettled(data: any, error, variables, context) {
            setIsLoading(false)
            if (
              Number(data?.data?.data?.post?.public_at) * 1000 >
              new Date().getTime()
            ) {
              setNavigateUrl(`/preview/event/${data?.data?.data?.post?.id}`)
            } else
              setNavigateUrl(
                `/detail/event/${createSlugNameDetail(
                  data?.data?.data?.post?.title,
                  data?.data?.data?.post?.id
                )}`
              )
          }
        }
      )
    }
  }

  /** Save Draft */
  const onSaveDraft = () => {
    const result: any = {
      title: watchTitle,
      content: watchContent,
      hashtag: getValues("tags"),
      game_id: getValues("selectedGames")?.map((item) => item.id)[0],
      public_at: moment(getValues("public_at")).format("YYYY/MM/DD HH:mm:ss "),
      thumbnail: getValues("thumbnail")
    }

    if (!!watchLinkEvent && type === CreateType.CREATE_EVENT) {
      result.link_event = watchLinkEvent
    }

    if (!!watchStartTime && type === CreateType.CREATE_EVENT) {
      result.start_time = moment(watchStartTime).format("YYYY-MM-DD HH:mm:ss")
    }
    if (!!watchEndTime && type === CreateType.CREATE_EVENT) {
      result.end_time = moment(watchEndTime).format("YYYY-MM-DD HH:mm:ss")
    }
    const mutate =
      type === CreateType.CREATE_EVENT ? mutateSaveDraftEvent : mutateSaveDraft
    mutate(result, {
      onSuccess(data, variables, context) {
        setShowAlert(false)

        router.back()
      },
      onError(error, variables, context) {}
    })
  }

  /** DeleteDraft */
  const onDeleteDraft = () => {
    const mutate =
      type === CreateType.CREATE_EVENT
        ? mutateDeleteDraftEvent
        : mutateDeleteDraft
    mutate(
      { id: dataDraft?.id },
      {
        onSuccess(data, variables, context) {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY.CHECK_DRAFT],
            refetchType: "active"
          })
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY.CHECK_DRAFT_EVENT],
            refetchType: "active"
          })
          setShowAlertDraft(false)
        },
        onError(error, variables, context) {}
      }
    )
  }

  const watchTitle = watch("title", "")
  const watchContent = watch("content", "")
  const watchThumbnail = watch("thumbnail", "")
  const watchStartTime = watch("start_time", undefined)
  const watchEndTime = watch("end_time", undefined)
  const watchLinkEvent = watch("link_event", "")
  // true: save draft, false: cancel save
  const onSubmitAlert = (value: boolean) => {
    if (value) {
      onSaveDraft()
    } else {
      setShowAlert(false)
      router.push("/news")
    }
  }

  const throwError = (message: string) => {
    toast({
      variant: "error",
      title: message
    })
  }
  const showAlertSaveDraft = () => {
    if (!!watchTitle && !!watchContent && !router?.query?.editId) {
      setShowAlert(true)
    } else router.push("/news")
  }

  useEffect(() => {
    router.beforePopState(({ as }) => {
      if (as !== router.asPath) {
        // Will run when leaving the current page; on back/forward actions
        // Add your logic here, like toggling the modal state
        showAlertSaveDraft()
      }
      return false
    })

    // return () => {
    //     router.beforePopState(() => true);
    // };
  }, [router])

  return (
    <main className="overflow-auto relative w-full flex flex-col justify-center items-start md:items-center py-2 md:py-[100px] gap-[48px] md:gap-0">
      {/* Web back UI */}
      <div
        className="hidden ld:flex absolute top-[50px] left-[75px] w-[32px] h-[32px] bg-main-01 rounded-xl items-center justify-center cursor-pointer p-0"
        onClick={showAlertSaveDraft}
      >
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="text-white"
        >
          <path
            d="M11.6667 9.3335L7 14.0002M7 14.0002L11.6667 18.6668M7 14.0002L21 14.0002"
            stroke="#FAF8FF"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>

      {/* Mobile back and show add-on Ui */}
      <div className="flex items-center justify-between w-full p-4 ld:hidden">
        <div
          className="flex w-[32px] h-[32px] bg-bg-disable rounded-[8px] items-center justify-center dark:bg-[#efefff]/10"
          onClick={showAlertSaveDraft}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 7L10 12L14 17"
              stroke="#7673E6"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div
          className="w-[40px] h-[32px] flex items-center justify-center bg-bg-disable rounded-[8px] dark:bg-[#efefff]/10"
          onClick={() => setHidden(true)}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.168 2.33268L15.168 13.666C15.168 13.9393 14.9413 14.166 14.668 14.166C14.3946 14.166 14.168 13.9393 14.168 13.666L14.168 2.33268C14.168 2.05935 14.3946 1.83268 14.668 1.83268C14.9413 1.83268 15.168 2.05935 15.168 2.33268Z"
              fill="#7673E6"
            />
            <path
              d="M14.6209 8.28715C14.5313 8.37672 14.4088 8.43329 14.2674 8.43329L1.06803 8.43328C0.794616 8.43328 0.568342 8.20701 0.568342 7.93359C0.568342 7.66018 0.794615 7.43391 1.06803 7.43391L14.2674 7.43391C14.5408 7.43391 14.767 7.66018 14.767 7.9336C14.767 8.07502 14.7105 8.19758 14.6209 8.28715Z"
              fill="#7673E6"
            />
            <path
              d="M6.22435 13.2065C6.02729 13.4036 5.70048 13.4036 5.50342 13.2065L0.567493 8.27062C0.370441 8.07357 0.370441 7.74675 0.567494 7.54969L5.50342 2.61377C5.70048 2.41671 6.0273 2.41671 6.22435 2.61377C6.4214 2.81082 6.4214 3.13764 6.22435 3.33469L1.64888 7.91016L6.22435 12.4856C6.4214 12.6827 6.4214 13.0095 6.22435 13.2065Z"
              fill="#7673E6"
            />
          </svg>
        </div>
      </div>

      <FormProvider {...methods}>
        <div className="flex-1 w-full max-w-full md:w-[55%] md:max-w-[55%] min-h-[80vh]">
          <Controller
            name="title"
            control={control}
            render={({ field }) => (
              <>
                <h1
                  className="editor-title w-full text-bg-01 dark:text-white Nunito-700 text-[24px] md:text-[40px] outline-none bg-transparent border-0 px-4 md:px-0"
                  contentEditable
                  ref={titleRef}
                  placeholder={t("news.please_input_title", {
                    field:
                      type === CreateType.CREATE_EVENT
                        ? t("news.event")
                        : t("news.post")
                  })}
                  onInput={(e: any) => {
                    setValue(field?.name, e?.target?.innerText)
                  }}
                />
                <div className="mt-[32px] px-4 md:px-0">
                  <p className="text-base text-bg-01 Nunito-500 dark:text-white">
                    {`(${field.value?.length}/${MAX_LENGTH_TITLE} ký tự)`}
                  </p>
                  {field.value?.length > MAX_LENGTH_TITLE && (
                    <p className="text-xs text-main-red-02 Nunito-400">
                      {t("news.limit_title_alert")}
                    </p>
                  )}
                </div>
              </>
            )}
          />
          <Controller
            name="content"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Editor
                id={"post-editor"}
                value={value}
                onChange={onChange}
                themApp={themeApp}
                throwError={throwError}
              />
            )}
          />
        </div>
        <CreatePostAddon
          hidden={hidden}
          setHidden={setHidden}
          onSubmit={editId ? onEdit : dataDraft?.id ? onEdit : onSubmit}
          disable={isDisable()}
        />
      </FormProvider>

      {/* Modal show alert if go back while editting */}
      <Dialog open={showAlert} onOpenChange={() => setShowAlert(false)}>
        <DialogContent className="rounded-[24px] sm:rounded-[24px] max-w-[95vw] md:max-w-[320px] p-[24px] border-0 focus-visible:outline-none">
          <div className="flex flex-col items-center justify-center w-full">
            <p className="text-base Nunito-700 text-bg-01 dark:text-white">
              {t("news.save_draft")}
            </p>
            <div className="flex items-center justify-end w-full mt-[32px] gap-[16px]">
              <Button
                variant="none"
                className="text-sm bg-bg-button text-main-01 Nunito-700 rounded-[8px] px-[16px] py-[6px] h-[36px]"
                autoFocus={false}
                onClick={() => onSubmitAlert(false)}
              >
                {t("common.cancel")}
              </Button>
              <Button
                variant="none"
                className="text-sm text-white bg-main-01 Nunito-700 rounded-[8px] px-[16px] py-[6px] h-[36px]"
                onClick={() => onSubmitAlert(true)}
              >
                {t("profile.save")}
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      {/* Modal show alert if exist a draft */}
      <Dialog open={showAlertDraft}>
        <DialogContent className="sm:rounded-[24px] md:max-w-[320px] p-[24px] border-0 focus-visible:outline-none">
          <div className="flex flex-col items-center justify-center w-full">
            <p className="text-base Nunito-700 text-bg-01">
              {t("news.draft_exist")}
            </p>
            <div className="flex items-center justify-end w-full mt-[32px] gap-[16px]">
              <Button
                variant="none"
                className="text-sm bg-bg-button text-main-01 Nunito-700"
                autoFocus={false}
                disabled={isPendingDeleteDraft || isPendingDeleteDraftEvent}
                onClick={onDeleteDraft}
              >
                {t("news.delete_draft")}
              </Button>
              <Button
                variant="none"
                className="text-sm text-white bg-main-01 Nunito-700"
                disabled={isPendingDeleteDraft || isPendingDeleteDraftEvent}
                onClick={() => fillDataIfExist(dataDraft)}
              >
                {t("news.continue_edit")}
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </main>
  )
}
