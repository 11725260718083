import { Button } from "@/app/components/ui/button"
import { DATE_FORMAT, INTERACTIVE_TYPE, MISSION_TYPE } from "@/app/configs"
import { MobileContext } from "@/app/context"
import useGAEventsTracker from "@/app/hooks/useGAEventsTracker"
import moment from "moment"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import React, { FC, useContext, useEffect, useState } from "react"

interface InteractiveMissionProps {
  dataReward: any
}

export const InteractiveMission: FC<InteractiveMissionProps> = ({
  dataReward
}) => {
  const { t } = useTranslation()
  const [listMission, setListMission] = useState<any>({})
  const router = useRouter()
  const { isMobile } = useContext(MobileContext)
  const GAEventsTracker = useGAEventsTracker("Reward")

  const onDoMission = (item: any) => {
    GAEventsTracker("Mission_do_begin", item?.title)
    if (!item?.my_task?.totalTaskSuccess) {
      if (item?.condition?.interaction === INTERACTIVE_TYPE.JOIN_GROUP) {
        router.push(`/groups/${item?.condition?.group_alias}`)
      } else if (
        item?.condition?.interaction === INTERACTIVE_TYPE.POST_CONTENT
      ) {
        router.push(`/`)
      } else if (
        item?.condition?.interaction === INTERACTIVE_TYPE.COMMENT_CONTENT
      ) {
        router.push(`/detail/news/${item?.condition?.news_id}`)
      } else if (
        item?.condition?.interaction === INTERACTIVE_TYPE.FOLLOW_USER
      ) {
        router.push(`/user/${item?.condition?.user_id}`)
      } else {
        router.push(`/game/${item?.condition?.slug_name}?tab=rating`)
      }
    }
  }

  useEffect(() => {
    setListMission(
      dataReward?.filter((item: any) => {
        return item?.type === MISSION_TYPE.INTERACTIVE
      })
    )
  }, [dataReward])

  return listMission?.length ? (
    <div className="mt-[40px] md:mt-0">
      <h1 className="text-[16px] Nunito-700 pb-[24px]">
        {t("reward.mission")}
      </h1>
      <div className="grid md:grid-cols-2 gap-[16px] md:gap-x-[24px] sd:gap-x-[56px] gap-y-[16px] sd:gap-y-[24px]">
        {listMission?.map((item: any, index: number) => {
          return (
            <div
              key={index}
              onClick={() => {
                onDoMission(item)
              }}
              className="w-full p-[12px] rounded-[16px] border border-black/10 dark:border-neutral-03 flex gap-[12px] items-center cursor-pointer"
            >
              {item?.condition?.game_icon || item?.condition?.group_avatar ? (
                <img
                  src={
                    item?.condition?.game_icon || item?.condition?.group_avatar
                  }
                  alt={item?.title}
                  title={item?.title}
                  width={48}
                  height={48}
                  className="rounded-[12px]"
                />
              ) : (
                <div
                  className="w-[48px] h-[48px] rounded-[12px] flex items-center justify-center"
                  style={{
                    background:
                      "linear-gradient(268deg, #B6B4F8 -15.03%, #AEAAF8 37.55%, #A39FD1 100%)"
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="26"
                    viewBox="0 0 24 26"
                    fill="none"
                  >
                    <path
                      d="M7.33333 10.6667H16.6667M7.33333 15.3334H16.6667M7.33333 20H12M7.33333 3.66671C7.33333 4.95537 8.378 6.00004 9.66667 6.00004H14.3333C15.622 6.00004 16.6667 4.95537 16.6667 3.66671M7.33333 3.66671C7.33333 2.37804 8.378 1.33337 9.66667 1.33337H14.3333C15.622 1.33337 16.6667 2.37804 16.6667 3.66671M7.33333 3.66671H6.16667C3.58934 3.66671 1.5 5.75605 1.5 8.33337V20C1.5 22.5774 3.58934 24.6667 6.16667 24.6667H17.8333C20.4107 24.6667 22.5 22.5774 22.5 20V8.33337C22.5 5.75605 20.4107 3.66671 17.8333 3.66671H16.6667"
                      stroke="#FAF8FF"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>
              )}
              <div>
                <p className="text-[14px] Nunito-600">{item.title}</p>
                <p className="text-[12px] text-neutral-05 dark:text-neutral-08">
                  {moment(item.start_time).format(DATE_FORMAT)} -{" "}
                  {moment(item.end_time).format(DATE_FORMAT)}
                </p>
              </div>
              <div className="ml-auto mr-0">
                {!item?.my_task?.totalTaskSuccess ? (
                  <Button
                    variant="main"
                    className="h-[32px] px-[20px] rounded-[12px] text-[12px]"
                  >
                    {t("reward.do_now")}
                  </Button>
                ) : (
                  <div
                    className={`w-[36px] h-[36px] rounded-[36px] flex items-center justify-center bg-bg-green`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="56"
                      height="56"
                      viewBox="0 0 56 56"
                      fill="none"
                      className="w-[20px] h-[20px]"
                    >
                      <path
                        d="M28.1438 5.14404C15.5495 5.14404 5.28662 15.4069 5.28662 28.0012C5.28662 40.5955 15.5495 50.8583 28.1438 50.8583C40.7381 50.8583 51.0009 40.5955 51.0009 28.0012C51.0009 15.4069 40.7381 5.14404 28.1438 5.14404ZM39.0695 22.744L26.1095 35.704C25.7895 36.024 25.3552 36.2069 24.8981 36.2069C24.4409 36.2069 24.0066 36.024 23.6866 35.704L17.2181 29.2355C16.5552 28.5726 16.5552 27.4755 17.2181 26.8126C17.8809 26.1498 18.9781 26.1498 19.6409 26.8126L24.8981 32.0698L36.6466 20.3212C37.3095 19.6583 38.4066 19.6583 39.0695 20.3212C39.7323 20.984 39.7323 22.0583 39.0695 22.744Z"
                        fill="#27908C"
                      />
                    </svg>
                  </div>
                )}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  ) : null
}
