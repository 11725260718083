import { AxiosResponse } from "axios"
import IApiResponse from "@interfaces/IApiResponse"
import { http } from "@/app/api"

const resource = "/v2/analytics"

export class AnalyticService {
  static getTotalNews(params?: {
    start_time?: string
    end_time?: string
  }): Promise<AxiosResponse<IApiResponse & { total: number }>> {
    return http.get(`${resource}/totalNews`, { params })
  }
  static getTotalLikeNews(params?: {
    start_time?: string
    end_time?: string
  }): Promise<AxiosResponse<IApiResponse & { total: number }>> {
    return http.get(`${resource}/totalLike`, { params })
  }

  static getTotalCommentNews(params?: {
    start_time?: string
    end_time?: string
  }): Promise<AxiosResponse<IApiResponse & { total: number }>> {
    return http.get(`${resource}/totalComment`, { params })
  }

  static getTotalEvent(params?: {
    start_time?: string
    end_time?: string
  }): Promise<AxiosResponse<IApiResponse & { total: number }>> {
    return http.get(`${resource}/totalEvents`, { params })
  }
  static getTotalUserJoinedEvent(params?: {
    start_time?: string
    end_time?: string
  }): Promise<AxiosResponse<IApiResponse & { total: number }>> {
    return http.get(`${resource}/totalUserJoinedEvent`, { params })
  }
  static getTotalUserFollowPartner(params?: {
    start_time?: string
    end_time?: string
  }): Promise<AxiosResponse<IApiResponse & { total: number }>> {
    return http.get(`${resource}/totalUserFollowPartner`, { params })
  }

  static getListDataEvent(params?: {
    start_time?: string
    end_time?: string
    page: number
  }): Promise<AxiosResponse<IApiResponse<any>>> {
    return http.get(`${resource}/dataEvents`, { params })
  }
  static getListDataNews(params?: {
    start_time?: string
    end_time?: string
    page: number
  }): Promise<AxiosResponse<IApiResponse<any>>> {
    return http.get(`${resource}/dataNews`, { params })
  }

  static getChartData(params: {
    start_time?: string
    end_time?: string
  }): Promise<AxiosResponse<IApiResponse<any>>> {
    return http.get(`${resource}/chartData`, { params })
  }
}
