import { QUERY_KEY, isClient } from "@/app/configs"
import {
  IGetListMerchandiseParams,
  IPramsPaymentFeeShip
} from "@/app/interfaces"
import { useQuery } from "@tanstack/react-query"
import { MerchandiseService } from "../../services/MerchandiseService"

const getListMerchandiseFn = async (params: IGetListMerchandiseParams) => {
  const data = await MerchandiseService.getListMerchandise(params)
  return data.data
}

const getListCategoryFn = async () => {
  const data = await MerchandiseService.getListCategory()
  return data.data.data
}

const getListGameMerchandiseFn = async () => {
  const data = await MerchandiseService.getListGame()
  return data.data.data
}

const getListMerchandiseCartFn = async () => {
  const data = await MerchandiseService.getListCart()
  return data.data.data
}

const getListMerchandiseDetailFn = async (id: string) => {
  const data = await MerchandiseService.getMerchandiseDetail(id)
  return data.data.data
}

const getListMerchandiseMethodFn = async () => {
  const data = await MerchandiseService.getMerchandiseMethod()
  return data.data.data
}

const getListMerchandiseAddressFn = async () => {
  const data = await MerchandiseService.getMerchandiseListAddress()
  return data.data.data
}

const getMerchandisePaymentCallbackFn = async (body: any) => {
  const data = await MerchandiseService.getPaymentMerchandiseCallback(body)
  return data.data
}

const getMerchandiseOnepayCallbackFn = async (body: any) => {
  const data = await MerchandiseService.getPaymentMerchandiseOnepayCallback(
    body
  )
  return data.data
}

const getMerchandisePaymentTransaction = async ({
  transaction_id
}: {
  transaction_id: string
}) => {
  const data = await MerchandiseService.getPaymentMerchandiseTransaction({
    transaction_id
  })
  return data.data
}

export const useQueryGetMerchandisePaymentTransaction = ({
  transaction_id
}: {
  transaction_id: string
}) => {
  return useQuery({
    queryKey: [QUERY_KEY.PAYMENT_TRANSACTION_MERCHANDISE, { transaction_id }],
    queryFn: () => getMerchandisePaymentTransaction({ transaction_id }),
    enabled: !!transaction_id,
    refetchOnWindowFocus: false,
    retry: 0
  })
}

export const useQueryGetMerchandiseOnepayCallBack = (params: any) => {
  return useQuery({
    queryKey: [QUERY_KEY.CALLBACK_ONEPAY_MERCHANDISE, params],
    queryFn: () => getMerchandiseOnepayCallbackFn(params),
    refetchOnWindowFocus: false,
    retry: 0
  })
}

export const useQueryGetMerchandisePaymentCallBack = (params: any) => {
  return useQuery({
    queryKey: [QUERY_KEY.CALLBACK_PAYMENT_MERCHANDISE, params],
    queryFn: () => getMerchandisePaymentCallbackFn(params),
    refetchOnWindowFocus: false,
    retry: 0
  })
}

export const useQueryGetListMerchandiseAddress = () => {
  return useQuery({
    queryKey: [QUERY_KEY.MERCHANDISE_ADDRESS],
    queryFn: () => getListMerchandiseAddressFn(),
    refetchOnWindowFocus: false,
    retry: 0
  })
}

export const useQueryGetListMerchandise = (
  params: IGetListMerchandiseParams
) => {
  return useQuery({
    queryKey: [QUERY_KEY.MERCHANDISE_LIST, params],
    queryFn: () => getListMerchandiseFn(params),
    enabled: !!params,
    refetchOnWindowFocus: false,
    retry: 0
  })
}

export const useQueryGetListCategory = () => {
  return useQuery({
    queryKey: [QUERY_KEY.MERCHANDISE_CATEGORY_LIST],
    queryFn: () => getListCategoryFn(),
    refetchOnWindowFocus: false,
    retry: 0
  })
}

export const useQueryGetListGameMechandise = () => {
  return useQuery({
    queryKey: [QUERY_KEY.MERCHANDISE_GAME_LIST],
    queryFn: () => getListGameMerchandiseFn(),
    refetchOnWindowFocus: false,
    retry: 0
  })
}

export const useQueryGetListMerchandiseCart = () => {
  const token = isClient ? window.localStorage.getItem("token") : null
  return useQuery({
    queryKey: [QUERY_KEY.MERCHANDISE_CART],
    queryFn: () => getListMerchandiseCartFn(),
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: !!token
  })
}

export const useQueryGetListMerchandiseDetail = (id: string) => {
  return useQuery({
    queryKey: [QUERY_KEY.MERCHANDISE_DETAIL, { id }],
    queryFn: () => getListMerchandiseDetailFn(id),
    refetchOnWindowFocus: false,
    retry: 0
  })
}

export const useQueryGetListMerchandiseMethod = () => {
  return useQuery({
    queryKey: [QUERY_KEY.MERCHANDISE_METHOD],
    queryFn: () => getListMerchandiseMethodFn(),
    refetchOnWindowFocus: false,
    retry: 0
  })
}
