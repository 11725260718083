import { DataEmpty } from "@/app/components/data-empty"
import { IconSearch } from "@/app/components/icon-tsx"
import { IconClose } from "@/app/components/icon-tsx/IconClose"
import { Button } from "@/app/components/ui/button"
import { Dialog, DialogContent, DialogHeader } from "@/app/components/ui/dialog"
import { AppContext } from "@/app/context"
import { removeVietnameseTones } from "@/app/helpers/function"
import { IGame, IGameDetailRequest } from "@/app/interfaces"
import { useQueryGetGameFollow } from "@/app/services/query-fn"
import { useMutationFollowGame } from "@/app/services/query-fn/game"
import { ScrollArea } from "@radix-ui/react-scroll-area"
import { useTranslation } from "next-i18next"
import React, { useContext, useState } from "react"

interface ModalProps {
  open: boolean
  onClose: () => void
}

export const ModalGameFollowing: React.FC<ModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation()
  const { themeApp } = useContext(AppContext)
  const [searchValue, setSearchValue] = useState<string>("")
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e?.target?.value)
  }
  const { data } = useQueryGetGameFollow(open)
  const { mutate } = useMutationFollowGame()

  const onFollowGame = (_data: IGameDetailRequest) => {
    mutate(_data)
  }
  const newfollowingList = () => {
    if (!!searchValue?.trim()?.length) {
      return (data?.game ?? []).filter((game: any) =>
        removeVietnameseTones(game.game_name)
          .toLowerCase()
          .includes(removeVietnameseTones(searchValue).toLowerCase())
      )
    } else return data?.game
  }

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:rounded-[24px] rounded-[24px] w-[90vw] md:max-w-[480px] p-0 dark:bg-bg-02">
        <DialogHeader className="w-full flex items-center justify-between flex-row border-b-[1px] border-black/10 py-[24px] px-[32px]">
          <p className="Nunito-700 text-[16px] text-bg-01 dark:text-white">
            {t("profile.follow_game")}
          </p>
          <div className="cursor-pointer" onClick={onClose}>
            <IconClose />
          </div>
        </DialogHeader>

        <div className="w-full px-[24px] pb-[32px]">
          <div className="flex items-center justify-between border-b-[1px] dark:border-0 gap-4 py-3 mb-4">
            <IconSearch fill={themeApp === "dark" ? "#fff" : "#100D28"} />
            <input
              type="text"
              value={searchValue}
              className="flex-1 text-sm border-0 outline-none dark:text-white dark:bg-transparent"
              placeholder="Tìm kiếm Game bạn đang theo dõi"
              onChange={onChange}
            />
          </div>
          <ScrollArea className="max-h-80 h-fit overflow-auto space-y-[12px]">
            {newfollowingList()?.length ? (
              newfollowingList()?.map((item: IGame, index) => (
                <div
                  className="flex items-center justify-between w-full py-2"
                  key={index}
                >
                  <div className="flex items-center gap-3">
                    <img
                      src={item?.icon}
                      alt={"avatar"}
                      width={56}
                      height={56}
                      className="object-cover rounded-[16px]"
                    />
                    <div className="flex flex-col gap-2">
                      <p className="flex-1 text-base Nunito-700 text-bg-01 dark:text-white">
                        {item?.game_name}
                      </p>
                      <div className="flex gap-[6px] items-center">
                        <div className="w-[14px] h-[14px] flex items-start justify-center">
                          <svg
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.85378 0.908161C5.32318 -0.0807139 6.67943 -0.0807157 7.14883 0.908159L7.96007 2.61719C8.14647 3.00987 8.50678 3.28205 8.92358 3.34502L10.7376 3.61908C11.7872 3.77765 12.2063 5.11874 11.4468 5.88847L10.1342 7.21876C9.83257 7.52443 9.69494 7.96482 9.76614 8.39642L10.076 10.2748C10.2553 11.3617 9.15807 12.1905 8.21927 11.6774L6.59678 10.7905C6.22399 10.5867 5.77862 10.5867 5.40582 10.7905L3.78333 11.6774C2.84453 12.1905 1.7473 11.3617 1.92659 10.2748L2.23646 8.39642C2.30766 7.96482 2.17003 7.52443 1.86843 7.21877L0.555813 5.88847C-0.203693 5.11874 0.21541 3.77765 1.26502 3.61908L3.07902 3.34502C3.49582 3.28205 3.85613 3.00987 4.04253 2.61719L4.85378 0.908161Z"
                              fill="#7673E6"
                            />
                          </svg>
                        </div>

                        <span className="text-xs Nunito-400 text-neutral dark:text-neutral-08">
                          {item?.rating?.total}
                        </span>
                        <div className="w-[4px] h-[4px] bg-neutral rounded-full dark:bg-white" />
                        <span className="text-xs Nunito-400 text-neutral dark:text-neutral-08">
                          {item?.developer}
                        </span>
                      </div>
                    </div>
                  </div>

                  <Button
                    variant="none"
                    className="outline-none bg-transparent text-neutral-06 h-[27px] px-[12px] flex items-center justify-center border-[1px] border-neutral-06 rounded-[8px] dark:text-neutral-08"
                    onClick={() => {
                      onFollowGame({
                        game_id: item?.id
                      })
                    }}
                  >
                    {t("profile.unfollow")}
                  </Button>
                </div>
              ))
            ) : (
              <div className="w-full flex items-center justify-center h-[200px]">
                <DataEmpty title={t("profile.no_data")} />
              </div>
            )}
          </ScrollArea>
        </div>
      </DialogContent>
    </Dialog>
  )
}
