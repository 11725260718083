import { IconClose } from "@/app/components/icon-tsx/IconClose"
import { Button } from "@/app/components/ui/button"
import { Dialog, DialogContent } from "@/app/components/ui/dialog"
import {
  DATE_FORMAT,
  DATE_FORMAT_FULL,
  GIFTCODE_TYPE,
  PACKAGE_TYPE,
  QUERY_KEY
} from "@/app/configs"
import { PackageContext } from "@/app/context/PackageContext"
import { useTranslation } from "next-i18next"
import React, { FC, useContext, useEffect } from "react"
import ImagePackage from "public/img/reward/package_default.png"
import ImageVoucher from "public/img/reward/voucher_default.png"
import ImageGiftcode from "public/img/reward/giftcode_default.png"
import moment from "moment"
import { useQueryClient } from "@tanstack/react-query"
import { useToast } from "@/app/components/ui/use-toast"
import { useMutationUseItem } from "@/app/services/query-fn/reward-center/package/package-mutation"
import useGAEventsTracker from "@/app/hooks/useGAEventsTracker"

interface ModalCardDetailProps {
  open: boolean
  handleClose: () => void
  listItem: any
}

export const ModalCardDetail: FC<ModalCardDetailProps> = ({
  open,
  handleClose,
  listItem
}) => {
  const { visible, setVisible, dataSelected, setDataSelected } =
    useContext(PackageContext)
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const { mutate, isPending } = useMutationUseItem()
  const GAEventsTracker = useGAEventsTracker("Inventory")

  const onClose = () => {
    setVisible({
      ...visible,
      view: false
    })
  }

  const onUse = async (item: any) => {
    GAEventsTracker("Inventory_used_voucher", item?.name)
    mutate(
      { item_id: item.id },
      {
        onSuccess: () => {
          queryClient.refetchQueries({
            queryKey: [QUERY_KEY.PACKAGE_LIST]
          })

          toast({
            variant: "success",
            title: "Áp dụng thành công!"
          })
        },
        onError: () => {}
      }
    )
  }

  useEffect(() => {
    if (listItem?.length && dataSelected) {
      const _selected = listItem?.find(
        (item: any) => item?.id === dataSelected.id
      )

      setDataSelected(_selected)
    }
  }, [listItem, dataSelected])

  const onCopyCode = () => {
    GAEventsTracker("Inventory_copied_voucher", dataSelected?.name)
    if (dataSelected?.type === PACKAGE_TYPE.GIFT_VOUCHER) {
      navigator.clipboard.writeText(
        dataSelected?.item_received?.gift_voucher || ""
      )
    }

    if (dataSelected?.type === PACKAGE_TYPE.VOUCHER) {
      navigator.clipboard.writeText(
        dataSelected?.item_received?.voucher_code || ""
      )
    }

    toast({
      variant: "success",
      title: "Sao chép thành công!"
    })
    onClose()
  }

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className="max-w-[502px] border-0 shadow-[0px_4px_30px_0px_rgba(0,0,0,0.06)] rounded-[24px] p-[24px] sm:rounded-[24px] gap-0 space-y-[24px] xs:w-[90vw]">
        <div className="flex items-center justify-between">
          <p className="text-[16px] Nunito-700">Voucher</p>
          <IconClose className="cursor-pointer" onClick={() => onClose()} />
        </div>
        <div className="">
          <div className="flex-1 space-y-[24px]">
            <div>
              <p className="text-[14px] text-neutral-05 dark:text-neutral-07">
                {t("reward.voucher_type")}
              </p>
              <p className="text-[16px] Nunito-500">{dataSelected?.name}</p>
            </div>
            <div>
              <p className="text-[14px] text-neutral-05 dark:text-neutral-07">
                {t("reward.received_time")}
              </p>
              <p className="text-[16px] Nunito-500">
                {moment.unix(dataSelected?.created_at).format(DATE_FORMAT)}
              </p>
            </div>

            {dataSelected?.item_received?.description && (
              <div>
                <p className="text-[14px] text-neutral-05 dark:text-neutral-07">
                  {t("reward.voucher_content")}
                </p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: dataSelected?.item_received?.description.replaceAll(
                      "\n",
                      "<br/>"
                    )
                  }}
                  className="text-[16px] Nunito-500"
                ></p>
              </div>
            )}

            {(dataSelected?.item_received?.gift_voucher ||
              dataSelected?.item_received?.voucher_code) && (
              <div className="border border-dashed border-black/10 dark:border-neutral-07 rounded-[12px] p-[12px] text-[24px] Nunito-700 text-main-01 text-center">
                {dataSelected?.type === PACKAGE_TYPE.GIFT_VOUCHER
                  ? dataSelected?.item_received?.gift_voucher
                  : dataSelected?.type === PACKAGE_TYPE.VOUCHER
                  ? dataSelected?.item_received?.voucher_code
                  : ""}
                {dataSelected?.item_received?.expired_date && (
                  <p className="text-[14px] text-neutral-05 dark:text-neutral-07 Nunito-400 leading-[19px]">
                    HSD:{" "}
                    {moment(dataSelected?.item_received?.expired_date).format(
                      DATE_FORMAT
                    )}
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="flex gap-[16px] justify-end items-center">
          <Button
            variant="grey"
            onClick={() => onClose()}
            className="text-neutral-05 dark:text-neutral-08 rounded-[16px] px-[24px] py-[12px] w-fit h-[46px]"
          >
            {t("merchandise.cancel")}
          </Button>
          <Button
            variant="main"
            loading={isPending}
            onClick={() => {
              onCopyCode()
            }}
            className="rounded-[16px] px-[24px] py-[12px] w-fit h-[46px]"
          >
            {t("reward.copy")}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
