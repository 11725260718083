import { PostCard } from "@/app/components/PostCard"
import { IconSearch } from "@/app/components/icon-tsx"
import { IPostCard } from "@/app/interfaces/IPost"
import { useQueryGetListStoredNews } from "@/app/services/query-fn/news"
import { useTranslation } from "next-i18next"
import React, { useContext, useMemo, useState } from "react"
import { useInView } from "react-intersection-observer"
import { FilterPostEvent } from "../../common/filter-post/FilterPostEvent"
import { DataEmpty } from "@/app/components/data-empty"
import { AppContext } from "@/app/context"
import { useRouter } from "next/router"
import { FormProvider, useForm } from "react-hook-form"
import { useDebounceEffect } from "@/app/hooks/useDebounceEffect"

export const initialFilterPost = {
  keyword: "",
  sort: -1
}

export const SavedPost = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const { themeApp } = useContext(AppContext)
  const id = useMemo(() => router?.query?.id, [router])
  const [tempSearch, setTempSearch] = useState<string>("")

  const methods = useForm<typeof initialFilterPost>({
    defaultValues: initialFilterPost
  })
  const watchKeyword = methods.watch("keyword", "")
  const watchSort = methods.watch("sort", -1)
  const { data: listNews, fetchNextPage } = useQueryGetListStoredNews(
    watchKeyword,
    watchSort
  )

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTempSearch(e?.target?.value)
  }

  useDebounceEffect(() => methods.setValue("keyword", tempSearch), 300, [
    tempSearch
  ])

  const { ref, inView } = useInView()

  React.useEffect(() => {
    if (inView) {
      fetchNextPage()
    }
  }, [fetchNextPage, inView])

  return (
    <div className="mt-4 md:mt-[32px] relative z-10">
      <div className="flex items-center justify-between w-full">
        <FormProvider {...methods}>
          <div className="max-w-[320px]">
            <div className="flex items-center justify-between border-b-[1px] dark:border-neutral-04 gap-4 py-3">
              <IconSearch
                fill={themeApp === "dark" ? "#7673E5" : "#100D28"}
                className="w-[20px] h-[20px]"
              />
              <input
                type="text"
                value={tempSearch}
                className="flex-1 text-sm bg-transparent border-0 outline-none"
                placeholder={t("profile.find_news_placeholder")}
                onChange={onChange}
              />
            </div>
          </div>
          <FilterPostEvent />
        </FormProvider>
      </div>
      {!!listNews && !!listNews?.pages?.flat()?.length ? (
        <div className="w-full mt-[24px] space-y-[24px]">
          {React.Children.toArray(
            listNews?.pages
              ?.flat()
              ?.map((item: IPostCard) => (
                <PostCard postcard={item} isCheckHashtag />
              ))
          )}
        </div>
      ) : (
        <div className="w-full flex items-center justify-center h-[200px]">
          <DataEmpty title={t("profile.no_data")} />
        </div>
      )}
      <button ref={ref} className="opacity-0">
        Load more
      </button>
    </div>
  )
}
