import { IUseGiftcodeParams } from "@/app/interfaces/IGiftcode"
import { GiftcodeServices } from "../../services/Giftcode"
import { useMutation } from "@tanstack/react-query"
import { QUERY_KEY } from "@/app/configs"

const useGiftcodeFn = (body: IUseGiftcodeParams) => {
  return GiftcodeServices.useGiftcode(body)
}

export const useMutationUseGiftcode = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.USE_GIFTCODE],
    mutationFn: useGiftcodeFn,
    onSuccess: (data, variables) => {}
  })
}
