import { AxiosResponse } from "axios"
import IApiResponse from "@interfaces/IApiResponse"
import { http } from "@/app/api"
import { IUser, IUserRequest } from "@/app/interfaces"

const resource = "/api/users"

export class FriendService {
  static getListFriend(
    page: number
  ): Promise<AxiosResponse<IApiResponse<IUser[]>>> {
    return http.get(`${resource}/listFriend`, {
      params: {
        page
      }
    })
  }
  static getListSearchFriend(
    page: number,
    keyword: string
  ): Promise<AxiosResponse<IApiResponse<IUser[]>>> {
    return http.get(`${resource}/searchFriend`, {
      params: {
        page,
        keyword
      }
    })
  }

  static getListSuggestFriend(
    page: number
  ): Promise<AxiosResponse<IApiResponse<IUser[]>>> {
    return http.get(`${resource}/suggestFriend`, {
      params: {
        page
      }
    })
  }

  static getListRequestFriend(
    page: number
  ): Promise<AxiosResponse<IApiResponse<IUserRequest[]>>> {
    return http.get(`${resource}/listRequestFriend`, {
      params: {
        page
      }
    })
  }

  static addFriend(body: {
    friend_id: string
  }): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`${resource}/addFriend`, body)
  }

  static unFriend(body: {
    friend_id: string
  }): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`${resource}/unFriend`, body)
  }

  static actionRequestFriend(body: {
    request_id: string
    status: "cancel" | "accepted"
  }): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`${resource}/acceptRequestFriend`, body)
  }

  static cancelSendingRequest(body: {
    request_id: string
  }): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`${resource}/removeRequestFriend`, body)
  }
}
