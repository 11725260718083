import { AppContext } from "@/app/context"
import { useContext } from "react"

export const IconArrowRight = ({ className }: { className?: string }) => {
  const { themeApp } = useContext(AppContext)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        d="M10 7L14 12L10 17"
        stroke="#7673E6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
