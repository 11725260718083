import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from "@/app/components/ui/dropdown-menu"
import { AppContext } from "@/app/context"
import { useTranslation } from "next-i18next"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { ModalUploadImage } from "../modal/ModalUploadImage"
import { useMutationUpdateProfile } from "@/app/services/query-fn/user/setting-mutation"
import { QUERY_KEY } from "@/app/configs"
import { useQueryClient } from "@tanstack/react-query"
import { useRouter } from "next/router"
import { useQueryGetProfileById } from "@/app/services/query-fn"
import { useWindowSize } from "@/app/hooks"
import { Avatar, AvatarFallback, AvatarImage } from "@/app/components/ui/avatar"
import { convertNameString } from "@/app/utils"

export const AvatarAndName = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const userId = useMemo(() => router?.query?.id, [router])
  const { userInfo } = useContext(AppContext)
  const [width] = useWindowSize()
  const [changeAvatar, setChangeAvatar] = useState<boolean>(false)
  const queryClient = useQueryClient()
  const { mutate: mutateRemoveFrame } = useMutationUpdateProfile()
  const { data: otherUser } = useQueryGetProfileById(
    userId !== userInfo?.userid && userId !== userInfo?.alias
      ? String(userId)
      : undefined
  )

  // useEffect(() => {
  //   if (
  //     !!userInfo &&
  //     userId !== userInfo?.userid &&
  //     userId !== userInfo?.alias &&
  //     !!otherUser
  //   ) {
  //     if (!otherUser?.authentic) {
  //       router.back()
  //     }
  //   }
  // }, [userId, userInfo, otherUser, router])

  const dataUser =
    userId !== userInfo?.userid && userId !== userInfo?.alias && !!otherUser
      ? otherUser
      : userInfo
  const onRemoveFrame = () => {
    mutateRemoveFrame(
      { fullname: userInfo?.fullname, revoke_frame: "yes" },
      {
        onSuccess(data, variables, context) {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY.PROFILE_INFO],
            refetchType: "active"
          })
        }
      }
    )
  }
  const onChangeAvatar = () => {
    setChangeAvatar(!changeAvatar)
  }
  return (
    <div className="flex flex-col items-center w-full">
      <div className="relative w-[80px] h-[80px] lg:w-[120px] lg:h-[120px]">
        <div className={`relative group z-10`}>
          <Avatar className="w-[80px] h-[80px] lg:w-[120px] lg:h-[120px]">
            <AvatarImage
              src={
                !!dataUser?.small_avatar
                  ? dataUser?.small_avatar
                  : !!dataUser?.avatar
                  ? dataUser?.avatar
                  : ""
              }
              alt={dataUser.fullname ?? ""}
              className="object-cover"
            />
            <AvatarFallback className="flex items-center justify-center w-full text-white bg-main-01">
              {convertNameString(dataUser.fullname ?? "")}
            </AvatarFallback>
          </Avatar>
          {!!dataUser?.frame_avatar && (
            <div
              className={`w-[80px] h-[80px] lg:w-[120px] lg:h-[120px] absolute top-0 left-0 rounded-full z-10`}
              style={{
                backgroundImage: `url(${dataUser?.frame_avatar})`,
                backgroundSize: "100%"
              }}
            ></div>
          )}
        </div>

        {(userId === userInfo?.userid || userId === userInfo?.alias) && (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <div className="absolute flex items-center justify-center w-[22px] h-[22px] bg-white rounded-full bottom-[-11px] left-[50%] translate-x-[-50%] cursor-pointer z-20">
                <div className="w-[20px] h-[20px] flex items-center justify-center bg-bg-button rounded-full relative z-30">
                  <svg
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.75129 3.72265C4.59812 3.95241 4.6602 4.26285 4.88997 4.41603C5.11973 4.5692 5.43017 4.50711 5.58334 4.27735L4.75129 3.72265ZM6.10483 2.59373L6.52085 2.87108V2.87108L6.10483 2.59373ZM10.8965 2.59373L11.3125 2.31638L10.8965 2.59373ZM11.418 4.27735C11.5711 4.50711 11.8816 4.5692 12.1113 4.41603C12.3411 4.26285 12.4032 3.95241 12.25 3.72265L11.418 4.27735ZM10.0007 9C10.0007 9.82843 9.32908 10.5 8.50065 10.5V11.5C9.88136 11.5 11.0007 10.3807 11.0007 9H10.0007ZM8.50065 10.5C7.67222 10.5 7.00065 9.82843 7.00065 9H6.00065C6.00065 10.3807 7.11994 11.5 8.50065 11.5V10.5ZM7.00065 9C7.00065 8.17157 7.67222 7.5 8.50065 7.5V6.5C7.11994 6.5 6.00065 7.61929 6.00065 9H7.00065ZM8.50065 7.5C9.32908 7.5 10.0007 8.17157 10.0007 9H11.0007C11.0007 7.61929 9.88136 6.5 8.50065 6.5V7.5ZM5.58334 4.27735L6.52085 2.87108L5.6888 2.31638L4.75129 3.72265L5.58334 4.27735ZM7.21423 2.5H9.78707V1.5H7.21423V2.5ZM10.4804 2.87108L11.418 4.27735L12.25 3.72265L11.3125 2.31638L10.4804 2.87108ZM9.78707 2.5C10.0657 2.5 10.3259 2.63925 10.4804 2.87108L11.3125 2.31638C10.9725 1.80635 10.4001 1.5 9.78707 1.5V2.5ZM6.52085 2.87108C6.67541 2.63925 6.9356 2.5 7.21423 2.5V1.5C6.60125 1.5 6.02882 1.80635 5.6888 2.31638L6.52085 2.87108ZM4.50065 4.5H12.5007V3.5H4.50065V4.5ZM14.6673 6.66667V11.3333H15.6673V6.66667H14.6673ZM12.5007 13.5H4.50065V14.5H12.5007V13.5ZM2.33398 11.3333V6.66667H1.33398V11.3333H2.33398ZM4.50065 13.5C3.30403 13.5 2.33398 12.53 2.33398 11.3333H1.33398C1.33398 13.0822 2.75175 14.5 4.50065 14.5V13.5ZM14.6673 11.3333C14.6673 12.53 13.6973 13.5 12.5007 13.5V14.5C14.2496 14.5 15.6673 13.0822 15.6673 11.3333H14.6673ZM12.5007 4.5C13.6973 4.5 14.6673 5.47005 14.6673 6.66667H15.6673C15.6673 4.91777 14.2496 3.5 12.5007 3.5V4.5ZM4.50065 3.5C2.75175 3.5 1.33398 4.91777 1.33398 6.66667H2.33398C2.33398 5.47005 3.30403 4.5 4.50065 4.5V3.5Z"
                      fill="#7673E6"
                    />
                  </svg>
                </div>
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-fit" align="center">
              <DropdownMenuItem
                className="focus:bg-bg-disable focus:text-main-01 bold-600--700 text-sm text-start flex justify-start px-4 py-3 rounded-[8px]"
                onClick={onChangeAvatar}
              >
                {t("common.change_avatar")}
              </DropdownMenuItem>
              {!!userInfo?.frame_avatar && (
                <DropdownMenuItem
                  className="focus:bg-bg-disable focus:text-main-01 bold-600--700 text-sm text-start flex justify-start px-4 py-3 rounded-[8px]"
                  onClick={onRemoveFrame}
                >
                  {t("common.delete_frame_profile")}
                </DropdownMenuItem>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        )}
      </div>

      <p className="text-base lg:text-[18px] text-bg-01 mt-[24px] lg:mt-[16px] Nunito-700 dark:text-white flex gap-2 items-center">
        {dataUser?.fullname}
        {!!dataUser?.authentic && (
          <span>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.9987 14.6663C11.6806 14.6663 14.6654 11.6816 14.6654 7.99967C14.6654 4.31778 11.6806 1.33301 7.9987 1.33301C4.3168 1.33301 1.33203 4.31778 1.33203 7.99967C1.33203 11.6816 4.3168 14.6663 7.9987 14.6663ZM11.06 6.30667C11.2296 6.08869 11.1903 5.77456 10.9723 5.60502C10.7544 5.43549 10.4402 5.47475 10.2707 5.69273L7.59936 9.1273C7.53996 9.20367 7.42822 9.21358 7.35631 9.14886L5.66652 7.62805C5.46126 7.44332 5.14512 7.45996 4.96039 7.66522C4.77566 7.87047 4.7923 8.18661 4.99755 8.37134L6.68734 9.89215C7.19076 10.3452 7.9729 10.2759 8.38871 9.74124L11.06 6.30667Z"
                fill="#7673E6"
              />
            </svg>
          </span>
        )}
      </p>

      <ModalUploadImage open={changeAvatar} onClose={onChangeAvatar} />
    </div>
  )
}
