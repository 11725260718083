import React, { useContext, useEffect, useState } from "react"
import "./style.module.css"
import { useTheme } from "next-themes"
import { AppContext } from "app/context/AppContext"
import IconLight from "../icon-tsx/IconLight"
import IconDark from "../icon-tsx/IconDark"

export const SelectTheme = () => {
  const [checked, setChecked] = useState(false)
  const { theme, setTheme } = useTheme()
  const { setThemeApp } = useContext(AppContext)

  const handleChangeTheme = () => {
    if (checked) {
      setChecked(false)
      setTheme("light")
      localStorage.setItem("theme", "light")
      document.documentElement.classList.remove("dark")
    } else {
      setChecked(true)
      setTheme("dark")
      localStorage.setItem("theme", "dark")
      document.documentElement.classList.add("dark")
    }
  }

  useEffect(() => {
    if (typeof localStorage !== "undefined") {
      const _theme = localStorage.getItem("theme")
      setTheme(_theme || "light")
      if (_theme === "dark") {
        setThemeApp("dark")
        setChecked(true)
        document.documentElement.classList.add("dark")
      } else {
        setChecked(false)
        setThemeApp("light")
        document.documentElement.classList.remove("dark")
      }
    }
  }, [theme])

  return (
    <label className="switch-container relative cursor-pointer outline-0">
      <IconLight
        fill={checked ? "" : "white"}
        className="absolute z-10 top-[6px] left-[6px] bottom-0"
      />
      <IconDark
        fill={!checked ? "#907ED9" : "white"}
        className="absolute z-10 top-[6px] right-[6px] bottom-0"
      />
      <input type="checkbox" checked={checked} onChange={handleChangeTheme} />
      <span className="switch-class round outline-0"></span>
    </label>
  )
}
