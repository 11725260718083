import React, { useContext } from "react"
import { AddonChild } from "./AddonChild"
import { MobileContext } from "@/app/context"
import { Sheet, SheetContent } from "@/app/components/ui/sheet"

interface CreatePostAddonProps {
  onSubmit: any
  disable: boolean
  hidden: boolean
  setHidden: React.Dispatch<React.SetStateAction<boolean>>
}
export const CreatePostAddon: React.FC<CreatePostAddonProps> = ({
  onSubmit,
  disable,
  hidden,
  setHidden
}) => {
  const { isMobile } = useContext(MobileContext)

  return (
    <>
      {isMobile ? (
        <>
          <Sheet open={hidden} onOpenChange={() => setHidden(false)}>
            <SheetContent className="flex items-start bg-white border-0 h-dvh flex-col justify-between overflow-auto dark:bg-[#30303A]">
              <AddonChild
                disable={disable}
                onSubmit={onSubmit}
                hidden={hidden}
                setHidden={setHidden}
              />
            </SheetContent>
          </Sheet>
        </>
      ) : (
        <div
          className={`flex fixed top-0 right-0 dark:bg-bg-02  ${
            hidden ? "w-[112px] items-center" : "w-[340px] items-start"
          } bg-white h-screen p-[32px] flex-col justify-between overflow-auto z-20`}
        >
          <AddonChild
            disable={disable}
            onSubmit={onSubmit}
            hidden={hidden}
            hiddenContent={!!hidden && !isMobile}
            setHidden={setHidden}
          />
        </div>
      )}
    </>
  )
}
