import React, { useContext, useMemo, useRef } from "react"
import { enumTypeGold, enumTypeGoldViewAll } from "../../tab"
import { useRouter } from "next/router"
import { Button } from "@/app/components/ui/button"
import { useTranslation } from "next-i18next"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from "@/app/components/ui/table"
import moment from "moment"
import { DATE_FORMAT_3 } from "@/app/configs"
import { useQueryGetUserPointHistory } from "@/app/services/query-fn/user/user-tab-query"
import { AppContext } from "@/app/context"
import { PointHistoryItem } from "@/app/interfaces"
import { useInView } from "react-intersection-observer"
import { FilterGold } from "../filter-all/FilterGold"
import { FormProvider, useForm } from "react-hook-form"
import { DataEmpty } from "@/app/components/data-empty"
import GoldIcon from "@public/img/gold_icon.png"

const LIMIT_CELL = 4
const initialFilter = {
  source: "",
  start_date: undefined as unknown as any,
  end_date: undefined as unknown as any
}

export const GoldTransaction = () => {
  const router = useRouter()
  const { userInfo } = useContext(AppContext)
  const { t } = useTranslation()
  const tabRef = useRef<HTMLDivElement>(null)
  const { ref, inView } = useInView()
  const methods = useForm<typeof initialFilter>({
    defaultValues: initialFilter
  })
  const watchSource = methods.watch("source", "")
  const watchStartTime = methods.watch("start_date", "")
  const watchEndTime = methods.watch("end_date", "")

  const { data: gold, fetchNextPage } = useQueryGetUserPointHistory(
    {
      type:
        String(router?.query?.gold) !== "undefined"
          ? String(router?.query?.gold)?.replace("-all", "")
          : "spent",
      source: watchSource,
      start_date: !!watchStartTime
        ? moment(watchStartTime).format("YYYY-MM-DD")
        : "",
      end_date: !!watchEndTime ? moment(watchEndTime).format("YYYY-MM-DD") : ""
    },
    userInfo?.userid
  )

  const listGold = useMemo(
    () =>
      String(router?.query?.gold?.includes("all"))
        ? gold?.pages.flat()
        : gold?.pages?.flat()?.slice(0, LIMIT_CELL),
    [router, gold]
  )

  React.useEffect(() => {
    if (inView && String(router?.query?.gold).includes("all")) {
      fetchNextPage()
    }
  }, [fetchNextPage, inView, router])

  const handleChangeTab = (value: enumTypeGold) => {
    if (router?.query?.gold?.includes("all")) {
      router.push({
        pathname: router.pathname,
        query: {
          id: router?.query?.id,
          tab: router?.query?.tab,
          gold:
            String(router?.query?.gold) === enumTypeGoldViewAll.SPENT_ALL
              ? enumTypeGoldViewAll.EARN_ALL
              : enumTypeGoldViewAll.SPENT_ALL
        }
      })
    } else
      router.push({
        pathname: router.pathname,
        query: router?.query?.type
          ? {
              id: router?.query?.id,
              tab: router?.query?.tab,
              type: router?.query?.type,
              gold: value
            }
          : {
              id: router?.query?.id,
              tab: router?.query?.tab,
              gold: value
            }
      })
  }

  const handleViewAll = () => {
    const momentType = String(router?.query?.gold)
    router.push({
      pathname: router.pathname,
      query: {
        id: router?.query?.id,
        tab: router?.query?.tab,
        gold:
          momentType === "undefined"
            ? enumTypeGoldViewAll.SPENT_ALL
            : `${momentType}-all`
      }
    })
  }

  const onNavigateToStoreItem = () => {
    router.push("/reward-center?tab=gold-shop")
  }
  return (
    <div className="px-0 lg:px-[24px] w-full pb-[80px] md:pb-0 ">
      <div className="flex gap-[32px] items-center">
        <span className="text-base Nunito-700 text-bg-01 dark:text-white">
          Playee Gold
        </span>
        <div
          className="flex items-center gap-2 opacity-100 cursor-pointer active:opacity-70"
          onClick={onNavigateToStoreItem}
        >
          <span className="text-sm text-main-01 Nunito-500">
            {t("store.exchange_item")}
          </span>
          <svg
            width="14"
            height="10"
            viewBox="0 0 14 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.5303 5.53033C13.8232 5.23744 13.8232 4.76256 13.5303 4.46967L9.53033 0.46967C9.23744 0.176777 8.76256 0.176777 8.46967 0.46967C8.17678 0.762563 8.17678 1.23744 8.46967 1.53033L11.1893 4.25H1C0.585787 4.25 0.25 4.58579 0.25 5C0.25 5.41421 0.585787 5.75 1 5.75H11.1893L8.46967 8.46967C8.17678 8.76256 8.17678 9.23744 8.46967 9.53033C8.76256 9.82322 9.23744 9.82322 9.53033 9.53033L13.5303 5.53033Z"
              fill="#7673E6"
            />
          </svg>
        </div>
      </div>
      <div className="flex items-center justify-between w-full mt-[16px]">
        {/* tab */}
        <div className="flex items-cente gap-[8px]" ref={tabRef}>
          <Button
            variant="none"
            className={`px-[16px] py-1 border rounded-[8px] Nunito-400 text-sm h-[29px] ${
              [
                "undefined",
                String(enumTypeGold.SPENT_GOLD),
                String(enumTypeGoldViewAll.SPENT_ALL)
              ].includes(String(router?.query?.gold))
                ? "text-main-01 border-main-01"
                : "border-black/10 text-neutral-03 dark:border-neutral-08 dark:text-neutral-07"
            }`}
            onClick={() => handleChangeTab(enumTypeGold.SPENT_GOLD)}
          >
            {t("reward.top_spent")}
          </Button>
          <Button
            variant="none"
            className={`px-[16px] py-1 border rounded-[8px] Nunito-400 text-sm h-[29px] ${
              [
                String(enumTypeGold.EARN_GOLD),
                String(enumTypeGoldViewAll.EARN_ALL)
              ].includes(String(router?.query?.gold))
                ? "text-main-01 border-main-01"
                : "border-black/10 text-neutral-03 dark:border-neutral-08 dark:text-neutral-07"
            }`}
            onClick={() => handleChangeTab(enumTypeGold.EARN_GOLD)}
          >
            {t("reward.top_earned")}
          </Button>
        </div>
        {String(router?.query?.gold).includes("all") && (
          <FormProvider {...methods}>
            <FilterGold />
          </FormProvider>
        )}
      </div>

      <div
        className={`min-w-full mt-[16px] ${
          String(router?.query?.gold)?.includes("all") ? "pb-[40px]" : ""
        }`}
      >
        {listGold?.length ? (
          <Table className="w-max lg:w-full">
            <TableHeader>
              <TableRow className="pt-[8px] pb-[16px]">
                <TableHead className="text-sm w-fit text-bg-01 Nunito-600">
                  {t("profile.time")}
                </TableHead>
                <TableHead className="min-w-full text-sm md:min-w-1/2 text-bg-01 Nunito-600">
                  {t("profile.activate")}
                </TableHead>
                <TableHead className="text-sm text-right text-bg-01 Nunito-600">
                  Gold
                </TableHead>
              </TableRow>
            </TableHeader>

            <TableBody>
              {listGold?.map((item: PointHistoryItem, index: number) => {
                return (
                  <TableRow className="last:border-0" key={index}>
                    <TableCell className="font-medium">
                      {moment.unix(item?.created_at).format(DATE_FORMAT_3)}
                    </TableCell>
                    <TableCell>
                      <p
                        className="pb-1 xs:text-[12px] dark:text-text-color-1"
                        dangerouslySetInnerHTML={{
                          __html: item.source_info.message || ""
                        }}
                      ></p>
                    </TableCell>
                    <TableCell>
                      <div className="flex items-end justify-end w-full gap-2">
                        {item?.source === "shop"
                          ? Number(item.point)
                          : `+${Number(item.point)}`}
                        <img src={GoldIcon.src} className="w-[24px] h-[24px]" />
                      </div>
                    </TableCell>
                  </TableRow>
                )
              })}
              <button ref={ref} className="opacity-0">
                Load more
              </button>
            </TableBody>
          </Table>
        ) : (
          <div className="w-full flex items-center justify-center h-[200px]">
            <DataEmpty title={t("profile.no_data")} />
          </div>
        )}
      </div>
      {!String(router?.query?.gold)?.includes("all") && (
        <Button
          variant="none"
          className="text-main-01 Nunito-700 text-sm pb-[40px] h-auto p-0 m-0 opacity-100 active:opacity-70"
          onClick={handleViewAll}
        >
          {t("profile.view_all")}&nbsp;
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M18.5303 12.5303C18.8232 12.2374 18.8232 11.7626 18.5303 11.4697L14.5303 7.46967C14.2374 7.17678 13.7626 7.17678 13.4697 7.46967C13.1768 7.76256 13.1768 8.23744 13.4697 8.53033L16.1893 11.25H6C5.58579 11.25 5.25 11.5858 5.25 12C5.25 12.4142 5.58579 12.75 6 12.75H16.1893L13.4697 15.4697C13.1768 15.7626 13.1768 16.2374 13.4697 16.5303C13.7626 16.8232 14.2374 16.8232 14.5303 16.5303L18.5303 12.5303Z"
              fill="#7673E6"
            />
          </svg>
        </Button>
      )}
    </div>
  )
}
