import { AppContext } from "@/app/context"
import { IGameAuthor, IUser, IUserRequest } from "@/app/interfaces"
import { IAuthor } from "@/app/interfaces/IPost"
import React, { useContext } from "react"
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar"
import { convertNameString } from "@/app/utils"
import { useRouter } from "next/router"
import { cn } from "@/app/lib/utils"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "../ui/tooltip"
import { GroupFriendAction } from "../connect-action/GroupConnectAction"
import { GroupFollowAction } from "../connect-action/GroupFollowAction"
import useGAEventsTracker from "@/app/hooks/useGAEventsTracker"

interface HoverUserAvatarProps {
  avatarUser: IUser | IUserRequest | IAuthor | IGameAuthor
  size: number
  text: {
    text: string
    className: string
    node?: React.ReactNode
  }
  subtext?: React.ReactNode
}
const EXAMPLE_SIZE = 40
const HOVER_DELAY = 300
export const HoverUserAvatar: React.FC<HoverUserAvatarProps> = ({
  avatarUser,
  size = EXAMPLE_SIZE,
  text,
  subtext
}) => {
  const router = useRouter()
  const { userInfo } = useContext(AppContext)
  const GAEventsTracker = useGAEventsTracker("Profile")
  const onNavigateToProfile = (e: any) => {
    e.stopPropagation()
    if ("authentic" in avatarUser && !!avatarUser?.authentic) {
      GAEventsTracker("Profile_infor")
      if (!!avatarUser?.alias) {
        router.push(`/user/${avatarUser?.alias}`)
      } else if ("userid" in avatarUser) {
        router.push(`/user/${avatarUser?.userid}`)
      } else {
        router.push(`/user/${avatarUser?.id}`)
      }
    }
  }

  const renderTriggerAvatar = () => {
    return (
      <div
        className="flex items-center gap-4 cursor-pointer w-fit group"
        onClick={onNavigateToProfile}
      >
        <div className={`relative`}>
          <Avatar className={`rounded-full w-[${size}px] h-[${size}px]`}>
            <AvatarImage
              src={
                !!avatarUser?.small_avatar
                  ? avatarUser?.small_avatar
                  : !!avatarUser?.avatar
                  ? avatarUser?.avatar
                  : ""
              }
              alt={avatarUser?.fullname}
              height={size}
              width={size}
              className={`object-cover aspect-square w-[${size}px] h-[${size}px] rounded-full`}
            />
            <AvatarFallback className="flex items-center justify-center w-full text-white bg-main-01">
              {convertNameString(avatarUser?.fullname ?? "P")}
            </AvatarFallback>
          </Avatar>
          {!!avatarUser?.frame_avatar && (
            <div
              className={`w-[${size}px] h-[${size}px] absolute top-0 left-0 rounded-full z-20`}
              style={{
                backgroundImage: `url(${avatarUser?.frame_avatar})`,
                backgroundSize: "100%"
              }}
            ></div>
          )}
        </div>
        {!!text.node ? (
          <>{text.node}</>
        ) : (
          <div className="flex flex-col">
            <p className={cn("flex items-center gap-[12px] ", text.className)}>
              <span className={cn("line-clamp-1", text.className)}>
                {text.text}
              </span>
              {"authentic" in avatarUser && !!avatarUser?.authentic && (
                <span>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.9987 14.6663C11.6806 14.6663 14.6654 11.6816 14.6654 7.99967C14.6654 4.31778 11.6806 1.33301 7.9987 1.33301C4.3168 1.33301 1.33203 4.31778 1.33203 7.99967C1.33203 11.6816 4.3168 14.6663 7.9987 14.6663ZM11.06 6.30667C11.2296 6.08869 11.1903 5.77456 10.9723 5.60502C10.7544 5.43549 10.4402 5.47475 10.2707 5.69273L7.59936 9.1273C7.53996 9.20367 7.42822 9.21358 7.35631 9.14886L5.66652 7.62805C5.46126 7.44332 5.14512 7.45996 4.96039 7.66522C4.77566 7.87047 4.7923 8.18661 4.99755 8.37134L6.68734 9.89215C7.19076 10.3452 7.9729 10.2759 8.38871 9.74124L11.06 6.30667Z"
                      fill="#7673E6"
                    />
                  </svg>
                </span>
              )}
            </p>
            {!!subtext && <>{subtext}</>}
          </div>
        )}
      </div>
    )
  }

  if (
    ("userid" in avatarUser && avatarUser?.userid !== userInfo?.userid) ||
    (avatarUser?.id !== userInfo?.userid && !userInfo?.authentic)
  )
    return (
      <TooltipProvider delayDuration={HOVER_DELAY}>
        <Tooltip>
          <TooltipTrigger asChild>{renderTriggerAvatar()}</TooltipTrigger>
          <TooltipContent
            className="bg-white dark:bg-[#26243C] dark:border-0 rounded-[16px] w-fit max-w-[360px] p-[12px] shadow-[0_3px_30px_0_rgba(0,0,0,0.15)]"
            side="top"
            align="start"
          >
            <div className="flex items-center justify-between w-full gap-[16px]">
              <div
                className="flex items-center gap-[12px] flex-1 cursor-pointer"
                onClick={onNavigateToProfile}
              >
                <div className={`relative`}>
                  <Avatar className="rounded-full">
                    <AvatarImage
                      src={
                        avatarUser?.small_avatar
                          ? avatarUser?.small_avatar
                          : avatarUser?.avatar
                          ? avatarUser?.avatar
                          : ""
                      }
                      alt={avatarUser?.fullname}
                      height={EXAMPLE_SIZE}
                      width={EXAMPLE_SIZE}
                      className="object-cover aspect-square"
                    />
                    <AvatarFallback className="flex items-center justify-center w-full text-white bg-main-01">
                      {convertNameString(avatarUser?.fullname ?? "P")}
                    </AvatarFallback>
                  </Avatar>
                  {!!avatarUser?.frame_avatar && (
                    <div
                      className={`w-[${EXAMPLE_SIZE}px] h-[${EXAMPLE_SIZE}px] absolute top-0 left-0 rounded-full z-20`}
                      style={{
                        backgroundImage: `url(${avatarUser?.frame_avatar})`,
                        backgroundSize: "100%"
                      }}
                    ></div>
                  )}
                </div>
                <p className="max-w-full text-base Nunito-700 text-bg-01 dark:text-main-white line-clamp-1">
                  {avatarUser?.fullname}
                </p>
                {"authentic" in avatarUser && !!avatarUser?.authentic && (
                  <span>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.9987 14.6663C11.6806 14.6663 14.6654 11.6816 14.6654 7.99967C14.6654 4.31778 11.6806 1.33301 7.9987 1.33301C4.3168 1.33301 1.33203 4.31778 1.33203 7.99967C1.33203 11.6816 4.3168 14.6663 7.9987 14.6663ZM11.06 6.30667C11.2296 6.08869 11.1903 5.77456 10.9723 5.60502C10.7544 5.43549 10.4402 5.47475 10.2707 5.69273L7.59936 9.1273C7.53996 9.20367 7.42822 9.21358 7.35631 9.14886L5.66652 7.62805C5.46126 7.44332 5.14512 7.45996 4.96039 7.66522C4.77566 7.87047 4.7923 8.18661 4.99755 8.37134L6.68734 9.89215C7.19076 10.3452 7.9729 10.2759 8.38871 9.74124L11.06 6.30667Z"
                        fill="#7673E6"
                      />
                    </svg>
                  </span>
                )}
              </div>
              <GroupFollowAction
                id={
                  "userid" in avatarUser ? avatarUser?.userid : avatarUser?.id
                }
              />
              <GroupFriendAction
                id={
                  "userid" in avatarUser ? avatarUser?.userid : avatarUser?.id
                }
              />
            </div>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    )
  return <>{renderTriggerAvatar()}</>
}
