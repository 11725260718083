import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "next-i18next"
import { Dialog, DialogContent } from "../ui/dialog"
import { IconClose } from "../icon-tsx/IconClose"
import { Button } from "../ui/button"
import { Input } from "../ui/input"
import { SelectAddress } from "./SelectAddress"
import {
  useMutationCreateAddress,
  useMutationUpdateAddress
} from "@/app/services/query-fn/merchandise/merchandise-mutation"
import { ICreateAddress } from "@/app/interfaces"
import provinces from "@/app/dummy/tinh_tp.json"
import districts from "@/app/dummy/quan_huyen.json"
import wards from "@/app/dummy/xa_phuong.json"
import { convertObjectToArray } from "@/app/utils"
import { useToast } from "../ui/use-toast"
import { useQueryClient } from "@tanstack/react-query"
import { QUERY_KEY } from "@/app/configs"
import useGAEventsTracker from "@/app/hooks/useGAEventsTracker"
import { DialogTrigger } from "@radix-ui/react-dialog"
import { useFacebookPixel } from "@/app/hooks/useFacebookPixel"
import { facebookPixel } from "@/app/containers/merchandise/facebookPixel"

const regexFullname =
  /^[a-zA-Z\sàáạảãâầấậẩẫăằắặẳẵèéẹẻẽêềếệểễìíịỉĩòóọỏõôồốộổỗơờớợởỡùúụủũưừứựửữỳýỵỷỹđÀÁẠẢÃÂẦẤẬẨẪĂẰẮẶẲẴÈÉẸẺẼÊỀẾỆỂỄÌÍỊỈĨÒÓỌỎÕÔỒỐỘỔỖƠỜỚỢỞỠÙÚỤỦŨƯỪỨỰỬỮỲÝỴỶỸĐ]+$/
const regexPhone = /^[0-9]+$/

interface ModalAddressMerchandiseProps {
  open: boolean
  onOpenChange: () => void
  isEdit?: boolean
  dataEdit?: any
}
const initialParams = {
  fullname: "",
  phone: "",
  city: "",
  district: "",
  ward: "",
  address: "",
  status: "backup"
}

export const ModalAddressMerchandise: React.FC<
  ModalAddressMerchandiseProps
> = ({ open, onOpenChange, dataEdit, isEdit }) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { mutate: addAddress } = useMutationCreateAddress()
  const { mutate: updateAddress } = useMutationUpdateAddress()
  const { toast } = useToast()
  const listProvinces = convertObjectToArray(provinces).sort((a, b) =>
    a.name.localeCompare(b.name)
  )
  const listDistricts = convertObjectToArray(districts).sort((a, b) =>
    a.name.localeCompare(b.name)
  )
  const listWards = convertObjectToArray(wards).sort((a, b) =>
    a.name.localeCompare(b.name)
  )
  const [params, setParams] = useState<ICreateAddress>(
    initialParams as ICreateAddress
  )

  const [error, setError] = useState(false)
  const GAEventsTracker = useGAEventsTracker("Store_Merchandise")

  const pixel = useFacebookPixel()

  useEffect(() => {
    if (dataEdit?.id) {
      const _city = listProvinces.find(
        (province) => province.name_with_type === dataEdit.city
      )
      const _district = listDistricts.find(
        (district) => district.name_with_type === dataEdit.district
      )
      const _ward = listWards.find(
        (ward) =>
          ward.name_with_type === dataEdit.ward &&
          ward.parent_code === _district.code
      )

      setParams({
        ...dataEdit,
        city: _city.code,
        district: _district.code,
        ward: _ward?.code || _district?.code
      })
    }
  }, [dataEdit])

  const onSubmit = () => {
    const _city = listProvinces.find(
      (province) => province.code === params.city
    )
    const _district = listDistricts.find(
      (district) => district.code === params.district
    )
    const _ward = listWards.find((ward) => ward.code === params.ward)
    if (!isEdit) {
      addAddress(
        {
          ...params,
          city: _city.name_with_type,
          district: _district.name_with_type,
          ward: _ward?.name_with_type || _district?.name_with_type
        },
        {
          onSuccess: () => {
            GAEventsTracker("Add_new_address")
            toast({
              variant: "success",
              title: "Thêm địa chỉ thành công!"
            })
            setParams(initialParams as ICreateAddress)
            setError(false)
            onOpenChange()
            queryClient.refetchQueries({
              queryKey: [QUERY_KEY.MERCHANDISE_ADDRESS]
            })
          },
          onError(error: any, variables, context) {
            console.log(error)

            toast({
              variant: "error",
              title:
                error?.response?.data?.message ||
                error?.data?.message ||
                "Có lỗi xảy ra!"
            })
          }
        }
      )
      facebookPixel.onTrackNewAddress(pixel)
    } else {
      updateAddress(
        {
          ...params,
          city: _city.name_with_type,
          district: _district.name_with_type,
          ward: _ward?.name_with_type || _district?.name_with_type,
          id: dataEdit.id,
          status: dataEdit.default ? "default" : "backup"
        },
        {
          onSuccess: () => {
            toast({
              variant: "success",
              title: "Cập nhật thành công!"
            })
            setParams(initialParams as ICreateAddress)
            onOpenChange()
            setError(false)
            queryClient.refetchQueries({
              queryKey: [QUERY_KEY.MERCHANDISE_ADDRESS]
            })
          },
          onError(error: any, variables, context) {
            toast({
              variant: "error",
              title: error?.response?.data?.message || error?.data?.message
            })
          }
        }
      )
    }
  }

  const onChangeValue = (value: string, key: string) => {
    setParams({
      ...params,
      [key]: value || ""
    })
  }

  const isFail = !regexFullname.test(params.fullname) || params.fullname === ""

  const renderFullnameInput = useMemo(() => {
    return (
      <Input
        onBlur={(e) => {
          if (!isFail) {
            setError(false)
          } else {
            setError(true)
          }
        }}
        onChange={(e) => {
          setError(false)
          onChangeValue(e.target.value, "fullname")
        }}
        maxLength={50}
        value={params.fullname}
        placeholder={t("merchandise.placeholder_name")}
        className={`rounded-[16px] border-black/10 text-bg-01 placeholder-neutral-06 dark:text-main-white dark:bg-white/10 ${
          error && "border-main-red-02"
        }`}
      />
    )
  }, [params.fullname, error, setError, isFail])

  useEffect(() => {
    if (!isFail) {
      setError(false)
    }
  }, [isFail])

  return (
    <Dialog
      open={open}
      onOpenChange={() => {
        setParams(initialParams as ICreateAddress)
        setError(false)
        onOpenChange()
      }}
    >
      <DialogTrigger></DialogTrigger>
      <DialogContent
        id="modal-add-address"
        className="sm:rounded-[16px] rounded-[16px] max-w-[90vw] md:max-w-[480px] p-[24px] border-0 focus-visible:outline-none dark:bg-bg-02"
      >
        <div className="flex items-center justify-between w-full">
          <p className="text-bg-01 Nunito-700 text-base mb-[40px dark:text-main-white">
            {t("merchandise.address_title")}
          </p>
          <div
            className="w-[32px] h-[32px] rounded-[8px] flex items-center justify-center  cursor-pointer"
            onClick={() => {
              setError(false)
              setParams(initialParams as ICreateAddress)
              onOpenChange()
            }}
          >
            <IconClose />
          </div>
        </div>
        <div className="flex flex-col w-full space-y-[24px]">
          <div className="flex flex-col gap-[6px] relative">
            <label className="text-sm text-neutral-06 Nunito-400 dark:text-main-white">
              {t("merchandise.label_name")}
            </label>
            {renderFullnameInput}
            {error && (
              <p className="Nunito-400 text-main-red-02 text-[12px] absolute bottom-[-18px] left-0">
                {t("message.field_invalid", {
                  field: t("merchandise.label_name")
                })}
              </p>
            )}
          </div>
          <div className="flex flex-col gap-[6px]">
            <label className="text-sm text-neutral-06 Nunito-400 dark:text-main-white">
              {t("merchandise.label_phone")}
            </label>
            <Input
              value={params.phone}
              maxLength={11}
              minLength={10}
              onChange={(e) => {
                if (regexPhone.test(e.target.value) || e.target.value === "") {
                  onChangeValue(e.target.value, "phone")
                }
              }}
              placeholder={t("merchandise.placeholder_phone")}
              className="rounded-[16px] border-black/10 text-bg-01 placeholder-neutral-06 dark:text-main-white dark:bg-white/10"
            />
          </div>
          <div className="flex flex-col gap-[6px]">
            <label className="text-sm text-neutral-06 Nunito-400 dark:text-main-white">
              {t("merchandise.label_address")}
            </label>
            <SelectAddress params={params} setParams={setParams} />
          </div>
          <div className="flex flex-col gap-[6px]">
            <label className="text-sm text-neutral-06 Nunito-400 dark:text-main-white">
              {t("merchandise.label_detail_address")}
            </label>
            <Input
              onChange={(e) => {
                onChangeValue(e.target.value, "address")
              }}
              value={params.address}
              placeholder={t("merchandise.placeholder_detail_address")}
              className="rounded-[16px] border-black/10 text-bg-01 placeholder-neutral-06 dark:text-main-white dark:bg-white/10"
            />
          </div>

          <div className="flex items-center justify-end w-full mt-[32px] gap-[16px]">
            <Button
              variant="none"
              className="text-base bg-bg-button text-neutral-05 Nunito-700 rounded-[16px] dark:text-main-white dark:bg-[#ECECEE1A]"
              autoFocus={false}
              onClick={() => {
                setParams(initialParams as ICreateAddress)
                onOpenChange()
                setError(false)
              }}
            >
              {t("common.cancel")}
            </Button>
            <Button
              variant="main"
              className="text-base Nunito-700 rounded-[16px]"
              disabled={
                !params.fullname ||
                !params.phone ||
                !params.address ||
                !params.city ||
                !params.district ||
                !params.ward ||
                error
              }
              onClick={onSubmit}
            >
              {t("reward.confirm")}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
