import React from "react"

export const IconToastSuccess = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M0 16.5V4.5C0 2.29086 1.79086 0.5 4 0.5H16C18.2091 0.5 20 2.29086 20 4.5V16.5C20 18.7091 18.2091 20.5 16 20.5H4C1.79086 20.5 0 18.7091 0 16.5Z"
        fill="#00A451"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5018 0.942536C20.8096 1.21963 20.8346 1.69385 20.5575 2.00173L11.8199 11.5991C10.8454 12.6819 9.19549 12.8168 8.05793 11.9068L4.53151 9.08566C4.20806 8.8269 4.15562 8.35493 4.41438 8.03149C4.67313 7.70804 5.1451 7.6556 5.46855 7.91436L8.99497 10.7355C9.51204 11.1492 10.262 11.0878 10.705 10.5956L19.4426 0.998283C19.7197 0.6904 20.1939 0.665442 20.5018 0.942536Z"
        fill="#00A451"
      />
    </svg>
  )
}
