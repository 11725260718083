import { OnlyShowAvatar } from "@/app/components/app-avatar/OnlyShowAvatar"
import { DataEmpty } from "@/app/components/data-empty"
import { IconSearch } from "@/app/components/icon-tsx"
import { IconClose } from "@/app/components/icon-tsx/IconClose"
import { LoadingBtnComponent } from "@/app/components/loading-btn"
import { Button } from "@/app/components/ui/button"
import { Checkbox } from "@/app/components/ui/checkbox"
import { Dialog, DialogContent } from "@/app/components/ui/dialog"
import { AppContext } from "@/app/context"
import { PackageContext } from "@/app/context/PackageContext"
import { useDebounceEffect } from "@/app/hooks/useDebounceEffect"
import { IUser } from "@/app/interfaces"
import { useMutatonSendGift } from "@/app/services/query-fn/user/user-friend-mutation"
import {
  useQueryGetListFriend,
  useQueryGetListFriendWithSearch
} from "@/app/services/query-fn/user/user-friend-query"
import { ScrollArea } from "@radix-ui/react-scroll-area"
import { useQueryClient } from "@tanstack/react-query"
import { useTranslation } from "next-i18next"
import { FC, useContext, useEffect, useState } from "react"
import { useInView } from "react-intersection-observer"

interface ModalViewFriendProps {
  open: boolean
  handleClose: () => void
}
const TIME_DEBOUNCE = 700

export const ListFriend = ({
  active = false,
  searchValue = "",
  setSelectedFriend,
  selectedFriend
}: {
  active: boolean
  searchValue: string
  selectedFriend: IUser | undefined
  setSelectedFriend: any
}) => {
  const { t } = useTranslation()
  const { data: listFriendWithoutSearch, fetchNextPage: fetchWithoutSearch } =
    useQueryGetListFriend(!!active, searchValue)

  const { data: listFriendWithSearch, fetchNextPage: fetchWithSearch } =
    useQueryGetListFriendWithSearch(!!active, searchValue)
  const { ref, inView } = useInView()

  const listFriendWithoutAuthenticate = () => {
    const listFriend = !!searchValue
      ? listFriendWithSearch
      : listFriendWithoutSearch

    return listFriend?.pages?.flat()?.length
      ? listFriend?.pages?.flat()?.filter((item) => !item?.authentic)
      : []
  }

  useEffect(() => {
    if (inView) {
      searchValue ? fetchWithSearch() : fetchWithoutSearch()
    }
  }, [fetchWithoutSearch, fetchWithSearch, searchValue, inView])

  return (
    <>
      {listFriendWithoutAuthenticate()?.length ? (
        listFriendWithoutAuthenticate().map((item, index) => (
          <div
            className="flex items-center justify-between w-full gap-4"
            key={index}
          >
            <Checkbox
              id="friend"
              onCheckedChange={(value: boolean) => {
                if (value) {
                  setSelectedFriend(item)
                } else {
                  setSelectedFriend(undefined)
                }
              }}
              checked={!!selectedFriend?.id && item.id === selectedFriend?.id}
            />
            <div className="flex flex-1 gap-4">
              <OnlyShowAvatar size={40} avatarUser={item} />
              <p className="flex items-center text-base Nunito-700 text-bg-01 dark:text-white">
                {item?.fullname}
              </p>
            </div>
          </div>
        ))
      ) : (
        <div className="w-full flex items-center justify-center h-[200px]">
          <DataEmpty title={t("profile.no_data")} />
        </div>
      )}
      <button ref={ref} className="opacity-0">
        Load more
      </button>
    </>
  )
}

export const ModalViewFriend: FC<ModalViewFriendProps> = ({
  open,
  handleClose
}) => {
  const {
    visible,
    setVisible,
    dataSelected,
    setSelectedFriend: setFriend
  } = useContext(PackageContext)
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { themeApp } = useContext(AppContext)
  const [keyword, setKeyword] = useState<string>("")
  const [tempSearchValue, setTempSearchValue] = useState<string>("")
  const [selectedFriend, setSelectedFriend] = useState<IUser | undefined>(
    undefined
  )
  const { mutate, isPending } = useMutatonSendGift()

  const onClose = () => {
    setVisible({
      ...visible,
      viewFriend: false
    })
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTempSearchValue(e?.target?.value)
  }

  useDebounceEffect(() => setKeyword(tempSearchValue?.trim()), TIME_DEBOUNCE, [
    tempSearchValue
  ])

  const onSubmit = () => {
    mutate(
      {
        item_id: dataSelected?.id,
        user_id: selectedFriend?.id || ""
      },
      {
        onSuccess: () => {
          setVisible({
            ...visible,
            sendSuccess: true,
            viewFriend: false
          })
          setFriend(undefined)
          setSelectedFriend(undefined)
        }
      }
    )
  }

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className="max-w-[420px] border-0 shadow-[0px_4px_30px_0px_rgba(0,0,0,0.06)] rounded-[24px] px-0 py-[16px] sm:rounded-[24px] gap-0 space-y-[12px] xs:w-[90vw]">
        <div className="flex items-center justify-between p-[16px] pt-0 border-b-[1px] border-black/10 dark:border-white/10">
          <p className="text-[16px] Nunito-700">Chọn bạn bè</p>
          <IconClose className="cursor-pointer" onClick={() => onClose()} />
        </div>
        <div className="flex px-[24px] justify-between gap-4 py-3 mb-4 border-0 h-[44px]">
          <IconSearch
            fill={themeApp === "dark" ? "#fff" : "#100D28"}
            className="w-[20px]"
          />
          <input
            type="text"
            value={tempSearchValue}
            className="flex-1 text-sm border-0 outline-none dark:text-white dark:bg-transparent"
            placeholder={t("profile.search")}
            onChange={onChange}
          />
        </div>
        <ScrollArea
          className={
            "h-[188px] px-[24px] sm:h-[268px] ld:h-[290px] overflow-auto space-y-[24px]"
          }
        >
          <ListFriend
            active={open}
            searchValue={keyword}
            setSelectedFriend={setSelectedFriend}
            selectedFriend={selectedFriend}
          />
        </ScrollArea>
        <div className="flex gap-[16px] justify-center items-center">
          <Button
            variant="main"
            disabled={!selectedFriend}
            onClick={onSubmit}
            className="rounded-[16px] px-[24px] py-[12px] w-fit h-[43px]"
          >
            {isPending ? <LoadingBtnComponent /> : t("reward.confirm")}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
