import { TLoginBody } from "@/app/interfaces/IUser"
import { UserService } from "../../services"
import { useMutation } from "@tanstack/react-query"
import { QUERY_KEY } from "@/app/configs"

const loginFn = (body: TLoginBody) => {
  return UserService.login(body)
}

export const useMutationNormalLogin = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.LOGIN_NORMAL],
    mutationFn: loginFn
  })
}
