import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip
} from "chart.js"
import { Bar } from "react-chartjs-2"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@/app/components/ui/select"
import { useTranslation } from "next-i18next"
import { isClient } from "@/app/configs"
import { AppContext, MobileContext } from "@/app/context"
import { useQueryGetChartData } from "@/app/services/query-fn/user/user-analytic-query"
import { getTimeBySubtract } from "@/app/helpers/function"
import { convertObjectToArray } from "@/app/utils"
import moment from "moment"
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip)

const MAX_BAR = 7
export const AnalyticChart = () => {
  const { t } = useTranslation()
  const chartRef = useRef<any>()
  const boxRef = useRef<HTMLDivElement>(null)
  const { themeApp } = useContext(AppContext)
  const { isMobile } = useContext(MobileContext)
  const [time, setTime] = useState<
    "7 days" | "30 days" | "45 days" | "60 days" | "90 days"
  >("7 days")
  const timeFilter = useMemo(() => {
    return getTimeBySubtract(time)
  }, [time])
  const FilterTime = [
    { label: t("profile.seven_days"), value: "7 days" },
    { label: t("profile._30_days"), value: "30 days" },
    { label: t("profile._45_days"), value: "45 days" },
    { label: t("profile._60_days"), value: "60 days" },
    { label: t("profile._90_days"), value: "90 days" }
  ]
  const comon = {
    plugins: {
      legend: {
        display: false
      },
      tooltip: {}
    },
    maintainAspectRatio: false
  }
  const optionsMain = {
    ...comon,
    layout: {
      padding: {
        top: 10,
        bottom: 10
      }
    },

    scales: {
      y: {
        boder: {
          display: false
        },
        beginAtZero: true,
        ticks: {
          display: false
        },
        grid: {
          display: true,
          drawBorder: false,
          drawTick: false,
          color: themeApp === "dark" ? "#706E7E" : "rgba(0, 0, 0, 0.1)",
          drawTicks: false
        }
      },

      x: {
        ticks: {
          display: true,
          fontColor: themeApp === "dark" ? "#fff" : "#000",
          padding: 10
        },
        grid: {
          display: false,
          drawBorder: false,
          drawTicks: false
        }
      }
    }
  }

  const option2 = {
    ...comon,
    layout: {
      padding: {
        bottom: 20
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        afterFit: (ctx: any) => {
          ctx.width = 50
        },
        ticks: {
          fontColor: "#736B8A",
          beginAtZero: true,
          stepSize: 200
        },
        grid: {
          display: false,
          drawBorder: false
        }
      },
      x: {
        ticks: {
          display: false
        },
        grid: {
          display: false,
          drawBorder: false
        }
      }
    }
  }
  const { data: chartData, isLoading } = useQueryGetChartData({ ...timeFilter })

  const onValueChange = (
    value: "7 days" | "30 days" | "45 days" | "60 days" | "90 days"
  ) => {
    setTime(value)
  }

  const getTotal = (array: any) => {
    const total = array.reduce(
      (acc: number, currentValue: any) =>
        acc +
        (Number(currentValue?.total_like) +
          Number(currentValue?.total_comment) +
          Number(currentValue?.total_join_event)),
      0
    )
    return total
  }

  const tranformData: any = useCallback(() => {
    if (!!chartData) {
      const chartDataArray = convertObjectToArray(chartData)
      moment.locale("en")
      return {
        labels: chartDataArray.map((data) => moment(data.day).format("DD")),
        datasets: [
          {
            data: chartDataArray.map(
              (data) =>
                Number(data?.total_like) +
                Number(data?.total_comment) +
                Number(data?.total_join_event)
            ),
            backgroundColor: ["#DAD9FE"],
            borderRadius: 10,
            borderSkipped: false,
            margin: 10,
            fill: true,
            barPercentage: 1,
            maxBarThickness: 40
          }
        ],
        total: getTotal(chartDataArray)
      }
    } else return {}
  }, [chartData, time])

  useEffect(() => {
    if (isClient && chartRef?.current && boxRef?.current) {
      const barLength = chartRef.current.data.labels.length ?? 0
      if (barLength > MAX_BAR) {
        const chartWidth = 530 + (barLength - MAX_BAR) * 40
        if (isMobile) {
          boxRef.current.style.width = `${chartWidth}px`
        } else boxRef.current.style.width = "60vw"
      }
    }
  }, [chartRef, boxRef, isMobile, tranformData, time])

  return (
    <div className="bg-white dark:bg-white/10 py-[16px] px-[12px] lg:px-[24px] rounded-[16px] min-h-[400px] col-span-12 md:col-span-7 lg:col-span-8">
      <div className="border-b border-main-01">
        <p className="text-neutral-05 text-sm Nunito-400 leading-5 mb-[12px] dark:text-neutral-08">
          {t("profile.total_view")}
        </p>
        <div className="flex justify-between items-end pb-[12px]">
          <p className="text-[32px] text-bg-01 Nunito-700 leading-10 dark:text-white">
            {tranformData()?.total?.toLocaleString() ?? 0}
          </p>

          <div>
            <Select onValueChange={onValueChange}>
              <SelectTrigger className="w-full max-w-[130px] max-h-[28px] file:m-0 text-xs Nunito-400 text-main-01 dark:text-white dark:bg-white/10 dark:backdrop-blur-[20px] border-0 bg-bg-disable text-left">
                <SelectValue
                  placeholder={t("profile.seven_days")}
                  defaultValue={t("profile.seven_days")}
                />
              </SelectTrigger>
              <SelectContent className="p-1 rounded-[16px]" align="end">
                {FilterTime.map((item: { label: string; value: string }) => (
                  <SelectItem
                    value={item?.value}
                    key={item?.value}
                    className="py-3 px-4 rounded-[8px] hover:!bg-bg-disable hover:!text-main-01 text-base Nunito-600 focus:bg-bg-disable focus:text-main-01 dark:hover:!bg-[#F1F1FC]/[.1] dark:focus:!bg-[#F1F1FC]/[.1]"
                  >
                    {item?.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </div>
      </div>
      {!isLoading && !!tranformData() && (
        <div className="container-chart w-full max-w-full flex h-[280px] mt-[40px]">
          <div className="w-[50px] h-full">
            <Bar options={option2} data={tranformData()} />
          </div>
          <div className="w-full max-w-full overflow-x-auto overflow-y-visible">
            <div ref={boxRef} className="h-[280px] w-[calc(100%-50px)]">
              <Bar
                ref={chartRef}
                options={optionsMain}
                data={tranformData()}
                className="w-full"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
