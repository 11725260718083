import React, { useMemo } from "react"
import { DataEmpty } from "@/app/components/data-empty"
import { Button } from "@/app/components/ui/button"
import { useQueryGetRequestFriend } from "@/app/services/query-fn/user/user-friend-query"
import { useTranslation } from "next-i18next"
import { useMutatonDoActionRequestFriend } from "@/app/services/query-fn/user/user-friend-mutation"
import { IUserRequest } from "@/app/interfaces"
import { useQueryClient } from "@tanstack/react-query"
import { QUERY_KEY } from "@/app/configs"
import { OnlyShowAvatar } from "@/app/components/app-avatar/OnlyShowAvatar"

export const ListRequestFriend = ({ active = false }: { active: boolean }) => {
  const { t } = useTranslation()
  const { data: listFriend } = useQueryGetRequestFriend(!!active)
  const queryClient = useQueryClient()
  const listWithoutAuthenticate = useMemo(() => {
    return listFriend?.pages?.flat()?.length
      ? listFriend?.pages?.flat()?.filter((item) => !item?.authentic)
      : []
  }, [listFriend])

  const { mutate, isPending } = useMutatonDoActionRequestFriend()

  const onDoActionRequestFriend = (
    user: IUserRequest,
    status: "cancel" | "accepted"
  ) => {
    mutate(
      {
        request_id: user?.request_id,
        status
      },
      {
        onSuccess(data, variables, context) {
          queryClient.refetchQueries({
            queryKey: [QUERY_KEY.LIST_SUGGEST_FRIEND]
          })
          queryClient.refetchQueries({
            queryKey: [QUERY_KEY.LIST_FRIEND]
          })
          queryClient.refetchQueries({
            queryKey: [QUERY_KEY.LIST_REQUEST_FRIEND]
          })
          queryClient.refetchQueries({
            queryKey: [QUERY_KEY.PROFILE_INFO]
          })
        }
      }
    )
  }
  return (
    <>
      {listWithoutAuthenticate?.length ? (
        listWithoutAuthenticate?.map((item, index) => (
          <div className="flex items-center justify-between w-full" key={index}>
            <div className="flex flex-1 gap-4">
              <OnlyShowAvatar size={40} avatarUser={item} />
              <p className="flex items-center text-base Nunito-700 text-bg-01 dark:text-white">
                {item?.fullname}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <Button
                variant="main"
                className="outline-none bg-main-01 h-[27px] px-[12px] flex items-center justify-center text-white rounded-[8px]"
                onClick={() => onDoActionRequestFriend(item, "accepted")}
                disabled={isPending}
              >
                {t("group.agree")}
              </Button>
              <Button
                variant="none"
                className="outline-none bg-transparent text-neutral-06 h-[27px] px-[12px] flex items-center justify-center border-[1px] border-neutral-06 rounded-[8px]"
                onClick={() => onDoActionRequestFriend(item, "cancel")}
                disabled={isPending}
              >
                {t("group.reject")}
              </Button>
            </div>
          </div>
        ))
      ) : (
        <div className="w-full flex items-center justify-center h-[200px]">
          <DataEmpty title={t("profile.no_data")} />
        </div>
      )}
    </>
  )
}
