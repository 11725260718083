import { Button } from "@/app/components/ui/button"
import { useToast } from "@/app/components/ui/use-toast"
import { QUERY_KEY } from "@/app/configs"
import { useMutatonDoActionRequestFriend } from "@/app/services/query-fn/user/user-friend-mutation"
import { useQueryClient } from "@tanstack/react-query"
import { useTranslation } from "next-i18next"
import React from "react"

export const ActionFriendNotification = ({
  noti,
  handleNoti
}: {
  noti: any
  handleNoti: any
}) => {
  const { t } = useTranslation()
  const { toast } = useToast()
  const { mutate: mutateDoActionFriend, isPending } =
    useMutatonDoActionRequestFriend()

  const queryClient = useQueryClient()

  const onDoActionRequestFriend = (status: "cancel" | "accepted") => {
    handleNoti(noti)
    mutateDoActionFriend(
      {
        request_id: String(noti?.content_id?.request_id),
        status
      },
      {
        onSuccess(data, variables, context) {
          toast({
            variant: "success",
            title:
              status === "accepted"
                ? t("profile.accept_add_friend")
                : t("profile.cancel_add_friend")
          })
          queryClient.refetchQueries({
            queryKey: [QUERY_KEY.LIST_SUGGEST_FRIEND]
          })
          queryClient.refetchQueries({
            queryKey: [QUERY_KEY.LIST_FRIEND]
          })
          queryClient.refetchQueries({
            queryKey: [QUERY_KEY.LIST_REQUEST_FRIEND]
          })
          queryClient.refetchQueries({
            queryKey: [QUERY_KEY.PROFILE_INFO]
          })
          queryClient.refetchQueries({
            queryKey: [QUERY_KEY.GET_PROFILE_BY_ID, noti?.content_id?.user_id]
          })
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY.NOTIFICATION_LIST, { noticeType: "all" }]
          })
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY.NOTIFICATION_LIST, { noticeType: "1" }]
          })
        },
        onError(error: any) {
          toast({
            variant: "error",
            title: error?.data?.message
          })
        }
      }
    )
  }

  if (!noti || noti?.content_id?.confirm) return <></>
  return (
    <div className="mt-[12px] flex gap-[8px]">
      <Button
        variant="main"
        className="h-[24px] rounded-[8px] px-[12px] text-[12px] Nunito-400"
        disabled={isPending}
        onClick={(e) => {
          e.stopPropagation()
          onDoActionRequestFriend("accepted")
        }}
      >
        {t("common.yes_did_id")}
      </Button>
      <Button
        variant="grey"
        className="h-[24px] dark:text-neutral-08 rounded-[8px] px-[12px] text-[12px] Nunito-400 border-black/10 border bg-transparent text-neutral-06 active:opacity-60"
        disabled={isPending}
        onClick={(e) => {
          e.stopPropagation()
          onDoActionRequestFriend("cancel")
        }}
      >
        {t("group.reject")}
      </Button>
    </div>
  )
}
