import { IconClose } from "@/app/components/icon-tsx/IconClose"
import { Button } from "@/app/components/ui/button"
import { Dialog, DialogContent } from "@/app/components/ui/dialog"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@/app/components/ui/select"
import { useToast } from "@/app/components/ui/use-toast"
import { GIFTCODE_TYPE, QUERY_KEY } from "@/app/configs"
import { PackageContext } from "@/app/context/PackageContext"
import useGAEventsTracker from "@/app/hooks/useGAEventsTracker"
import { useMutationUseItem } from "@/app/services/query-fn/reward-center/package/package-mutation"
import { useQueryGetListGameByGiftcode } from "@/app/services/query-fn/reward-center/package/package-query"
import { useQueryClient } from "@tanstack/react-query"
import { useTranslation } from "next-i18next"
import { FC, useContext, useState } from "react"

interface ModalUseGiftcodeProps {
  open: boolean
  handleClose: () => void
  listItem: any
}

export const ModalUseGiftcode: FC<ModalUseGiftcodeProps> = ({
  open,
  handleClose,
  listItem
}) => {
  const { visible, setVisible, dataSelected, setDataSelected } =
    useContext(PackageContext)
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const { mutate, isPending } = useMutationUseItem()
  const [game, setGame] = useState<any>()
  const { data: listGame } = useQueryGetListGameByGiftcode({
    item_id: dataSelected?.id || ""
  })
  const GAEventsTracker = useGAEventsTracker("Inventory")

  const OptionGame = listGame?.data || []

  const onChangeGame = (value: string) => {
    const _game: any = OptionGame.find((item: any) => item?.game_id === value)
    setGame(_game)
    GAEventsTracker("Inventory_selected_game", _game?.game_name)
  }

  const onClose = () => {
    setVisible({
      ...visible,
      confirmGiftcode: false
    })

    setDataSelected()
  }

  const onUse = async (item: any) => {
    GAEventsTracker("Inventory_used_giftcode", item?.name)
    mutate(
      { item_id: item.id, game_id: game?.game_id || "" },
      {
        onSuccess: () => {
          queryClient.refetchQueries({
            queryKey: [QUERY_KEY.PACKAGE_LIST]
          })

          toast({
            variant: "success",
            title: "Áp dụng thành công!"
          })
          onClose()
        },
        onError: () => {}
      }
    )
  }

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className="max-w-[420px] border-0 shadow-[0px_4px_30px_0px_rgba(0,0,0,0.06)] rounded-[24px] p-[24px] sm:rounded-[24px] gap-0 space-y-[24px] xs:w-[90vw]">
        <div className="flex items-center justify-between">
          <p className="text-[16px] Nunito-700">
            {t("reward.exchange_giftcode")}
          </p>
          <IconClose className="cursor-pointer" onClick={() => onClose()} />
        </div>

        <div>
          <p className="text-[14px] text-neutral-05 dark:text-neutral-07 pb-[8px]">
            {t("reward.pick_game_by_giftcode")}
          </p>

          <Select
            value={game?.game_id || ""}
            onValueChange={(value: string) => onChangeGame(value)}
          >
            <div className="relative">
              <SelectTrigger
                asChild={false}
                className="w-full h-[46px] rounded-[16px] border border-black/10 Nunito-400 dark:bg-white/10 dark:backdrop-blur-[20px] text-left"
              >
                <SelectValue placeholder={t("common.chose_game")}>
                  <div className="flex-1">{game?.game_name || ""}</div>
                </SelectValue>
              </SelectTrigger>
              {game?.game_id && (
                <IconClose
                  className="cursor-pointer absolute top-[50%] translate-y-[-50%] right-[16px]"
                  onClick={() => {
                    setGame({})
                  }}
                />
              )}
            </div>

            <SelectContent className="p-1 rounded-[16px]">
              {OptionGame.map((item: Record<string, string>) => (
                <SelectItem
                  value={item?.game_id}
                  key={item?.game_id}
                  aria-expanded
                  className="p-3 rounded-[8px] hover:!bg-bg-disable hover:!text-main-01 Nunito-500 focus:bg-bg-disable focus:text-main-01 dark:hover:!bg-[#F1F1FC]/[.1] dark:focus:!bg-[#F1F1FC]/[.1]"
                >
                  {item?.game_name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div>
          <p className="text-[14px] text-neutral-05 dark:text-neutral-07">
            {t("reward.giftcode_name")}
          </p>
          <p className="text-[16px] Nunito-500">{dataSelected?.name}</p>
        </div>

        <div className="flex gap-[16px] justify-end items-center">
          <Button
            variant="grey"
            onClick={() => onClose()}
            className="text-neutral-05 dark:text-neutral-08 rounded-[16px] px-[24px] py-[12px] w-fit h-[46px]"
          >
            {t("merchandise.cancel")}
          </Button>
          <Button
            variant="main"
            loading={isPending}
            disabled={!game?.game_id}
            onClick={() => {
              if (dataSelected?.status === GIFTCODE_TYPE.PENDING) {
                onUse(dataSelected)
              }
            }}
            className="rounded-[16px] px-[24px] py-[12px] w-fit h-[46px]"
          >
            {t("reward.use_now")}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
