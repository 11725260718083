import { AppContext } from "@/app/context"
import { useContext } from "react"

export const IconGame = ({
  fill,
  className
}: {
  fill?: string
  className?: string
}) => {
  const { themeApp } = useContext(AppContext)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      className={className}
    >
      <path
        d="M10.2141 12.46L7.16406 15.51"
        stroke={fill ? fill : "#65676B"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.19531 12.49L10.2453 15.54"
        stroke={fill ? fill : "#65676B"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1758 14H14.1858"
        stroke={fill ? fill : "#65676B"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.1133 14H18.1233"
        stroke={fill ? fill : "#65676B"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.1445 15.98V15.96"
        stroke={fill ? fill : "#65676B"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.1445 12.04V12.02"
        stroke={fill ? fill : "#65676B"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6445 22C20.6445 22 22.6445 20 22.6445 15V13C22.6445 8 20.6445 6 15.6445 6H9.64453C4.64453 6 2.64453 8 2.64453 13V15C2.64453 20 4.64453 22 9.64453 22H15.6445Z"
        stroke={fill ? fill : "#65676B"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.655 2L13.645 3.01C13.635 3.56 13.195 4 12.645 4H12.615C12.065 4 11.625 4.45 11.625 5C11.625 5.55 12.075 6 12.625 6H13.625"
        stroke={fill ? fill : "#65676B"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
