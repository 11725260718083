import { IconClose } from "@/app/components/icon-tsx/IconClose"
import { IconGold } from "@/app/components/icon-tsx/IconGold"
import { Button } from "@/app/components/ui/button"
import { Dialog, DialogContent } from "@/app/components/ui/dialog"
import { IGift } from "@/app/interfaces/IReward"
import { useTranslation } from "next-i18next"
import React, { FC } from "react"

interface ModalViewItemProps {
  open: boolean
  onOpenChange: () => void
  dataSelected: IGift
  onConfirmBuy: () => void
}

export const ModalViewItem: FC<ModalViewItemProps> = ({
  open,
  onOpenChange,
  dataSelected,
  onConfirmBuy
}) => {
  const { t } = useTranslation()

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:rounded-[16px] gap-0 rounded-[16px] max-w-[90vw] md:max-w-[685px] p-[24px] border-0 focus-visible:outline-none dark:bg-bg-02 space-y-[24px]">
        <div className="w-full flex items-center justify-between">
          <p className="text-bg-01 Nunito-700 text-base mb-[40px dark:text-main-white">
            {t("reward.detail")}
          </p>
          <div
            className="w-[32px] h-[32px] rounded-[8px] flex items-center justify-center  cursor-pointer"
            onClick={onOpenChange}
          >
            <IconClose />
          </div>
        </div>
        <div className="flex gap-[24px]">
          <img
            src={dataSelected.image}
            alt={dataSelected.item_name}
            className="object-cover aspect-square h-fit rounded-[12px] xs:rounded-[8px] w-[116px] sd:w-[230px]"
          />
          <div className="flex flex-col justify-between">
            <div>
              <p className="text-[14px] text-neutral-05 dark:text-neutral-07 pb-[6px] leading-[19px]">
                {t("reward.exchange_package")}
              </p>
              <p className="Nunito-500">{dataSelected.item_name}</p>
            </div>
            <div>
              <p className="text-[14px] text-neutral-05 dark:text-neutral-07 pb-[6px] leading-[19px]">
                {t("reward.criteria")}
              </p>
              <div className="text-[14px] flex items-center gap-[3px] Nunito-500">
                <p className="leading-[14px]">{dataSelected.price}</p>
                <IconGold />
              </div>
            </div>
            <div className="border border-dashed border-black/10 dark:border-neutral-07 dark:text-neutral-07 p-[12px] rounded-[12px] w-full text-[16px] Nunito-400 text-neutral-05">
              {t("reward.exchange_note")}
            </div>
          </div>
        </div>
        <div className="flex items-center justify-end gap-[16px]">
          <Button
            variant="grey"
            className="text-nutral-05 dark:text-neutral-08 rounded-[16px] px-[24px] py-[12px] h-[46px] w-full md:w-fit"
            onClick={onOpenChange}
          >
            {t("merchandise.cancel")}
          </Button>
          <Button
            variant="main"
            className="px-[24px] py-[12px] h-[46px] rounded-[16px] w-full md:w-fit"
            onClick={onConfirmBuy}
          >
            {t("store.buy_now")}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
