import React, { FC } from "react"
import { LoadingOutside } from "../icon-tsx/LoadingOutside"
import { LoadingInside } from "../icon-tsx/LoadingInside"

export const LoadingBtnComponent: FC<{
  classname?: string
}> = ({ classname }) => {
  return (
    <div className={`relative w-[21px] h-[21px] flex item-center ${classname}`}>
      <LoadingOutside
        style={{
          top: "15%",
          transform: "translate(50%, 50%)"
        }}
        className="rotate absolute z-1"
      />
      <LoadingInside
        style={{
          top: "25%",
          left: "15%",
          transform: "translate(50%, 50%)"
        }}
        className="rotate-back absolute"
      />
    </div>
  )
}
