import { AxiosResponse } from "axios"
import IApiResponse from "@interfaces/IApiResponse"
import {
  IBuyItemParams,
  IGift,
  IGiftList,
  IListSpin,
  IMissionUpdateProfilePrams,
  IPackage,
  IPackageParams,
  IShopParams,
  IUserItemParams
} from "@interfaces/IReward"
import { http } from "@/app/api"

const resource = "/api/task"
const resourceUser = "/api/bag"
const resourceSpin = "/api/spin"
const resourceShop = "/api/shop"

interface ShopResult {
  errorCode: number
  message: string
  data: [IGift]
}

export default class RewardService {
  static getListTask(): Promise<AxiosResponse<IApiResponse>> {
    return http.get(`${resource}/list`, { params: { limit: 20 } })
  }
  static confirmTask(task_id: string): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`${resource}/confirm`, { task_id })
  }

  // verify account task
  static verifyAccountTask(
    params: IMissionUpdateProfilePrams
  ): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`${resource}/verifyAccount`, params)
  }

  static confirmVerifyAccountCode(params: {
    task_id: string
    code: string
  }): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`${resource}/verifyAccount/confirm-code`, params)
  }
  static resendVerifyAccountCode(): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`${resource}/verifyAccount/resend-code`)
  }

  static getPackage(
    params: IPackageParams
  ): Promise<AxiosResponse<IApiResponse>> {
    return http.get(`${resourceUser}/items`, { params: params })
  }

  static getGameByGiftcode(
    params: IBuyItemParams
  ): Promise<AxiosResponse<IApiResponse>> {
    return http.get(`${resourceUser}/listGameByItem`, { params: params })
  }

  static getItemBag(
    params: IUserItemParams
  ): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`${resourceUser}/useItem`, params)
  }
  static getListSpin(): Promise<AxiosResponse<IApiResponse>> {
    return http.get(`${resourceSpin}/items`)
  }
  static doSpin(spin_id: string): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`${resourceSpin}/doSpin`, { spin_id })
  }
  static getLeaderboard(query?: string): Promise<AxiosResponse<IApiResponse>> {
    return http.get(`${resource}/rank`, {
      params: { filter: query }
    })
  }
  static historySpin(page: number): Promise<AxiosResponse<IApiResponse<any>>> {
    return http.get(`${resourceSpin}/history`, { params: { page } })
  }

  static getLastSpin(): Promise<AxiosResponse<IApiResponse>> {
    return http.get(`${resourceSpin}/lastHistory`)
  }

  //shop
  static getListShop(params: IShopParams): Promise<AxiosResponse<ShopResult>> {
    return http.get(`${resourceShop}/items`, {
      params: params
    })
  }

  static buyItem(params: IBuyItemParams): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`${resourceShop}/buyItem`, params)
  }

  static getStickersByUser(): Promise<AxiosResponse<IApiResponse>> {
    return http.get(`${resourceUser}/stickersByUser`)
  }
}
