import { useQuery } from "@tanstack/react-query"
import { QUERY_KEY } from "@/app/configs"
import { EventService } from "../../services/EventServices"

const getEventDetail = async (id: string) => {
  const data = await EventService.getEventDetail(id)
  return data?.data?.data
}

const getEventDetailForEdit = async (id: string) => {
  const data = await EventService.getEventDetailForEdit(id)
  return data?.data?.data
}

export const useQueryGetEventDetail = (id: string, type: string) => {
  return useQuery({
    queryKey: [QUERY_KEY.EVENT_DETAIL, id],
    queryFn: () => getEventDetail(id),
    enabled: !!id && id !== "undefined" && type === "event"
  })
}

export const useQueryGetEventDetailForEdit = (id: string, type: string) => {
  return useQuery({
    queryKey: [QUERY_KEY.EVENT_DETAIL_FOR_EDIT, id],
    queryFn: () => getEventDetailForEdit(id),
    enabled: !!id && id !== "undefined" && type === "event"
  })
}
