import { IconCalendar } from "@/app/components/icon-tsx/IconCalendar"
import { Button } from "@/app/components/ui/button"
import { Calendar } from "@/app/components/ui/calendar"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger
} from "@/app/components/ui/dropdown-menu"
import { ScrollArea } from "@/app/components/ui/scroll-area"
import { DATE_FORMAT, END_YEAR } from "@/app/configs"
import { CustomSelectMonthYear } from "@/app/containers/create-post/add-on/CustomSelectMontYear"
import moment from "moment"
import { useTranslation } from "next-i18next"
import React, { useMemo } from "react"
import { useFormContext } from "react-hook-form"

interface StartTimeProps {
  open: boolean
  onChange: () => void
}
export const StartTimeFilter: React.FC<StartTimeProps> = ({
  open,
  onChange
}) => {
  const { watch, setValue } = useFormContext()
  const { t } = useTranslation()
  const selectedStartTime = watch("start_date", undefined)
  const handleDaySelectStartTime = (date: Date | undefined) => {
    setValue("start_date", date)
    onChange()
  }

  const content = useMemo(() => {
    return (
      <DropdownMenu open={open} onOpenChange={onChange}>
        <DropdownMenuTrigger asChild>
          <Button
            variant="none"
            className="bg-disable w-full text-neutral flex justify-between items-center py-[8px] dark:text-main-white border-b dark:border-neutral-07 rounded-none"
            onClick={onChange}
          >
            <span>
              {selectedStartTime
                ? moment(selectedStartTime).format(DATE_FORMAT)
                : t("profile.from_date")}
            </span>
            <IconCalendar />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-[276px]" align="end">
          <ScrollArea className="h-fit">
            <Calendar
              mode="single"
              selected={selectedStartTime}
              onSelect={handleDaySelectStartTime}
              className="p-0 rounded-md"
              showOutsideDays
              captionLayout="dropdown"
              fromYear={new Date().getFullYear()}
              toYear={END_YEAR}
              components={{
                Head: () => <CustomSelectMonthYear />
              }}
            />
          </ScrollArea>
        </DropdownMenuContent>
      </DropdownMenu>
    )
  }, [open])

  return <>{content}</>
}
