import { IconCopied } from "@/app/components/icon-tsx/IconCopied"
import { IconNonCopy } from "@/app/components/icon-tsx/IconNonCopy"
import { AppContext } from "@/app/context"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { useRouter } from "next/router"
import { useQueryGetProfileById } from "@/app/services/query-fn"
import { OPEN_MODAL } from "@/app/configs"

const TIME_OUT = 3000
export const InviteCode = () => {
  const router = useRouter()
  const { userInfo, themeApp, setOpenModal } = useContext(AppContext)
  const userId = useMemo(() => router?.query?.id, [router])

  const { data: otherUser } = useQueryGetProfileById(
    userId !== userInfo?.userid && userId !== userInfo?.alias
      ? String(userId)
      : undefined
  )

  const dataUser =
    userId !== userInfo?.userid && userId !== userInfo?.alias && !!otherUser
      ? otherUser
      : userInfo

  const [copied, setIsCopied] = useState<boolean>(false)
  const onCopyInviteCode = () => {
    navigator.clipboard.writeText(dataUser?.referent_code)
    setIsCopied(true)
  }
  const onChangeShowModal = () => {
    setOpenModal(OPEN_MODAL.SHARE_REFER_CODE)
  }
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (copied) setIsCopied(false)
    }, TIME_OUT)

    return () => {
      clearTimeout(timeout)
    }
  }, [copied])

  return (
    <>
      <div className="w-full flex items-center justify-center my-1 md:my-[8px] gap-[20px]">
        <div className="flex gap-[7px] items-center">
          <p className="text-[#27908C] text-sm Nunito-400">
            Invite code:{" "}
            <span className="Nunito-700">{dataUser?.referent_code}</span>
          </p>
          <div className="w-[1px] h-[17px] bg-[#27908C]" />

          <div className="cursor-pointer w-fit" onClick={onCopyInviteCode}>
            {copied ? <IconCopied /> : <IconNonCopy stroke="#27908C" />}
          </div>
        </div>
        {(userId === userInfo?.userid || userId === userInfo?.alias) && (
          <div
            className="w-[34px] h-[34px] rounded-[10px] flex items-center justify-center cursor-pointer dark:bg-[rgba(240,242,245,0.1)]"
            onClick={onChangeShowModal}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.66602 7.33337L14.1327 1.8667"
                stroke={themeApp === "dark" ? "#fff" : "#585669"}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.6668 4.53334V1.33334H11.4668"
                stroke={themeApp === "dark" ? "#fff" : "#585669"}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.33398 1.33334H6.00065C2.66732 1.33334 1.33398 2.66668 1.33398 6.00001V10C1.33398 13.3333 2.66732 14.6667 6.00065 14.6667H10.0007C13.334 14.6667 14.6673 13.3333 14.6673 10V8.66668"
                stroke={themeApp === "dark" ? "#fff" : "#585669"}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        )}
      </div>
    </>
  )
}
