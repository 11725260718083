import React, { FC } from "react"
import { usePagination, DOTS } from "./usePagination"
import { IconArrowRight } from "../icon-tsx/IconArrowRight"
import { IconArrowLeft } from "../icon-tsx/IconArrowLeft"

interface PaginationProps {
  onPageChange: (param: any) => void
  totalCount: number
  siblingCount?: number
  currentPage: number
  pageSize: number
  className?: string
}

const Pagination: FC<PaginationProps> = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className
  } = props

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  })

  if (currentPage === 0 || paginationRange.length < 2) {
    return null
  }

  const onNext = () => {
    if (
      paginationRange &&
      currentPage < Number(paginationRange[paginationRange.length - 1])
    ) {
      onPageChange(currentPage + 1)
    }
  }

  const onPrevious = () => {
    if (currentPage > 1) onPageChange(currentPage - 1)
  }

  let lastPage = paginationRange[paginationRange.length - 1]

  return (
    <ul className={`flex gap-[8px] ${className}`}>
      <li
        className={`p-[3px] rounded-[8px] border border-main-01 h-fit mr-[8px] ${
          currentPage === 1 ? "opacity-60" : "cursor-pointer"
        }`}
        onClick={onPrevious}
      >
        <IconArrowLeft />
      </li>
      {paginationRange.map((pageNumber: any) => {
        if (pageNumber === DOTS) {
          return (
            <li key={pageNumber} className="pagination-item dots">
              &#8230;
            </li>
          )
        }

        return (
          <li
            key={pageNumber}
            className={`w-[32px] h-[32px] rounded-[8px] text-[14px] justify-center items-center flex ${
              pageNumber === currentPage
                ? "bg-main-01 text-main-white cursor-default"
                : "text-neutral-03 bg-white dark:bg-bg-button/10 dark:text-main-white border border-black/10 cursor-pointer"
            }`}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        )
      })}
      <li
        className={`p-[3px] rounded-[8px] border border-main-01 h-fit ml-[8px] ${
          currentPage === lastPage ? "opacity-60" : "cursor-pointer"
        }`}
        onClick={onNext}
      >
        <IconArrowRight />
      </li>
    </ul>
  )
}

export default Pagination
