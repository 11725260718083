import { Input } from "@/app/components/ui/input"
import React from "react"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "next-i18next"

export const LinkEvent = () => {
  const { t } = useTranslation()
  const { watch, setValue } = useFormContext()
  const watchLinkEvent = watch("link_event", "")
  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue("link_event", e?.target?.value)
  }

  return (
    <div className="relative w-full">
      <span className="text-bg-01 text-[18px] Nunito-700 dark:text-white">
        {t("news.attach_link")}
      </span>
      <Input
        onChange={onChangeValue}
        value={watchLinkEvent}
        placeholder={t("news.input_content")}
        className="border-0 border-b-[1px] rounded-none h-10 dark:bg-transparent"
      />
    </div>
  )
}
