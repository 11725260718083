import { useToast } from "@/app/components/ui/use-toast"
import { GIFTCODE_TYPE, PACKAGE_TYPE, QUERY_KEY } from "@/app/configs"
import { AppContext } from "@/app/context"
import { PackageContext } from "@/app/context/PackageContext"
import { useMutationUseItem } from "@/app/services/query-fn/reward-center/package/package-mutation"
import { useQueryClient } from "@tanstack/react-query"
import { useTranslation } from "next-i18next"
import ImagePackage from "public/img/reward/package_default.png"
import { FC, useContext } from "react"
import { PackageCard } from "../PackageCard"
import useGAEventsTracker from "@/app/hooks/useGAEventsTracker"

interface OtherCardItemprops {
  item: any
}

export const OtherCardItem: FC<OtherCardItemprops> = ({ item }) => {
  const { t } = useTranslation()
  const { userInfo: userProfile } = useContext(AppContext)
  const { visible, setVisible, setPreview, setDataSelected } =
    useContext(PackageContext)
  const { mutate, isPending } = useMutationUseItem()
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const GAEventsTracker = useGAEventsTracker("Inventory")

  // gift_item => không show action gì
  // avatar, sticker => nếu chưa dùng => hiển thị dùng ngay
  // avatar => dùng rồi => hiển thị gỡ
  // sticker => đã dùng => hiển thị chi tiết

  const textAction = () => {
    const isGiftItem = item?.type === PACKAGE_TYPE.GIFT_ITEM
    const isAvatar = item?.type === PACKAGE_TYPE.AVATAR

    let result = ""

    if (!isGiftItem) {
      if (!isAvatar) {
        result =
          item.status === GIFTCODE_TYPE.PENDING ? t("reward.use_now") : ""
      } else {
        result =
          userProfile?.frame_avatar === item?.item_received?.frame_avatar
            ? t("common.delete_frame")
            : t("reward.use_now")
      }
    }

    return result
  }

  const textDetail = () => {
    const isGiftItem = item?.type === PACKAGE_TYPE.GIFT_ITEM
    const isAvatar = item?.type === PACKAGE_TYPE.AVATAR

    let result = ""

    if (!isGiftItem && !isAvatar) {
      result = item.status === GIFTCODE_TYPE.USED ? t("reward.detail") : ""
    }

    return result
  }

  const onUse = async (item: any) => {
    if (userProfile?.frame_avatar === item?.item_received?.frame_avatar) {
      mutate(
        { item_id: item.id, game_id: "", revoke: "yes" },
        {
          onSuccess: () => {
            GAEventsTracker("Inventory_removed_frame")
            queryClient.resetQueries({
              queryKey: [QUERY_KEY.PROFILE_INFO]
            })
            queryClient.refetchQueries({
              queryKey: [QUERY_KEY.PACKAGE_LIST]
            })
            toast({
              variant: "success",
              title: "Gỡ thành công!"
            })
          },
          onError: () => {}
        }
      )
    } else {
      mutate(
        { item_id: item.id },
        {
          onSuccess: () => {
            if (item?.type === PACKAGE_TYPE.AVATAR) {
              GAEventsTracker("Inventory_used_frame")
            }
            if (item?.type === PACKAGE_TYPE.STICKER) {
              GAEventsTracker("Inventory_used_sticker")
            }
            queryClient.resetQueries({
              queryKey: [QUERY_KEY.PROFILE_INFO]
            })
            queryClient.refetchQueries({
              queryKey: [QUERY_KEY.PACKAGE_LIST]
            })
            queryClient.refetchQueries({
              queryKey: [QUERY_KEY.GET_STICKER_USER]
            })
            if (item.type === PACKAGE_TYPE.AVATAR) {
              // show modal apply avatar success
              setVisible({
                ...visible,
                avatarSuccess: true
              })
            } else {
              toast({
                variant: "success",
                title: "Áp dụng thành công!"
              })
            }
          },
          onError: () => {}
        }
      )
    }
  }

  return (
    <PackageCard
      cardItem={item as any}
      textDetail={textDetail()}
      textAction={textAction()}
      src={item?.item_received?.icon}
      actionDetail={() => {
        GAEventsTracker("Inventory_view_sticker")
        if (item?.type === PACKAGE_TYPE.STICKER) {
          setVisible({
            ...visible,
            viewSticker: true
          })
          setDataSelected(item)
        } else {
          setPreview({
            open: true,
            src: !!item?.image ? item?.image : ImagePackage.src
          })
        }
      }}
      action={() => onUse(item)}
      loading={isPending}
    />
  )
}
