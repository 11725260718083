import { GIFTCODE_TYPE, PACKAGE_TYPE } from "@/app/configs"
import { PackageContext } from "@/app/context/PackageContext"
import { useTranslation } from "next-i18next"
import { FC, useContext } from "react"
import { PackageCard } from "../PackageCard"
import useGAEventsTracker from "@/app/hooks/useGAEventsTracker"
import { AppContext } from "@/app/context"

interface GiftcodeItemprops {
  item: any
}

export const GiftcodeItem: FC<GiftcodeItemprops> = ({ item }) => {
  const { t } = useTranslation()
  const { visible, setVisible, setDataSelected } = useContext(PackageContext)
  const GAEventsTracker = useGAEventsTracker("Inventory")
  const { setVisibleGiftcodeDetail, setSelectedGiftcode } =
    useContext(AppContext)

  const textDetail = () => {
    let result = ""

    if (item.status === GIFTCODE_TYPE.USED) {
      result = t("reward.detail")
    }

    return result
  }
  const textAction = () => {
    let result = ""

    if (item.status === GIFTCODE_TYPE.PENDING) {
      result = t("reward.use_now")
    }

    return result
  }

  const onUse = (item: any) => {
    setVisible({
      ...visible,
      confirmGiftcode: true
    })

    setDataSelected(item)
  }

  return (
    <PackageCard
      cardItem={item as any}
      textDetail={textDetail()}
      textAction={textAction()}
      actionDetail={() => {
        GAEventsTracker("Inventory_view_giftcode")
        if (item?.type === PACKAGE_TYPE.GIFTCODE || item?.is_used) {
          setVisible({
            ...visible,
            viewGiftcode: true
          })
          setDataSelected(item)
        }

        if (item?.type === PACKAGE_TYPE.GC && !item?.is_used) {
          setVisibleGiftcodeDetail(true)
          setSelectedGiftcode({
            id: item?.item_received?.gc_event_id,
            has_used: item?.has_used
          })
        }
      }}
      action={() => onUse(item)}
    />
  )
}
