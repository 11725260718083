import { IconClose } from "@/app/components/icon-tsx/IconClose"
import { IconGold } from "@/app/components/icon-tsx/IconGold"
import { Button } from "@/app/components/ui/button"
import { Dialog, DialogContent } from "@/app/components/ui/dialog"
import { IGift } from "@/app/interfaces/IReward"
import { useTranslation } from "next-i18next"
import React, { FC } from "react"

interface ModalConfirmBuyProps {
  open: boolean
  onOpenChange: () => void
  dataSelected: IGift
  onBuy: () => void
}

export const ModalConfirmBuy: FC<ModalConfirmBuyProps> = ({
  open,
  onOpenChange,
  dataSelected,
  onBuy
}) => {
  const { t } = useTranslation()

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:rounded-[16px] gap-0 rounded-[16px] max-w-[90vw] md:max-w-[354px] p-[24px] border-0 focus-visible:outline-none dark:bg-bg-02 space-y-[40px]">
        <div className="w-full flex items-center justify-between">
          <p className="text-bg-01 Nunito-700 text-base mb-[40px dark:text-main-white">
            {t("common.buy_item")}
          </p>
          <div
            className="w-[32px] h-[32px] rounded-[8px] flex items-center justify-center  cursor-pointer"
            onClick={onOpenChange}
          >
            <IconClose />
          </div>
        </div>
        <div className="space-y-[16px]">
          <div className="flex justify-between items-center">
            <p className="text-[14px] text-neutral-05 dark:text-neutral-07 pb-[6px] leading-[19px]">
              {t("reward.exchange_package")}
            </p>
            <p className="Nunito-500">{dataSelected.item_name}</p>
          </div>
          <div className="flex justify-between items-center">
            <p className="text-[14px] text-neutral-05 dark:text-neutral-07 pb-[6px] leading-[19px]">
              {t("reward.criteria")}
            </p>
            <div className="text-[14px] flex items-center gap-[3px] Nunito-500">
              <p className="leading-[14px]">{dataSelected.price}</p>
              <IconGold />
            </div>
          </div>
        </div>
        <div className="flex items-center justify-end gap-[16px]">
          <Button
            variant="grey"
            className="text-nutral-05 dark:text-neutral-07 rounded-[16px] px-[24px] py-[12px] h-[46px]"
            onClick={onOpenChange}
          >
            {t("merchandise.cancel")}
          </Button>
          <Button
            variant="main"
            className="px-[24px] py-[12px] h-[46px] rounded-[16px]"
            onClick={onBuy}
          >
            {t("store.buy_now")}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
