export const IconGameFill = ({
  fill,
  className
}: {
  fill?: string
  className?: string
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        d="M16.65 5.71973H7.35C4.4 5.71973 2 8.11973 2 11.0697V16.6497C2 19.5997 4.4 21.9997 7.35 21.9997H16.65C19.6 21.9997 22 19.5997 22 16.6497V11.0697C22 8.11973 19.6 5.71973 16.65 5.71973ZM14.5 12.0197C14.5 11.4697 14.95 11.0197 15.5 11.0197C16.05 11.0197 16.5 11.4697 16.5 12.0197C16.5 12.5697 16.05 13.0297 15.5 13.0297C14.95 13.0297 14.5 12.5897 14.5 12.0397V12.0197ZM10.13 16.0697C9.98 16.2197 9.79 16.2897 9.6 16.2897C9.41 16.2897 9.22 16.2197 9.07 16.0697L8.04 15.0397L7.05 16.0297C6.9 16.1797 6.71 16.2497 6.52 16.2497C6.33 16.2497 6.14 16.1797 5.99 16.0297C5.7 15.7397 5.7 15.2597 5.99 14.9697L6.98 13.9797L6.02 13.0197C5.73 12.7297 5.73 12.2497 6.02 11.9597C6.31 11.6697 6.79 11.6697 7.08 11.9597L8.04 12.9197L9.03 11.9297C9.32 11.6397 9.8 11.6397 10.09 11.9297C10.38 12.2197 10.38 12.6997 10.09 12.9897L9.1 13.9797L10.13 15.0097C10.42 15.2997 10.42 15.7797 10.13 16.0697ZM13.54 14.9997C12.99 14.9997 12.53 14.5497 12.53 13.9997C12.53 13.4497 12.97 12.9997 13.52 12.9997H13.54C14.09 12.9997 14.54 13.4497 14.54 13.9997C14.54 14.5497 14.1 14.9997 13.54 14.9997ZM15.5 16.9697C14.95 16.9697 14.5 16.5297 14.5 15.9797V15.9597C14.5 15.4097 14.95 14.9597 15.5 14.9597C16.05 14.9597 16.5 15.4097 16.5 15.9597C16.5 16.5097 16.06 16.9697 15.5 16.9697ZM17.48 14.9997C16.93 14.9997 16.47 14.5497 16.47 13.9997C16.47 13.4497 16.91 12.9997 17.46 12.9997H17.48C18.03 12.9997 18.48 13.4497 18.48 13.9997C18.48 14.5497 18.04 14.9997 17.48 14.9997Z"
        fill={fill ? fill : "#7673E6"}
      />
      <path
        d="M13.6399 2.71L13.6299 3.65C13.6199 4.53 12.8899 5.26 11.9999 5.26C11.8499 5.26 11.7599 5.36 11.7599 5.49C11.7599 5.62 11.8599 5.72 11.9899 5.72H10.3799C10.3699 5.65 10.3599 5.57 10.3599 5.49C10.3599 4.59 11.0899 3.86 11.9799 3.86C12.1299 3.86 12.2299 3.76 12.2299 3.63L12.2399 2.69C12.2499 2.31 12.5599 2 12.9399 2H12.9499C13.3399 2 13.6399 2.32 13.6399 2.71Z"
        fill={fill ? fill : "#7673E6"}
      />
    </svg>
  )
}
