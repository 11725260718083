import { Button } from "@/app/components/ui/button"
import React from "react"
import {
  enumTypeTransaction,
  enumTypeViewAll,
  enumViewDetailTransaction
} from "../../tab"
import { useRouter } from "next/router"
import { IconArrowLeft } from "@/app/components/icon-tsx/IconArrowLeft"
import { IconArrowRight } from "@/app/components/icon-tsx/IconArrowRight"
import { useTranslation } from "next-i18next"
import { FilterAllTransaction } from "../filter-all/FilterAll"

export const PurchaseHeader = ({
  prevRef,
  nextRef,
  handleChangeTab
}: {
  prevRef: any
  nextRef: any
  handleChangeTab: (value: enumTypeTransaction) => void
}) => {
  const { t } = useTranslation()
  const router = useRouter()

  const onNavigateToStore = () => {
    router.push("/store")
  }
  return !String(router?.query?.type)?.includes("detail") ? (
    <>
      <div className="flex gap-[32px] items-center">
        <span className="text-base Nunito-700 text-bg-01 dark:text-white">
          {t("profile.buy")}
        </span>
        <div
          className="flex items-center gap-2 opacity-100 cursor-pointer active:opacity-70"
          onClick={onNavigateToStore}
        >
          <span className="text-sm text-main-01 Nunito-500">
            {t("store.title")}
          </span>
          <svg
            width="14"
            height="10"
            viewBox="0 0 14 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.5303 5.53033C13.8232 5.23744 13.8232 4.76256 13.5303 4.46967L9.53033 0.46967C9.23744 0.176777 8.76256 0.176777 8.46967 0.46967C8.17678 0.762563 8.17678 1.23744 8.46967 1.53033L11.1893 4.25H1C0.585787 4.25 0.25 4.58579 0.25 5C0.25 5.41421 0.585787 5.75 1 5.75H11.1893L8.46967 8.46967C8.17678 8.76256 8.17678 9.23744 8.46967 9.53033C8.76256 9.82322 9.23744 9.82322 9.53033 9.53033L13.5303 5.53033Z"
              fill="#7673E6"
            />
          </svg>
        </div>
      </div>
      <div className="flex flex-col-reverse md:flex-row gap-4 md:items-center md:justify-between w-full">
        {/* tab */}
        <div className="flex items-cente gap-[8px]">
          <Button
            variant="none"
            className={`px-[16px] py-1 border rounded-[8px] Nunito-400 text-sm h-[29px] ${
              [
                "undefined",
                String(enumTypeTransaction.CARD),
                String(enumTypeViewAll.CARD_ALL),
                String(enumViewDetailTransaction.CARD_DETAIL)
              ].includes(String(router?.query?.type))
                ? "text-main-01 border-main-01"
                : "border-black/10 text-neutral-03 dark:border-neutral-08 dark:text-neutral-07"
            }`}
            onClick={() => handleChangeTab(enumTypeTransaction.CARD)}
          >
            {t("profile.card")}
          </Button>
          <Button
            variant="none"
            className={`px-[16px] py-1 border rounded-[8px] Nunito-400 text-sm h-[29px] ${
              [
                String(enumTypeTransaction.TOPUP),
                String(enumTypeViewAll.TOPUP_ALL),
                String(enumViewDetailTransaction.TOPUP_DETAIL)
              ].includes(String(router?.query?.type))
                ? "text-main-01 border-main-01"
                : "border-black/10 text-neutral-03 dark:border-neutral-08 dark:text-neutral-07"
            }`}
            onClick={() => handleChangeTab(enumTypeTransaction.TOPUP)}
          >
            {t("profile.topup")}
          </Button>
          <Button
            variant="none"
            className={`px-[16px] py-1 border rounded-[8px] Nunito-400 text-sm h-[29px] ${
              [
                String(enumTypeTransaction.MERCHANDISE),
                String(enumTypeViewAll.MERCHANDISE_ALL),
                String(enumViewDetailTransaction.MERCHANDISE_DETAIL)
              ].includes(String(router?.query?.type))
                ? "text-main-01 border-main-01"
                : "border-black/10 text-neutral-03 dark:border-neutral-08 dark:text-neutral-07"
            }`}
            onClick={() => handleChangeTab(enumTypeTransaction.MERCHANDISE)}
          >
            Merchandise
          </Button>
        </div>
        {String(router?.query?.type)?.includes("all") ? (
          <FilterAllTransaction />
        ) : (
          <div className="hidden md:flex items-center gap-[8px] ml-auto">
            <div
              className={`p-[2px] rounded-[10px] border border-main-01 cursor-pointer`}
              onClick={() => {
                prevRef?.current && prevRef.current.click()
              }}
            >
              <IconArrowLeft />
            </div>
            <div
              className="p-[2px] rounded-[10px] border border-main-01 cursor-pointer"
              onClick={() => {
                nextRef?.current && nextRef.current.click()
              }}
            >
              <IconArrowRight />
            </div>
          </div>
        )}
      </div>
    </>
  ) : (
    <></>
  )
}
