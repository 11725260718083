import React from "react"
interface Iprops {}

export const IconNoVocher = ({}: Iprops) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="29"
      viewBox="0 0 24 29"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99965 5.6665C6.99965 2.90508 9.23822 0.666504 11.9996 0.666504C14.7611 0.666504 16.9996 2.90508 16.9996 5.6665V7.01287C19.5227 7.18883 21.599 9.12765 21.917 11.6717L23.2503 22.3383C23.6482 25.5215 21.1661 28.3332 17.9581 28.3332H6.04115C2.83316 28.3332 0.3511 25.5215 0.749002 22.3383L2.08233 11.6717C2.40034 9.12765 4.47657 7.18883 6.99965 7.01287V5.6665ZM14.9996 5.6665V6.99984H8.99965V5.6665C8.99965 4.00965 10.3428 2.6665 11.9996 2.6665C13.6565 2.6665 14.9996 4.00965 14.9996 5.6665ZM7.99967 16.6665C7.44739 16.6665 6.99967 17.1142 6.99967 17.6665C6.99967 18.2188 7.44739 18.6665 7.99967 18.6665H15.9997C16.552 18.6665 16.9997 18.2188 16.9997 17.6665C16.9997 17.1142 16.552 16.6665 15.9997 16.6665H7.99967Z"
        fill="#7673E6"
      />
    </svg>
  )
}
