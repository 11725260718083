import { useToast } from "@/app/components/ui/use-toast"
import { GIFTCODE_TYPE, PACKAGE_TYPE, QUERY_KEY } from "@/app/configs"
import { AppContext } from "@/app/context"
import { PackageContext } from "@/app/context/PackageContext"
import { useMutationUseItem } from "@/app/services/query-fn/reward-center/package/package-mutation"
import { useQueryClient } from "@tanstack/react-query"
import { useTranslation } from "next-i18next"
import ImagePackage from "public/img/reward/package_default.png"
import { FC, useContext } from "react"
import { PackageCard } from "../PackageCard"
import useGAEventsTracker from "@/app/hooks/useGAEventsTracker"

interface VoucherCardItemprops {
  item: any
}

export const VoucherCardItem: FC<VoucherCardItemprops> = ({ item }) => {
  const { t } = useTranslation()
  const { userInfo: userProfile } = useContext(AppContext)
  const { visible, setVisible, setDataSelected } = useContext(PackageContext)
  const { mutate, isPending } = useMutationUseItem()
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const GAEventsTracker = useGAEventsTracker("Inventory")

  const textAction = () => {
    let result = ""

    if (item.status === GIFTCODE_TYPE.PENDING) {
      result = t("reward.use_now")
    }

    return result
  }

  const textDetail = () => {
    let result = ""

    if (
      // item.type === PACKAGE_TYPE.VOUCHER ||
      item.status === GIFTCODE_TYPE.USED
    ) {
      result = t("reward.detail")
    }

    return result
  }

  const onUse = async (item: any) => {
    GAEventsTracker("Inventory_used_voucher", item?.name)

    mutate(
      { item_id: item.id },
      {
        onSuccess: async (data: any) => {
          await queryClient.refetchQueries({
            queryKey: [QUERY_KEY.PACKAGE_LIST]
          })

          toast({
            variant: "success",
            title: "Áp dụng thành công!"
          })

          GAEventsTracker("Inventory_view_voucher")
          setVisible({
            ...visible,
            view: true
          })

          setDataSelected({
            ...item,
            status: "done",
            item_received: data.data.data?.item_received || []
          })
        },
        onError: () => {}
      }
    )
  }

  return (
    <PackageCard
      cardItem={item as any}
      textDetail={textDetail()}
      textAction={textAction()}
      src={
        item?.image
          ? item?.image
          : !!item?.item_received?.store_product?.icon
          ? item?.item_received?.store_product?.icon
          : ""
      }
      actionDetail={() => {
        GAEventsTracker("Inventory_view_voucher")
        setVisible({
          ...visible,
          view: true
        })

        setDataSelected(item)
      }}
      action={() => {
        onUse(item)
      }}
      loading={isPending}
    />
  )
}
