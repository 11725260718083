export const getNumberList = (total: number, numberPerCol: number) => {
  if (total % numberPerCol === 0) {
    return Math.floor(total / numberPerCol)
  } else {
    return Math.floor(total / numberPerCol) + 1
  }
}

export const getEmptyListByNumber = (length: number) => {
  let list = []

  for (let i = 0; i < length; i++) {
    list.push(i)
  }
  return list
}

export const convertNameString = (fullname: string) => {
  const words = fullname.split(" ")
  const initials = words.map((word) => word.charAt(0))
  const result = initials.join("")
  return result.substring(0, 2).toUpperCase()
}

export function customRound(number: number) {
  const roundedNumber = +number.toFixed(1) // Làm tròn đến 2 chữ số sau dấu phẩy và chuyển thành số

  // Kiểm tra xem số đó có là số tròn hoặc chỉ có một số sau dấu thập phân không
  if (roundedNumber % 1 === 0) {
    return Math.round(roundedNumber) // Làm tròn số nguyên
  } else {
    return roundedNumber // Giữ nguyên số đã làm tròn
  }
}

export const convertObjectToArray = (obj: Object) =>
  Object.entries(obj).map(([key, value]) => ({
    key,
    ...value
  }))
