import { IconClose } from "@/app/components/icon-tsx/IconClose"
import { IconCopied } from "@/app/components/icon-tsx/IconCopied"
import { IconNonCopy } from "@/app/components/icon-tsx/IconNonCopy"
import { Button } from "@/app/components/ui/button"
import { Dialog, DialogContent } from "@/app/components/ui/dialog"
import { useToast } from "@/app/components/ui/use-toast"
import { DATE_FORMAT, GIFTCODE_TYPE, QUERY_KEY } from "@/app/configs"
import { PackageContext } from "@/app/context/PackageContext"
import useGAEventsTracker from "@/app/hooks/useGAEventsTracker"
import { useMutationUseItem } from "@/app/services/query-fn/reward-center/package/package-mutation"
import { useQueryClient } from "@tanstack/react-query"
import moment from "moment"
import { useTranslation } from "next-i18next"
import { FC, useContext, useEffect, useState } from "react"

interface ModalAppotaCardDetailProps {
  open: boolean
  handleClose: () => void
  listItem: any
}

export const ModalAppotaCardDetail: FC<ModalAppotaCardDetailProps> = ({
  open,
  handleClose,
  listItem
}) => {
  const { visible, setVisible, dataSelected, setDataSelected } =
    useContext(PackageContext)
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const { mutate, isPending } = useMutationUseItem()
  const [copySerial, setCopySerial] = useState(false)
  const GAEventsTracker = useGAEventsTracker("Inventory")

  const onClose = () => {
    setVisible({
      ...visible,
      appotaDetail: false
    })

    setDataSelected()
  }

  const onUse = async (item: any) => {
    GAEventsTracker("Inventory_used_ACard")
    mutate(
      { item_id: item.id },
      {
        onSuccess: () => {
          queryClient.refetchQueries({
            queryKey: [QUERY_KEY.PACKAGE_LIST]
          })

          toast({
            variant: "success",
            title: "Áp dụng thành công!"
          })
        },
        onError: () => {
          toast({
            variant: "error",
            title: t("message.out_of_giftcode")
          })
        }
      }
    )
  }

  useEffect(() => {
    if (listItem?.length && dataSelected) {
      const _selected = listItem?.find(
        (item: any) => item?.id === dataSelected.id
      )

      setDataSelected(_selected)
    }
  }, [listItem, dataSelected])

  const onCopyCode = (type: "code" | "serial") => {
    if (type === "code") {
      navigator.clipboard.writeText(
        dataSelected?.item_received?.card_code || ""
      )
      toast({
        variant: "success",
        title: "Sao chép thành công!"
      })
      onClose()
    }
    if (type === "serial") {
      setCopySerial(true)
      navigator.clipboard.writeText(
        dataSelected?.item_received?.card_seri || ""
      )
    }
  }

  useEffect(() => {
    if (copySerial) {
      setTimeout(() => {
        setCopySerial(false)
      }, 2000)
    }
  }, [copySerial])

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className="max-w-[420px] rounded-[24px] p-[24px] border-0 shadow-[0px_4px_30px_0px_rgba(0,0,0,0.06)] sm:rounded-[24px] gap-0 space-y-[24px] xs:w-[90vw]">
        <div className="flex items-center justify-between">
          <p className="text-[16px] Nunito-700">
            {t("reward.card_information")}
          </p>
          <IconClose className="cursor-pointer" onClick={() => onClose()} />
        </div>

        <div>
          <p className="text-[14px] text-neutral-05 dark:text-neutral-07">
            {t("reward.exchange_package")}
          </p>
          <p className="text-[16px] Nunito-500">{dataSelected?.name}</p>
        </div>
        <div>
          <p className="text-[14px] text-neutral-05 dark:text-neutral-07">
            {t("reward.exchange_time")}
          </p>
          <p className="text-[16px] Nunito-500">
            {moment.unix(dataSelected?.created_at).format(DATE_FORMAT)}
          </p>
        </div>

        {dataSelected?.item_received?.card_seri && (
          <div>
            <p className="text-[14px] text-neutral-05 dark:text-neutral-07">
              {t("store.serial_code")}
            </p>
            <div
              onClick={() => onCopyCode("serial")}
              className="flex w-fit items-center cursor-pointer"
            >
              <p className="text-[16px] Nunito-500 border-r dark:border-neutral-07 pr-[8px] mr-[8px]">
                {dataSelected?.item_received?.card_seri}
              </p>
              {copySerial ? <IconCopied /> : <IconNonCopy stroke="#9F9EA9" />}
            </div>
          </div>
        )}

        {dataSelected?.item_received?.card_code && (
          <div className="border border-dashed border-black/10 dark:border-neutral-07 rounded-[12px] p-[12px] text-[24px] Nunito-700 text-main-01 text-center leading-[38px]">
            {dataSelected?.item_received?.card_code}

            {dataSelected?.item_received?.expired_date && (
              <p className="text-[14px] text-neutral-05 dark:text-neutral-07 Nunito-400 leading-[19px]">
                HSD:{" "}
                {moment(dataSelected?.item_received?.expired_date).format(
                  DATE_FORMAT
                )}
              </p>
            )}
          </div>
        )}

        <div className="flex gap-[16px] justify-end items-center">
          <Button
            variant="grey"
            onClick={() => onClose()}
            className="text-neutral-05 dark:text-neutral-08 rounded-[16px] px-[24px] py-[12px] w-fit h-[46px]"
          >
            {t("merchandise.cancel")}
          </Button>
          <Button
            variant="main"
            loading={isPending}
            onClick={() => {
              if (dataSelected?.status === GIFTCODE_TYPE.PENDING) {
                onUse(dataSelected)
              } else {
                onCopyCode("code")
              }
            }}
            className="rounded-[16px] px-[24px] py-[12px] w-fit h-[46px]"
          >
            {dataSelected?.status === GIFTCODE_TYPE.PENDING
              ? t("reward.use_now")
              : t("reward.copy")}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
