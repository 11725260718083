import { Button } from "@/app/components/ui/button"
import React, { useContext, useState } from "react"
import { AppContext } from "@/app/context"
import { OPEN_MODAL } from "@/app/configs"

export const FavoriteHashtag = () => {
  const { userInfo, setOpenModal } = useContext(AppContext)
  const onChangeShowEdit = () => {
    setOpenModal(OPEN_MODAL.EDIT_HASHTAG)
  }

  return (
    <>
      {Array.isArray(userInfo?.topic_interest) &&
      !!userInfo?.topic_interest?.length ? (
        <div className="w-full flex items-start justify-between mt-[48px] gap-[12px]">
          <div className="flex flex-wrap items-center gap-2">
            {React.Children.toArray(
              userInfo?.topic_interest.map((item: any) => (
                <Button
                  variant="none"
                  className="bg-button text-main-01 text-sm px-2 py-[2px] max-h-[32px] flex items-center dark:bg-[#EFEFFF1A]"
                >
                  #{item}
                </Button>
              ))
            )}
          </div>
          <div className="w-[1px] h-[16px] bg-black/10" />
          <div className="cursor-pointer" onClick={onChangeShowEdit}>
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3 17.5H18M11.987 4.42643C11.987 4.42643 11.987 5.78861 13.3492 7.1508C14.7114 8.51299 16.0736 8.51299 16.0736 8.51299M6.59969 14.9901L9.46028 14.5814C9.87291 14.5225 10.2553 14.3313 10.55 14.0365L17.4358 7.1508C18.1881 6.39848 18.1881 5.17874 17.4358 4.42642L16.0736 3.06424C15.3213 2.31192 14.1015 2.31192 13.3492 3.06424L6.46347 9.94997C6.16873 10.2447 5.97754 10.6271 5.91859 11.0397L5.50994 13.9003C5.41913 14.536 5.964 15.0809 6.59969 14.9901Z"
                stroke="#7673E6"
                strokeLinecap="round"
              />
            </svg>
          </div>
        </div>
      ) : null}
    </>
  )
}
