export const IconNewsFill = ({
  fill,
  className
}: {
  fill?: string
  className?: string
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 2H7C5.34315 2 4 3.34315 4 5V15.6458C4.79613 14.9333 5.84747 14.5 7 14.5H20V5C20 3.34315 18.6569 2 17 2ZM8 5.25C7.58579 5.25 7.25 5.58579 7.25 6C7.25 6.41421 7.58579 6.75 8 6.75H16C16.4142 6.75 16.75 6.41421 16.75 6C16.75 5.58579 16.4142 5.25 16 5.25H8ZM7.25 10C7.25 9.58579 7.58579 9.25 8 9.25H12C12.4142 9.25 12.75 9.58579 12.75 10C12.75 10.4142 12.4142 10.75 12 10.75H8C7.58579 10.75 7.25 10.4142 7.25 10ZM7 16H20V19C20 20.6569 18.6569 22 17 22H7C5.34315 22 4 20.6569 4 19C4 17.3431 5.34315 16 7 16Z"
        fill={fill ? fill : "#7673E6"}
      />
    </svg>
  )
}
