import { ModalViewMedia } from "@/app/components/view-media/ModalViewMedia"
import { useFormContext } from "react-hook-form"
import { ModalApplyAvatarSuccess } from "./modal/ModalApplyAvatarSuccess"
import { FC, useContext } from "react"
import { PackageContext } from "@/app/context/PackageContext"
import { ModalCardDetail } from "./modal/ModalCardDetail"
import { ModalAppotaCardDetail } from "./modal/ModalAppotaCard"
import { ModalUseGiftcode } from "./modal/ModalUseGiftcode"
import { ModalViewGiftcode } from "./modal/ModalViewGiftcode"
import { ModalViewSticker } from "./modal/ModalViewSticker"
import { ModalViewFriend } from "./modal/ModalChooseFriend"
import { ModalSendSuccess } from "./modal/ModalSendSuccess"

interface PackageModalContainerProps {
  listItem: any
  resetQuery: () => void
}

export const PackageModalContainer: FC<PackageModalContainerProps> = ({
  listItem,
  resetQuery
}) => {
  const { visible, setVisible, setPreview, preview, dataSelected } =
    useContext(PackageContext)

  return (
    <>
      <ModalViewGiftcode open={visible.viewGiftcode} handleClose={() => {}} />

      {dataSelected?.id && (
        <ModalUseGiftcode
          open={visible.confirmGiftcode}
          handleClose={() => {}}
          listItem={listItem}
        />
      )}

      <ModalAppotaCardDetail
        open={visible.appotaDetail}
        handleClose={() => {}}
        listItem={listItem}
      />

      <ModalCardDetail
        open={visible.view}
        handleClose={() => {}}
        listItem={listItem}
      />

      <ModalApplyAvatarSuccess
        open={visible.avatarSuccess}
        handleClose={() => {}}
      />

      <ModalViewSticker open={visible.viewSticker} handleClose={() => {}} />

      {/* view media full screen */}
      <ModalViewMedia
        open={preview.open}
        src={preview.src}
        handleClose={() =>
          setPreview({
            open: false,
            src: ""
          })
        }
      />
      <ModalViewFriend open={visible.viewFriend} handleClose={() => {}} />
      <ModalSendSuccess
        open={visible.sendSuccess}
        handleClose={() => {}}
        resetQuery={resetQuery}
      />
    </>
  )
}
