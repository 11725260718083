import { createContext, useEffect, useState } from "react"
import { UserService } from "../services/services"
import { isClient } from "../configs"

interface RewardContextProps {
  pointReward: number
  setPointReward: any
  getPoint: any
}

export const RewardContext = createContext<RewardContextProps>({
  pointReward: 0,
  setPointReward: () => {},
  getPoint: () => {}
})

interface RewardProps {
  children: any
}

export const RewardProvider = ({ children }: RewardProps) => {
  const [pointReward, setPointReward] = useState<number>(0)
  const accessToken = isClient ? window.localStorage.getItem("token") : null

  const getData = async () => {
    await UserService.getUserPoint()
      .then((resp) => {
        setPointReward(resp.data.data.point)
      })
      .catch((e) => console.log(e))
  }

  useEffect(() => {
    if (accessToken) {
      getData()
    }
  }, [accessToken])

  return (
    <RewardContext.Provider
      value={{
        pointReward: pointReward,
        setPointReward: setPointReward,
        getPoint: getData
      }}
    >
      {children}
    </RewardContext.Provider>
  )
}
