import { Button } from "@/app/components/ui/button"
import { Calendar } from "@/app/components/ui/calendar"
import {
  Dialog,
  DialogContent,
  DialogTrigger
} from "@/app/components/ui/dialog"
import { END_YEAR } from "@/app/configs"
import { useTranslation } from "next-i18next"
import React, { useMemo, useState } from "react"
import { useFormContext } from "react-hook-form"
import { CustomSelectMonthYear } from "./CustomSelectMontYear"

export const PublicAtMobile = ({
  showModal,
  onChangeShowModal
}: {
  showModal: boolean
  onChangeShowModal: () => void
}) => {
  const { t } = useTranslation()
  const { setValue, watch } = useFormContext()

  const [timeValue, setTimeValue] = useState<string>("00:00")
  const selectedPublicAt = watch("public_at", undefined)
  const [date, setDate] = useState<any>(selectedPublicAt)
  const onSubmitSelectTime = (value: "false" | "true") => {
    if (!!date && value === "false") {
      setValue("public_at", date)
      onChangeShowModal()
    }
    if (value === "true") {
      setDate(undefined)
      onChangeShowModal()
    }
  }

  const handleTimeChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const time = e.target.value
    if (!selectedPublicAt) {
      setTimeValue(time)
      return
    }
    const [hours, minutes] = time.split(":").map((str) => parseInt(str, 10))
    const newSelectedDate = new Date(
      selectedPublicAt.getFullYear(),
      selectedPublicAt.getMonth(),
      selectedPublicAt.getDate(),
      hours,
      minutes
    )
    setDate(newSelectedDate)
    setTimeValue(time)
  }

  const handleDaySelect = (date: Date | undefined) => {
    if (!timeValue || !date) {
      setValue("public_at", date)
      return
    }
    const [hours, minutes] = timeValue
      .split(":")
      .map((str) => parseInt(str, 10))
    const newDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      hours,
      minutes
    )
    setDate(newDate)
  }

  const onCancelPublicTime = () => {
    setValue("public_at", undefined)
    setTimeValue("00:00")
    setDate(undefined)
    onChangeShowModal()
  }

  const memoCalendar = useMemo(
    () => (
      <div className="w-full bg-white space-y-[24px] dark:bg-transparent">
        <Calendar
          mode="single"
          selected={date}
          onSelect={handleDaySelect}
          className="p-0 rounded-md"
          showOutsideDays
          captionLayout="dropdown"
          fromYear={new Date().getFullYear()}
          toYear={END_YEAR}
          components={{
            Head: () => <CustomSelectMonthYear />
          }}
          footer={
            <div className="space-y-[24px]">
              <div className="flex items-center justify-between mt-2">
                <span className="text-base Nunito-600 text-main-01">
                  {t("news.post_time")}:
                </span>
                <input
                  type="time"
                  value={timeValue}
                  onChange={handleTimeChange}
                  className="text-base"
                />
              </div>
              <div className="flex items-center justify-end gap-4">
                <Button
                  variant="none"
                  className="bg-disable text-neutral rounded-[12px] max-h-[34px] py-[6px] px-[24px]"
                  onClick={() => onSubmitSelectTime("true")}
                >
                  {t("common.cancel")}
                </Button>

                {selectedPublicAt?.getTime() !== date?.getTime() ? (
                  <Button
                    variant="none"
                    disabled={!date || date < new Date()}
                    className="text-white bg-disable bg-main-01 rounded-[12px] max-h-[34px] py-[6px] px-[24px]"
                    onClick={() => onSubmitSelectTime("false")}
                  >
                    {t("news.set_time")}
                  </Button>
                ) : (
                  <Button
                    variant="none"
                    className="text-white bg-disable bg-main-01 rounded-[12px] max-h-[34px] py-[6px] px-[24px]"
                    onClick={onCancelPublicTime}
                  >
                    Hủy hẹn lịch
                  </Button>
                )}
              </div>
              {date < new Date() && (
                <p className="mt-1 text-xs Nunito-400 text-main-red">
                  {t("news.please_select_future_date")}
                </p>
              )}
            </div>
          }
        />
      </div>
    ),
    [timeValue, selectedPublicAt, date, showModal]
  )

  return (
    <div className="flex md:hidden">
      <Dialog open={showModal} onOpenChange={onChangeShowModal}>
        <DialogTrigger>
          <div
            className={`${
              !!selectedPublicAt ? "bg-main-01" : "border-main-01 border-[1px]"
            } rounded-[12px] w-[44px] h-[44px] min-w-[44px] min-h-[44px] flex items-center justify-center`}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 2V5"
                stroke={selectedPublicAt ? "#fff" : "#7673E6"}
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <path
                d="M8 2V5"
                stroke={selectedPublicAt ? "#fff" : "#7673E6"}
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <path
                d="M3 7.5C3 5.29086 4.79086 3.5 7 3.5H17C19.2091 3.5 21 5.29086 21 7.5V18C21 20.2091 19.2091 22 17 22H7C4.79086 22 3 20.2091 3 18V7.5Z"
                stroke={selectedPublicAt ? "#fff" : "#7673E6"}
                strokeWidth="1.5"
              />
              <path
                d="M3 9H21"
                stroke={selectedPublicAt ? "#fff" : "#7673E6"}
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
          </div>
        </DialogTrigger>
        <DialogContent className="w-[90vw] rounded-[24px] p-4 dark:border-0 dark:bg-[#1A1925]">
          {memoCalendar}
        </DialogContent>
      </Dialog>
    </div>
  )
}
