export const IconStoreFill = ({
  fill,
  className
}: {
  fill?: string
  className?: string
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5 4V6.25C20.5 8.32107 19.0076 10 17.1667 10C15.3257 10 13.8333 8.32107 13.8333 6.25C13.8333 8.32107 12.3409 10 10.5 10C8.65905 10 7.16667 8.32107 7.16667 6.25C7.16667 8.32107 5.67428 10 3.83333 10C1.99238 10 0.5 8.32107 0.5 6.25V4C0.5 1.79086 2.29086 0 4.5 0H16.5C18.7091 0 20.5 1.79086 20.5 4ZM17.1667 11.4999C15.8295 11.4999 14.6772 10.9373 13.8333 10.0559C12.9895 10.9373 11.8371 11.4999 10.5 11.4999C9.16288 11.4999 8.01049 10.9373 7.16667 10.0559C6.32285 10.9373 5.17046 11.4999 3.83333 11.4999C2.96672 11.4999 2.1777 11.2636 1.5 10.8573V15.9999C1.5 18.2091 3.29086 19.9999 5.5 19.9999H15.5C17.7091 19.9999 19.5 18.2091 19.5 15.9999V10.8573C18.8223 11.2636 18.0333 11.4999 17.1667 11.4999ZM14.1533 15.6317C14.3567 15.9925 14.2292 16.4499 13.8683 16.6533C12.6532 17.3384 11.6065 17.7479 10.5028 17.75C9.39781 17.7521 8.3491 17.3459 7.12915 16.6519C6.76912 16.4471 6.64329 15.9892 6.8481 15.6292C7.05292 15.2691 7.51082 15.1433 7.87085 15.3481C9.00705 15.9945 9.78212 16.2514 10.4999 16.25C11.2191 16.2486 11.9944 15.9879 13.1317 15.3467C13.4925 15.1433 13.9499 15.2708 14.1533 15.6317Z"
        fill={fill ? fill : "#7673E6"}
      />
    </svg>
  )
}
