import { SelectTheme } from "@/app/components/header/SelectTheme"
import { LANGUAGE_OPTION, QUERY_KEY, isClient } from "@/app/configs"
import { AppContext } from "@/app/context"
import { useMutationUpdateLanguage } from "@/app/services/query-fn/user/setting-mutation"
import { animated, useSpring } from "@react-spring/web"
import { useQueryClient } from "@tanstack/react-query"
import { jwtDecode } from "jwt-decode"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import React, { useContext } from "react"

export const SettingWeb = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const { userInfo, themeApp } = useContext(AppContext)
  const queryClient = useQueryClient()

  const { mutate: mutateUpdateLanguage } = useMutationUpdateLanguage()
  const handleChangeLang = async (value: "vi" | "en") => {
    mutateUpdateLanguage(
      {
        lang: value
      },
      {
        onSettled(data, error, variables, context) {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY.PROFILE_INFO],
            refetchType: "active"
          })
        }
      }
    )
    localStorage.setItem("language", value ?? "")
    // const tab = String(router?.query?.tab)
    // const pushInfo =
    //   tab === "undefined"
    //     ? "/user/[id]"
    //     : `/user/[id]?tab=${String(router?.query?.tab)}`
    router.push("", "", {
      locale: value
    })
  }

  const onSubmitChangePassword = () => {
    if (isClient) {
      const token = localStorage.getItem("token")
      if (token) {
        const decodeToken: any = jwtDecode(token)
        router.push(
          `https://id.gamota.com/authorize?access_token=${decodeToken?.data?.id}&callback=/change_password&redirect=${window.location.href}`
        )
      }
    }
  }

  const fade = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    delay: 100
  })

  return (
    <>
      <animated.div style={fade}>
        <div className="w-full px-0 md:px-[32px] py-[24px] pb-[80px]">
          <p className="text-base Nunito-700 text-bg-01 dark:text-white">
            {t("profile.setting")}
          </p>
          <div className="w-full space-y-[16px] mt-[16px]">
            <div className="flex items-center justify-between w-full">
              <p className="text-sm Nunito-400 text-neutral dark:text-white">
                {t("profile.theme")}
              </p>
              <SelectTheme />
            </div>
            <div className="flex items-center justify-between w-full">
              <p className="text-sm Nunito-400 text-neutral dark:text-white">
                {t("profile.language")}
              </p>
              <div className="flex items-center gap-[12px]">
                <p
                  className={`${
                    userInfo?.lang === LANGUAGE_OPTION.VIETNAMESE
                      ? "text-main-01"
                      : "text-bg-01 dark:text-white"
                  } Nunito-500 text-sm cursor-pointer`}
                  onClick={() => handleChangeLang("vi")}
                >
                  {t("profile.vietnamese")}
                </p>
                <span className="h-[16px] w-[1px] bg-black/10" />
                <p
                  className={`${
                    userInfo?.lang === LANGUAGE_OPTION.ENGLISH
                      ? "text-main-01"
                      : "text-bg-01 dark:text-white"
                  } Nunito-500 text-sm cursor-pointer`}
                  onClick={() => handleChangeLang("en")}
                >
                  English
                </p>
              </div>
            </div>
            <div className="flex items-center justify-between w-full">
              <p className="text-sm Nunito-400 text-neutral dark:text-white">
                {t("profile.change_password")}
              </p>
              <p
                className="flex items-center text-sm cursor-pointer Nunito-500 text-bg-01 dark:text-white"
                onClick={onSubmitChangePassword}
              >
                {t("profile.change")}&nbsp;
                <span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 7L14 12L10 17"
                      stroke={themeApp === "dark" ? "#FAF8FF" : "#100D28"}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </p>
            </div>
          </div>
        </div>
      </animated.div>
    </>
  )
}
