import { useInfiniteQuery, useQuery } from "@tanstack/react-query"
import { CreateType, QUERY_KEY } from "@/app/configs"
import { EventService } from "../../services/EventServices"
import { INewsByFilter, IParamEventByType } from "@/app/interfaces"

const getEventsFn = async (pageParam: number, keyword?: string) => {
  const data = await EventService.getAllEvent(pageParam, keyword)
  return data.data.data
}
const checkDraftFn = async () => {
  const res = await EventService.checkDraft()
  return res?.data?.data
}

const getListEventByTypeFn = async (params: IParamEventByType) => {
  const res = await EventService.getListEventsByType(params)
  return res?.data?.data
}

const getRelatedEventByGame = async (params: {
  pageParam: number
  game_id: string
  limit: number
}) => {
  const res = await EventService.relatedEventByGame(params)
  return res?.data?.data
}
const getRelatedEvent = async (params: {
  pageParam: number
  id: string
  limit: number
}) => {
  const res = await EventService.relatedEvent(params)
  return res?.data?.data
}

const getMyEvent = async (param: INewsByFilter, pageParam: number) => {
  const res = await EventService.getMyEvent(param, pageParam)
  return res?.data?.data
}

const getAllEventByHashtag = async (keyword: string, pageParam: number) => {
  const res = await EventService.getAllEventByHashtag(keyword, pageParam)
  return res?.data?.data
}
const getOtherUserEvent = async (
  param: INewsByFilter & { other_userid: string },
  pageParam: number
) => {
  const res = await EventService.getOtherUserEvent(param, pageParam)
  return res?.data?.data
}

export const useQueryGetAllEventByHashtag = (
  keyword: string,
  enabled: boolean
) => {
  return useInfiniteQuery({
    queryKey: [QUERY_KEY.LIST_EVENT_BY_HASHTAG, keyword],
    queryFn: ({ pageParam }) => getAllEventByHashtag(keyword, pageParam),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.length > 9 ? pages?.length + 1 : undefined
    },
    enabled: !!keyword && !!enabled,
    refetchOnMount: "always"
  })
}
export const useQueryGetMyEventByFilter = (param: INewsByFilter) => {
  return useInfiniteQuery({
    queryKey: [QUERY_KEY.MY_EVENT_BY_FILTER, Object.values(param).join("-")],
    queryFn: ({ pageParam }) => getMyEvent(param, pageParam),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.length > 9 ? pages?.length + 1 : undefined
    },
    refetchOnMount: "always"
  })
}

export const useQueryGetOtherEventByFilter = (
  param: INewsByFilter & { other_userid: string },
  enabled: boolean
) => {
  return useInfiniteQuery({
    queryKey: [
      QUERY_KEY.OTHER_USER_EVENT_BY_FILTER,
      Object.values(param).join("-")
    ],
    queryFn: ({ pageParam }) => getOtherUserEvent(param, pageParam),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.length > 9 ? pages?.length + 1 : undefined
    },
    enabled: !!enabled,
    refetchOnMount: "always"
  })
}

export const useQueryGetListRelatedEvent = ({
  id,
  limit,
  type
}: {
  id: string
  limit: number
  type: string
}) => {
  return useQuery({
    queryKey: [QUERY_KEY.LIST_RELATED_EVENT, id],
    queryFn: () => getRelatedEvent({ pageParam: 1, id, limit }),
    enabled:
      !!id && id !== "undefined" && type !== "undefined" && type === "event",
    refetchOnMount: "always"
  })
}

export const useQueryGetListEvent = (enabled: boolean, keyword?: string) => {
  return useInfiniteQuery({
    queryKey: [QUERY_KEY.LIST_EVENT, keyword],
    queryFn: ({ pageParam }) => getEventsFn(pageParam, keyword),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.length > 9 ? pages?.length + 1 : undefined
    },
    enabled: !!enabled,
    refetchOnMount: "always"
  })
}

export const useQueryCheckDraftEvent = (type: string) => {
  return useQuery({
    queryKey: [QUERY_KEY.CHECK_DRAFT_EVENT],
    queryFn: () => checkDraftFn(),
    enabled: !!type && type === String(CreateType.CREATE_EVENT)
  })
}

export const useQueryGetListEventByType = (
  params: Omit<IParamEventByType, "page">,
  enabled: boolean
) => {
  return useInfiniteQuery({
    queryKey: [QUERY_KEY.LIST_EVENT_BY_TYPE, Object.values(params).join("_")],
    queryFn: ({ pageParam }) =>
      getListEventByTypeFn({
        ...params,
        page: pageParam
      }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.length > 9 ? pages?.length + 1 : undefined
    },
    enabled: !!enabled,
    refetchOnMount: "always"
  })
}

export const useQueryGetListRelatedEventsByGame = ({
  game_id,
  limit
}: {
  game_id: string
  limit: number
}) => {
  return useInfiniteQuery({
    queryKey: [QUERY_KEY.LIST_RELATED_EVENT_BY_GAME, game_id],
    queryFn: ({ pageParam }) =>
      getRelatedEventByGame({ pageParam: pageParam, game_id, limit }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.length > 9 ? pages?.length + 1 : undefined
    },
    enabled: !!game_id && game_id !== "undefined",
    refetchOnMount: "always"
  })
}
