import React from "react"

import { Dialog, DialogContent } from "@/app/components/ui/dialog"

interface ModalViewMediaProps {
  open: boolean
  src: string
  handleClose: () => void
}

export const ModalViewMedia: React.FC<ModalViewMediaProps> = ({
  open,
  src,
  handleClose
}) => {
  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className="sm:rounded-[24px] px-6 py-4 bg-transparent outline-none border-0 flex items-center justify-center w-fit">
        <img src={src} className="max-w-[80vw] max-h-[80vh]" />
      </DialogContent>
    </Dialog>
  )
}
