export const IconCart = ({ className }: { className?: string }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5143 1.80935C12.9879 2.09349 13.1415 2.70775 12.8573 3.18133L8.85734 9.848C8.57319 10.3216 7.95893 10.4751 7.48535 10.191C7.01177 9.90685 6.85821 9.29259 7.14236 8.81901L11.1424 2.15234C11.4265 1.67876 12.0408 1.5252 12.5143 1.80935Z"
        fill="#7673E6"
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.5143 10.191C24.0408 10.4751 23.4265 10.3216 23.1424 9.848L19.1424 3.18133C18.8582 2.70775 19.0118 2.09349 19.4854 1.80934C19.9589 1.5252 20.5732 1.67876 20.8573 2.15234L24.8573 8.81901C25.1415 9.29259 24.9879 9.90685 24.5143 10.191Z"
        fill="#7673E6"
      />
      <path
        opacity="0.4"
        d="M27.0429 25.0773C26.5365 27.5554 24.3744 29.3333 21.8673 29.3333H10.1334C7.62622 29.3333 5.46415 27.5554 4.95771 25.0773L2.77782 14.4107C2.10158 11.1017 4.6057 8 7.95347 8L24.0472 8C27.395 8 29.8991 11.1017 29.2228 14.4107L27.0429 25.0773Z"
        fill="#7673E6"
      />
      <path
        d="M24.0472 8H7.95347C4.97283 8 2.66094 10.4587 2.667 13.3333H29.3336C29.3397 10.4587 27.0278 8 24.0472 8Z"
        fill="#7673E6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 17.6667C12.5523 17.6667 13 18.1144 13 18.6667L13 24C13 24.5523 12.5523 25 12 25C11.4477 25 11 24.5523 11 24L11 18.6667C11 18.1144 11.4477 17.6667 12 17.6667Z"
        fill="#7673E6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 17.6667C20.5523 17.6667 21 18.1144 21 18.6667L21 24C21 24.5523 20.5523 25 20 25C19.4477 25 19 24.5523 19 24L19 18.6667C19 18.1144 19.4477 17.6667 20 17.6667Z"
        fill="#7673E6"
      />
    </svg>
  )
}
