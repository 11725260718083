import { useTranslation } from "next-i18next"
import React, { useCallback, useContext } from "react"
import { Button } from "../ui/button"
import { useQueryGetProfileById } from "@/app/services/query-fn"
import { AppContext } from "@/app/context"
import {
  useMutatonAddFriend,
  useMutatonCancelSendingRequestFn,
  useMutatonDoActionRequestFriend,
  useMutatonUnFriend
} from "@/app/services/query-fn/user/user-friend-mutation"
import { IUser } from "@/app/interfaces"
import { useQueryClient } from "@tanstack/react-query"
import { QUERY_KEY } from "@/app/configs"
import { useToast } from "../ui/use-toast"

export const GroupFriendAction = ({
  id,
  gameId
}: {
  id: string
  gameId?: string
}) => {
  const { t } = useTranslation()
  const { toast } = useToast()
  const { data, isFetching } = useQueryGetProfileById(id)
  const { mutate: mutateAddFriend } = useMutatonAddFriend()
  const { mutate: mutateDoActionFriend, isPending } =
    useMutatonDoActionRequestFriend()
  const { mutate: mutateUnFriend } = useMutatonUnFriend()
  const { mutate: mutateCancelSendingRequest } =
    useMutatonCancelSendingRequestFn()
  const queryClient = useQueryClient()
  const onDoActionRequestFriend = (
    user: IUser,
    status: "cancel" | "accepted"
  ) => {
    mutateDoActionFriend(
      {
        request_id: String(user?.pending_request_id),
        status
      },
      {
        onSuccess(data, variables, context) {
          queryClient.refetchQueries({
            queryKey: [QUERY_KEY.LIST_SUGGEST_FRIEND]
          })
          queryClient.refetchQueries({
            queryKey: [QUERY_KEY.LIST_FRIEND]
          })
          queryClient.refetchQueries({
            queryKey: [QUERY_KEY.LIST_REQUEST_FRIEND]
          })
          queryClient.refetchQueries({
            queryKey: [QUERY_KEY.PROFILE_INFO]
          })
          queryClient.refetchQueries({
            queryKey: [QUERY_KEY.GET_PROFILE_BY_ID, id]
          })
          if (!!gameId) {
            queryClient.refetchQueries({
              queryKey: [QUERY_KEY.LIST_SUGGEST_FRIEND_BY_GAME, gameId]
            })
          }
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY.NOTIFICATION_LIST, { noticeType: "all" }]
          })
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY.NOTIFICATION_LIST, { noticeType: "1" }]
          })
        },
        onError(error: any) {
          toast({
            variant: "error",
            title: error?.data?.message
          })
        }
      }
    )
  }
  const onRequestAddFrend = (user?: IUser) => {
    if (user?.userid) {
      mutateAddFriend(
        {
          friend_id: user?.userid
        },
        {
          onSuccess(data, variables, context) {
            queryClient.refetchQueries({
              queryKey: [QUERY_KEY.LIST_FRIEND]
            })
            queryClient.refetchQueries({
              queryKey: [QUERY_KEY.LIST_SUGGEST_FRIEND]
            })
            queryClient.refetchQueries({
              queryKey: [QUERY_KEY.GET_PROFILE_BY_ID, id]
            })
            if (!!gameId) {
              queryClient.refetchQueries({
                queryKey: [QUERY_KEY.LIST_SUGGEST_FRIEND_BY_GAME, gameId]
              })
            }
          }
        }
      )
    }
  }

  const onUnFriend = (user: IUser) => {
    mutateUnFriend(
      {
        friend_id: user?.userid
      },
      {
        onSuccess(data, variables, context) {
          queryClient.refetchQueries({
            queryKey: [QUERY_KEY.LIST_FRIEND]
          })
          queryClient.refetchQueries({
            queryKey: [QUERY_KEY.LIST_SUGGEST_FRIEND]
          })
          queryClient.refetchQueries({
            queryKey: [QUERY_KEY.PROFILE_INFO]
          })
          queryClient.refetchQueries({
            queryKey: [QUERY_KEY.GET_PROFILE_BY_ID, id]
          })
        }
      }
    )
  }

  const onCancelSendingRequest = (user: IUser) => {
    mutateCancelSendingRequest(
      {
        request_id: String(user?.sending_request_id)
      },
      {
        onSuccess(data, variables, context) {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY.LIST_SUGGEST_FRIEND]
          })
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY.PROFILE_INFO]
          })
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY.GET_PROFILE_BY_ID, user?.id]
          })
        }
      }
    )
  }

  const { userInfo } = useContext(AppContext)

  const renderButtonNormalUser = useCallback(() => {
    if (data?.sending_request) {
      return (
        <Button
          variant="main"
          className="outline-none bg-transparent text-neutral-06 h-[28px] px-[16px] flex items-center justify-center border-[1px] border-neutral-06 rounded-[8px] Nunito-400 text-[14px]"
          onClick={(e) => {
            e.stopPropagation()
            onCancelSendingRequest(data)
          }}
        >
          {t("group.remove_invite")}
        </Button>
      )
    }
    if (data?.pending_request && !!data?.pending_request_id) {
      return (
        <div className="flex items-center gap-2">
          <Button
            variant="main"
            className="outline-none bg-main-01 h-[28px] px-[16px] flex items-center justify-center text-white rounded-[8px] Nunito-400 text-[14px]"
            onClick={(e) => {
              e.stopPropagation()
              onDoActionRequestFriend(data, "accepted")
            }}
          >
            {t("group.agree")}
          </Button>
          <Button
            variant="none"
            className="outline-none bg-transparent text-neutral-06 h-[28px] px-[16px] flex items-center justify-center border-[1px] border-neutral-06 rounded-[8px] Nunito-400 text-[14px]"
            onClick={(e) => {
              e.stopPropagation()
              onDoActionRequestFriend(data, "cancel")
            }}
          >
            {t("group.reject")}
          </Button>
        </div>
      )
    }

    if (data?.is_friend) {
      return (
        <Button
          variant="none"
          className="outline-none bg-transparent text-neutral-06 h-[28px] px-[16px] flex items-center justify-center border-[1px] border-neutral-06 rounded-[8px] Nunito-400 text-[14px]"
          onClick={(e) => {
            e.stopPropagation()
            onUnFriend(data)
          }}
        >
          {t("profile.unfriend")}
        </Button>
      )
    } else
      return (
        <>
          <Button
            variant="main"
            className="outline-none bg-main-01 h-[28px] px-[16px] hidden lg:flex items-center justify-center text-white rounded-[8px] Nunito-400 text-[14px]"
            onClick={(e) => {
              e.stopPropagation()
              onRequestAddFrend(data)
            }}
          >
            {t("profile.addfriend")}
          </Button>
          <button
            className="outline-none bg-transparent h-[28px] px-[16px] flex lg:hidden items-center justify-center text-white rounded-[8px]"
            onClick={(e) => {
              e.stopPropagation()
              onRequestAddFrend(data)
            }}
          >
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.25 0.203125H16.25C18.4591 0.203125 20.25 1.99399 20.25 4.20312V16.2031C20.25 18.4123 18.4591 20.2031 16.25 20.2031H4.25C2.04086 20.2031 0.25 18.4123 0.25 16.2031V4.20313C0.25 1.99399 2.04086 0.203125 4.25 0.203125ZM10.25 5.45312C10.6642 5.45312 11 5.78891 11 6.20312V9.45312H14.25C14.6642 9.45312 15 9.78891 15 10.2031C15 10.6173 14.6642 10.9531 14.25 10.9531H11V14.2031C11 14.6173 10.6642 14.9531 10.25 14.9531C9.83579 14.9531 9.5 14.6173 9.5 14.2031V10.9531H6.25C5.83579 10.9531 5.5 10.6173 5.5 10.2031C5.5 9.78891 5.83579 9.45312 6.25 9.45312H9.5V6.20312C9.5 5.78891 9.83579 5.45312 10.25 5.45312Z"
                fill="#7673E6"
              />
            </svg>
          </button>
        </>
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, id])

  if (!userInfo?.userid) return <></>
  if (isFetching && !data) return <></>
  if (userInfo?.userid === id || userInfo?.alias === id) return <></>
  if (data?.authentic || userInfo?.authentic) return <></>
  return <>{renderButtonNormalUser()}</>
}
