import { IconArrowRight } from "@/app/components/icon-tsx/IconArrowRight"
import { IconCopied } from "@/app/components/icon-tsx/IconCopied"
import { IconNonCopy } from "@/app/components/icon-tsx/IconNonCopy"
import { MISSION_TYPE } from "@/app/configs"
import { AppContext } from "@/app/context"
import { Trans, useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import React, { useContext, useEffect, useState } from "react"
import { ModalShareInviteCode } from "./ModalShareInviteCode"
import { IconGift } from "@/app/components/icon-tsx/IconGift"

interface IReferalMission {
  dataReward: any
}

export const ReferralMission: React.FC<IReferalMission> = ({ dataReward }) => {
  const { t } = useTranslation()
  const [listReferal, setListReferal] = useState<any>({})
  const { userInfo: userProfile, themeApp } = useContext(AppContext)
  const [copied, setIsCopied] = useState<boolean>(false)
  const router = useRouter()
  const [showModal, setShowModal] = useState<boolean>(false)
  const onChangeShowModal = () => {
    setShowModal(!showModal)
  }

  const onCopyInviteCode = () => {
    navigator.clipboard.writeText(userProfile?.referent_code)
    setIsCopied(true)
  }

  const renderCode = () => {
    return (
      <div className="flex items-center md:px-[12px] md:py-[6px] rounded-[12px] md:border border-black/10 dark:border-neutral-03 h-fit">
        <div className="Nunito-400 dark:text-neutral-09">
          <span className="">
            {userProfile?.referent_code?.replace(
              userProfile?.referent_code?.substring(4, 15),
              "xxx"
            )}
          </span>
          <span className="h-full w-[1px] bg-black/10 dark:bg-neutral-03"></span>
        </div>

        <div
          className="cursor-pointer w-fit ml-[8px]"
          onClick={onCopyInviteCode}
        >
          {copied ? <IconCopied /> : <IconNonCopy stroke="#9F9EA9" />}
        </div>
        <div className="w-[1px] h-[16px] mx-[12px] bg-black/10 dark:bg-neutral-03"></div>
        <div
          className="w-[32px] h-[32px] rounded-[12px] flex items-center justify-center cursor-pointer bg-bg-02-text-box dark:bg-white/10"
          onClick={onChangeShowModal}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.66602 7.33337L14.1327 1.8667"
              stroke={themeApp === "dark" ? "#fff" : "#585669"}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.6668 4.5335V1.3335H11.4668"
              stroke={themeApp === "dark" ? "#fff" : "#585669"}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.33398 1.3335H6.00065C2.66732 1.3335 1.33398 2.66683 1.33398 6.00016V10.0002C1.33398 13.3335 2.66732 14.6668 6.00065 14.6668H10.0007C13.334 14.6668 14.6673 13.3335 14.6673 10.0002V8.66683"
              stroke={themeApp === "dark" ? "#fff" : "#585669"}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
    )
  }

  useEffect(() => {
    setListReferal(
      dataReward?.find((item: any) => {
        return item?.type === MISSION_TYPE.INVITE
      })
    )
  }, [dataReward])

  return listReferal?.sub_tasks?.length ? (
    <div className="w-full space-y-[16px]">
      <div className="flex justify-between">
        <div className="flex md:justify-between items-center md:w-full">
          <p className="Nunito-700 text-[16px]">
            {t("reward.invite_friend_title")}
          </p>
          <div className="w-[1px] h-[16px] bg-black/10 dark:bg-white/10 mx-[8px] md:hidden"></div>
          <div
            className="flex text-main-01 cursor-pointer items-center gap-[12px] text-[14px]"
            onClick={() => router.push("/reward-center/referral-history")}
          >
            <p
              className=""
              onClick={() => router.push("/reward-center/referral-history")}
            >
              {t("reward.invite_friend_history")}
            </p>
            <IconArrowRight className="hidden md:block" />
          </div>
        </div>
        <div className="md:hidden">{renderCode()}</div>
      </div>
      {userProfile?.id && (
        <div className="flex gap-[32px]">
          <p className="text-neutral-04 dark:text-neutral-09 text-[14px] w-full flex-1">
            <Trans
              i18nKey={t("reward.invite_friend_desc")}
              components={[
                <span className="text-main-01 Nunito-600" key={0}></span>
              ]}
            />
          </p>
          <div className="hidden md:block">{renderCode()}</div>
        </div>
      )}
      <div className="flex justify-evenly">
        {listReferal?.sub_tasks?.length &&
          [...listReferal?.sub_tasks]?.map((item: any, index: number) => {
            return (
              <div
                key={index}
                className="flex flex-col items-center gap-[16px]"
              >
                <p className="text-neural-03 dark:text-main-white text-[14px] Nunito-700">
                  {t("reward.invite_number", { number: item?.day })}
                </p>
                <div>
                  <div>
                    {listReferal?.my_task?.totalTaskSuccess >= item?.day ? (
                      <div
                        className={`w-[36px] h-[36px] rounded-[36px] flex items-center justify-center bg-bg-green`}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="56"
                          height="56"
                          viewBox="0 0 56 56"
                          fill="none"
                          className="w-[20px] h-[20px]"
                        >
                          <path
                            d="M28.1438 5.14404C15.5495 5.14404 5.28662 15.4069 5.28662 28.0012C5.28662 40.5955 15.5495 50.8583 28.1438 50.8583C40.7381 50.8583 51.0009 40.5955 51.0009 28.0012C51.0009 15.4069 40.7381 5.14404 28.1438 5.14404ZM39.0695 22.744L26.1095 35.704C25.7895 36.024 25.3552 36.2069 24.8981 36.2069C24.4409 36.2069 24.0066 36.024 23.6866 35.704L17.2181 29.2355C16.5552 28.5726 16.5552 27.4755 17.2181 26.8126C17.8809 26.1498 18.9781 26.1498 19.6409 26.8126L24.8981 32.0698L36.6466 20.3212C37.3095 19.6583 38.4066 19.6583 39.0695 20.3212C39.7323 20.984 39.7323 22.0583 39.0695 22.744Z"
                            fill="#27908C"
                          />
                        </svg>
                      </div>
                    ) : (
                      <IconGift fill={themeApp === "dark" ? "#7673E5" : ""} />
                    )}
                  </div>
                </div>
              </div>
            )
          })}
      </div>
      <ModalShareInviteCode open={showModal} handleClose={onChangeShowModal} />
    </div>
  ) : null
}
