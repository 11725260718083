import { IconBack } from "@/app/components/icon-tsx/IconBack"
import { IconCopied } from "@/app/components/icon-tsx/IconCopied"
import { IconNonCopy } from "@/app/components/icon-tsx/IconNonCopy"
import { DATE_FORMAT_FULL } from "@/app/configs"
import moment from "moment"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import { FC, useState } from "react"

interface TopupDetailProps {
  dataSelected: any
  setDataSelected: (param: any) => void
}

export const TopupDetail: FC<TopupDetailProps> = ({
  dataSelected,
  setDataSelected
}) => {
  const { t } = useTranslation()
  const router = useRouter()
  const onBack = () => {
    router.back()
    setDataSelected("")
  }
  const [transactionCopy, setTransactionCopy] = useState<string | null>(null)
  const onCopyTransaction = (transaction_id: string) => (e: any) => {
    e.stopPropagation()
    navigator.clipboard.writeText(`${transaction_id}`)
    setTransactionCopy(transaction_id)
  }
  const renderGameInfo = () => {
    return (
      <div className="pb-[24px] rounded-[5px] border-b border-dashed">
        <div className="flex items-center mt-[18px]">
          <img
            src={dataSelected?.game_icon}
            alt={dataSelected?.game_name}
            className="w-[48px] h-[48px] rounded-[12px] mr-[13px] object-cover border border-[rgba(0, 0, 0, 0.10)]"
          />
          <div>
            <p className="text-[14px] Nunito-600">{dataSelected?.game_name}</p>
            <p className="text-[12px] Nunito-400 text-text-grey-03 dark:text-neutral-08">
              {t("store.buy_date")}{" "}
              {moment.unix(dataSelected?.created_at).format(DATE_FORMAT_FULL)}
            </p>
          </div>

          <span className="ml-auto mr-0 text-main-01 text-[16px] Nunito-600 dark:text-main-01">
            đ {dataSelected?.amount?.toLocaleString()}
          </span>
        </div>
      </div>
    )
  }
  const renderOrderDetail = () => {
    return (
      <div className="py-[24px] border-b border-dashed rounded-[5px]">
        <p className="text-bg-01 Nunito-600 text-base mb-[22px] dark:text-white">
          {t("store.order_infor")}
        </p>
        <div className="flex items-center justify-between mb-[12px]">
          <p className="text-sm Nunito-400 text-neutral-05 dark:text-neutral-08">
            {t("topup.title_transaction")}
          </p>
          <div
            className="flex items-center gap-[10px] cursor-pointer"
            onClick={onCopyTransaction(dataSelected?.transaction_id)}
          >
            <p className="text-sm Nunito-600 text-bg-01 dark:text-white">
              {dataSelected?.transaction_id}
            </p>{" "}
            <span className="text-black/[.1] dark:text-white">|</span>
            {transactionCopy === dataSelected?.transaction_id ? (
              <IconCopied />
            ) : (
              <IconNonCopy />
            )}
          </div>
        </div>

        <div className="flex items-center justify-between mb-[12px]">
          <p className="text-sm Nunito-400 text-neutral-05 dark:text-neutral-08">
            {t("topup.server")}
          </p>
          <p className="text-sm Nunito-600 text-bg-01 dark:text-white">
            {dataSelected?.server_name}
          </p>
        </div>
        <div className="flex items-center justify-between ">
          <p className="text-sm Nunito-400 text-neutral-05 dark:text-neutral-08">
            {t("topup.name_role")}
          </p>
          <p className="text-sm Nunito-600 text-bg-01 dark:text-white">
            {dataSelected?.role_name}
          </p>
        </div>
      </div>
    )
  }
  const renderOrderAmount = () => {
    return (
      <div className="mb-[64px] md:mb-4  rounded-[5px] py-[24px]">
        <div className="ml-auto flex flex-col gap-[12px]">
          <div className="flex justify-between">
            <p className="text-neutral-05 dark:text-neutral-08 text-[14px]">
              {t("store.payment_method")}
            </p>
            <p className="text-base text-bg-01 dark:text-main-white Nunito-600">
              {dataSelected?.method}
            </p>
          </div>
          <div className="flex justify-between">
            <p className="text-neutral-05 dark:text-neutral-08 text-[14px]">
              {t("store.total")}
            </p>
            <p className="text-[18px] text-main-01 Nunito-700">
              {dataSelected?.amount?.toLocaleString()}&nbsp;đ
            </p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="w-full m-auto">
      {renderGameInfo()}
      {renderOrderDetail()}
      {renderOrderAmount()}
    </div>
  )
}
