export const IconGoogle = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.0104 12.2761C24.0104 11.4603 23.9443 10.6402 23.8032 9.83769H12.4844V14.4587H18.9661C18.6971 15.949 17.8329 17.2674 16.5674 18.1052V21.1035H20.4344C22.7052 19.0135 24.0104 15.927 24.0104 12.2761Z"
        fill="#4285F4"
      />
      <path
        d="M12.4844 24.0005C15.7208 24.0005 18.4502 22.9378 20.4388 21.1035L16.5718 18.1052C15.4959 18.8371 14.107 19.2516 12.4888 19.2516C9.35815 19.2516 6.70373 17.1395 5.75132 14.2999H1.76087V17.3909C3.79798 21.443 7.94717 24.0005 12.4844 24.0005Z"
        fill="#34A853"
      />
      <path
        d="M5.74692 14.2999C5.24426 12.8096 5.24426 11.1958 5.74692 9.7054V6.61446H1.76088C0.0588806 10.0052 0.0588683 14.0001 1.76087 17.3909L5.74692 14.2999Z"
        fill="#FBBC04"
      />
      <path
        d="M12.4844 4.74929C14.1952 4.72284 15.8487 5.3666 17.0877 6.5483L20.5138 3.12225C18.3444 1.08514 15.4651 -0.0348322 12.4844 0.000442455C7.94717 0.000442455 3.79799 2.55787 1.76088 6.61446L5.74692 9.7054C6.69493 6.86138 9.35374 4.74929 12.4844 4.74929Z"
        fill="#EA4335"
      />
    </svg>
  )
}
