import { useQuery } from "@tanstack/react-query"
import { UserService } from "../../services"
import { useRouter } from "next/router"
import { QUERY_KEY } from "@/app/configs"

const loginGoogleFn = (code?: string) => {
  return UserService.loginByGoogle(code)
}

export const useQueryLoginGoogle = (code?: string) => {
  return useQuery({
    queryKey: [QUERY_KEY.LOGIN_GOOGLE, code],
    queryFn: () => loginGoogleFn(code),
    enabled: !!code,
    refetchOnWindowFocus: false,
    retry: 0
  })
}

export const useQueryGetUrlLoginGoogle = () => {
  const router = useRouter()
  return useQuery({
    queryKey: [QUERY_KEY.GET_URL_LOGIN_GOOGLE],
    queryFn: () => loginGoogleFn(),
    enabled: false,
    refetchOnWindowFocus: false,

    // Sử dụng khi muốn transform data
    select(data) {
      if (!!data?.data?._url) {
        localStorage.setItem("urlBeforeLogin", router.asPath)
        router.push(data?.data?._url)
      }
    }
  })
}
