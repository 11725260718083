import { QUERY_KEY } from "@/app/configs"
import { useInfiniteQuery } from "@tanstack/react-query"
import { FriendService } from "../../services/FriendSerivces"

export const useQueryGetListFriend = (enable: boolean, keyword: string) => {
  return useInfiniteQuery({
    queryKey: [QUERY_KEY.LIST_FRIEND],
    queryFn: async ({ pageParam }) => {
      const res = await FriendService.getListFriend(pageParam)
      return res?.data?.data
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.length > 9 ? pages?.length + 1 : undefined
    },
    enabled: !!enable && !keyword
  })
}

export const useQueryGetListFriendWithSearch = (
  enable: boolean,
  keyword: string
) => {
  return useInfiniteQuery({
    queryKey: [QUERY_KEY.LIST_FRIEND_WITH_SEARCH, keyword],
    queryFn: async ({ pageParam }) => {
      const res = await FriendService.getListSearchFriend(pageParam, keyword)
      return res?.data?.data
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.length > 9 ? pages?.length + 1 : undefined
    },
    enabled: !!enable && !!keyword
  })
}

export const useQueryGetSuggestFriend = (enable: boolean) => {
  return useInfiniteQuery({
    queryKey: [QUERY_KEY.LIST_SUGGEST_FRIEND],
    queryFn: async ({ pageParam }) => {
      const res = await FriendService.getListSuggestFriend(pageParam)
      return res?.data?.data
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.length > 9 ? pages?.length + 1 : undefined
    },
    enabled: !!enable
  })
}

export const useQueryGetRequestFriend = (enable: boolean) => {
  return useInfiniteQuery({
    queryKey: [QUERY_KEY.LIST_REQUEST_FRIEND],
    queryFn: async ({ pageParam }) => {
      const res = await FriendService.getListRequestFriend(pageParam)
      return res?.data?.data
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.length > 9 ? pages?.length + 1 : undefined
    },
    enabled: !!enable
  })
}
