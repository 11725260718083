import { IGameBannerRequest, IGameRequest } from "@/app/interfaces"
import { useInfiniteQuery, useQuery } from "@tanstack/react-query"
import { GameServices } from "../../services/GameServices"
import { QUERY_KEY } from "@/app/configs"

const getListGameFn = async (params: IGameRequest) => {
  const data = await GameServices.getAllGames(params)

  return data.data.data
}

const getListCategoryFn = async () => {
  const data = await GameServices.getAllCategories()

  return data.data.data
}

const getListBannerFn = async (params: IGameBannerRequest) => {
  const data = await GameServices.getListBanner(params)

  return data.data.data
}

export const useQueryGetListGame = (params: IGameRequest) => {
  return useQuery({
    queryKey: [QUERY_KEY.LIST_GAME, params],
    queryFn: () => getListGameFn(params),
    enabled: !!params,
    refetchOnWindowFocus: false,
    retry: 0
  })
}

export const useQueryGetAllCategory = () => {
  return useQuery({
    queryKey: [QUERY_KEY.LIST_CATEGORY],
    queryFn: getListCategoryFn,
    refetchOnWindowFocus: false,
    retry: 0
  })
}

export const useQueryGetListGameBanner = (params: IGameBannerRequest) => {
  return useQuery({
    queryKey: [QUERY_KEY.LIST_GAME_BANNER, params],
    queryFn: () => getListBannerFn(params),
    enabled: !!params,
    refetchOnWindowFocus: false,
    retry: 0
  })
}

export const useInfiniteGetListGame = (params: IGameRequest) => {
  return useInfiniteQuery({
    queryKey: [QUERY_KEY.LIST_GAME_INFINITE, params],
    queryFn: ({ pageParam }) => getListGameFn({ ...params, page: pageParam }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.length > 9 ? pages?.length + 1 : undefined
    }
  })
}
