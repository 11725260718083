import { useInfiniteQuery, useQuery } from "@tanstack/react-query"
import { COMMENT_LIMIT_SIZE, QUERY_KEY, REPLY_LIMIT_SIZE } from "@/app/configs"
import { NewsService } from "../../services"

const getCommentsFn = async (postId: string, pageParam: number) => {
  const data = await NewsService.getCommentByNewsId(postId, pageParam)
  return data.data.data
}

const getNewsDetail = async (id: string) => {
  const data = await NewsService.getNewsDetail(id)
  return data?.data?.data
}

const getNewsDetailForEdit = async (id: string) => {
  const data = await NewsService.getNewsDetailForEdit(id)
  return data?.data?.data
}
const getListRepliesByIdFn = async (
  body: {
    id: string
    comment_id: string
  },
  pageParam: number
) => {
  const data = await NewsService.getListReplies({
    ...body,
    page: String(pageParam)
  })
  return data?.data?.data
}

const getStickersByUser = async () => {
  const data = await NewsService.getStickersByUser()
  return data?.data?.data
}

export const useQueryGetNewsDetail = (id: string, type: string) => {
  return useQuery({
    queryKey: [QUERY_KEY.NEWS_DETAIL, id],
    queryFn: () => getNewsDetail(id),
    enabled: !!id && id !== "undefined" && type === "news"
  })
}

export const useQueryGetNewsDetailForEdit = (id: string, type: string) => {
  return useQuery({
    queryKey: [QUERY_KEY.NEWS_DETAIL_FOR_EDIT, id],
    queryFn: () => getNewsDetailForEdit(id),
    enabled: !!id && id !== "undefined" && type === "news"
  })
}

export const useQueryGetListComments = (postId: string) => {
  return useInfiniteQuery({
    queryKey: [QUERY_KEY.LIST_COMMENT, postId],
    queryFn: ({ pageParam }) => getCommentsFn(postId, pageParam),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.length >= COMMENT_LIMIT_SIZE ? pages?.length + 1 : 0
    },
    enabled: !!postId
  })
}

export const useQueryGetListReplies = (
  body: {
    id: string
    comment_id: string
  },
  isShow: boolean
) => {
  return useInfiniteQuery({
    queryKey: [
      QUERY_KEY.LIST_REPLY,
      { id: body.id, comment_id: body.comment_id }
    ],
    queryFn: ({ pageParam }) => getListRepliesByIdFn(body, pageParam),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.length >= REPLY_LIMIT_SIZE ? pages?.length + 1 : 0
    },
    enabled: !!body.id && !!body.comment_id && isShow
  })
}

export const useQueryGetStickerUser = () => {
  return useQuery({
    queryKey: [QUERY_KEY.GET_STICKER_USER],
    queryFn: getStickersByUser
  })
}
