import axios from "axios"

const BASE_API_URL = process.env.NEXT_PUBLIC_SERVER_BASE_URL
const AUTH_PAGE = "/"

export const http = axios.create({
  baseURL: `${BASE_API_URL}`,
  headers: {
    "Content-Type": "application/json"
  }
})
