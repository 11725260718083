import { AxiosResponse } from "axios"
import IApiResponse from "@interfaces/IApiResponse"
import { http } from "@/app/api"
import { IPostCard } from "@/app/interfaces/IPost"
import { IComment, ICommentResponse } from "@/app/interfaces/IComment"
import { COMMENT_LIMIT_SIZE } from "@/app/configs"
import { IAddNewsRequest, INewsByFilter } from "@/app/interfaces"

const resource = "/v2/news"

export class NewsService {
  static getAllNews(
    pageParam: number
  ): Promise<AxiosResponse<IApiResponse<IPostCard[]>>> {
    return http.get(`${resource}/list`, {
      params: {
        page: pageParam
      }
    })
  }
  static getAllNewsByHashtag(
    keyword: string,
    pageParam: number
  ): Promise<AxiosResponse<IApiResponse<IPostCard[]>>> {
    return http.get(`${resource}/hashtag`, {
      params: { keyword, page: pageParam }
    })
  }
  static getListStoreNews(
    pageParam: number,
    keyword: string,
    sort: number
  ): Promise<AxiosResponse<IApiResponse<IPostCard[]>>> {
    return http.get(`${resource}/listStore`, {
      params: keyword
        ? {
            page: pageParam,
            keyword,
            sort
          }
        : {
            page: pageParam,
            sort
          }
    })
  }

  static getNewsDetail(
    postId: string
  ): Promise<AxiosResponse<IApiResponse<IPostCard>>> {
    return http.get(`${resource}/view`, { params: { id: postId } })
  }

  static getNewsDetailForEdit(
    postId: string
  ): Promise<AxiosResponse<IApiResponse<IPostCard>>> {
    return http.get(`${resource}/detail`, { params: { id: postId } })
  }

  static getCommentByNewsId(
    postId: string,
    pageParam: number
  ): Promise<AxiosResponse<IApiResponse<IComment[]>>> {
    return http.post(`${resource}/comment/list`, {
      id: postId,
      page: String(pageParam),
      limit: COMMENT_LIMIT_SIZE
    })
  }

  static deleteNews(id: string): Promise<AxiosResponse<IApiResponse<any>>> {
    return http.post(`${resource}/delete`, {
      id
    })
  }

  static addnews(body: IAddNewsRequest): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`${resource}/add`, body)
  }

  static editNews(body: IAddNewsRequest): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`${resource}/edit`, body)
  }

  static checkDraft(): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`${resource}/draf`)
  }

  static saveDraft(
    body: IAddNewsRequest
  ): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`${resource}/saveDraf`, body)
  }

  static deleteDraft(body: {
    id: string
  }): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`${resource}/delete`, body)
  }

  static getCommentByPostId(
    postId: string,
    page: string,
    limit = 2
  ): Promise<AxiosResponse<IApiResponse<IComment[]>>> {
    return http.post(`${resource}/comment/list`, { id: postId, page, limit })
  }

  static commentToPost(
    body: any
  ): Promise<AxiosResponse<IApiResponse<ICommentResponse>>> {
    return http.post(`${resource}/comment`, body, {
      headers: { "Content-Type": "multipart/form-data" }
    })
  }

  static likePost(body: { id: string }): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`${resource}/like`, body)
  }

  static likeComment(body: {
    id: string
  }): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`${resource}/like/comment`, body)
  }

  static replyToComment(
    body: any
  ): Promise<AxiosResponse<IApiResponse<ICommentResponse>>> {
    return http.post(`${resource}/comment/reply`, body, {
      headers: { "Content-Type": "multipart/form-data" }
    })
  }
  static getListReplies(body: {
    id: string
    comment_id: string
    page: string
  }): Promise<AxiosResponse<IApiResponse<any>>> {
    return http.post(`${resource}/comment/listReplies`, body)
  }

  static deleteComment(body: {
    id: string
    comment_id: string
  }): Promise<AxiosResponse<IApiResponse<any>>> {
    return http.post(`${resource}/comment/delete`, body)
  }

  static updateComment(
    formData: any
  ): Promise<AxiosResponse<IApiResponse<any>>> {
    return http.post(`${resource}/comment/update`, formData, {
      headers: { "Content-Type": "multipart/form-data" }
    })
  }

  static getStickersByUser(): Promise<AxiosResponse<IApiResponse>> {
    return http.get(`/api/bag/stickersByUser`)
  }

  static uploadMedia(formData: any): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`v2/upload/media`, formData, {
      headers: { "Content-Type": "multipart/form-data" }
    })
  }

  static relatedNews(params: {
    id: string
    limit: number
    pageParam: number
  }): Promise<AxiosResponse<IApiResponse<IPostCard[]>>> {
    return http.get(`${resource}/related`, {
      params: {
        page: String(params.pageParam),
        id: params.id,
        limit: String(params.limit)
      }
    })
  }

  static getNewsByType({
    type,
    limit
  }: {
    type: string
    limit: number
  }): Promise<AxiosResponse<IApiResponse<IPostCard[]>>> {
    return http.get(`${resource}/search`, {
      params: {
        type,
        limit
      }
    })
  }

  static getMyNewsByFilter(
    param: INewsByFilter,
    pageParam: number
  ): Promise<AxiosResponse<IApiResponse<IPostCard[]>>> {
    return http.get(`${resource}/search`, {
      params: {
        type: "my_posts",
        ...param,
        page: pageParam
      }
    })
  }

  static getOtherUserNewsByFilter(
    param: INewsByFilter & { other_userid: string },
    pageParam: number
  ): Promise<AxiosResponse<IApiResponse<IPostCard[]>>> {
    return http.get(`${resource}/search`, {
      params: {
        type: "other_user_posts",
        ...param,
        page: pageParam,
        other_userid: param.other_userid
      }
    })
  }

  static getLatestNews(
    pageParam: number
  ): Promise<AxiosResponse<IApiResponse<IPostCard[]>>> {
    return http.get(`${resource}/search`, {
      params: {
        page: pageParam,
        type: "newest"
      }
    })
  }

  static relatedNewsByGame(params: {
    game_id: string
    limit: number
    pageParam: number
  }): Promise<AxiosResponse<IApiResponse<IPostCard[]>>> {
    return http.get(`${resource}/listByGame`, {
      params: {
        page: String(params.pageParam),
        game_id: params.game_id,
        limit: String(params.limit)
      }
    })
  }

  static storeNews(id: string): Promise<AxiosResponse<IApiResponse<any>>> {
    return http.post(`${resource}/store`, {
      id
    })
  }

  static removeStoreNews(
    id: string
  ): Promise<AxiosResponse<IApiResponse<any>>> {
    return http.post(`${resource}/store/delete`, {
      id
    })
  }

  static getAllNewsByUser(
    pageParam: number,
    sort: number
  ): Promise<AxiosResponse<IApiResponse<IPostCard[]>>> {
    return http.get(`${resource}/listByUser`, {
      params: {
        page: pageParam,
        sort
      }
    })
  }
  static getAllNewsByOtherUser(
    pageParam: number,
    sort: number,
    other_userid: string
  ): Promise<AxiosResponse<IApiResponse<IPostCard[]>>> {
    return http.get(`${resource}/listByUser`, {
      params: {
        page: pageParam,
        other_userid,
        sort
      }
    })
  }
}
