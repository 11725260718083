import { PACKAGE_TYPE, TAB_ITEM_PACKAGE_TYPE } from "@/app/configs"
import { compareValues } from "@/app/lib/utils"

export const listTab = (t: any) => [
  {
    title: t("common.all"),
    value: "",
    tab: ""
  },
  {
    title: "Giftcode",
    value: "giftcode,gc",
    tab: TAB_ITEM_PACKAGE_TYPE.GIFTCODE
  },
  {
    title: "Voucher",
    value: "voucher,gift_voucher",
    tab: TAB_ITEM_PACKAGE_TYPE.VOUCHER
  },
  {
    title: t("reward.tab_card"),
    value: "appota_card",
    tab: TAB_ITEM_PACKAGE_TYPE.CARD
  },
  {
    title: t("reward.tab_other"),
    value: "avatar,sticker,gift_item",
    tab: TAB_ITEM_PACKAGE_TYPE.OTHER
  }
]

export const convertData = (data: any) => {
  const _listGift = data?.giftcode?.length
    ? [
        ...data.giftcode.map((item: any) => {
          return { ...item, type: PACKAGE_TYPE.GIFTCODE }
        })
      ]
    : []
  const _listGCNew = data?.gc?.length
    ? [
        ...data.gc.map((item: any) => {
          return { ...item, type: PACKAGE_TYPE.GC }
        })
      ]
    : []
  const _listSticker = data?.sticker?.length
    ? [
        ...data?.sticker?.map((item: any) => {
          return { ...item, type: PACKAGE_TYPE.STICKER }
        })
      ]
    : []
  const _listAvatar = data?.avatar?.length
    ? [
        ...data?.avatar?.map((item: any) => {
          return { ...item, type: PACKAGE_TYPE.AVATAR }
        })
      ]
    : []
  const _listAppotaCard = data?.appota_card?.length
    ? [
        ...data?.appota_card?.map((item: any) => {
          return { ...item, type: PACKAGE_TYPE.APPOTA_CARD }
        })
      ]
    : []
  const _listVoucher = data?.voucher?.length
    ? [
        ...data?.voucher?.map((item: any) => {
          return { ...item, type: PACKAGE_TYPE.VOUCHER }
        })
      ]
    : []

  const _listGiftVoucher = data?.gift_voucher?.length
    ? [
        ...data?.gift_voucher?.map((item: any) => {
          return { ...item, type: PACKAGE_TYPE.GIFT_VOUCHER }
        })
      ]
    : []

  const _listGiftItem = data?.gift_item?.length
    ? [
        ...data?.gift_item?.map((item: any) => {
          return { ...item, type: PACKAGE_TYPE.GIFT_ITEM }
        })
      ]
    : []

  const _list = [
    ..._listAppotaCard,
    ..._listGift,
    ..._listGCNew,
    ..._listAvatar,
    ..._listSticker,
    ..._listVoucher,
    ..._listGiftVoucher,
    ..._listGiftItem
  ].sort(compareValues("created_at", "desc"))
  return _list
}
