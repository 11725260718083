import { QUERY_KEY } from "@/app/configs"
import { IGame, IGameDetailRequest } from "@/app/interfaces"
import { IBuyItemParams } from "@/app/interfaces/IReward"
import RewardService from "@/app/services/services/RewardService"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useRouter } from "next/router"

const buyItemFn = (body: IBuyItemParams) => {
  return RewardService.buyItem(body)
}

export const useMutationBuyItem = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.BUY_ITEM_SHOP],
    mutationFn: buyItemFn
  })
}
