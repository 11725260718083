import { useTranslation } from "next-i18next"
import React, { FC } from "react"
import { IconNoVocher } from "../icon-tsx/IconNoVoucher"

interface DataEmptyProps {
  title?: string
  icon?: any
}

export const DataEmpty: FC<DataEmptyProps> = ({ title, icon }) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col items-center">
      <div className="bg-button dark:bg-transparent w-[48px] h-[48px] flex items-center justify-center rounded-[12px]">
        {icon ? icon : <IconNoVocher />}
      </div>
      <p className="pt-[16px] text-neutral-06 text-[14px] Nunito-500 dark:text-neutral-07">
        {title || t("profile.no_data")}
      </p>
    </div>
  )
}
