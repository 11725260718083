import IApiResponse from "@interfaces/IApiResponse"
import { isClient } from "app/configs"
import { AxiosInstance, AxiosResponse } from "axios"
import { i18n } from "next-i18next"

const interceptor = ($http: AxiosInstance) => {
  $http.interceptors.request.use((config) => {
    config.params = config.params || {}
    config.params["lang"] = i18n?.language
    if (isClient && config.headers) {
      const token = localStorage.getItem("token")
      if (!!token) {
        config.headers["Game-Portal-Auth"] = `Bear ${token}`
      }
    }
    return config
  })

  $http.interceptors.response.use(
    (response: AxiosResponse<IApiResponse>) => {
      if (
        response &&
        response.request &&
        response.request.responseURL &&
        !response.request.responseURL.endsWith("/api/users/login") &&
        response.data.errorCode === 1
      ) {
        // store.dispatch(setVerifyModal(true))
        if (isClient) {
          const newUrl = new URL(window.location.href)
          newUrl.searchParams.set("global-modal", "verify")
          window.history.replaceState({}, "", newUrl)
        }
        return Promise.reject(response)
      } else if (response.data && response.data.errorCode !== 0) {
        if (response.request.responseURL.includes("/api/users/login")) {
          // store.dispatch(setDataError(response.data))
        }
        return Promise.reject(response)
      }
      return response
    },
    (error) => {
      if (isClient) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("token")
          // store.dispatch(setModal(true))
        } else if (error.data && error.data.errorCode === 1) {
          // store.dispatch(setVerifyModal(true))
        } else {
          return Promise.reject(error)
        }
        return Promise.reject()
      }
      return Promise.reject(error)
    }
  )
}

export default interceptor
