import { AxiosInstance, AxiosResponse } from "axios"
import { i18n } from "next-i18next"
import { OPEN_MODAL, isClient } from "../configs"
import IApiResponse from "../interfaces/IApiResponse"
import { useContext } from "react"
import { AppContext } from "../context"

const useAxiosInterceptors = ($http: AxiosInstance) => {
  const { setOpenModal } = useContext(AppContext)
  $http.interceptors.request.use((config) => {
    config.params = config.params || {}
    config.params["lang"] = i18n?.language
    if (isClient && config.headers) {
      const token = localStorage.getItem("token")
      if (!!token) {
        config.headers["Game-Portal-Auth"] = `Bear ${token}`
      }
    }
    return config
  })

  $http.interceptors.response.use(
    (response: AxiosResponse<IApiResponse>) => {
      if (
        response &&
        response.request &&
        response.request.responseURL &&
        !response.request.responseURL.endsWith("/api/users/login") &&
        response.data.errorCode === 1
      ) {
        if (isClient) {
          //   do something with the error
        }
        return Promise.reject(response)
      } else if (response.data && response.data.errorCode !== 0) {
        // do something with the error
        return Promise.reject(response)
      }
      return response
    },
    (error) => {
      if (isClient) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("token")
          setOpenModal(OPEN_MODAL.LOGIN_MODAL)
        } else if (error.data && error.data.errorCode === 1) {
          // do something with the error
        } else {
          return Promise.reject(error)
        }
        return Promise.reject()
      }
      return Promise.reject(error)
    }
  )
}

export default useAxiosInterceptors
