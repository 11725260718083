import { GroupFollowAction } from "@/app/components/connect-action/GroupFollowAction"
import { Button } from "@/app/components/ui/button"
import { isClient } from "@/app/configs"
import { AppContext } from "@/app/context"
import { jwtDecode } from "jwt-decode"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import React, { useContext, useMemo } from "react"

export const ActionOtherUser = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const { userInfo } = useContext(AppContext)
  const userid = useMemo(() => router?.query?.id, [router])
  const onNavigate = () => {
    if (isClient) {
      const token = localStorage.getItem("token")
      if (token) {
        const decodeToken: any = jwtDecode(token)
        router.push(
          `https://id.gamota.com/authorize?access_token=${decodeToken?.data?.id}&callback=/user/verify_email&redirect=${window.location.href}`
        )
      }
    }
  }
  if (userInfo?.userid !== userid && userid !== userInfo?.alias) {
    return (
      <>
        <div className="w-fit mx-auto my-[24px]">
          <GroupFollowAction id={String(userid)} />
        </div>
      </>
    )
  }
  return (
    <>
      {!userInfo?.email_verified &&
        router?.query?.update !== "1" &&
        (userInfo?.userid === userid || userInfo?.alias === userid) && (
          <Button variant="none" className="gap-[8px]" onClick={onNavigate}>
            <span className="text-main-01 text-[16px] Nunito-600">
              {t("profile.account_not_verified")}
            </span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 16L18 12M18 12L14 8M18 12L6 12"
                stroke="#7673E6"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Button>
        )}
    </>
  )
}
