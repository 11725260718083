import React, { useRef, FC, SetStateAction } from "react"
import "./style.css"

interface MultiRangeSliderProps {
  min: number
  max: number
  step: number
  minVal: number
  setMinVal: React.Dispatch<SetStateAction<number>>
  onChangeScaleImage: any
}

export const ZoomSlider: FC<MultiRangeSliderProps> = ({
  min,
  max,
  step = 10000,
  minVal,
  setMinVal,
  onChangeScaleImage
}) => {
  const minValRef = useRef(min)
  const onChangeInput = (value: number) => {
    setMinVal(value)
    minValRef.current = value
    onChangeScaleImage(value)
  }
  const onChangeMaxMinInput = () => {
    const value = minVal === min ? max : min
    onChangeInput(value)
  }
  return (
    <div className="flex w-full gap-2 mt-[32px] items-center">
      <div className="cursor-pointer" onClick={onChangeMaxMinInput}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17 7L13 11M7 17L11 13M13 11H17M13 11V7M11 13V17M11 13H7"
            stroke="#7673E6"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18 2H6C3.79086 2 2 3.79086 2 6V18C2 20.2091 3.79086 22 6 22H18C20.2091 22 22 20.2091 22 18V6C22 3.79086 20.2091 2 18 2Z"
            stroke="#7673E6"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>

      <div className="flex flex-col items-center w-full">
        <input
          type="range"
          min={min}
          max={max}
          step={step}
          value={minVal}
          onChange={(event) => onChangeInput(Number(event?.target?.value))}
          className="range-slider"
        />
      </div>
    </div>
  )
}
