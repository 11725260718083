import React, { useMemo, useState } from "react"
import { useFormContext } from "react-hook-form"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger
} from "@/app/components/ui/dropdown-menu"
import { Button } from "@/app/components/ui/button"
import { IconCalendar } from "@/app/components/icon-tsx/IconCalendar"
import { ScrollArea } from "@/app/components/ui/scroll-area"
import { Calendar } from "@/app/components/ui/calendar"
import { useTranslation } from "next-i18next"
import moment from "moment"
import { DATE_FORMAT_3, END_YEAR } from "@/app/configs"
import { CustomSelectMonthYear } from "./CustomSelectMontYear"

export const StartTime = () => {
  const { t } = useTranslation()
  const { setValue, watch } = useFormContext()
  const [openStartTime, setOpenStartTime] = useState<boolean>(false)
  const [timeStart, setTimeStart] = useState<string>("00:00")

  const selectedStartTime = watch("start_time", undefined)
  const selectedEndTime = watch("end_time", undefined)
  const handleStartTimeChange: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    const time = e.target.value
    if (!selectedStartTime) {
      setTimeStart(time)
      return
    }
    const [hours, minutes] = time.split(":").map((str) => parseInt(str, 10))
    const newSelectedDate = new Date(
      selectedStartTime.getFullYear(),
      selectedStartTime.getMonth(),
      selectedStartTime.getDate(),
      hours,
      minutes
    )
    setValue("start_time", newSelectedDate)
    setTimeStart(time)
  }

  const handleDaySelectStartTime = (date: Date | undefined) => {
    if (!timeStart || !date) {
      setValue("start_time", date)
      return
    }
    const [hours, minutes] = timeStart
      .split(":")
      .map((str) => parseInt(str, 10))
    const newDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      hours,
      minutes
    )
    setValue("start_time", newDate)
  }

  const onCloseSelectStartDate = () => {
    setOpenStartTime(false)
    setValue("start_time", undefined)
  }

  const onSubmitSelectTime = () => {
    setOpenStartTime(false)
  }

  const content = useMemo(() => {
    return (
      <div className="flex-1">
        <DropdownMenu
          open={openStartTime}
          onOpenChange={onCloseSelectStartDate}
        >
          <DropdownMenuTrigger asChild>
            <Button
              variant="none"
              className="bg-disable w-full text-neutral flex justify-between items-center py-[8px] dark:text-white"
              onClick={() => setOpenStartTime(true)}
            >
              <span>
                {selectedStartTime
                  ? moment(selectedStartTime).format(DATE_FORMAT_3)
                  : t("news.start")}
              </span>
              <IconCalendar />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-[276px]" align="center" side="left">
            <ScrollArea className="h-fit">
              <Calendar
                mode="single"
                selected={selectedStartTime}
                onSelect={handleDaySelectStartTime}
                className="p-0 rounded-md"
                showOutsideDays
                captionLayout="dropdown"
                fromYear={new Date().getFullYear()}
                toYear={END_YEAR}
                components={{
                  Head: () => <CustomSelectMonthYear />
                }}
                footer={
                  <div className="space-y-[24px]">
                    <div className="flex items-center justify-between mt-2">
                      <span className="text-base Nunito-600 text-main-01">
                        {t("news.post_time")}:
                      </span>
                      <input
                        type="time"
                        value={timeStart}
                        onChange={handleStartTimeChange}
                        className="text-base"
                      />
                    </div>
                    <div className="flex items-center justify-end gap-4">
                      <Button
                        variant="none"
                        className="bg-disable text-neutral dark:text-white rounded-[12px] max-h-[34px] py-[6px] px-[24px]"
                        onClick={onCloseSelectStartDate}
                      >
                        {t("common.cancel")}
                      </Button>

                      <Button
                        variant="none"
                        disabled={
                          !selectedStartTime ||
                          selectedStartTime < new Date() ||
                          (!!selectedEndTime &&
                            selectedStartTime > selectedEndTime)
                        }
                        className="text-white bg-disable bg-main-01 rounded-[12px] max-h-[34px] py-[6px] px-[24px]"
                        onClick={onSubmitSelectTime}
                      >
                        {t("news.set_time")}
                      </Button>
                    </div>
                    {selectedStartTime < new Date() ? (
                      <p className="mt-1 text-xs Nunito-400 text-main-red">
                        {t("news.please_select_future_date")}
                      </p>
                    ) : !!selectedEndTime &&
                      selectedStartTime > selectedEndTime ? (
                      <p className="mt-1 text-xs Nunito-400 text-main-red">
                        {t("news.start_time_smaller")}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                }
              />
            </ScrollArea>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    )
  }, [selectedStartTime, selectedEndTime, openStartTime, timeStart])
  return <>{content}</>
}
