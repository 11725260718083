import React from "react"

export const IconCommentNews = ({ className }: { className?: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M13 3H11C6.02944 3 2 7.02944 2 12V17C2 19.2091 3.79086 21 6 21H13C17.9706 21 22 16.9706 22 12C22 7.02944 17.9706 3 13 3Z"
        stroke="#7673E6"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <ellipse cx="12" cy="12" rx="1" ry="1" fill="#7673E6" />
      <ellipse cx="16" cy="12" rx="1" ry="1" fill="#7673E6" />
      <ellipse cx="8" cy="12" rx="1" ry="1" fill="#7673E6" />
    </svg>
  )
}
