import React, { useContext, useEffect, useState } from "react"
import { IconGame } from "../icon-tsx/IconGame"
import { useTranslation } from "next-i18next"
import { MENU_TAB } from "@/app/configs"
import { IconNews } from "../icon-tsx/IconNews"
import { IconStore } from "../icon-tsx/IconStore"
import { useRouter } from "next/router"
import Link from "next/link"
import { AppContext } from "@/app/context"
import { IconGameFill } from "../icon-tsx/IconGameFill"
import { IconNewsFill } from "../icon-tsx/IconNewsFill"
import { IconStoreFill } from "../icon-tsx/IconStoreFill"
import useGAEventsTracker from "@/app/hooks/useGAEventsTracker"

export const MenuBar = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const { themeApp } = useContext(AppContext)
  const [curTab, setCurTab] = useState(router.pathname)
  const GAEventsTracker = useGAEventsTracker("Store")
  const GAEventsTrackerGame = useGAEventsTracker("Game")
  const GAEventsTrackerNews = useGAEventsTracker("News")

  const listTab = [
    {
      icon:
        curTab === MENU_TAB.GAME_TAB ? (
          <IconGameFill fill={themeApp === "dark" ? "#FAF8FF" : ""} />
        ) : (
          <IconGame
            fill={themeApp === "dark" ? "#FAF8FF" : ""}
            className="icon-stroke"
          />
        ),
      title: t("common.game_tab_title"),
      path: MENU_TAB.GAME_TAB
    },
    {
      icon:
        curTab === MENU_TAB.NEWS_TAB ? (
          <IconNewsFill fill={themeApp === "dark" ? "#FAF8FF" : ""} />
        ) : (
          <IconNews
            fill={themeApp === "dark" ? "#FAF8FF" : ""}
            className="icon-fill"
          />
        ),
      title: t("common.news_tab_title"),
      path: MENU_TAB.NEWS_TAB
    },
    {
      icon:
        curTab === MENU_TAB.STORE_TAB ? (
          <IconStoreFill fill={themeApp === "dark" ? "#FAF8FF" : ""} />
        ) : (
          <IconStore
            fill={themeApp === "dark" ? "#FAF8FF" : ""}
            className="icon-stroke"
          />
        ),
      title: t("common.side_bar_store"),
      path: MENU_TAB.STORE_TAB
    }
  ]

  const activeTab = listTab.find((tab) => tab.path === curTab)

  useEffect(() => {
    setCurTab(router.pathname)
  }, [router])

  return (
    <ul className="md:flex items-center m-auto gap-[8px] text-main-01 hidden relative">
      {listTab.map((menuItem, index) => {
        return (
          <li
            key={index}
            className={`sidebar_link cursor-pointer opacity-1 ${
              curTab == menuItem.path
                ? "sidebar_link--active Nunito-600 opacity-0"
                : ""
              // "hover:bg-button dark:hover:bg-main-01"
            }`}
            onClick={() => {
              if (menuItem.path === MENU_TAB.STORE_TAB) {
                GAEventsTracker("Click_Store_Tab")
              }

              if (menuItem.path === MENU_TAB.GAME_TAB) {
                GAEventsTrackerGame("Click_Games_Tab")
              }

              if (menuItem.path === MENU_TAB.NEWS_TAB) {
                GAEventsTrackerNews("Click_Games_Tab")
              }

              setCurTab(menuItem.path)
              router.push(menuItem.path)
            }}
            style={{
              zIndex: 2
            }}
          >
            <div className="flex items-center w-fit gap-[8px]">
              {menuItem.icon}{" "}
              {/* {curTab == menuItem.path && (
                <p className="whitespace-nowrap text-main-01 text-[14px] transition duration-100 linear lg:text-[16px] dark:text-main-white">
                  {menuItem.title}
                </p>
              )} */}
            </div>
          </li>
        )
      })}

      {activeTab && (
        <div
          id="overlay"
          className={`navigation-tab-overlay z-1 flex items-center justify-center ${
            curTab === MENU_TAB.GAME_TAB
              ? "left-0"
              : curTab === MENU_TAB.NEWS_TAB
              ? "left-[63px]"
              : "left-[126px]"
          }`}
          style={{
            zIndex: 1
          }}
        >
          <div className="flex items-center w-fit gap-[8px]">
            {activeTab?.icon}

            <p className="whitespace-nowrap text-main-01 text-[14px] transition duration-500 linear lg:text-[16px] dark:text-main-white Nunito-600">
              {activeTab?.title}
            </p>
          </div>
        </div>
      )}
    </ul>
  )
}
