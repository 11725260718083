import { ModalAddressMerchandise } from "@/app/components/address-merchandise/ModalAddressMerchandise"
import React, { useCallback, useState } from "react"
import { ModalSelectAddressProfile } from "../modal/ModalSelectAddressProfile"
import { IMerchandiseAddress } from "@/app/interfaces"
import { useToast } from "@/app/components/ui/use-toast"
import { useQueryClient } from "@tanstack/react-query"
import {
  useMutationDeleteAddress,
  useMutationUpdateAddress
} from "@/app/services/query-fn/merchandise/merchandise-mutation"
import { QUERY_KEY } from "@/app/configs"
import { useQueryGetListMerchandiseAddress } from "@/app/services/query-fn/merchandise/merchandise-query"

export const MyMerchandiseAddress = () => {
  const [openListAddress, setOpenListAddress] = useState<boolean>(false)
  const [openAddAddress, setOpenAddAddress] = useState<boolean>(false)
  const [dataSeleted, setDataSelected] = useState<IMerchandiseAddress>()

  const { data: listAddress } = useQueryGetListMerchandiseAddress()

  const defaultAddress = useCallback(() => {
    const defaultItem = listAddress?.find(
      (item: IMerchandiseAddress) => !!item.default
    )
    if (!!defaultItem) {
      return `${defaultItem?.address}, ${defaultItem?.ward}, ${defaultItem?.district}, ${defaultItem?.city}`
    } else return ""
  }, [listAddress])

  const onOpenChangeListAddress = () => {
    setOpenListAddress(!openListAddress)
  }
  const onOpenChangeAddAddress = () => {
    setOpenAddAddress(!openAddAddress)
  }

  const { toast } = useToast()
  const queryClient = useQueryClient()
  const { mutate: updateAdress } = useMutationUpdateAddress()
  const { mutate: deleteAddressFn } = useMutationDeleteAddress()

  const onSetAddressDefault = (address: IMerchandiseAddress) => {
    updateAdress(
      {
        ...address,
        status: "default"
      },
      {
        onSuccess: () => {
          toast({
            variant: "success",
            title: "Cập nhật thành công!"
          })
          queryClient.refetchQueries({
            queryKey: [QUERY_KEY.MERCHANDISE_ADDRESS]
          })
        }
      }
    )
  }

  const onDeleteAddress = (id: string) => {
    deleteAddressFn(
      {
        id
      },
      {
        onSuccess: () => {
          toast({
            variant: "success",
            title: "Xoá thành công!"
          })
          queryClient.refetchQueries({
            queryKey: [QUERY_KEY.MERCHANDISE_ADDRESS]
          })
        }
      }
    )
  }

  const onUpdateAddress = (address: IMerchandiseAddress) => {
    setDataSelected(address)
    onOpenChangeAddAddress()
  }

  return (
    <div className="flex justify-end flex-1">
      <div className="flex items-center justify-center gap-[12px]">
        <div className="items-center hidden md:flex gap-[12px]">
          <p className="text-sm text-bg-01 Nunito-500 dark:text-white">
            {defaultAddress()}
          </p>
          <div className="w-[1px] h-[16px] bg-black/10 dark:bg-neutral-03" />
        </div>

        <div
          className="cursor-pointer flex w-[24px] h-[24px]"
          onClick={onOpenChangeListAddress}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.5 17.5H17.5M11.487 4.42643C11.487 4.42643 11.487 5.78861 12.8492 7.1508C14.2114 8.51299 15.5736 8.51299 15.5736 8.51299M6.09969 14.9901L8.96028 14.5814C9.37291 14.5225 9.7553 14.3313 10.05 14.0365L16.9358 7.1508C17.6881 6.39848 17.6881 5.17874 16.9358 4.42642L15.5736 3.06424C14.8213 2.31192 13.6015 2.31192 12.8492 3.06424L5.96347 9.94997C5.66873 10.2447 5.47754 10.6271 5.41859 11.0397L5.00994 13.9003C4.91913 14.536 5.464 15.0809 6.09969 14.9901Z"
              stroke="#7673E6"
              strokeLinecap="round"
            />
          </svg>
        </div>
      </div>

      {/* Modal list address, action with address */}
      <ModalSelectAddressProfile
        open={openListAddress}
        onClose={onOpenChangeListAddress}
        onSelectAddNewAddress={onOpenChangeAddAddress}
        listAddress={listAddress ?? []}
        onSetAddressDefault={onSetAddressDefault}
        onDeleteAddress={onDeleteAddress}
        onUpdateAddress={onUpdateAddress}
      />

      {/* Modal create and edit address */}
      <ModalAddressMerchandise
        open={openAddAddress}
        onOpenChange={onOpenChangeAddAddress}
        isEdit={!!dataSeleted?.id}
        dataEdit={dataSeleted}
      />
    </div>
  )
}
