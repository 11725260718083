import { createContext, useState } from "react"
import { IUser } from "../interfaces/IUser"
import useAxiosInterceptors from "../hooks/useAxiosInterceptor"
import { http } from "../api"
import { IGiftcode } from "../interfaces/IGiftcode"

interface AppContextProps {
  themeApp: string
  setThemeApp: any
  userInfo: IUser
  setUserInfo: any
  unReadCount: number
  setUnReadCount: any
  openModal: string | null
  setOpenModal: any
  visibleGiftcodeDetail: boolean
  setVisibleGiftcodeDetail: any
  visibleUseGiftcode: boolean
  setVisibleUseGiftcode: any
  selectedGiftcode: IGiftcode
  setSelectedGiftcode: any
}

export const AppContext = createContext<AppContextProps>({
  themeApp: "light",
  setThemeApp: () => {},
  userInfo: {} as IUser,
  setUserInfo: () => {},
  unReadCount: 0,
  setUnReadCount: () => {},
  openModal: null,
  setOpenModal: () => {},
  visibleGiftcodeDetail: false,
  setVisibleGiftcodeDetail: () => {},
  visibleUseGiftcode: false,
  setVisibleUseGiftcode: () => {},
  selectedGiftcode: {} as IGiftcode,
  setSelectedGiftcode: () => {}
})

interface AppProps {
  children: any
}

export const AppProvider = ({ children }: AppProps) => {
  const [theme, setTheme] = useState("light")
  const [unReadCount, setUnReadCount] = useState(0)
  const [userInfo, setUserInfo] = useState<IUser>({} as IUser)
  const [openModal, setOpenModal] = useState<null | string>(null)
  const [visibleGiftcodeDetail, setVisibleGiftcodeDetail] =
    useState<boolean>(false)
  const [visibleUseGiftcode, setVisibleUseGiftcode] = useState<boolean>(false)
  const [selectedGiftcode, setSelectedGiftcode] = useState<IGiftcode>(
    {} as IGiftcode
  )

  return (
    <AppContext.Provider
      value={{
        themeApp: theme,
        setThemeApp: setTheme,
        userInfo,
        setUserInfo,
        unReadCount,
        setUnReadCount,
        openModal,
        setOpenModal,
        visibleGiftcodeDetail,
        setVisibleGiftcodeDetail,
        selectedGiftcode,
        setSelectedGiftcode,
        visibleUseGiftcode,
        setVisibleUseGiftcode
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
