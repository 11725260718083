import React from "react"

export const IconNonCopy = ({
  stroke = "#9F9EA9",
  className
}: {
  stroke?: string
  className?: string
}) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_11185_37072)">
        <path
          d="M6.66602 6.6665V4.99984C6.66602 3.15889 8.1584 1.6665 9.99935 1.6665L14.9993 1.6665C16.8403 1.6665 18.3327 3.15889 18.3327 4.99984V9.99984C18.3327 11.8408 16.8403 13.3332 14.9993 13.3332H13.3327M6.66602 6.6665H4.99935C3.1584 6.6665 1.66602 8.15889 1.66602 9.99984V14.9998C1.66602 16.8408 3.1584 18.3332 4.99935 18.3332H9.99935C11.8403 18.3332 13.3327 16.8408 13.3327 14.9998V13.3332M6.66602 6.6665H9.99935C11.8403 6.6665 13.3327 8.15889 13.3327 9.99984V13.3332"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_11185_37072">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
