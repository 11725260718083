import { IOrderParams } from "@/app/interfaces/IStore"
import TopupService from "../../services/TopupService"
import { useInfiniteQuery } from "@tanstack/react-query"
import { QUERY_KEY } from "@/app/configs"
import StoreService from "../../services/StoreServices"
import {
  enumTypeTransaction,
  enumTypeViewAll
} from "@/app/containers/users/normal-user/tab"
import { IPointHistoryParams, IPramsPaymentHistory } from "@/app/interfaces"
import { UserService } from "../../services"
import { MerchandiseService } from "../../services/MerchandiseService"

const getTopupTransaction = async (param: IOrderParams) => {
  const res = await TopupService.getPaymentHistory(param)
  return res?.data?.data
}
const getCardTransaction = async (param: IOrderParams) => {
  const res = await StoreService.getPaymentHistory(param)
  return res?.data?.data
}

const getUserPointHistory = async (param: IPointHistoryParams) => {
  const res = await UserService.getUserPointHistory(param)
  return res?.data?.data
}

const getMerchandiseTransaction = async (param: IPramsPaymentHistory) => {
  const res = await MerchandiseService.getPaymentMerchandiseHistory(param)
  return res?.data?.data
}

export const useQueryGetUserPointHistory = (
  param: Omit<IPointHistoryParams, "page">,
  userId: string
) => {
  return useInfiniteQuery({
    queryKey: [QUERY_KEY.POINT_HISTORY, userId, param],
    queryFn: ({ pageParam }) =>
      getUserPointHistory({ ...param, page: pageParam }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.length > 9 ? pages?.length + 1 : undefined
    },
    enabled: !!userId && userId !== "undefined"
  })
}

export const useQueryGetUserMerchandiseHistory = (
  param: Omit<IPramsPaymentHistory, "page">,
  userId: string,
  type: string
) => {
  return useInfiniteQuery({
    queryKey: [QUERY_KEY.MERCHANDISE_HISTORY, userId, param],
    queryFn: ({ pageParam }) =>
      getMerchandiseTransaction({ ...param, page: pageParam }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.length > 9 ? pages?.length + 1 : undefined
    },
    enabled:
      !!userId &&
      userId !== "undefined" &&
      (type === String(enumTypeTransaction.MERCHANDISE) ||
        type === String(enumTypeViewAll.MERCHANDISE_ALL))
  })
}

export const useQueryGetTopupTransaction = (
  param: Omit<IOrderParams, "page">,
  userId: string,
  type: string
) => {
  return useInfiniteQuery({
    queryKey: [QUERY_KEY.TOPUP_TRANSACTION, userId, param],
    queryFn: ({ pageParam }) =>
      getTopupTransaction({ ...param, page: pageParam }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.length > 9 ? pages?.length + 1 : undefined
    },
    enabled:
      (!!userId &&
        userId !== "undefined" &&
        type === String(enumTypeTransaction.TOPUP)) ||
      type === String(enumTypeViewAll.TOPUP_ALL)
  })
}

export const useQueryGetCardTransaction = (
  param: Omit<IOrderParams, "page">,
  userId: string,
  type: string
) => {
  return useInfiniteQuery({
    queryKey: [QUERY_KEY.CARD_TRANSACTION, userId, param],
    queryFn: ({ pageParam }) =>
      getCardTransaction({ ...param, page: pageParam }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.length > 9 ? pages?.length + 1 : undefined
    },
    enabled:
      (!!userId &&
        userId !== "undefined" &&
        type === String(enumTypeTransaction.CARD)) ||
      type === String(enumTypeViewAll.CARD_ALL) ||
      type === "undefined"
  })
}
