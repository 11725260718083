export const IconSelectCreateEvent = ({
  className
}: {
  className?: string
}) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 2C9.5 1.58579 9.16421 1.25 8.75 1.25C8.33579 1.25 8 1.58579 8 2V3.5H7.75C5.54086 3.5 3.75 5.29086 3.75 7.5V8.25H21.75V7.5C21.75 5.29086 19.9591 3.5 17.75 3.5H17.5V2C17.5 1.58579 17.1642 1.25 16.75 1.25C16.3358 1.25 16 1.58579 16 2V3.5H9.5V2ZM21.75 9.75H3.75V18C3.75 20.2091 5.54086 22 7.75 22H17.75C19.9591 22 21.75 20.2091 21.75 18V9.75ZM13.5 12C13.5 11.5858 13.1642 11.25 12.75 11.25C12.3358 11.25 12 11.5858 12 12V14.25H9.75C9.33579 14.25 9 14.5858 9 15C9 15.4142 9.33579 15.75 9.75 15.75H12V18C12 18.4142 12.3358 18.75 12.75 18.75C13.1642 18.75 13.5 18.4142 13.5 18V15.75H15.75C16.1642 15.75 16.5 15.4142 16.5 15C16.5 14.5858 16.1642 14.25 15.75 14.25H13.5V12Z"
        fill="#7673E6"
      />
    </svg>
  )
}
