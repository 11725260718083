import { AppContext } from "@/app/context"
import { useContext } from "react"

export const IconNews = ({
  fill,
  className
}: {
  fill?: string
  className?: string
}) => {
  const { themeApp } = useContext(AppContext)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      className={className}
    >
      <path
        d="M8.64453 6.25C8.23032 6.25 7.89453 6.58579 7.89453 7C7.89453 7.41421 8.23032 7.75 8.64453 7.75V6.25ZM16.6445 7.75C17.0587 7.75 17.3945 7.41421 17.3945 7C17.3945 6.58579 17.0587 6.25 16.6445 6.25V7.75ZM8.64453 10.25C8.23032 10.25 7.89453 10.5858 7.89453 11C7.89453 11.4142 8.23032 11.75 8.64453 11.75V10.25ZM12.6445 11.75C13.0587 11.75 13.3945 11.4142 13.3945 11C13.3945 10.5858 13.0587 10.25 12.6445 10.25V11.75ZM7.64453 16.75H20.6445V15.25H7.64453V16.75ZM17.6445 21.25H7.64453V22.75H17.6445V21.25ZM7.64453 21.25C6.40189 21.25 5.39453 20.2426 5.39453 19H3.89453C3.89453 21.0711 5.57346 22.75 7.64453 22.75V21.25ZM17.6445 22.75C19.7156 22.75 21.3945 21.0711 21.3945 19H19.8945C19.8945 20.2426 18.8872 21.25 17.6445 21.25V22.75ZM7.64453 15.25C5.57346 15.25 3.89453 16.9289 3.89453 19H5.39453C5.39453 17.7574 6.40189 16.75 7.64453 16.75V15.25ZM7.64453 2.75H17.6445V1.25H7.64453V2.75ZM19.8945 5V19H21.3945V5H19.8945ZM5.39453 19V5H3.89453V19H5.39453ZM17.6445 2.75C18.8872 2.75 19.8945 3.75736 19.8945 5H21.3945C21.3945 2.92893 19.7156 1.25 17.6445 1.25V2.75ZM7.64453 1.25C5.57346 1.25 3.89453 2.92893 3.89453 5H5.39453C5.39453 3.75736 6.40189 2.75 7.64453 2.75V1.25ZM8.64453 7.75H16.6445V6.25H8.64453V7.75ZM8.64453 11.75H12.6445V10.25H8.64453V11.75Z"
        fill={fill ? fill : "#65676B"}
      />
    </svg>
  )
}
