export const IconReadAll = ({
  className,
  fill
}: {
  className?: string
  fill?: string
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
    >
      <path
        d="M1.66797 10.833L4.60665 13.184C5.29608 13.7355 6.29601 13.6537 6.88664 12.9975L13.3346 5.83301M6.66797 10.833L9.60666 13.184C10.2961 13.7355 11.296 13.6537 11.8866 12.9975L18.3346 5.83301"
        stroke={fill ? fill : "#7673E6"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
