import { AppContext } from "@/app/context"
import { useContext } from "react"

export const IconClose = ({
  className,
  fill,
  onClick
}: {
  className?: string
  fill?: string
  onClick?: any
}) => {
  const { themeApp } = useContext(AppContext)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
      onClick={onClick}
    >
      <path
        d="M16.2431 7.75786L7.75781 16.2431M16.2431 16.2431L7.75781 7.75781"
        stroke={fill ? fill : themeApp === "dark" ? "#FAF8FF" : "#28303F"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
