import {
  createSlugNameDetail,
  extractText,
  timeFormatter
} from "@/app/helpers/function"
import { IPostCard } from "@/app/interfaces/IPost"
import { useRouter } from "next/router"
import React, { FC, useCallback, useContext, useMemo } from "react"
import { DATE_FORMAT_HOUR } from "@/app/configs"
import { animated, useSpring } from "@react-spring/web"
import { useTranslation } from "next-i18next"
import moment from "moment"
import Link from "next/link"
import { OnlyShowAvatar } from "../app-avatar/OnlyShowAvatar"
import { GroupFollowAction } from "../connect-action/GroupFollowAction"
import { AppContext } from "@/app/context"

interface IPostCardProps {
  postcard: IPostCard
  imgClassName?: string
  isInPage?: boolean
  isCheckHashtag?: boolean
}

export const PostCard: FC<IPostCardProps> = ({
  postcard,
  imgClassName,
  isInPage = false,
  isCheckHashtag = false
}) => {
  const router = useRouter()
  const { t } = useTranslation()
  const { userInfo } = useContext(AppContext)

  const convertHashtag = useCallback(() => {
    const result = []
    for (let tag in postcard?.post?.hashtag) {
      result.push(postcard?.post?.hashtag[tag])
    }
    return result
  }, [postcard])

  const renderContent = useMemo(() => {
    return typeof document === "undefined" ? null : postcard?.post?.content
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postcard?.post?.content])

  const onNavigateToDetail = () => {
    const isNotPublish =
      postcard?.post?.public_at * 1000 > Number(new Date().getTime())
    router.push({
      pathname: isNotPublish ? "/preview/[type]/[id]" : "/detail/[type]/[id]",
      query: {
        type: "news",
        id: isNotPublish
          ? postcard?.post?.id
          : createSlugNameDetail(postcard?.post?.title, postcard?.post?.id)
      }
    })
  }

  const onNavigateToProfile = () => {
    router.push(
      `/user/${
        !!postcard?.author?.alias
          ? postcard?.author?.alias
          : postcard?.author?.id
      }`
    )
  }

  const fade = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    delay: 100
  })

  return (
    <animated.div style={fade}>
      <div className="w-full border-b-[1px] border-black/10 dark:border-neutral-03 md:border-0 pb-4 md:pb-0">
        <div className="relative grid grid-cols-12 gap-0 md:gap-[24px] w-full rounded-[16px]">
          {/* Show in mobile, hidden in web */}
          <div className="flex md:hidden items-center justify-between gap-3 pb-[16px] w-full col-span-12 md:col-span-0">
            <div
              className="cursor-pointer relative gap-[12px] items-center flex max-w-[60%]"
              onClick={onNavigateToProfile}
            >
              <OnlyShowAvatar size={40} avatarUser={postcard?.author} />
              <div className="flex flex-col">
                <div className="flex items-center gap-[12px] ">
                  <p className="text-sm text-bg-01 Nunito-700 dark:text-white line-clamp-1">
                    {postcard?.author?.fullname}
                  </p>
                  {!!postcard?.author?.authentic && (
                    <span>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M7.9987 14.6663C11.6806 14.6663 14.6654 11.6816 14.6654 7.99967C14.6654 4.31778 11.6806 1.33301 7.9987 1.33301C4.3168 1.33301 1.33203 4.31778 1.33203 7.99967C1.33203 11.6816 4.3168 14.6663 7.9987 14.6663ZM11.06 6.30667C11.2296 6.08869 11.1903 5.77456 10.9723 5.60502C10.7544 5.43549 10.4402 5.47475 10.2707 5.69273L7.59936 9.1273C7.53996 9.20367 7.42822 9.21358 7.35631 9.14886L5.66652 7.62805C5.46126 7.44332 5.14512 7.45996 4.96039 7.66522C4.77566 7.87047 4.7923 8.18661 4.99755 8.37134L6.68734 9.89215C7.19076 10.3452 7.9729 10.2759 8.38871 9.74124L11.06 6.30667Z"
                          fill="#7673E6"
                        />
                      </svg>
                    </span>
                  )}
                </div>
                <>
                  {!!postcard?.post?.public_at &&
                  postcard?.post?.public_at * 1000 >
                    Number(new Date().getTime()) ? (
                    <p className="text-xs text-bg-01 Nunito-400 dark:text-white">
                      {t("news.public_time")}&nbsp;&nbsp;
                      {moment(Number(postcard?.post?.public_at) * 1000).format(
                        DATE_FORMAT_HOUR
                      )}
                    </p>
                  ) : (
                    <p className="text-xs text-bg-01 Nunito-400 dark:text-white">
                      {timeFormatter(
                        Number(
                          postcard?.post?.public_at ??
                            postcard?.post?.created_at
                        ) * 1000
                      )}
                    </p>
                  )}
                </>
              </div>
            </div>
            {!isCheckHashtag && !!userInfo?.userid && (
              <GroupFollowAction id={postcard?.author?.id} />
            )}
          </div>

          <div className="col-span-12 md:col-span-4">
            <img
              src={
                !!postcard?.post?.thumbnail
                  ? postcard?.post?.thumbnail
                  : "https://picsum.photos/id/277/1920/1200"
              }
              loading="lazy"
              alt={postcard?.post?.title}
              title={postcard?.post?.title}
              className={`w-full min-w-full h-auto rounded-[16px] object-cover aspect-[16/9] cursor-pointer ${imgClassName}`}
              onClick={onNavigateToDetail}
            />
          </div>

          <div
            className={`col-span-12 md:col-span-8 flex flex-col my-0 lg:my-6 ${
              !!isCheckHashtag && !!convertHashtag()?.length && "lg:!my-0"
            }  ${isInPage && "my-0 lg:!my-0"}`}
          >
            {/* Show in web, hidden in mobile */}
            {!!postcard?.post?.public_at &&
            postcard?.post?.public_at * 1000 > Number(new Date().getTime()) ? (
              <p
                className={`text-[14px] Nunito-400 text-main-01 hidden md:block ${
                  isInPage && "md:text-[8px] ld:text-[14px]"
                }`}
              >
                {t("news.public_time")}&nbsp;&nbsp;
                {moment(Number(postcard?.post?.public_at) * 1000).format(
                  DATE_FORMAT_HOUR
                )}
              </p>
            ) : (
              <p
                className={`text-[14px] Nunito-400 hidden md:block ${
                  isInPage && "md:text-[8px] ld:text-[14px]"
                }`}
              >
                {timeFormatter(
                  Number(
                    postcard?.post?.public_at ?? postcard?.post?.created_at
                  ) * 1000
                )}
              </p>
            )}

            <div>
              <h3
                className={`text-[16px] md:text-[18px] Nunito-700 leading-normal mt-[16px] md:mt-[8px] mb-[8px] cursor-pointer line-clamp-1 ${
                  isInPage && "md:text-[12px] ld:text-[18px]"
                }`}
                onClick={onNavigateToDetail}
              >
                {postcard?.post?.title}
              </h3>
              <div
                className={`inline-block w-full md:w-[70%] ${
                  isInPage && "md:hidden ld:inline-block"
                }`}
              >
                <div
                  className={`text-[14px] Nunito-400 ${
                    !!convertHashtag()?.length
                      ? "line-clamp-1 lg:line-clamp-1"
                      : "line-clamp-2 lg:line-clamp-3"
                  } ${
                    !isCheckHashtag
                      ? "line-clamp-2 lg:line-clamp-3"
                      : "line-clamp-1 lg:line-clamp-1"
                  } dark:text-white break-words`}
                  dangerouslySetInnerHTML={{
                    __html: extractText(renderContent || "") ?? ""
                  }}
                ></div>
                <span
                  className={`text-main-01 text-[14px] h-[28px] Nunito-400 cursor-pointer ${
                    isInPage && "md:hidden ld:block"
                  }`}
                  onClick={onNavigateToDetail}
                >
                  {t("common.view_more")}
                </span>
              </div>
            </div>

            {/* Show in mobile, hidden in web */}
            {!!convertHashtag()?.length && (
              <div className="flex md:hidden gap-[8px] mb-0 pt-2">
                {convertHashtag().map((hashtag: string, index: number) => {
                  return (
                    <div
                      key={index}
                      className="px-[8px] py-[2px] rounded-[8px] bg-button dark:bg-[#EFEFFF]/[.1] text-main-01 text-[14px]"
                    >
                      #{hashtag?.replaceAll("#", "")}
                    </div>
                  )
                })}
              </div>
            )}

            {/* Show in web, hidden in mobile */}
            {!!convertHashtag()?.length && (
              <div
                className={`hidden lg:flex gap-[8px] mt-auto mb-0 pt-4 ${
                  isInPage && "md:pt-0 ld:pt-4"
                }`}
              >
                {convertHashtag().map((hashtag: string, index: number) => {
                  if (!hashtag) return null
                  return (
                    <Link
                      href={`/hashtag/${hashtag?.replaceAll("#", "")}`}
                      key={index}
                    >
                      <div className="px-[8px] py-[2px] rounded-[8px] bg-button dark:bg-[#EFEFFF]/[.1] text-main-01 text-[14px]">
                        #{hashtag?.replaceAll("#", "")}
                      </div>
                    </Link>
                  )
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </animated.div>
  )
}
