import { http } from "@/app/api"
import IApiResponse from "@/app/interfaces/IApiResponse"
import {
  ICode,
  IGameGiftcode,
  IGiftcode,
  IGiftcodeDetail,
  IGiftcodeParams,
  IRoleGiftcode,
  IRoleGiftcodeParams,
  IServerGiftcode,
  IServerGiftcodeParams,
  IUseGiftcodeParams
} from "@/app/interfaces/IGiftcode"
import { AxiosResponse } from "axios"

const resource = "/gc"

export class GiftcodeServices {
  static getAllGiftcode(
    params?: IGiftcodeParams
  ): Promise<AxiosResponse<IApiResponse<IGiftcode[]>>> {
    return http.get(`${resource}/events`, { params })
  }

  static getGiftcodeDetail({
    event_id
  }: {
    event_id: string
  }): Promise<AxiosResponse<IApiResponse<IGiftcodeDetail>>> {
    return http.get(`${resource}/events/detail/${event_id}`, {
      params: { event_id }
    })
  }

  static getGiftcodeInfo({
    event_id
  }: {
    event_id: string
  }): Promise<AxiosResponse<IApiResponse<any>>> {
    return http.get(`${resource}/events/getGiftcode/${event_id}`, {
      params: { event_id }
    })
  }

  static getGameGiftcode(): Promise<AxiosResponse<IApiResponse<any>>> {
    return http.get(`${resource}/games/list`)
  }

  static getServerGiftcode(
    params: IServerGiftcodeParams
  ): Promise<AxiosResponse<IApiResponse<IServerGiftcode>>> {
    return http.get(`${resource}/games/server/${params?.game_id}`, { params })
  }

  static getRoleGiftcode(
    params: IRoleGiftcodeParams
  ): Promise<AxiosResponse<IApiResponse<IRoleGiftcode[]>>> {
    return http.get(`${resource}/games/role/${params?.game_id}`, { params })
  }

  static useGiftcode(
    params: IUseGiftcodeParams
  ): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`${resource}/games/useGiftcode/${params?.game_id}`, params)
  }
}
