import { LoginModal, SelectCreatePostModal } from "@/app/containers"
import React from "react"
import { CommingSoonModal } from "./CommingSoonModal"
import { ModalEditHashtag } from "@/app/containers/users/common/modal/ModalEditHashtag"
import { ModalInviteCode } from "@/app/containers/users/common/modal/ModalInviteCode"
import { GiftcodeModalContainer } from "@/app/containers/giftcode/modal/GiftcodeModalContainer"

export const ModalContainer = () => {
  return (
    <>
      <LoginModal />
      <SelectCreatePostModal />
      <CommingSoonModal />
      <ModalEditHashtag />
      <ModalInviteCode />
      <GiftcodeModalContainer />
    </>
  )
}
