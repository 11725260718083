import React, { useEffect, useMemo } from "react"
import { DataEmpty } from "@/app/components/data-empty"
import { Button } from "@/app/components/ui/button"
import { useQueryGetSuggestFriend } from "@/app/services/query-fn/user/user-friend-query"
import { useTranslation } from "next-i18next"
import { useMutatonAddFriend } from "@/app/services/query-fn/user/user-friend-mutation"
import { IUser } from "@/app/interfaces"
import { useQueryClient } from "@tanstack/react-query"
import { QUERY_KEY } from "@/app/configs"
import { OnlyShowAvatar } from "@/app/components/app-avatar/OnlyShowAvatar"
import { useInView } from "react-intersection-observer"

export const ListProposeFriend = ({ active = false }: { active: boolean }) => {
  const { t } = useTranslation()
  const { data: listFriend, fetchNextPage } = useQueryGetSuggestFriend(!!active)
  const { ref, inView } = useInView()

  useEffect(() => {
    if (inView) {
      fetchNextPage()
    }
  }, [fetchNextPage, inView])

  const listWithoutAuthenticate = useMemo(() => {
    return listFriend?.pages?.flat()?.length
      ? listFriend?.pages?.flat()?.filter((item) => !item?.authentic)
      : []
  }, [listFriend])
  const { mutate } = useMutatonAddFriend()

  const queryClent = useQueryClient()

  const onRequestAddFrend = (user: IUser) => {
    mutate(
      {
        friend_id: user?.id
      },
      {
        onSuccess(data, variables, context) {
          queryClent.refetchQueries({
            queryKey: [QUERY_KEY.LIST_FRIEND]
          })
          queryClent.refetchQueries({
            queryKey: [QUERY_KEY.LIST_SUGGEST_FRIEND]
          })
        }
      }
    )
  }

  return (
    <>
      {listWithoutAuthenticate?.length ? (
        <div className="w-full space-y-[24px]">
          {listWithoutAuthenticate?.map((item, index) => (
            <div
              className="flex items-center justify-between w-full"
              key={index}
            >
              <div className="flex flex-1 gap-4">
                <OnlyShowAvatar size={40} avatarUser={item} />
                <p className="flex items-center text-base Nunito-700 text-bg-01 dark:text-white">
                  {item?.fullname}
                </p>
              </div>

              {!item?.authentic && (
                <Button
                  variant="none"
                  className="outline-none bg-main-01 h-[27px] px-[12px] flex items-center justify-center text-white rounded-[8px]"
                  onClick={() => onRequestAddFrend(item)}
                >
                  {t("profile.addfriend")}
                </Button>
              )}
            </div>
          ))}
          <button ref={ref} className="opacity-0">
            Load more
          </button>
        </div>
      ) : (
        <div className="w-full flex items-center justify-center h-[200px]">
          <DataEmpty title={t("profile.no_data")} />
        </div>
      )}
    </>
  )
}
