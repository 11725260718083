export const IconGold = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <path
        d="M12 12C14.2091 9.79086 14.2091 6.20914 12 4C9.79086 1.79086 6.20914 1.79086 4 4C1.79086 6.20914 1.79086 9.79086 4 12C6.20914 14.2091 9.79086 14.2091 12 12Z"
        fill="#E9B522"
      />
      <path
        d="M8.00045 14.7657C4.26963 14.7657 1.23438 11.7304 1.23438 7.99962C1.23438 4.26881 4.26963 1.23358 8.00045 1.23358C11.7313 1.23358 14.7665 4.26881 14.7665 7.99962C14.7665 11.7304 11.7313 14.7657 8.00045 14.7657ZM8.00045 2.34274C4.88124 2.34274 2.34354 4.88041 2.34354 7.99962C2.34354 11.1188 4.88124 13.6566 8.00045 13.6566C11.1197 13.6566 13.6574 11.1189 13.6574 7.99962C13.6574 4.88038 11.1197 2.34274 8.00045 2.34274Z"
        fill="#F7DE61"
      />
      <path
        d="M1.64696 7.99962C1.64696 4.33786 4.57099 1.34648 8.20672 1.23701C8.13818 1.23494 8.06947 1.23358 8.00045 1.23358C4.26963 1.23358 1.23438 4.26881 1.23438 7.99962C1.23438 11.7304 4.26963 14.7657 8.00045 14.7657C8.0695 14.7657 8.13821 14.7643 8.20672 14.7623C4.57099 14.6528 1.64696 11.6614 1.64696 7.99962Z"
        fill="#FFF2BD"
      />
      <path
        d="M7.99996 2.34296C7.93949 2.34296 7.87936 2.34425 7.81934 2.34615C10.8553 2.44187 13.2956 4.94106 13.2956 7.99983C13.2956 11.0586 10.8553 13.5578 7.81934 13.6536C7.87933 13.6555 7.93949 13.6568 7.99996 13.6568C11.1192 13.6568 13.6569 11.1191 13.6569 7.99983C13.6569 4.88059 11.1192 2.34296 7.99996 2.34296Z"
        fill="#CE9811"
      />
      <g filter="url(#filter0_d_12475_61912)">
        <path
          d="M8.12033 11.452C7.37988 11.452 6.75144 11.312 6.23499 11.032C5.72477 10.752 5.33588 10.36 5.06833 9.85602C4.80077 9.35202 4.66699 8.76091 4.66699 8.08269C4.66699 7.56002 4.74477 7.09024 4.90033 6.67335C5.0621 6.25024 5.29233 5.89246 5.59099 5.60002C5.89588 5.30135 6.26299 5.07113 6.69233 4.90935C7.12788 4.74758 7.61944 4.66669 8.16699 4.66669C8.50299 4.66669 8.83899 4.70402 9.17499 4.77869C9.51099 4.85335 9.83455 4.9778 10.1457 5.15202C10.2763 5.22669 10.3634 5.32313 10.407 5.44135C10.4568 5.55335 10.4692 5.67469 10.4443 5.80535C10.4257 5.9298 10.3759 6.0418 10.295 6.14135C10.2203 6.23469 10.1208 6.29691 9.99633 6.32802C9.8781 6.35291 9.74121 6.32802 9.58566 6.25335C9.38033 6.14135 9.16255 6.06046 8.93233 6.01069C8.7021 5.95469 8.4501 5.92669 8.17633 5.92669C7.73455 5.92669 7.36433 6.01069 7.06566 6.17869C6.76699 6.34047 6.54299 6.58313 6.39366 6.90669C6.25055 7.22402 6.17899 7.61602 6.17899 8.08269C6.17899 8.7858 6.34699 9.3178 6.68299 9.67869C7.02521 10.0396 7.52921 10.22 8.19499 10.22C8.41899 10.22 8.64921 10.1982 8.88566 10.1547C9.1221 10.1111 9.35544 10.0489 9.58566 9.96802L9.31499 10.556V8.76402H8.47499C8.28833 8.76402 8.14521 8.71735 8.04566 8.62402C7.9461 8.53069 7.89633 8.40313 7.89633 8.24135C7.89633 8.07335 7.9461 7.9458 8.04566 7.85869C8.14521 7.76535 8.28833 7.71869 8.47499 7.71869H9.94033C10.127 7.71869 10.267 7.76847 10.3603 7.86802C10.4599 7.96758 10.5097 8.11069 10.5097 8.29735V10.4627C10.5097 10.6245 10.4785 10.7614 10.4163 10.8734C10.3541 10.9854 10.2483 11.0662 10.099 11.116C9.81899 11.2156 9.50477 11.2965 9.15633 11.3587C8.80788 11.4209 8.46255 11.452 8.12033 11.452Z"
          fill="#F7DE61"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_12475_61912"
          x="4.66699"
          y="4.66669"
          width="6.34277"
          height="7.28534"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.5" dy="0.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.807843 0 0 0 0 0.596078 0 0 0 0 0.0666667 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12475_61912"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12475_61912"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}
