import { DataEmpty } from "@/app/components/data-empty"
import Pagination from "@/app/components/pagination"
import { DATE_FORMAT_2, PACKAGE_TYPE, QUERY_KEY } from "@/app/configs"
import { PackageProvider } from "@/app/context/PackageContext"
import { IGift } from "@/app/interfaces/IReward"
import { useQueryGetListPackage } from "@/app/services/query-fn/reward-center/package/package-query"
import moment from "moment"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { PackageFilter } from "./PackageFilter"
import { PackageModalContainer } from "./PackageModalContainer"
import { AppotaCardItem } from "./card-item/AppotaCardItem"
import { GiftcodeItem } from "./card-item/GiftcodeItem"
import { OtherCardItem } from "./card-item/OtherCardItem"
import { VoucherCardItem } from "./card-item/VoucherCardItem"
import { convertData, listTab } from "./function"
import { useQueryClient } from "@tanstack/react-query"
import { Skeleton } from "@/app/components/ui/skeleton"

const initialFilter = {
  start_date: undefined as unknown as any,
  end_date: undefined as unknown as any,
  status: ""
}

export const PackageContainer = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const router = useRouter()
  const [dataSelected, setDataSelected] = useState<IGift>()
  const methods = useForm<typeof initialFilter>({
    defaultValues: initialFilter
  })

  const [curtab, setCurtab] = useState<string>("")
  const [params, setParams] = useState({
    item_type: "",
    status: "",
    start_date: "",
    end_date: "",
    limit: 12,
    page: 1,
    game_id: ""
  })
  const { data: listItem, isLoading } = useQueryGetListPackage({
    ...params,
    start_date: params.start_date
      ? moment(params.start_date).format(DATE_FORMAT_2)
      : "",
    end_date: params.end_date
      ? moment(params.end_date).format(DATE_FORMAT_2)
      : ""
  })

  useEffect(() => {
    if (!!router?.query?.item) {
      setCurtab(String(router?.query?.item))
      const _value_type =
        listTab(t).find((item) => item.tab === router?.query?.item)?.value || ""
      setParams({ ...params, item_type: _value_type })
    }
  }, [router])

  const triggerContent = (item: any) => {
    switch (item.type) {
      case PACKAGE_TYPE.APPOTA_CARD: {
        return <AppotaCardItem item={item} key={item.id} />
      }
      case PACKAGE_TYPE.VOUCHER:
      case PACKAGE_TYPE.GIFT_VOUCHER: {
        return <VoucherCardItem item={item} key={item.id} />
      }
      case PACKAGE_TYPE.AVATAR:
      case PACKAGE_TYPE.STICKER:
      case PACKAGE_TYPE.GIFT_ITEM: {
        return <OtherCardItem item={item} key={item.id} />
      }
      default: {
        return <GiftcodeItem item={item} key={item.id} />
      }
    }
  }

  const resetQuery = () => {
    queryClient.refetchQueries({
      queryKey: [
        QUERY_KEY.PACKAGE_LIST,
        {
          ...params,
          start_date: params.start_date
            ? moment(params.start_date).format(DATE_FORMAT_2)
            : "",
          end_date: params.end_date
            ? moment(params.end_date).format(DATE_FORMAT_2)
            : ""
        }
      ]
    })
  }

  const renderLoading = () => {
    return (
      <div className="flex flex-col w-full h-[265px] p-[8px] bg-white dark:bg-white/10 rounded-[12px] xs:rounded-[8px] border border-black/10">
        <Skeleton className="w-[full] rounded-[12px] aspect-square xs:rounded-[8px] bg-[#EAEAEA] dark:bg-[#FFFFFF]/10" />
        <div className="space-y-2 mt-3">
          <Skeleton className="h-[16px] w-[60%] bg-[#EAEAEA] dark:bg-[#FFFFFF]/10" />
          <Skeleton className="h-[32px] w-[50%] bg-[#EAEAEA] dark:bg-[#FFFFFF]/10" />
        </div>
      </div>
    )
  }

  return (
    <PackageProvider>
      <div>
        <div className="flex flex-col md:flex-row justify-between md:items-center mt-[16px] gap-[16px]">
          <div className="flex gap-[8px] max-w-[576px] overflow-scroll">
            {listTab(t).map((item) => {
              return (
                <div
                  key={item.value}
                  className={`px-[16px] py-[4px] border text-[14px] leading-[19px] rounded-[8px] cursor-pointer whitespace-nowrap ${
                    curtab === item.tab
                      ? "bg-main-01 text-white border-main-01 Nunito-500"
                      : "text-neutral border-black/10 dark:text-neutral-08 dark:border-neutral-08 Nunito-400"
                  }`}
                  onClick={() => {
                    setParams({
                      ...params,
                      item_type: item.value,
                      page: 1,
                      game_id: ""
                    })
                    setCurtab(item.tab)
                    router.replace({
                      pathname: router.pathname,
                      query: {
                        ...router.query,
                        item: item.tab
                      }
                    })
                  }}
                >
                  {item.title}
                </div>
              )
            })}
          </div>

          <FormProvider {...methods}>
            <PackageFilter params={params} setParams={setParams} />
          </FormProvider>
        </div>
        <div className="mt-[24px] grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 pc:grid-cols-6 gap-[16px] pc:gap-[24px]">
          {convertData(listItem?.data)?.map((item: any, index: number) => {
            return triggerContent(item)
          })}

          {isLoading &&
            Array(12)
              .fill(1)
              .map((number) => {
                return renderLoading()
              })}

          {!isLoading && convertData(listItem?.data).length === 0 && (
            <div className="mt-[60px] col-span-6">
              <DataEmpty title={t("profile.no_data")} />
            </div>
          )}

          <div className="col-span-2 sm:col-span-3 md:col-span-4 lg:col-span-5 pc:col-span-6 flex justify-end mt-[8px]">
            <Pagination
              totalCount={(listItem as any)?.totalItems || 0}
              onPageChange={(page) => setParams({ ...params, page: page })}
              pageSize={12}
              currentPage={params.page}
            />
          </div>
        </div>
      </div>
      <PackageModalContainer
        listItem={convertData(listItem?.data)}
        resetQuery={resetQuery}
      />
    </PackageProvider>
  )
}
