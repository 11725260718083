import IMetaPage from "@interfaces/IMetaPage"
export const GA_ID = process.env.NEXT_PUBLIC_GTAGS_ID
export const FACEBOOK_PIXEL_ID = "2582194471816724"
const URI = process.env.NEXT_PUBLIC_HOST_URL ?? ""
export const DATE_FORMAT = "DD/MM/YYYY"
export const DATE_FORMAT_2 = "DD-MM-YYYY"
export const DATE_FORMAT_FULL = "HH:mm:ss DD/MM/YYYY"
export const DATE_FORMAT_3 = "HH:mm DD/MM/YYYY"
export const DATE_FORMAT_2_REVERT = "YYYY-MM-DD"
export const DATE_FORMAT_HOUR = "h[h] DD/MM/YYYY"

export const LANGUAGE_OPTION = {
  VIETNAMESE: "vi",
  ENGLISH: "en"
}

export const OPEN_MODAL = {
  SELECT_CREATE_POST_MODAL: "select-create-post-modal",
  LOGIN_MODAL: "login-modal",
  SHARE_MODAL: "share-modal",
  VIEW_MEDIA: "view-media",
  COMMINGSOON_MODAL: "commingsoon_modal",
  FRIEND_MODAL: "friend-modal",
  EDIT_HASHTAG: "edit-hashtag",
  SHARE_REFER_CODE: "share-refer-code"
}

export const MENU_TAB = {
  GAME_TAB: "/",
  NEWS_TAB: "/news",
  STORE_TAB: "/store",
  USER: "/user/[id]",
  SELECT_CREATE_POST: `/?modal=${OPEN_MODAL.SELECT_CREATE_POST_MODAL}`,
  OPTION_PROFILE: "/option-profile"
}

export const meta: IMetaPage = {
  title: "Playee - Entertaining the world",
  desc: "Tham gia cộng đồng Playee, khám phá thế giới game vô tận",
  keyword: "",
  url: URI,
  site_name: "Playee",
  image: URI + "/img/playee_portal.png",
  canonical: URI
}

export const QUERY_KEY = {
  // common
  SEARCH: "search",
  LOGIN_FACEBOOK: "login-facebook",
  LOGIN_GOOGLE: "login-google",
  LOGIN_NORMAL: "login-normal",
  GET_URL_LOGIN_FACEBOOK: "get-url-login-facebook",
  GET_URL_LOGIN_GOOGLE: "get-url-login-google",
  PROFILE_INFO: "profile-info",
  NOTIFICATION_LIST: "notification-list",

  // Game
  LIST_GAME: "list-game",
  LIST_GAME_BANNER: "list-game-banner",
  LIST_CATEGORY: "list-category",
  GAME_DETAIL: "game-detail",
  FOLLOW_GAME: "follow-game",
  REGISTER_GAME: "register-game",
  GAME_REVIEW_LIST: "game-review-list",
  LIKE_REVIEW: "like-review",
  RATE_GAME: "rate-game",
  REPLY_REVIEW_GAME: "reply-review-game",
  DELETE_REVIEW_GAME: "delete-review-game",
  DELETE_REPLY_REVIEW_GAME: "delete-reply-review-game",
  LIST_GAME_INFINITE: "list-game-infinite",

  // News Events
  LIST_NEWS: "list-news",
  LIST_EVENT: "list-event",
  NEWS_DETAIL: "news-detail",
  LIST_COMMENT: "list-comments",
  CREATE_NEWS: "create-news",
  SAVE_DRAFT: "save-draft",
  ADD_COMMENT: "add-comment",
  LIKE_POST: "like-post",
  LIKE_COMMENT: "like-comment",
  REPLY_COMMENT: "reply-comment",
  LIST_REPLY: "list-reply",
  DELETE_COMMENT: "delete-comment",
  UPDATE_COMMENT: "update-comment",
  GET_STICKER_USER: "get-sticker-user",
  CHECK_DRAFT: "check-draft",
  CHECK_DRAFT_EVENT: "check-draft-event",
  DELETE_DRAFT: "delete-draft",
  UPLOAD_MEDIA: "upload-media",
  DELETE_NEWS: "delete-news",
  CREATE_EVENT: "create-event",
  EVENT_DETAIL: "event-detail",
  DELETE_EVENT: "delete-event",
  EDIT_NEWS: "edit-news",
  EDIT_EVENT: "edit-event",
  JOIN_EVENT: "join-event",
  DELETE_DRAFT_EVENT: "delete-draft-event",
  SAVE_DRAFT_EVENTS: "save-draft-event",
  LIST_RELATED_NEWS: "list-related-news",
  LIST_HOT: "list-hot",
  NEWS_FOR_YOU: "news-for-you",
  LIST_NEWS_LATEST: "list-news-latest",
  LIST_NEWS_BY_HASHTAG: "list-news-by-hashtag",
  LIST_EVENT_BY_TYPE: "list-event-by-type",
  LIST_RELATED_NEWS_BY_GAME: "list-related-news-by-game",
  LIST_RELATED_EVENT_BY_GAME: "list-related-event-by-game",
  LIST_RELATED_EVENT: "list-related-event",
  REG_NOTIFICATION_EVENT: "reg-notification-event",
  LIST_NEWS_BY_USER: "list-news-by-user",
  LIST_NEWS_BY_OTHER_USER: "list-news-by-other-user",
  MY_NEWS_BY_FILTER: "my-news-by-filter",
  MY_EVENT_BY_FILTER: "my-event-by-filter",
  LIST_EVENT_BY_HASHTAG: "list-event-by-hashtag",

  // profile
  FOLLOW_PARTNER: "follow-partner",
  LIST_FOLLOWING: "list-following",
  UPDATE_LANGUAGE: "update-language",
  UPLOAD_AVATAR: "upload-avatar",
  UPDATE_PROFILE: "update-profile",
  STORE_NEWS: "store-new",
  REMOVE_STORE_NEWS: "remove-store-news",
  LIST_STORED_NEWS: "list-stored-news",
  HOT_TOPIC: "hot-topic",
  LIST_HOBBIES: "list-hobbies",
  UPDATE_INTEREST_TOPIC: "update-interest-topic",
  TOPUP_TRANSACTION: "topup-transaction",
  CARD_TRANSACTION: "card-transaction",
  POINT_HISTORY: "point-history",
  UPDATE_INVITE_CODE: "update-invite-code",
  NEWS_DETAIL_FOR_EDIT: "news-detail-for-edit",
  EVENT_DETAIL_FOR_EDIT: "event-detail-for-edit",
  ANALYTIC_GET_TOTAL_NEWS: "analytic-get-total-news",
  ANALYTIC_GET_TOTAL_EVENT: "analytic-get-total-event",
  ANALYTIC_GET_TOTAL_USER_FOLLOW_PARTNER:
    "analytic-get-total-user-follow-partner",
  ANALYTIC_GET_TOTAL_USER_JOINED_EVENT: "analytic-get-total-user-joined-event",
  ANALYTIC_NEWS: "analytic-news",
  ANALYTIC_EVENT: "analytic-event",
  GET_PROFILE_BY_ID: "get-profile-by-id",
  ANALYTIC_GET_TOTAL_LIKE_NEWS: "analytic-get-total-like-news",
  ANALYTIC_GET_TOTAL_COMMENT_NEWS: "analytic-get-total-comment-news",
  LIST_FRIEND: "list-friend",
  LIST_FRIEND_WITH_SEARCH: "list-friend-with-search",
  LIST_SUGGEST_FRIEND: "list-suggest-friend",
  ADD_FRIEND: "add-friend",
  SEND_FRIEND: "send-friend",
  ACTION_REQUEST_FRIEND: "action-request-friend",
  UN_FRIEND: "un-friend",
  LIST_REQUEST_FRIEND: " list-request-friend",
  ANALYTIC_CHART_DATA: "analytic-chart-data",
  LIST_SUGGEST_FRIEND_BY_GAME: "list-suggest-friend-by-game",
  OTHER_USER_EVENT_BY_FILTER: "other-user-event-by-filter",
  OTHER_USER_NEWS_BY_FILTER: "other-user-news-by-filter",
  CANCEL_SENDING_REQUEST: "cancel-sending-request",

  //store
  LIST_PRODUCT: "list-product",
  TOPUP_LIST_GAME: "topup-list-game",
  TOPUP_CHECK_PIN: "topup-check-pin",
  TOPUP_DO_LA_CHARGING: "topup-do-la-charging",
  TOPUP_LA_TRANSACTION: "topup-la-transaction",
  ALL_PROVINCE: "all-province",
  CREATE_ADDRESS: "create-address",

  // reward-center
  LIST_SHOP_ITEM: "list-shop-item",
  BUY_ITEM_SHOP: "buy-item-shop",
  LIST_MISSION: "list-mission",
  LIST_GAME_FOLLOW: "list-game-follow",
  PACKAGE_LIST: "package-list",
  REFERRAL_HISTORY: "referral-history",
  USE_ITEM: "use-item",
  LIST_GAME_BY_GIFTCODE: "list-game-by-giftcode",

  // help-center
  GET_LIST_CATEGORY: "get-list-category",
  SUBMIT_TICKET: "submit-ticket",
  GET_LIST_TICKET: "get-list-ticket",

  // merchandise

  MERCHANDISE_LIST: "merchandise-list",
  MERCHANDISE_CATEGORY_LIST: "merchandise-category-list",
  MERCHANDISE_GAME_LIST: "merchandise-game-list",
  MERCHANDISE_CART: "merchandise-cart",
  MERCHANDISE_DETAIL: "merchandise-detail",
  MERCHANDISE_METHOD: "merchandise-method",
  MERCHANDISE_ADDRESS: "merchandise-address",
  BUY_NOW_MERCHANDISE: "buy-now-merchandise",
  CREATE_NOW_PAYMENT_MERCHANDISE: "create-now-payment-merchandise",
  ADD_TO_CART_MERCHANDISE: "add-to-cart-merchandise",
  UPDATE_CART_MERCHANDISE: "update-cart-merchandise",
  UPDATE_QUICK_CART_MERCHANDISE: "update-quick-cart-merchandise",
  DELETE_CART_MERCHANDISE: "delete-cart-merchandise",
  DESTROY_ORDER: "destroy-order",
  DELETE_ADDRESS_MERCHANDISE: "delete-address-merchandise",
  UPDATE_ADDRESS_MERCHANDISE: "update-address-merchandise",
  CREATE_PAYMENT_MERCHANDISE: "create-payment-merchandise",
  CALLBACK_PAYMENT_MERCHANDISE: "callback-payment-merchandise",
  CALLBACK_ONEPAY_MERCHANDISE: "callback-onepay-merchandise",
  PAYMENT_TRANSACTION_MERCHANDISE: "payment-transaction-merchandise",
  MERCHANDISE_PAYMENT_FEE_SHIP: "merchandise-payment-fee-ship",
  MERCHANDISE_HISTORY: "merchandise-history",

  //giftcode
  LIST_GIFTCODE: "list-giftcode",
  LIST_GAME_GIFTCODE: "list-game-giftcode",
  LIST_SERVER_GIFTCODE: "list-server-giftcode",
  LIST_ROLE_GIFTCODE: "list-role-giftcode",
  GIFTCODE_DETAIL: "giftcode-detail",
  GIFTCODE_INFO: "giftcode-info",
  USE_GIFTCODE: "use-giftcode"
}

export const STALE_TIME = 60 * 1000
export const MAX_LENGTH_TITLE = 100
export const COMMENT_LIMIT_SIZE = 5
export const REPLY_LIMIT_SIZE = 10
export const LIMIT_QUANTITY = 20
export const LIMIT_PAGE_POST = 10
export const SPLIT_URL_STRING: string = "/Gifs_"

export const LIST_FILE_TYPE_IMAGE: string[] = [
  "png",
  "jpeg",
  "jpg",
  "svg",
  "gif"
]
export const NOTIFICATION_TYPE = {
  COMMENT: "comment",
  REPLY_COMMENT: "reply_comment",
  LIKE: "like",
  TASK: "task",
  SPIN: "spin",
  SHOP: "shop",
  EVENT: "event",
  TICKET: "ticket",
  GROUP_NOTI: "group_noti",
  PURCHASE: "purchase",
  PAYMENT_RESULT: "payment_result",
  CREATE_ORDER_RESULT: "create_order_result",
  TOPUP_GAME: "topup_game",
  SPIN_GAME: "spin_game",
  TOPUP_GAME_EVENT: "topup_game_event",
  REG_NOTI_EVEMT: "reg_noti_event",
  REQUEST_FRIEND: "request_friend",
  ACCEPT_FRIEND: "accept_request_friend",
  BAG_EXPIRED: "bag_expired",
  EVENT_MERCHANDISE: "event_merchandise",
  SEND_GIFT_FRIEND: "send_gift_friend"
}

export const GC_TYPE = {
  BY_GAME: "by-game",
  FOR_YOU: "for-you",
  IS_HOT: "is-hot",
  AVAILABLE: "available"
}

export const MISSION_TYPE = {
  CHECKIN: "checkin",
  ACTIVATE: "activate",
  INVITE: "invite",
  INTERACTIVE: "interaction",
  VERIFY_ACCOUNT: "verify_account"
}

export const INTERACTIVE_TYPE = {
  REVIEW_GAME: "comment_review_game",
  RATE_GAME: "rate_game",
  POST_CONTENT: "post_content",
  JOIN_GROUP: "join_group",
  COMMENT_CONTENT: "comment_content",
  FOLLOW_USER: "follow_user"
}

export const REWARD_TAB = {
  MY_ORDER: "my-order",
  SHOP_GOLD: "shop-gold",
  EXCHANGE_ITEM: "exchange-item",
  REWARD_CENTER: "reward-center"
}

export const EMOTION_ELEMENT_HEIGHT = 454

export enum CreateType {
  CREATE_POST = "create-news",
  CREATE_EVENT = "create-event"
}
export const vnMonth = [
  "Tháng 1",
  "Tháng 2",
  "Tháng 3",
  "Tháng 4",
  "Tháng 5",
  "Tháng 6",
  "Tháng 7",
  "Tháng 8",
  "Tháng 9",
  "Tháng 10",
  "Tháng 11",
  "Tháng 12"
]

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
]

export const FROM_YEAR = 2000
export const END_YEAR = 2100
export const TIMEOUT_SPIN = 4000

export const SPIN_TYPE = {
  POINT: "point",
  GIFTCODE: "giftcode",
  LOST_TURN: "lost_turn"
}

export const PACKAGE_TYPE = {
  AVATAR: "avatar",
  GIFTCODE: "giftcode",
  GC: "gc",
  STICKER: "sticker",
  APPOTA_CARD: "appota_card",
  VOUCHER: "voucher",
  GIFT_VOUCHER: "gift_voucher",
  GIFT_ITEM: "gift_item"
}

export const GIFTCODE_TYPE = {
  USED: "done",
  PENDING: "pending"
}

export const TAB_ITEM_PACKAGE_TYPE = {
  GIFTCODE: "giftcode",
  CARD: "appota_card",
  VOUCHER: "voucher",
  OTHER: "other"
}

export const _24H_TIME_STAMP = 86400
export const _15M_TIME_STAMP = 900
