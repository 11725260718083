import { useTranslation } from "next-i18next"
import React, { useContext, useEffect, useState } from "react"

import { Dialog, DialogClose, DialogContent } from "@/app/components/ui/dialog"
import { IconClose } from "@/app/components/icon-tsx/IconClose"
import { Button } from "@/app/components/ui/button"
import { useToast } from "@/app/components/ui/use-toast"
import Link from "next/link"
import { Input } from "@/app/components/ui/input"
import { AppContext } from "@/app/context"
import { useMutationUpdateInviteCode } from "@/app/services/query-fn/user/setting-mutation"
import { useQueryClient } from "@tanstack/react-query"
import { OPEN_MODAL, QUERY_KEY } from "@/app/configs"
import { useRouter } from "next/router"

export const ModalInviteCode = () => {
  const { userInfo, openModal, setOpenModal } = useContext(AppContext)
  const { t } = useTranslation()
  const { toast } = useToast()
  const router = useRouter()
  const queryClient = useQueryClient()
  const [value, setValue] = useState<string>("")
  const { mutate } = useMutationUpdateInviteCode()
  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e?.target?.value)
  }
  const onCopyLink = () => {
    const code = `${process.env.NEXT_PUBLIC_HOST_URL}?referent_code=${userInfo?.referent_code}`
    navigator.clipboard.writeText(code)
    toast({
      variant: "success",
      title: t("message.copied")
    })
  }

  useEffect(() => {
    const refCode = router?.query?.referent_code
    if (!!refCode) {
      setValue(String(refCode))
    }
  }, [router])

  const handleClose = () => {
    const { referent_code, ...otherQuery } = router?.query
    setValue("")
    setOpenModal(null)
    router.push({
      pathname: router.pathname,
      query: otherQuery
    })
  }
  const onSubmitInviteCode = () => {
    mutate(
      { invite_code: value?.trim() },
      {
        onSuccess(data, variables, context) {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY.PROFILE_INFO],
            refetchType: "active"
          })
          handleClose()
        },
        onError(error: any, variables, context) {
          toast({ title: error?.data?.message, variant: "error" })
        }
      }
    )
  }

  return (
    <Dialog
      open={openModal === OPEN_MODAL.SHARE_REFER_CODE}
      onOpenChange={handleClose}
    >
      <DialogContent className="w-fit rounded-[24px] sm:rounded-[24px] md:min-w-[468px] md:max-w-[473px] px-6 py-4 dark:border-0 dark:bg-[#1A1925] min-w-[300px]">
        <DialogClose className="absolute transition-opacity rounded-sm right-6 top-3 focus:outline-none disabled:pointer-events-none"></DialogClose>
        <div className="flex flex-col justify-center w-full">
          <div className="flex items-center justify-between w-full">
            <p className="text-bg-01 Nunito-700 text-base mb-[40px dark:text-main-white">
              {t("profile.invite_code_title")}
            </p>
            <div
              className="w-[32px] h-[32px] rounded-[8px] flex items-center justify-center bg-bg-button dark:bg-[#ECECEE]/10 cursor-pointer"
              onClick={handleClose}
            >
              <IconClose />
            </div>
          </div>
          <div className="w-full my-[24px] px-4 py-1 md:py-[13px] flex gap-3 border-[1px] border-black/10 rounded-2xl dark:bg-[#fff]/10 justify-between items-center">
            <Input
              placeholder={t("common.invite_code_placeholder")}
              className="border-0 p-0 max-h-[32px] dark:bg-transparent"
              value={value}
              onChange={onChangeValue}
            />
            <Button
              variant="main"
              className="px-[12px] py-[4px] max-h-[32px]"
              disabled={!value}
              onClick={onSubmitInviteCode}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 16L18 12M18 12L14 8M18 12L6 12"
                  stroke="#FAF8FF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Button>
          </div>
          <div className="flex items-center justify-between w-full border-t-[1px] pt-[24px] border-black/10">
            {typeof window !== "undefined" && (
              <div className="flex w-full gap-4">
                {/* Facebook */}
                <Link
                  href={`https://www.facebook.com/sharer/sharer.php?u=${process.env.NEXT_PUBLIC_HOST_URL}?referent_code=${userInfo?.referent_code}`}
                  target="_blank"
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="cursor-pointer"
                  >
                    <rect width="48" height="48" rx="16" fill="#337FFF" />
                    <path
                      d="M29.8737 25.5625L30.5576 21.2172H26.3447V18.3927C26.3447 17.2046 26.9329 16.0436 28.8136 16.0436H30.7559V12.3433C29.6248 12.1629 28.4819 12.0653 27.3364 12.0513C23.869 12.0513 21.6053 14.1357 21.6053 17.9039V21.2172H17.7617V25.5625H21.6053V36.0727H26.3447V25.5625H29.8737Z"
                      fill="white"
                    />
                  </svg>
                </Link>

                {/* Telegram */}
                <Link
                  href={`https://t.me/share/url?url=${process.env.NEXT_PUBLIC_HOST_URL}?referent_code=${userInfo?.referent_code}`}
                  target="_blank"
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="cursor-pointer"
                  >
                    <rect width="48" height="48" rx="16" fill="#34AADF" />
                    <path
                      d="M13.3537 23.1498C13.3537 23.1498 23.1191 19.0367 26.506 17.5884C27.8043 17.0092 32.2072 15.1553 32.2072 15.1553C32.2072 15.1553 34.2393 14.3443 34.07 16.314C34.0135 17.125 33.5619 19.9636 33.1104 23.0339C32.433 27.3788 31.6992 32.129 31.6992 32.129C31.6992 32.129 31.5863 33.4615 30.6267 33.6932C29.6671 33.9249 28.0865 32.8822 27.8043 32.6504C27.5784 32.4767 23.5707 29.8698 22.1031 28.5953C21.7079 28.2477 21.2564 27.5526 22.1595 26.7415C24.1916 24.8298 26.6188 22.4547 28.0865 20.9485C28.7639 20.2533 29.4412 18.6312 26.6188 20.6008C22.6111 23.4395 18.6598 26.1043 18.6598 26.1043C18.6598 26.1043 17.7566 26.6835 16.0632 26.1622C14.3697 25.6409 12.394 24.9456 12.394 24.9456C12.394 24.9456 11.0394 24.0767 13.3537 23.1498Z"
                      fill="white"
                    />
                  </svg>
                </Link>

                {/* x-twitter */}
                <Link
                  href={`https://twitter.com/intent/tweet?text=${process.env.NEXT_PUBLIC_HOST_URL}?referent_code=${userInfo?.referent_code}`}
                  target="_blank"
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="cursor-pointer"
                  >
                    <rect width="48" height="48" rx="16" fill="#0F1419" />
                    <path
                      d="M25.905 22.4695L33.3512 14H31.5869L25.1184 21.3524L19.9558 14H14L21.8086 25.1192L14 34H15.7643L22.591 26.2338L28.0442 34H34L25.905 22.4695ZM23.4877 25.2168L22.6954 24.1089L16.4005 15.3015H19.1108L24.1925 22.412L24.9815 23.5199L31.5861 32.7619H28.8758L23.4877 25.2168Z"
                      fill="white"
                    />
                  </svg>
                </Link>
              </div>
            )}
            <Button variant="none" onClick={onCopyLink}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.1211 9.87866L9.87845 14.1213"
                  stroke="#7673E6"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
                <path
                  d="M10.332 7.00003L11.9987 5.33336C13.8396 3.49239 16.8244 3.49239 18.6654 5.33335V5.33335C20.5063 7.17431 20.5063 10.1591 18.6654 12.0001L16.9987 13.6667"
                  stroke="#7673E6"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
                <path
                  d="M13.668 17L12.0013 18.6666C10.1604 20.5076 7.17558 20.5076 5.33462 18.6666V18.6666C3.49365 16.8257 3.49365 13.8409 5.33462 11.9999L7.00129 10.3333"
                  stroke="#7673E6"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
              <span className="text-sm text-bg-01 Nunito-700 dark:text-white">
                &nbsp;&nbsp;{t("reward.copy")}
              </span>
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
