import { useEffect } from "react"
import Socket from "@services/Socket"
import { INotice } from "@interfaces/INotice"
import { isClient } from "../configs"

export const useSocket = ({
  event,
  handleEvent,
  dependences,
  nonAuthen,
  name,
  path,
  type = "listen",
  handleEventNotification
}: {
  event: string
  handleEvent: any
  dependences?: any
  nonAuthen?: boolean
  name?: string | null
  path?: string | null
  type?: "emit" | "listen"
  handleEventNotification?: (data: INotice) => void
}) => {
  const token = isClient ? window.localStorage.getItem("token") || "" : ""

  useEffect(() => {
    const socketIo = new Socket()
    const socketInstance = socketIo.getInstance({
      name,
      path,
      token
    })
    if (token || nonAuthen) {
      if (typeof event === "string") {
        if (type === "listen") {
          socketInstance.on(event, (msg: any) => {
            handleEvent(msg)
            if (event === "connection" && !!token) {
              socketInstance.emit("login", token)

              socketInstance.on("login", (msg: any) => {
                // console.log("login", msg)
              })

              socketInstance.on("notification", (data: any) => {
                handleEventNotification &&
                  !!data &&
                  handleEventNotification(data as INotice)
              })

              socketInstance.on("inbox", (res: any) => {
                // console.log("res", res)
              })
            }
          })
        } else {
          if (Array.isArray(handleEvent)) {
            socketInstance.emit(event, ...handleEvent)
          } else socketInstance.emit(event, handleEvent)
        }
      }
    }
    return () => {
      if (token || nonAuthen) {
        if (typeof event === "string") {
          socketInstance.off(event, handleEvent)
          socketInstance.off("notification", (data: any) => {
            handleEventNotification &&
              !!data &&
              handleEventNotification(data as INotice)
          })
          socketInstance.off("inbox", (res: any) => {
            // console.log("res", res)
          })
          socketInstance.off("login", (msg: any) => {
            // console.log("login", msg)
          })
          // socketInstance.off("login")
        }
      }
    }
  }, [token, ...(dependences || [])])
}
