import React, { FC, useState } from "react"
import provinces from "@/app/dummy/tinh_tp.json"
import districts from "@/app/dummy/quan_huyen.json"
import wards from "@/app/dummy/xa_phuong.json"
import { convertObjectToArray } from "@/app/utils"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "../ui/select"
import { useTranslation } from "next-i18next"
import { ICreateAddress } from "@/app/interfaces"

interface SelectAddressProps {
  params: ICreateAddress
  setParams: (params: ICreateAddress) => void
}

export const SelectAddress: FC<SelectAddressProps> = ({
  params,
  setParams
}) => {
  const { t } = useTranslation()
  const listProvinces = convertObjectToArray(provinces).sort((a, b) =>
    a.name.localeCompare(b.name)
  )
  const listDistricts = convertObjectToArray(districts).sort((a, b) =>
    a.name.localeCompare(b.name)
  )
  const listWards = convertObjectToArray(wards).sort((a, b) =>
    a.name.localeCompare(b.name)
  )

  const sortedProvices = listProvinces.sort((a, b) => {
    // Đưa phần tử có index là 10 lên đầu
    if (a.key === "01") return -1
    if (b.key === "01") return 1

    // Đưa phần tử có key là 20 đứng sau phần tử có key là 10
    if (a.key === "79") return -1
    if (b.key === "79") return 1

    // Giữ nguyên thứ tự cho các phần tử còn lại
    return 0
  })

  const onValueChange = (value: string, field: string) => {
    if (field === "city") {
      setParams({
        ...params,
        city: value,
        district: "",
        ward: ""
      })
    }
    if (field === "district") {
      if (!listWards?.find((item) => item?.parent_code === value)?.code) {
        setParams({
          ...params,
          city: params.city,
          district: value,
          ward: value
        })
      } else {
        setParams({
          ...params,
          city: params.city,
          district: value,
          ward: ""
        })
      }
    }
    if (field === "ward") {
      setParams({ ...params, ward: value })
    }
  }

  return (
    <div className="grid grid-cols-3 gap-2">
      <div>
        <Select
          value={params?.city}
          onValueChange={(value: string) => onValueChange(value, "city")}
        >
          <SelectTrigger className="w-full border-0 h-[44px] border-b-[1px] md:border text-sm Nunito-600 text-bg-01 dark:text-white dark:bg-white/10 dark:backdrop-blur-[20px] rounded-[16px] text-left">
            <SelectValue placeholder={t("merchandise.placeholder_province")} />
          </SelectTrigger>
          <SelectContent className="p-1 rounded-[16px]">
            {sortedProvices.map((item) => (
              <SelectItem
                value={item.code}
                key={item.code}
                className="p-3 rounded-[8px] hover:!bg-bg-disable hover:!text-main-01 text-base Nunito-600 focus:bg-bg-disable focus:text-main-01 dark:hover:!bg-[#F1F1FC]/[.1] dark:focus:!bg-[#F1F1FC]/[.1]"
              >
                {item.name_with_type}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      <div>
        <Select
          onValueChange={(value: string) => onValueChange(value, "district")}
          disabled={!params?.city}
          value={params?.district}
        >
          <SelectTrigger className="w-full border-0 h-[44px] border-b-[1px] md:border text-sm Nunito-600 text-bg-01 dark:text-white dark:bg-white/10 dark:backdrop-blur-[20px] rounded-[16px] text-left">
            <SelectValue placeholder={t("merchandise.placeholder_district")} />
          </SelectTrigger>
          <SelectContent className="p-1 rounded-[16px]">
            {listDistricts
              ?.filter((item) => item.parent_code === params.city)
              .map((item) => (
                <SelectItem
                  value={item.code}
                  key={item.code}
                  className="p-3 rounded-[8px] hover:!bg-bg-disable hover:!text-main-01 text-base Nunito-600 focus:bg-bg-disable focus:text-main-01 dark:hover:!bg-[#F1F1FC]/[.1] dark:focus:!bg-[#F1F1FC]/[.1]"
                >
                  {item.name_with_type}
                </SelectItem>
              ))}
          </SelectContent>
        </Select>
      </div>
      <div>
        {!listWards?.find((item) => item?.parent_code === params?.district)
          ?.code &&
          params?.district && (
            <div className="w-full h-[44px] p-[12px] rounded-[16px] border border-black/10 text-sm Nunito-600 text-bg-01 dark:text-white dark:bg-white/10 dark:backdrop-blur-[20px] truncate ...">
              {
                listDistricts?.find((item) => item.code === params.district)
                  ?.name_with_type
              }
            </div>
          )}
        <Select
          onValueChange={(value: string) => onValueChange(value, "ward")}
          disabled={!params?.district}
          value={params?.ward}
        >
          <SelectTrigger
            className={`${
              !listWards?.find((item) => item?.parent_code === params?.district)
                ?.code &&
              params?.district &&
              "hidden"
            } w-full h-[44px] border-0 border-b-[1px] md:border text-sm Nunito-600 text-bg-01 dark:text-white dark:bg-white/10 dark:backdrop-blur-[20px] rounded-[16px] text-left`}
          >
            <SelectValue placeholder={t("merchandise.placeholder_ward")} />
          </SelectTrigger>
          <SelectContent className="p-1 rounded-[16px]">
            {listWards
              ?.filter((item) => item?.parent_code === params?.district)
              ?.map((item) => (
                <SelectItem
                  value={item.code}
                  key={item.code}
                  className="p-3 rounded-[8px] hover:!bg-bg-disable hover:!text-main-01 text-base Nunito-600 focus:bg-bg-disable focus:text-main-01 dark:hover:!bg-[#F1F1FC]/[.1] dark:focus:!bg-[#F1F1FC]/[.1]"
                >
                  {item.name_with_type}
                </SelectItem>
              ))}
          </SelectContent>
        </Select>
      </div>
    </div>
  )
}
