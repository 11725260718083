import { IGameBannerRequest, IGameRequest } from "@/app/interfaces"
import { useInfiniteQuery, useQuery } from "@tanstack/react-query"
import { QUERY_KEY } from "@/app/configs"
import { IShopParams } from "@/app/interfaces/IReward"
import RewardService from "@/app/services/services/RewardService"

const getListShopItemFn = async (params: IShopParams) => {
  const data = await RewardService.getListShop(params)

  return data.data
}

export const useQueryGetListShopItem = (params: IShopParams) => {
  return useQuery({
    queryKey: [QUERY_KEY.LIST_SHOP_ITEM, params],
    queryFn: () => getListShopItemFn(params),
    enabled: !!params,
    refetchOnWindowFocus: false,
    retry: 0
  })
}
