import { createContext, useEffect, useState } from "react"

interface MobileContextProps {
  isMobile: boolean
  mobileOS: null
  isHorizontalIpad: boolean
  setIsHorizontalIpad: any
}

interface MobileProps {
  children: any
}

export const MobileContext = createContext<MobileContextProps>({
  isMobile: false,
  mobileOS: null,
  isHorizontalIpad: false,
  setIsHorizontalIpad: () => {}
})

export const MobileProvider = ({ children }: MobileProps) => {
  const [isMobile, setIsMobile] = useState(false)
  const [mobileOS, setmobileOS] = useState<any>(null)
  const [isHorizontalIpad, setIsHorizontalIpad] = useState<boolean>(false)
  useEffect(() => {
    const userAgent = navigator.userAgent
    setIsMobile(/iPhone|iPad|iPod|Android/i.test(userAgent))
    setmobileOS(getmobileOS(userAgent))
  }, [])

  return (
    <MobileContext.Provider
      value={{ isMobile, mobileOS, isHorizontalIpad, setIsHorizontalIpad }}
    >
      {children}
    </MobileContext.Provider>
  )
}

const getmobileOS = (userAgent: string) => {
  if (/Android/i.test(userAgent)) {
    return "Android"
  }
  if (/iPhone|iPad|iPod/i.test(userAgent)) {
    return "iOS"
  }
  return "PC"
}
