import { AppContext } from "@/app/context"
import React, { useContext } from "react"

export const IconCalendar = () => {
  const { themeApp } = useContext(AppContext)
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 8.38965C3 6.18051 4.79086 4.38965 7 4.38965H17C19.2091 4.38965 21 6.18051 21 8.38965V18.8896C21 21.0988 19.2091 22.8896 17 22.8896H7C4.79086 22.8896 3 21.0988 3 18.8896V8.38965Z"
        stroke={themeApp === "dark" ? "#fff" : "#100D28"}
        strokeWidth="1.5"
      />
      <path
        d="M3 9.88965H21"
        stroke={themeApp === "dark" ? "#fff" : "#100D28"}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M8 2.88965L8 5.88965"
        stroke={themeApp === "dark" ? "#fff" : "#100D28"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 2.88965V5.88965"
        stroke={themeApp === "dark" ? "#fff" : "#100D28"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="12"
        cy="15.8896"
        r="1"
        fill={themeApp === "dark" ? "#fff" : "#100D28"}
      />
      <circle
        cx="16"
        cy="15.8896"
        r="1"
        fill={themeApp === "dark" ? "#fff" : "#100D28"}
      />
      <circle
        cx="8"
        cy="15.8896"
        r="1"
        fill={themeApp === "dark" ? "#fff" : "#100D28"}
      />
    </svg>
  )
}
