import { createContext, useState } from "react"

interface PackageContextProps {
  visible: any
  setVisible: any
  preview: any
  setPreview: any
  dataSelected: any
  setDataSelected: any
  selectedFriend: any
  setSelectedFriend: any
}

export const PackageContext = createContext<PackageContextProps>({
  preview: {
    src: "",
    open: false
  },
  visible: {
    avatarSuccess: false,
    view: false,
    appotaDetail: false,
    confirmGiftcode: false,
    iewGiftcode: false,
    viewSticker: false,
    viewFriend: false,
    sendSuccess: false
  },
  setVisible: () => {},
  setPreview: () => {},
  setDataSelected: () => {},
  dataSelected: undefined,
  selectedFriend: undefined,
  setSelectedFriend: () => {}
})

interface PackageProps {
  children: any
}

export const PackageProvider = ({ children }: PackageProps) => {
  const [preview, setPreview] = useState({
    src: "",
    open: false
  })
  const [visible, setVisible] = useState({
    avatarSuccess: false,
    view: false,
    appotaDetail: false,
    confirmGiftcode: false,
    viewGiftcode: false,
    viewSticker: false,
    viewFriend: false,
    sendSuccess: false
  })

  const [dataSelected, setDataSelected] = useState()
  const [selectedFriend, setSelectedFriend] = useState()

  return (
    <PackageContext.Provider
      value={{
        dataSelected: dataSelected,
        setDataSelected: setDataSelected,
        visible,
        setVisible,
        preview,
        setPreview,
        selectedFriend,
        setSelectedFriend
      }}
    >
      {children}
    </PackageContext.Provider>
  )
}
