import React from "react"
import { SettingUserInfomation, SettingWeb } from "../../../common"
export const PartnerSetting = () => {
  return (
    <div className="w-full">
      <SettingUserInfomation />
      <SettingWeb />
    </div>
  )
}
