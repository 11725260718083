import type { DefaultOptions } from "@tanstack/react-query"
import { STALE_TIME } from "./constants"

export const defaultOptions: DefaultOptions = {
  queries: {
    // onError: queryErrorHandler,
    staleTime: STALE_TIME,
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false
  },
  mutations: {
    // onError: queryErrorHandler
  }
}
