import React from "react"
import { PurchaseTransaction } from "../component/main-purchase/PurchaseTransaction"
import { GoldTransaction } from "../component/main-gold/GoldTransaction"
import { useRouter } from "next/router"
import { animated, useSpring } from "@react-spring/web"

export enum enumTypeTransaction {
  CARD = "card",
  TOPUP = "topup",
  MERCHANDISE = "merchandise"
}

export enum enumTypeViewAll {
  CARD_ALL = "card-all",
  TOPUP_ALL = "topup-all",
  MERCHANDISE_ALL = "merchandise-all"
}

export enum enumTypeGold {
  EARN_GOLD = "earn",
  SPENT_GOLD = "spent"
}
export enum enumTypeGoldViewAll {
  EARN_ALL = "earn-all",
  SPENT_ALL = "spent-all"
}

export enum enumViewDetailTransaction {
  CARD_DETAIL = "card-detail",
  TOPUP_DETAIL = "topup-detail",
  MERCHANDISE_DETAIL = "merchandise-detail"
}

export const TransactionManager = () => {
  const router = useRouter()
  const fadePurchase = useSpring({
    opacity: !String(router?.query?.gold)?.includes("all") ? 1 : 0,
    from: { opacity: !String(router?.query?.gold)?.includes("all") ? 0 : 1 },
    delay: 100
  })

  const fadeGold = useSpring({
    opacity: !String(router?.query?.type)?.includes("all") ? 1 : 0,
    from: { opacity: !String(router?.query?.type)?.includes("all") ? 0 : 1 },
    delay: 100
  })

  return (
    <div className="mt-[16px] lg:mt-[24px] space-y-[24px] lg:space-y-[40px]">
      <animated.div style={fadePurchase}>
        {!String(router?.query?.gold)?.includes("all") && (
          <PurchaseTransaction />
        )}
      </animated.div>

      <animated.div style={fadeGold}>
        {!String(router?.query?.type)?.includes("all") &&
          !String(router?.query?.type)?.includes("detail") && (
            <GoldTransaction />
          )}
      </animated.div>
    </div>
  )
}
