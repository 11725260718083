import { AppContext } from "@/app/context"
import { useContext } from "react"

export const IconHeart = ({ className }: { className?: string }) => {
  const { themeApp } = useContext(AppContext)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 33 33"
      fill="none"
      className={className}
    >
      <path
        d="M23.1679 9.16722C24.6407 9.16722 25.8346 10.3611 25.8346 11.8339M16.5013 8.10394L17.4147 7.16722C20.2559 4.25351 24.8625 4.2535 27.7037 7.16722C30.4686 10.0027 30.5536 14.5723 27.8961 17.5138L20.2609 25.9648C18.2326 28.2099 14.77 28.2099 12.7416 25.9648L5.10654 17.5138C2.44901 14.5723 2.53397 10.0027 5.2989 7.16724C8.14013 4.25352 12.7467 4.25352 15.5879 7.16725L16.5013 8.10394Z"
        stroke="#7673E6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
