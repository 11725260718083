import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import { memo, useContext, useState } from "react"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from "@/app/components/ui/dropdown-menu"
import { AppContext } from "@/app/context"
import { IconArrowDown } from "../icon-tsx/IconArrowDown"
import { IconLogout } from "../icon-tsx/IconLogout"
import React from "react"
import { IconSetting } from "../icon-tsx/IconSetting"
import { IconHelpCenter } from "../icon-tsx/IconHelpCenter"
import { IconInventory } from "../icon-tsx/IconInventory"
import { ENUM_PARTNER_TAB } from "@/app/containers/users/partner/PartnerTab"
import { OnlyShowAvatar } from "../app-avatar/OnlyShowAvatar"
import { useQueryClient } from "@tanstack/react-query"
import Link from "next/link"
import useGAEventsTracker from "@/app/hooks/useGAEventsTracker"
import { IconCart } from "../icon-tsx/IconCart"

const AccountHeader = () => {
  const router = useRouter()
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const { userInfo, setUserInfo } = useContext(AppContext)
  const queryClient = useQueryClient()
  const avatarSrc = userInfo?.small_avatar
    ? userInfo?.small_avatar
    : userInfo?.avatar
    ? userInfo.avatar
    : ""
  const GAEventsTracker = useGAEventsTracker("Profile")
  const GAEventsTrackerInventory = useGAEventsTracker("Inventory")
  const GAEventsTrackerHelpCenter = useGAEventsTracker("HelpCenter")
  const handleLogout = () => {
    setOpen(false)
    router.push("/")
    localStorage.removeItem("token")
    queryClient.clear()
    setUserInfo({})
  }

  const handleGoToInventory = () => {
    setOpen(false)
    router.push("/reward-center")
    GAEventsTrackerInventory("Inventory_view")
  }

  const handleGoTransaction = () => {
    setOpen(false)
    router.push(
      `/user/${
        userInfo?.alias ? userInfo?.alias : userInfo?.userid
      }?tab=transaction-manager`
    )
  }

  const handleGoToSetting = () => {
    setOpen(false)
    router.push({
      pathname: "/user/[id]",
      query: userInfo?.authentic
        ? {
            id: !!userInfo?.alias ? userInfo?.alias : userInfo?.userid,
            tab: ENUM_PARTNER_TAB.PARTNER_SETTING
          }
        : {
            id: !!userInfo?.alias ? userInfo?.alias : userInfo?.userid
          }
    })
  }

  const handleGoToHelpCenter = () => {
    GAEventsTrackerHelpCenter("Help_center_view_main")
    router.push("/help-center")
  }
  const listMenuItem = [
    {
      id: "setting",
      icon: <IconSetting className="w-[24px] h-[24px]" />,
      label: t("profile.setting"),
      function: handleGoToSetting
    },
    {
      id: "transaction_manage",
      icon: <IconCart className="w-[24px] h-[24px]" />,
      label: t("profile.tab_transaction"),
      function: handleGoTransaction
    },
    {
      id: "inventory",
      icon: <IconInventory className="w-[24px] h-[24px]" />,
      label: t("common.inventory"),
      function: handleGoToInventory
    },
    {
      id: "help-center",
      icon: <IconHelpCenter className="w-[24px] h-[24px]" />,
      label: t("common.help_center"),
      function: handleGoToHelpCenter
    },
    {
      id: "logout",
      icon: <IconLogout className="w-[24px] h-[24px]" />,
      label: t("common.logout"),
      function: handleLogout
    }
  ]

  return (
    <div>
      <DropdownMenu
        open={open}
        onOpenChange={() => {
          setOpen(false)
        }}
      >
        <DropdownMenuTrigger
          asChild
          onClick={() => {
            setOpen(true)
          }}
        >
          <div
            className="cursor-pointer relative gap-[12px] items-center flex "
            title={userInfo.fullname}
          >
            <OnlyShowAvatar size={40} avatarUser={userInfo} />
            <p className="text-[16px] Nunito-700 text-bg-01 dark:text-main-white truncate ... max-w-[100px] hidden sd:block">
              {userInfo.fullname}
            </p>
            <IconArrowDown className="hidden sd:block" />
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          className="w-[276px] shadow-[0_3px_30px_0_rgba(0,0,0,0.15)] rounded-[16px]"
          align="end"
        >
          {React.Children.toArray(
            listMenuItem.map((item) => (
              <DropdownMenuItem
                onClick={item.function}
                className="flex items-center p-[16px] gap-[12px] w-full focus:bg-bg-disable dark:focus:bg-[#F1F1FC1A] rounded-[12px] active:bg-bg-disable dark:active:bg-[#F1F1FC1A] "
              >
                <div className="w-[40px] h-[40px] rounded-[12px] bg-button dark:bg-[rgba(239,239,255,0.10)] flex items-center justify-center">
                  {item.icon}
                </div>
                <span className="text-[14px] text-bg-01 Nunito-700 dark:text-white">
                  {item.label}
                </span>
              </DropdownMenuItem>
            ))
          )}
          <div className="flex items-center justify-between w-full p-[16px] h-[56px]">
            <div className="flex items-center gap-3">
              <Link href="/term-of-services" target="_blank">
                <p className="text-sm cursor-pointer Nunito-400 text-main-01">
                  Terms
                </p>
              </Link>

              <div className="w-[1px] h-[12px] bg-main-01 " />
              <Link href="/privacy-policy" target="_blank">
                <p className="text-sm cursor-pointer Nunito-400 text-main-01">
                  Privacy
                </p>
              </Link>
            </div>
            <div className="flex items-center gap-1">
              <Link
                href="https://www.facebook.com/PlayeeGamePortal"
                target="_blank"
              >
                <div className="w-[24px] h-[24px] flex items-center justify-center cursor-pointer">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.2008 8.04381C15.2008 4.04305 11.9772 0.799805 8.00078 0.799805C4.02433 0.799805 0.800781 4.04305 0.800781 8.04381C0.800781 11.6594 3.4337 14.6564 6.87578 15.1998V10.1378H5.04766V8.04381H6.87578V6.44786C6.87578 4.63233 7.95072 3.62949 9.59533 3.62949C10.3828 3.62949 11.207 3.77098 11.207 3.77098V5.55368H10.2992C9.40478 5.55368 9.12578 6.11212 9.12578 6.68556V8.04381H11.1227L10.8034 10.1378H9.12578V15.1998C12.5679 14.6564 15.2008 11.6594 15.2008 8.04381Z"
                      fill="#7673E6"
                    />
                  </svg>
                </div>
              </Link>
              <Link href="mailto:contact@playee.vn" target="_blank">
                <div className="w-[24px] h-[24px] flex items-center justify-center cursor-pointer">
                  <svg
                    width="15"
                    height="12"
                    viewBox="0 0 15 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.67167 11.2997H3.89514V5.89987L0.71875 3.51758V10.3468C0.71875 10.8741 1.14597 11.2997 1.67167 11.2997Z"
                      fill="#7673E6"
                    />
                    <path
                      d="M11.5195 11.2997H13.743C14.2703 11.2997 14.6959 10.8725 14.6959 10.3468V3.51758L11.5195 5.89987"
                      fill="#7673E6"
                    />
                    <path
                      d="M11.5195 1.77105V5.90036L14.6959 3.51806V2.24751C14.6959 1.06907 13.3507 0.397261 12.4089 1.10401"
                      fill="#7673E6"
                    />
                    <path
                      d="M3.89453 5.89981V1.77051L7.7062 4.62926L11.5179 1.77051V5.89981L7.7062 8.75856"
                      fill="#7673E6"
                    />
                    <path
                      d="M0.71875 2.24751V3.51806L3.89514 5.90036V1.77105L3.00575 1.10401C2.06236 0.397261 0.71875 1.06907 0.71875 2.24751Z"
                      fill="#7673E6"
                    />
                  </svg>
                </div>
              </Link>
            </div>
          </div>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}

export default memo(AccountHeader)
