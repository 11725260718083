import { Button } from "@/app/components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger
} from "@/app/components/ui/dropdown-menu"
import React, { useContext, useState } from "react"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "next-i18next"
import { StartTimeFilter } from "../../../common/filter-post/StartTime"
import { EndTimeFilter } from "../../../common/filter-post/EndTime"
import { IconSearch } from "@/app/components/icon-tsx"
import { AppContext } from "@/app/context"
import { useDebounceEffect } from "@/app/hooks/useDebounceEffect"

export const FilterAllTransaction = () => {
  const { setValue, watch } = useFormContext()
  const { themeApp } = useContext(AppContext)
  const { t } = useTranslation()
  const [openFilter, setOpenFilter] = useState<boolean>(false)
  const [openStartTime, setOpenStartTime] = useState<boolean>(false)
  const [tempSearch, setTempSearch] = useState<string>("")

  const selectedStartTime = watch("start_date", undefined)
  const selectedEndTime = watch("end_date", undefined)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTempSearch(e?.target?.value)
  }
  useDebounceEffect(() => setValue("keyword", tempSearch), 300, [tempSearch])

  const onChangeStartTime = () => {
    setOpenStartTime(!openStartTime)
  }
  const [openEndTime, setOpenEndTime] = useState<boolean>(false)
  const onChangeEndTime = () => {
    setOpenEndTime(!openEndTime)
  }
  const clearFilter = () => {
    setValue("start_date", undefined)
    setValue("end_date", undefined)
    setOpenFilter(false)
  }
  return (
    <div className="md:max-w-[280px] w-full flex items-center justify-between border-b-[1px] px-[16px] py-[8px]">
      <div className="flex items-center justify-between gap-[12px]">
        <IconSearch
          fill={themeApp === "dark" ? "#7673E5" : "#100D28"}
          className="w-[16px] h-[16px]"
        />
        <input
          type="text"
          className="flex-1 text-sm bg-transparent border-0 outline-none"
          placeholder={t("profile.search")}
          onChange={onChange}
        />
      </div>
      <DropdownMenu open={openFilter} onOpenChange={() => setOpenFilter(false)}>
        <DropdownMenuTrigger asChild>
          <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="cursor-pointer"
            onClick={() => setOpenFilter(true)}
          >
            <path
              d="M2 5.1665H4M4 5.1665C4 6.27107 4.89543 7.1665 6 7.1665C7.10457 7.1665 8 6.27107 8 5.1665C8 4.06193 7.10457 3.1665 6 3.1665C4.89543 3.1665 4 4.06193 4 5.1665ZM2 11.8332H6M12 11.8332H14M12 11.8332C12 12.9377 11.1046 13.8332 10 13.8332C8.89543 13.8332 8 12.9377 8 11.8332C8 10.7286 8.89543 9.83317 10 9.83317C11.1046 9.83317 12 10.7286 12 11.8332ZM10 5.1665H14"
              stroke={themeApp === "dark" ? "#7673E5" : "#100D28"}
              strokeWidth="1.5"
              strokeLinecap="round"
            />
          </svg>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          align="end"
          className="min-w-[281px] rounded-[24px] px-[16px] py-[24px] overflow-visible dark:bg-[#30303A]"
        >
          <div className="w-full bg-white dark:bg-[#30303A]">
            <p className="text-base Nunito-600 text-neutral-03 dark:text-white">
              {t("profile.time_title")}
            </p>
            <div className="flex flex-col items-center gap-[12px]">
              <StartTimeFilter
                open={openStartTime}
                onChange={onChangeStartTime}
              />
              <EndTimeFilter open={openEndTime} onChange={onChangeEndTime} />
            </div>
            {selectedStartTime >= selectedEndTime && (
              <p className="mt-2 text-xs text-main-red-02">
                {t("profile.invalid_date")}
              </p>
            )}
            <div className="w-full flex items-center justify-end gap-[16px] mt-[24px]">
              <Button
                variant="none"
                className="text-base bg-bg-button text-neutral Nunito-700 h-[30px] py-[6px] px-[24px] rounded-[12px]"
                autoFocus={false}
                onClick={clearFilter}
              >
                {t("merchandise.cancel")}
              </Button>
              <Button
                variant="none"
                disabled={
                  !selectedStartTime ||
                  !selectedEndTime ||
                  selectedStartTime > selectedEndTime
                }
                className="text-base text-white bg-main-01 h-[30px] Nunito-700 py-[6px] px-[24px] rounded-[12px]"
                onClick={() => setOpenFilter(false)}
              >
                {t("profile.filter")}
              </Button>
            </div>
          </div>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}
