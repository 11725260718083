import React from "react"

export const IconCopied = ({
  fill = "#27908C",
  className
}: {
  fill?: string
  className?: string
}) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M9.67531 1.5C7.46288 1.5 6.37452 2.25121 6.08673 4.05369C5.99892 4.60363 6.4628 5.0625 7.01972 5.0625H8.32531C11.4753 5.0625 12.9378 6.525 12.9378 9.675V10.9806C12.9378 11.5375 13.3967 12.0014 13.9466 11.9136C15.7491 11.6258 16.5003 10.5374 16.5003 8.325V5.175C16.5003 2.55 15.4503 1.5 12.8253 1.5H9.67531Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.175 6C2.55 6 1.5 7.05 1.5 9.675V12.825C1.5 15.45 2.55 16.5 5.175 16.5H8.325C10.95 16.5 12 15.45 12 12.825V9.675C12 7.05 10.95 6 8.325 6H5.175ZM6.435 13.02C6.33 13.125 6.195 13.1775 6.0525 13.1775C5.91 13.1775 5.775 13.125 5.67 13.02L4.275 11.625C4.065 11.415 4.065 11.0775 4.275 10.8675C4.485 10.6575 4.8225 10.6575 5.0325 10.8675L6.045 11.88L8.4525 9.4725C8.6625 9.2625 9 9.2625 9.21 9.4725C9.42 9.6825 9.4275 10.0275 9.2175 10.2375L6.435 13.02Z"
        fill={fill}
      />
    </svg>
  )
}
