import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function isNumber(value: any) {
  return typeof value === "number" && isFinite(value)
}

export const removeNullKeyObject = (obj: any) => {
  const newObj: any = {}

  for (const key in obj) {
    if (
      obj.hasOwnProperty(key) &&
      obj[key] !== undefined &&
      obj[key] !== null &&
      obj[key] !== ""
    ) {
      newObj[key] = obj[key]
    }
  }

  return newObj
}

export function compareValues(key: string, order = "asc") {
  return function (a: any, b: any) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // nếu không tồn tại
      return 0
    }

    const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key]
    const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key]

    let comparison = 0
    if (varA > varB) {
      comparison = 1
    } else if (varA <= varB) {
      comparison = -1
    }
    return order == "desc" ? comparison * -1 : comparison
  }
}
