import { IconClose } from "@/app/components/icon-tsx/IconClose"
import { IconInventory } from "@/app/components/icon-tsx/IconInventory"
import { IconSelectDown } from "@/app/components/icon-tsx/IconSelectDown"
import { LoadingComponent } from "@/app/components/loading"
import { LoadingBtnComponent } from "@/app/components/loading-btn"
import { Button } from "@/app/components/ui/button"
import { Dialog, DialogContent } from "@/app/components/ui/dialog"
import { Input } from "@/app/components/ui/input"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@/app/components/ui/select"
import { useToast } from "@/app/components/ui/use-toast"
import { AppContext } from "@/app/context"
import { useDebounceEffect } from "@/app/hooks/useDebounceEffect"
import useGAEventsTracker from "@/app/hooks/useGAEventsTracker"
import { useMutationUseGiftcode } from "@/app/services/query-fn/giftcode/giftcode-mutation"
import {
  useQueryGetListGameGiftcode,
  useQueryGetListRoleGiftcode,
  useQueryGetListServerGiftcode
} from "@/app/services/query-fn/giftcode/giftcode-query"
import { useQueryClient } from "@tanstack/react-query"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import { useContext, useEffect, useState } from "react"

const TIME_DEBOUNCE = 300

export const UseGiftcodeModal = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const { userInfo } = useContext(AppContext)
  const {
    visibleUseGiftcode,
    setVisibleUseGiftcode,
    setSelectedGiftcode,
    selectedGiftcode
  } = useContext(AppContext)
  const { toast } = useToast()
  const [game, setGame] = useState<any>()
  const [server, setServer] = useState<any>()
  const [role, setRole] = useState<any>()
  const [userID, setUserID] = useState<string>("")
  const [debounceSearch, setDebounceSearch] = useState<string>("")
  const [code, setCode] = useState<string>("")
  const GAEventsTracker = useGAEventsTracker("Giftcode")
  const { data: listGame } = useQueryGetListGameGiftcode()
  const { data: dataServer, isLoading: loadingGetServer } =
    useQueryGetListServerGiftcode({
      user_id: userID,
      game_id: game?.game_id
    })
  const {
    data: dataRole,
    isLoading: loadingGetRole,
    error
  } = useQueryGetListRoleGiftcode({
    user_id: dataServer?.data?.user?.user_id || "",
    game_id: game?.game_id,
    server_id: server?.server_id
  })

  useDebounceEffect(() => setUserID(debounceSearch), TIME_DEBOUNCE, [
    debounceSearch
  ])

  const { mutate, isPending } = useMutationUseGiftcode()
  const queryClient = useQueryClient()
  const OptionGame = listGame ? Object.values(listGame) : []
  const OptionServer = dataServer?.data?.servers
    ? Object.entries(dataServer?.data?.servers).map(([id, name]) => ({
        server_id: id,
        server_name: name
      }))
    : []

  const OptionRole = dataRole?.data ? dataRole?.data : []

  const onChangeGame = (value: string) => {
    const _game: any = OptionGame.find((item: any) => item?.game_id === value)
    setGame(_game)
    setServer({})
    setRole({})
  }

  useEffect(() => {
    if (!!dataServer?.data.servers && !dataServer?.data?.user?.user_id) {
      toast({
        variant: "error",
        title: "Nhân vật không tồn tại"
      })
    }
  }, [dataServer])

  const onChangeServer = (value: string) => {
    const _server: any = OptionServer.find(
      (item: any) => item?.server_id === value
    )
    setServer(_server)
    setRole({})
  }

  const onChangeRole = (value: string) => {
    const _role: any = OptionRole.find((item: any) => item?.role_id === value)
    setRole(_role)
  }

  useEffect(() => {
    if (selectedGiftcode?.giftcode?.[0]) {
      setCode(selectedGiftcode?.giftcode?.[0]?.code)
    }
  }, [selectedGiftcode])

  useEffect(() => {
    const _error: any = error

    if (_error && _error?.data?.errorCode !== 0) {
      toast({
        variant: "error",
        title: _error?.data?.message
      })
    }
  }, [error as any])

  useEffect(() => {
    if (game?.is_copub === "no") {
      setDebounceSearch(userInfo?.username)
      setUserID(userInfo?.username)
    } else {
      setDebounceSearch("")
      setUserID("")
    }
  }, [game])

  useEffect(() => {
    if (!!selectedGiftcode.game_id && OptionGame?.length > 0) {
      const _game: any = OptionGame.find(
        (item: any) => item?.game_id === selectedGiftcode?.game_id
      )
      setGame(_game)
    }
  }, [selectedGiftcode, OptionGame])

  const onChangeText = (e: any) => {
    setDebounceSearch(e.target.value)
  }

  const onUseGiftcode = () => {
    GAEventsTracker("Giftcode_confirmed")
    mutate(
      {
        user_id: dataServer?.data?.user?.user_id || "",
        code: code,
        game_id: game?.game_id,
        role_id: role?.role_id,
        server_id: server?.server_id
      },
      {
        onSuccess: () => {
          toast({
            variant: "success",
            title: "Sử dụng thành công!"
          })
          setVisibleUseGiftcode(false)
          queryClient.clear()
        },
        onError(error: any) {
          toast({
            variant: "error",
            title: error?.data?.message || "Sử dụng không thành công!"
          })
        }
      }
    )
  }

  return (
    <Dialog open={visibleUseGiftcode}>
      <DialogContent
        id="modal-use-giftcode"
        className="space-y-[16px] gap-0 md:rounded-[16px] max-w-[100vw] md:max-w-[500px] p-[24px] border-0 focus-visible:outline-none dark:bg-bg-02 translate-y-0 md:translate-y-[-50%] bottom-0 top-auto rounded-0 md:top-[50%] md:bottom-auto max-h-[100dvh] overflow-auto"
      >
        <div className="flex justify-between items-center w-full">
          <p className="text-bg-01 Nunito-700 text-base mb-[40px dark:text-main-white">
            {t("giftcode.use_giftcode")}
          </p>
          <div
            className="w-[30px] h-[30px] rounded-[8px] flex items-center justify-center  cursor-pointer"
            onClick={() => {
              setVisibleUseGiftcode(false)
              setSelectedGiftcode({})
              setGame({})
              setUserID("")
              setServer({})
              setRole({})
              setCode("")
            }}
          >
            <IconClose />
          </div>
        </div>
        {/* giftcode */}
        <div>
          <div className="mb-[8px] flex justify-between">
            <p className="text-neutral-05 text-[14px]">
              {t("giftcode.enter_giftcode")}
            </p>
            <div
              className="flex items-center gap-1 cursor-pointer"
              onClick={() => {
                GAEventsTracker("Giftcode_inventory")
                window.open("/reward-center?tab=package&item=giftcode")
              }}
            >
              <p className="text-main-01 text-[12px] Nunito-500">
                {t("common.inventory")}
              </p>
              <IconInventory className="w-[12px]" />
            </div>
          </div>

          <Input
            placeholder={t("giftcode.enter_giftcode")}
            className="w-full h-[40px] rounded-[12px] px-[16px] border border-black/10 Nunito-400 dark:bg-white/10 dark:backdrop-blur-[20px] text-left"
            onChange={(e) => {
              setCode(e.target.value)
            }}
            value={code?.trim()}
          />
        </div>

        {/* select game */}
        <div>
          <p className="text-neutral-05 text-[14px] pb-[8px]">
            {t("common.chose_game")}
          </p>
          <Select
            value={game?.game_id || ""}
            onValueChange={(value: string) => onChangeGame(value)}
          >
            <div className="relative">
              <SelectTrigger
                asChild={false}
                disabled={!!selectedGiftcode?.game_id}
                className="w-full h-[40px] rounded-[12px] px-[16px] border border-black/10 Nunito-400 dark:bg-white/10 dark:backdrop-blur-[20px] text-left"
              >
                <SelectValue placeholder={t("common.chose_game")}>
                  <div className="flex-1 text-[12px] text-main-01 flex items-center Nunito-600">
                    <img
                      src={game?.icon}
                      className="w-[24px] h-[24px] rounded-[6px] mr-[8px]"
                    />
                    {game?.game_name || ""}
                  </div>
                </SelectValue>
                <IconSelectDown />
              </SelectTrigger>
            </div>

            <SelectContent className="p-1 rounded-[16px]">
              {OptionGame.map((item: any) => (
                <SelectItem
                  value={item?.game_id}
                  key={item?.game_id}
                  aria-expanded
                  className="px-3 py-2 rounded-[8px] hover:!bg-bg-disable hover:!text-main-01 Nunito-500 focus:bg-bg-disable focus:text-main-01 dark:hover:!bg-[#F1F1FC]/[.1] dark:focus:!bg-[#F1F1FC]/[.1]"
                >
                  <div className="flex items-center">
                    <img
                      src={item?.icon}
                      className="w-[24px] h-[24px] rounded-[6px] mr-[8px]"
                    />
                    <p>{item?.game_name}</p>
                  </div>
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        {/* user id */}
        <div>
          <p className="text-neutral-05 text-[14px] pb-[8px]">
            {t("topup.input_user_id", {
              field: "User ID"
            })}
          </p>
          <Input
            placeholder={t("topup.input_user_id", {
              field: "User ID"
            })}
            className="w-full h-[40px] rounded-[12px] px-[16px] border border-black/10 Nunito-400 dark:bg-white/10 dark:backdrop-blur-[20px] text-left"
            onChange={onChangeText}
            value={debounceSearch?.trim()}
          />
        </div>

        {/* select server  */}
        <div>
          <p className="text-neutral-05 text-[14px] pb-[8px]">Server</p>
          <Select
            value={server?.server_id || ""}
            disabled={
              !game?.game_id ||
              !dataServer?.data?.servers ||
              !dataServer?.data?.user?.user_id
            }
            onValueChange={(value: string) => onChangeServer(value)}
          >
            <div className="relative">
              <SelectTrigger
                asChild={false}
                className="w-full h-[40px] rounded-[12px] px-[16px] border border-black/10 Nunito-400 dark:bg-white/10 dark:backdrop-blur-[20px] text-left"
              >
                {!!loadingGetServer ? (
                  <LoadingBtnComponent />
                ) : (
                  <SelectValue placeholder={t("topup.server")}>
                    <div className="flex-1 text-[12px] flex items-center Nunito-600">
                      {server?.server_name || ""}
                    </div>
                  </SelectValue>
                )}
                <IconSelectDown />
              </SelectTrigger>
            </div>

            <SelectContent className="p-1 rounded-[16px]">
              {!!loadingGetServer ? (
                <LoadingBtnComponent />
              ) : (
                OptionServer.map((item: any) => (
                  <SelectItem
                    value={item?.server_id}
                    key={item?.server_id}
                    aria-expanded
                    className="px-3 py-2 rounded-[8px] hover:!bg-bg-disable hover:!text-main-01 Nunito-500 focus:bg-bg-disable focus:text-main-01 dark:hover:!bg-[#F1F1FC]/[.1] dark:focus:!bg-[#F1F1FC]/[.1]"
                  >
                    <div className="flex items-center">
                      <p>{item?.server_name}</p>
                    </div>
                  </SelectItem>
                ))
              )}
            </SelectContent>
          </Select>
        </div>

        {/* select role  */}
        <div>
          <p className="text-neutral-05 text-[14px] pb-[8px]">
            {t("topup.role_name")}
          </p>
          <Select
            value={role?.role_id || ""}
            disabled={!game?.game_id || !server?.server_id || !dataRole?.data}
            onValueChange={(value: string) => onChangeRole(value)}
          >
            <div className="relative">
              <SelectTrigger
                asChild={false}
                className="w-full h-[40px] rounded-[12px] px-[16px] border border-black/10 Nunito-400 dark:bg-white/10 dark:backdrop-blur-[20px] text-left"
              >
                {loadingGetRole ? (
                  <LoadingBtnComponent />
                ) : (
                  <SelectValue placeholder={t("topup.role_name")}>
                    <div className="flex-1 text-[12px] flex items-center Nunito-600">
                      {role?.role_name || ""}
                    </div>
                  </SelectValue>
                )}
                <IconSelectDown />
              </SelectTrigger>
            </div>

            <SelectContent className="p-1 rounded-[16px]">
              {loadingGetRole ? (
                <LoadingBtnComponent />
              ) : (
                OptionRole.map((item: any) => (
                  <SelectItem
                    value={item?.role_id}
                    key={item?.role_id}
                    aria-expanded
                    className="px-3 py-2 rounded-[8px] hover:!bg-bg-disable hover:!text-main-01 Nunito-500 focus:bg-bg-disable focus:text-main-01 dark:hover:!bg-[#F1F1FC]/[.1] dark:focus:!bg-[#F1F1FC]/[.1]"
                  >
                    <div className="flex items-center">
                      <p>{item?.role_name}</p>
                    </div>
                  </SelectItem>
                ))
              )}
            </SelectContent>
          </Select>
        </div>

        <p
          className="text-[12px] text-neutral-03 dark:text-neutral-06"
          dangerouslySetInnerHTML={{
            __html: t("giftcode.use_giftcode_desc")
          }}
        ></p>
        <div className="flex items-center gap-[8px]">
          <Button
            variant="none"
            className="w-full h-[46px] text-base bg-bg-button text-neutral-05 Nunito-600 rounded-[16px] dark:text-main-white dark:bg-[#ECECEE1A]"
            autoFocus={false}
            onClick={() => {
              setVisibleUseGiftcode(false)
              setSelectedGiftcode({})
              setGame({})
              setUserID("")
              setServer({})
              setRole({})
              setCode("")
              GAEventsTracker("Giftcode_cancel")
            }}
          >
            {t("common.cancel")}
          </Button>
          <Button
            variant="main"
            disabled={
              !game || !userID || !server?.server_id || !role?.role_id || !code
            }
            className="w-full h-[46px] text-base Nunito-600 rounded-[16px]"
            onClick={onUseGiftcode}
          >
            {isPending ? <LoadingBtnComponent /> : t("reward.confirm")}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
