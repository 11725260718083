import { AxiosResponse } from "axios"
import IApiResponse from "@interfaces/IApiResponse"
import { http } from "@/app/api"
import { IPostCard } from "@/app/interfaces/IPost"
import {
  IAddNewsRequest,
  INewsByFilter,
  IParamEventByType
} from "@/app/interfaces"
import { removeNullKeyObject } from "@/app/lib/utils"

const resource = "/v2/events"

export class EventService {
  static getAllEvent(
    pageParam: number,
    keyword?: string
  ): Promise<AxiosResponse<IApiResponse<IPostCard[]>>> {
    return http.get(`${resource}/list`, {
      params: !!keyword
        ? { page: pageParam, keyword }
        : {
            page: pageParam
          }
    })
  }

  static getDetailEvent(
    event_id: string
  ): Promise<AxiosResponse<IApiResponse>> {
    return http.get(`/api/events/detail`, {
      params: {
        event_id
      }
    })
  }

  static getRankEvent(event_id: string): Promise<AxiosResponse<IApiResponse>> {
    return http.get(`/api/events/rank`, {
      params: {
        event_id
      }
    })
  }

  static getAllEventByHashtag(
    keyword: string,
    pageParam: number
  ): Promise<AxiosResponse<IApiResponse<IPostCard[]>>> {
    return http.get(`${resource}/hashtag`, {
      params: { page: pageParam, keyword }
    })
  }

  static getEventDetail(
    postId: string
  ): Promise<AxiosResponse<IApiResponse<IPostCard>>> {
    return http.get(`${resource}/view`, { params: { id: postId } })
  }

  static getEventDetailForEdit(
    postId: string
  ): Promise<AxiosResponse<IApiResponse<IPostCard>>> {
    return http.get(`${resource}/detail`, { params: { id: postId } })
  }

  static addEvent(body: IAddNewsRequest): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`${resource}/add`, body)
  }

  static editEvent(
    body: IAddNewsRequest
  ): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`${resource}/edit`, body)
  }

  static uploadMedia(formData: any): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`v2/upload/media`, formData, {
      headers: { "Content-Type": "multipart/form-data" }
    })
  }

  static deleteEvent(id: string): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`${resource}/delete`, {
      id
    })
  }

  static joinEvent(id: string): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`${resource}/join`, {
      id
    })
  }

  static regNotificationEvent(
    id: string
  ): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`${resource}/regNoti`, {
      id
    })
  }

  static checkDraft(): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`${resource}/draf`)
  }

  static saveDraft(
    body: IAddNewsRequest
  ): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`${resource}/saveDraf`, body)
  }

  static deleteDraft(body: {
    id: string
  }): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`${resource}/delete`, body)
  }

  static getListEventsByType(
    param: IParamEventByType
  ): Promise<AxiosResponse<IApiResponse<IPostCard[]>>> {
    const result = removeNullKeyObject(param)
    return http.get(`${resource}/search`, {
      params: {
        ...result
      }
    })
  }

  static relatedEvent(params: {
    id: string
    limit: number
    pageParam: number
  }): Promise<AxiosResponse<IApiResponse<IPostCard[]>>> {
    return http.get(`${resource}/related`, {
      params: {
        page: String(params.pageParam),
        id: params.id,
        limit: String(params.limit)
      }
    })
  }

  static relatedEventByGame(params: {
    game_id: string
    limit: number
    pageParam: number
  }): Promise<AxiosResponse<IApiResponse<IPostCard[]>>> {
    return http.get(`${resource}/listByGame`, {
      params: {
        page: String(params.pageParam),
        game_id: params.game_id,
        limit: String(params.limit)
      }
    })
  }

  static getMyEvent(
    param: INewsByFilter,
    pageParam: number
  ): Promise<AxiosResponse<IApiResponse<IPostCard[]>>> {
    return http.get(`${resource}/listByUser`, {
      params: {
        ...param,
        page: pageParam
      }
    })
  }

  static getOtherUserEvent(
    param: INewsByFilter & { other_userid: string },
    pageParam: number
  ): Promise<AxiosResponse<IApiResponse<IPostCard[]>>> {
    return http.get(`${resource}/listByUser`, {
      params: {
        ...param,
        page: pageParam
      }
    })
  }
}
