import { useQueryGetCardTransaction } from "@/app/services/query-fn/user/user-tab-query"
import { useRouter } from "next/router"
import React, { Ref, useMemo, useRef } from "react"
import { ItemCardTransaction } from "./ItemCardTransaction"
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious
} from "@/app/components/ui/carousel"
import { DataEmpty } from "@/app/components/data-empty"
import { useTranslation } from "next-i18next"

const LIMIT_CAROUSEL = 8
export const CardTransaction = ({
  prevRef,
  nextRef,
  onSelecteData
}: {
  prevRef: Ref<HTMLButtonElement>
  nextRef: Ref<HTMLButtonElement>
  onSelecteData: (item: any) => void
}) => {
  const carouselRef = useRef<HTMLDivElement>(null)

  const { t } = useTranslation()
  const router = useRouter()
  const userId = useMemo(() => router?.query?.id, [router])
  const type = useMemo(() => router?.query?.type, [router])
  const { data: transactions } = useQueryGetCardTransaction(
    {},
    String(userId),
    String(type)
  )
  return (
    <div>
      <div className="relative flex">
        {!!transactions?.pages?.flat()?.length ? (
          <Carousel
            className="w-full"
            opts={{
              align: "start"
            }}
          >
            <div className="overflow-auto">
              <CarouselContent className="-ml-0 md:-ml-[24px] min-h-[159px]">
                {transactions?.pages
                  ?.flat()
                  ?.slice(0, LIMIT_CAROUSEL)
                  .map((item: any, i: number) => {
                    return (
                      <CarouselItem
                        className={`md:min-w-0 ${
                          i === 0 ? "basis-4/5 pl-0" : "pl-[8px]"
                        } md:basis-1/2 lg:basis-1/3 2xl:basis-1/3 md:pl-[24px] flex`}
                        key={i}
                      >
                        <ItemCardTransaction
                          item={item}
                          key={i}
                          onSelecteData={onSelecteData}
                        />
                      </CarouselItem>
                    )
                  })}
              </CarouselContent>
            </div>
            <CarouselNext ref={nextRef} className="hidden" />
            <CarouselPrevious ref={prevRef} className="hidden" />
          </Carousel>
        ) : (
          <div className="w-full min-h-[160px] flex items-center justify-center">
            <DataEmpty title={t("profile.no_data")} />
          </div>
        )}
      </div>
    </div>
  )
}
