import { GiftcodeDetailModal } from "./GiftcodeDetailModal"
import { UseGiftcodeModal } from "./UseGiftcodeModal"

export const GiftcodeModalContainer = () => {
  return (
    <>
      <GiftcodeDetailModal />
      <UseGiftcodeModal />
    </>
  )
}
