import { doNothing } from "@/app/helpers"

interface IconSearchProps {
  className?: string
  fill?: string
  onClick?: () => void
}

export const IconSearch = ({
  fill = "#7673E6",
  className,
  onClick
}: IconSearchProps) => {
  return (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={!!onClick ? onClick : doNothing}
    >
      <g id="search">
        <path
          id="combo shape"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.5352 9.5C17.5352 13.7802 14.0654 17.25 9.78516 17.25C5.50495 17.25 2.03516 13.7802 2.03516 9.5C2.03516 5.21979 5.50495 1.75 9.78516 1.75C14.0654 1.75 17.5352 5.21979 17.5352 9.5ZM9.78516 18.75C14.8938 18.75 19.0352 14.6086 19.0352 9.5C19.0352 4.39137 14.8938 0.25 9.78516 0.25C4.67652 0.25 0.535156 4.39137 0.535156 9.5C0.535156 14.6086 4.67652 18.75 9.78516 18.75ZM20.7069 20.4217C19.9357 21.1928 18.6855 21.1928 17.9144 20.4217L16.0415 18.5488C17.1317 17.7936 18.0788 16.8465 18.8339 15.7563L20.7068 17.6292C21.478 18.4003 21.478 19.6506 20.7069 20.4217Z"
          fill={fill}
        />
      </g>
    </svg>
  )
}
