import { IconClose } from "@/app/components/icon-tsx/IconClose"
import { LoadingBtnComponent } from "@/app/components/loading-btn"
import { Button } from "@/app/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogTrigger
} from "@/app/components/ui/dialog"
import { RadioGroup, RadioGroupItem } from "@/app/components/ui/radio-group"
import { Textarea } from "@/app/components/ui/textarea"
import { QUERY_KEY } from "@/app/configs"
import { useMutationDestroyOrder } from "@/app/services/query-fn/merchandise/merchandise-mutation"
import { useQueryClient } from "@tanstack/react-query"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import React, { FC, useMemo, useState } from "react"

interface ModalCancelMerchandiseProps {
  open: boolean
  onOpenChange: () => void
  dataSelected: any
}

export const ModalCancelMerchandise: FC<ModalCancelMerchandiseProps> = ({
  open,
  onOpenChange,
  dataSelected
}) => {
  const { t } = useTranslation()
  const [value, setValue] = useState("")
  const router = useRouter()
  const queryClient = useQueryClient()
  const userId = useMemo(() => router?.query?.id, [router])
  const type = useMemo(() => router?.query?.type, [router])
  const listReason = [
    {
      label: t("profile.change_address_reason"),
      value: "Muốn thay đổi địa chỉ giao hàng"
    },
    {
      label: t("profile.change_voucher"),
      value: "Muốn nhập/thay đổi mã Voucher"
    },
    {
      label: t("profile.change_product"),
      value:
        "Muốn thay đổi sản phẩm trong sản phẩm (size, màu sắc, số lượng,...)"
    },
    {
      label: t("profile.payment_trouble"),
      value: "Thủ tục thanh toán quá rắc rối"
    },
    {
      label: t("profile.chip_other"),
      value: "Tìm thấy giá rẻ hơn ở chỗ khác"
    },
    {
      label: t("profile.dont_want_to_by"),
      value: "Đổi ý, không muốn mua nữa"
    }
  ]
  const { mutate: destroyOrder, isPending } = useMutationDestroyOrder()

  const [selected, setSelected] = useState(listReason[0])

  const onDestroy = () => {
    destroyOrder(
      {
        transaction_id: dataSelected?.transaction_id,
        reason: selected?.value
      },
      {
        onSuccess: () => {
          router.push({
            pathname: router.pathname,
            query: { ...router.query, type: "merchandise" }
          })
          queryClient.clear()
          onOpenChange()
        }
      }
    )
  }

  return (
    <Dialog
      open={open}
      onOpenChange={() => {
        onOpenChange()
      }}
    >
      <DialogTrigger></DialogTrigger>
      <DialogContent className="sm:rounded-[16px] rounded-[16px] max-w-[90vw] md:max-w-[480px] border-0 focus-visible:outline-none  py-[20px] px-0 dark:bg-bg-02 space-y-[16px] gap-0">
        <div className="flex justify-between items-center pb-[16px] border-b-[1px] border-black/10 dark:border-white/10 px-[24px]">
          <p className="text-[16px] Nunito-700">{t("profile.pick_reason")}</p>

          <div
            className="w-[24px] h-[24px] rounded-[8px] cursor-pointer"
            onClick={() => {
              onOpenChange()
            }}
          >
            <IconClose />
          </div>
        </div>
        <p className="text-[14px] Nunito-500 text-main-01 px-[24px]">
          {t("profile.pick_reason_desc")}
        </p>
        <RadioGroup
          value={`r${selected?.label}`}
          defaultValue={`r${selected?.label}`}
          className="gap-[16px] px-[24px]"
        >
          {listReason.map((reason, index) => {
            return (
              <div
                key={`r${reason.label}`}
                className="flex space-x-[12px] items-center"
              >
                <RadioGroupItem
                  value={`r${reason.label}`}
                  id={`r${reason.label}`}
                  onClick={() => setSelected(reason)}
                />
                <div
                  onClick={() => setSelected(reason)}
                  className="cursor-pointer w-full flex-1"
                >
                  <p className="text-[14px] Nunito-600">{reason.label}</p>
                </div>
              </div>
            )
          })}
          <div
            key={`r${t("reward.tab_other")}`}
            className="flex space-x-[12px]"
          >
            <RadioGroupItem
              value={`r${t("reward.tab_other")}`}
              id={`r${t("reward.tab_other")}`}
              onClick={() =>
                setSelected({
                  label: t("reward.tab_other"),
                  value: value
                })
              }
            />
            <div
              onClick={() => {
                setSelected({
                  label: t("reward.tab_other"),
                  value: value
                })
              }}
              className="cursor-pointer w-full flex-1"
            >
              <p className="text-neutral-06 dark:text-main-white text-[14px] Nunito-600 leading-[18px]">
                {t("reward.tab_other")}
              </p>

              <Textarea
                placeholder={t("profile.other_reason")}
                className="mt-2 text-[14px]"
                disabled={selected?.label !== t("reward.tab_other")}
                value={value}
                onChange={(e) => {
                  setValue(e.target.value)
                  setSelected({
                    label: t("reward.tab_other"),
                    value: e.target.value
                  })
                }}
              ></Textarea>
            </div>
          </div>
        </RadioGroup>

        <Button
          variant="main"
          className="w-fit mx-auto px-[24px] text-[14px]"
          disabled={selected?.label === t("reward.tab_other") && !value}
          onClick={onDestroy}
        >
          {isPending ? (
            <LoadingBtnComponent />
          ) : (
            t("profile.cancel_merchandise")
          )}
        </Button>
      </DialogContent>
    </Dialog>
  )
}
