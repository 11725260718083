import { PACKAGE_TYPE } from "@/app/configs"
import React, { FC, useContext } from "react"
import ImagePackage from "public/img/reward/package_default.png"
import ImageVoucher from "public/img/reward/voucher_default.png"
import ImageGiftcode from "public/img/reward/giftcode_default.png"
import { Button } from "@/app/components/ui/button"
import { LoadingBtnComponent } from "@/app/components/loading-btn"
import { PackageContext } from "@/app/context/PackageContext"
import { AppContext } from "@/app/context"
import Image from "next/image"

interface PackageCardProps {
  cardItem: any
  textDetail?: string
  textAction?: string
  actionDetail?: () => void
  action?: () => void
  loading?: boolean
  src?: string
}

export const PackageCard: FC<PackageCardProps> = ({
  cardItem,
  textDetail,
  textAction,
  actionDetail,
  action,
  loading = false,
  src
}) => {
  const { setVisible, visible, setDataSelected } = useContext(PackageContext)
  const { userInfo } = useContext(AppContext)

  return (
    <div
      key={`${cardItem.id}-${cardItem?.type}`}
      onClick={() => {
        if (cardItem?.type === PACKAGE_TYPE.GC) {
          actionDetail && actionDetail()
        }
      }}
      className="flex flex-col p-[8px] bg-white dark:bg-white/10 rounded-[12px] xs:rounded-[8px] border border-black/10 cursor-pointer md:hover:shadow-[0px_3px_30px_0px_rgba(0,0,0,0.15)] transition duration-300 active:bg-bg-button active:border-bg-button dark:active:border-black/10 md:active:bg-white dark:md:active:bg-white/10 md:active:border-black/10 "
    >
      <div className="w-full relative rounded-[12px] overflow-hidden">
        <Image
          width={177}
          height={177}
          src={
            src
              ? src
              : cardItem.image
              ? cardItem.image
              : cardItem?.type === PACKAGE_TYPE.GIFTCODE
              ? ImageGiftcode.src
              : cardItem?.type === PACKAGE_TYPE.VOUCHER ||
                cardItem?.type === PACKAGE_TYPE.GIFT_VOUCHER
              ? ImageVoucher.src
              : ImagePackage.src
          }
          alt={cardItem.name}
          className="w-full object-cover aspect-square rounded-[12px] xs:rounded-[8px]"
        />
        {(cardItem?.total_group > 0 || cardItem?.item_group > 0) && (
          <p className="absolute bottom-0 right-0 rounded-tl-[6px] bg-white text-main-01 text-[12px] Nunito-700 leading-[16px] p-[4px] min-w-[24px] text-center">
            {cardItem?.total_group || cardItem?.item_group}
          </p>
        )}
      </div>

      <div className="mt-[8px] p-[8px] flex flex-col h-full flex-1">
        <p className="text-[14px] xs:text-[12px] text-center md:text-left xs:leading-[16px] Nunito-700 leading-[19px] pb-[8px]">
          {cardItem.name}
        </p>

        <div className="flex justify-between gap-[6px] mt-auto mb-0">
          {textDetail && (
            <Button
              onClick={actionDetail}
              variant="grey"
              className="h-[28px] flex-1 text-[12px] text-neutral-05 dark:text-neutral-08 Nunito-400 rounded-[8px] w-full px-0"
            >
              {textDetail}
            </Button>
          )}
          {textAction && (
            <Button
              onClick={action}
              variant="main"
              className="h-[28px] flex-1 text-[12px] rounded-[8px] Nunito-400 w-full px-0"
            >
              {loading ? (
                <LoadingBtnComponent classname="scale-[0.7]" />
              ) : (
                textAction
              )}
            </Button>
          )}

          {!cardItem?.is_used &&
            !userInfo?.authentic &&
            ![PACKAGE_TYPE.GIFT_ITEM].includes(cardItem.type) && (
              <div
                className="w-fit w-[28px] h-[28px] bg-bg-button rounded-[8px] flex items-center justify-center cursor-pointer active:opacity-60"
                onClick={(e) => {
                  e.stopPropagation()
                  setVisible({
                    ...visible,
                    viewFriend: true
                  })
                  setDataSelected(cardItem)
                }}
              >
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none">
                  <path
                    d="M3.00002 7.33331H13.6667M3.00002 7.33331C2.26364 7.33331 1.66669 6.73636 1.66669 5.99998V5.33331C1.66669 4.59693 2.26364 3.99998 3.00002 3.99998H13.6667C14.4031 3.99998 15 4.59693 15 5.33331V5.99998C15 6.73636 14.4031 7.33331 13.6667 7.33331M3.00002 7.33331L3.00002 13.3333C3.00002 14.0697 3.59697 14.6666 4.33335 14.6666H12.3334C13.0697 14.6666 13.6667 14.0697 13.6667 13.3333V7.33331M8.33335 3.99998H11C11.7364 3.99998 12.3334 3.40303 12.3334 2.66665C12.3334 1.93027 11.7364 1.33331 11 1.33331C9.52726 1.33331 8.33335 2.52722 8.33335 3.99998ZM8.33335 3.99998H5.66669C4.93031 3.99998 4.33335 3.40303 4.33335 2.66665C4.33335 1.93027 4.93031 1.33331 5.66669 1.33331C7.13945 1.33331 8.33335 2.52722 8.33335 3.99998ZM8.33335 3.99998V14.6666"
                    stroke="#7673E6"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
            )}
        </div>
      </div>
    </div>
  )
}
