import { useMutation } from "@tanstack/react-query"
import { FriendService } from "../../services/FriendSerivces"
import { QUERY_KEY } from "@/app/configs"
import { UserService } from "../../services"

const addFriendFn = (body: { friend_id: string }) => {
  return FriendService.addFriend(body)
}

const unFriendFn = (body: { friend_id: string }) => {
  return FriendService.unFriend(body)
}

const sendGiftFriendFn = (body: { user_id: string; item_id: string }) => {
  return UserService.sendGift(body.user_id, body.item_id)
}

const actionRequestFriendFn = (body: {
  request_id: string
  status: "cancel" | "accepted"
}) => FriendService.actionRequestFriend(body)

const cancelSendingRequestFn = (body: { request_id: string }) =>
  FriendService.cancelSendingRequest(body)

export const useMutatonSendGift = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.SEND_FRIEND],
    mutationFn: sendGiftFriendFn
  })
}

export const useMutatonAddFriend = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.ADD_FRIEND],
    mutationFn: addFriendFn
  })
}

export const useMutatonUnFriend = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.UN_FRIEND],
    mutationFn: unFriendFn
  })
}

export const useMutatonDoActionRequestFriend = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.ACTION_REQUEST_FRIEND],
    mutationFn: actionRequestFriendFn
  })
}

export const useMutatonCancelSendingRequestFn = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.CANCEL_SENDING_REQUEST],
    mutationFn: cancelSendingRequestFn
  })
}
