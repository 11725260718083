import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import React, { useContext } from "react"

import { Dialog, DialogContent } from "@/app/components/ui/dialog"
import { OPEN_MODAL } from "@/app/configs"

import { Button } from "@/app/components/ui/button"
import { AppContext } from "@/app/context"

export const CommingSoonModal = () => {
  const { t } = useTranslation()

  const { openModal, setOpenModal } = useContext(AppContext)
  const handleClose = () => {
    setOpenModal(null)
  }

  return (
    <Dialog
      open={openModal === OPEN_MODAL.COMMINGSOON_MODAL}
      onOpenChange={handleClose}
    >
      <DialogContent className="sm:rounded-[24px] md:max-w-[320px] p-[24px] border-0 focus-visible:outline-none w-fit rounded-[24px] min-w-[300px]">
        <div className="flex flex-col w-full mt-[20px]">
          <p className="text-base text-center align-middle Nunito-700 text-bg-01 dark:text-white">
            {t("common.coming_soon_title")}
          </p>
          <div className="flex items-center justify-center w-full mt-[32px] gap-[16px]">
            <Button
              variant="none"
              className="text-sm text-white bg-main-01 Nunito-700"
              onClick={handleClose}
            >
              {t("reward.confirm")}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
