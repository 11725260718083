import { useQueryGetUserMerchandiseHistory } from "@/app/services/query-fn/user/user-tab-query"
import { useRouter } from "next/router"
import React, { useMemo } from "react"

import { DataEmpty } from "@/app/components/data-empty"
import { useTranslation } from "next-i18next"
import { useInView } from "react-intersection-observer"
import { useFormContext } from "react-hook-form"
import { removeNullKeyObject } from "@/app/lib/utils"
import moment from "moment"
import { ItemMerchandiseTransaction } from "./ItemMerchandiseTransaction"

export const AllMerchandiseTransaction = ({
  onSelecteData
}: {
  onSelecteData: (item: any) => void
}) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { ref, inView } = useInView()
  const userId = useMemo(() => router?.query?.id, [router])
  const type = useMemo(() => router?.query?.type, [router])
  const { watch } = useFormContext()
  const watchKeyword = watch("keyword", "")
  const watchStartTime = watch("start_date", undefined)
  const watchEndTime = watch("end_date", undefined)

  const { data: merchandises, fetchNextPage } =
    useQueryGetUserMerchandiseHistory(
      removeNullKeyObject({
        keyword: watchKeyword,
        start_date: !!watchStartTime
          ? moment(watchStartTime).format("YYYY-MM-DD")
          : undefined,
        end_date: !!watchEndTime
          ? moment(watchEndTime).format("YYYY-MM-DD")
          : undefined
      }),
      String(userId),
      String(type)
    )

  React.useEffect(() => {
    if (inView) {
      fetchNextPage()
    }
  }, [fetchNextPage, inView])

  return (
    <div className="w-full relative pb-[40px]">
      {!!merchandises?.pages?.flat()?.length ? (
        <>
          <div className="grid grid-cols-1 md:grid-cols-2 sd:grid-cols-3 gap-[24px]">
            {merchandises?.pages?.flat()?.map((item: any, i: number) => {
              return (
                <ItemMerchandiseTransaction
                  item={item}
                  key={i}
                  onSelecteData={onSelecteData}
                />
              )
            })}
          </div>
          <button ref={ref} className="opacity-0">
            Load more
          </button>
        </>
      ) : (
        <div className="w-full min-h-[160px] flex items-center justify-center">
          <DataEmpty title={t("profile.no_data")} />
        </div>
      )}
    </div>
  )
}
