import { QUERY_KEY } from "@/app/configs"
import { IGame, IGameDetailRequest } from "@/app/interfaces"
import { IBuyItemParams, IUserItemParams } from "@/app/interfaces/IReward"
import RewardService from "@/app/services/services/RewardService"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useRouter } from "next/router"

const useItemFn = (body: IUserItemParams) => {
  return RewardService.getItemBag(body)
}

export const useMutationUseItem = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.USE_ITEM],
    mutationFn: useItemFn
  })
}
