import { IconClose } from "@/app/components/icon-tsx/IconClose"
import { IconGold } from "@/app/components/icon-tsx/IconGold"
import { Button } from "@/app/components/ui/button"
import { Dialog, DialogContent } from "@/app/components/ui/dialog"
import { IGift } from "@/app/interfaces/IReward"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import React, { FC } from "react"
import { ENUM_NORMAL_TAB } from ".."

interface ModalResultFailProps {
  open: boolean
  onOpenChange: () => void
  textFail: string
  notEnoughPoint?: boolean
}

export const ModalResultFail: FC<ModalResultFailProps> = ({
  open,
  onOpenChange,
  textFail,
  notEnoughPoint = false
}) => {
  const { t } = useTranslation()
  const router = useRouter()

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:rounded-[16px] gap-0 rounded-[16px] max-w-[90vw] md:max-w-[461px] p-[24px] border-0 focus-visible:outline-none dark:bg-bg-02">
        <div className="w-full flex">
          <div
            className="w-[32px] h-[32px] rounded-[8px] flex items-center justify-center ml-auto mr-0 bg-bg-button dark:bg-bg-button/10 cursor-pointer"
            onClick={onOpenChange}
          >
            <IconClose />
          </div>
        </div>

        <div
          className={`w-[96px] h-[96px] rounded-[96px] mx-auto flex items-center justify-center mb-[24px] bg-error`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="57"
            height="56"
            viewBox="0 0 57 56"
            fill="none"
            className=""
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M51.8332 27.9998C51.8332 40.8865 41.3865 51.3332 28.4998 51.3332C15.6132 51.3332 5.1665 40.8865 5.1665 27.9998C5.1665 15.1132 15.6132 4.6665 28.4998 4.6665C41.3865 4.6665 51.8332 15.1132 51.8332 27.9998ZM36.337 35.8368C35.6535 36.5202 34.5455 36.5202 33.8621 35.8368L28.4999 30.4746L23.1376 35.8368C22.4542 36.5202 21.3461 36.5202 20.6627 35.8368C19.9793 35.1534 19.9793 34.0454 20.6627 33.3619L26.025 27.9997L20.6628 22.6375C19.9793 21.9541 19.9793 20.846 20.6628 20.1626C21.3462 19.4792 22.4542 19.4792 23.1376 20.1626L28.4999 25.5248L33.8621 20.1626C34.5455 19.4792 35.6535 19.4792 36.3369 20.1626C37.0203 20.846 37.0203 21.9541 36.3369 22.6375L30.9747 27.9997L36.337 33.3619C37.0204 34.0453 37.0204 35.1534 36.337 35.8368Z"
              fill="#EB5757"
            />
          </svg>
        </div>

        <h1 className="text-center Nunito-700 text-[18px] pb-[8px]">
          {t("reward.buy_gift_fail_title")}
        </h1>
        <h2 className="text-center text-neutral dark:text-neutral-08 Nunito-500 pb-[24px]">
          {textFail}
        </h2>

        {notEnoughPoint && (
          <Button
            variant="main"
            className="px-[16px] py-[8px] h-[35px] rounded-[12px] w-fit text-[14px] Nunito-600 mx-auto"
            onClick={() => {
              router.push({
                pathname: router.pathname,
                query: {
                  tab: ENUM_NORMAL_TAB.MISSION_CENTER
                }
              })
              onOpenChange()
            }}
          >
            {t("common.get_more_spin")} Gold
          </Button>
        )}
      </DialogContent>
    </Dialog>
  )
}
