export const IconEvent = ({
  className,
  fill
}: {
  className?: string
  fill?: string
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="15"
      viewBox="0 0 12 15"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.83333 1.33331C3.83333 1.05717 3.60948 0.833313 3.33333 0.833313C3.05719 0.833313 2.83333 1.05717 2.83333 1.33331V2.50612C1.4532 2.92631 0.389421 4.07256 0.0869288 5.49998H11.9131C11.6106 4.07256 10.5468 2.92631 9.16667 2.50613V1.33331C9.16667 1.05717 8.94281 0.833313 8.66667 0.833313C8.39052 0.833313 8.16667 1.05717 8.16667 1.33331V2.33672C8.11139 2.33446 8.05583 2.33331 8 2.33331H4C3.94417 2.33331 3.88861 2.33446 3.83333 2.33672L3.83333 1.33331ZM0 6.49998H12V10.6666C12 12.8758 10.2091 14.6666 8 14.6666H4C1.79086 14.6666 0 12.8758 0 10.6666V6.49998ZM6 10.6666C6.36819 10.6666 6.66667 10.3682 6.66667 9.99998C6.66667 9.63179 6.36819 9.33331 6 9.33331C5.63181 9.33331 5.33333 9.63179 5.33333 9.99998C5.33333 10.3682 5.63181 10.6666 6 10.6666ZM9.33333 9.99998C9.33333 10.3682 9.03486 10.6666 8.66667 10.6666C8.29848 10.6666 8 10.3682 8 9.99998C8 9.63179 8.29848 9.33331 8.66667 9.33331C9.03486 9.33331 9.33333 9.63179 9.33333 9.99998ZM3.33333 10.6666C3.70152 10.6666 4 10.3682 4 9.99998C4 9.63179 3.70152 9.33331 3.33333 9.33331C2.96514 9.33331 2.66667 9.63179 2.66667 9.99998C2.66667 10.3682 2.96514 10.6666 3.33333 10.6666Z"
        fill={fill ? fill : "#FAF8FF"}
      />
    </svg>
  )
}
