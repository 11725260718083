import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import React, { useCallback, useContext } from "react"

import { Dialog, DialogContent } from "@/app/components/ui/dialog"
import { CreateType, OPEN_MODAL } from "@/app/configs"

import {
  IconSelectCreateEvent,
  IconSelectCreatePost
} from "@/app/components/icon-tsx"
import { AppContext } from "@/app/context"

export const SelectCreatePostModal = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const { openModal, setOpenModal } = useContext(AppContext)

  const onRemoveParamAndNavigate = useCallback(
    (type: CreateType) => {
      router.push(`/create-post?type=${type}`)
    },
    [router]
  )
  const handleClose = () => {
    setOpenModal(null)
  }

  const handleChooseType = (type: CreateType) => () => {
    onRemoveParamAndNavigate(type)
    handleClose()
  }

  return (
    <Dialog
      open={openModal === OPEN_MODAL.SELECT_CREATE_POST_MODAL}
      onOpenChange={handleClose}
    >
      <DialogContent className="sm:rounded-[48px] md:max-w-[473px] py-[48px] px-[24px] md:px-[60px] bg-transparent dark:bg-transparent border-0 focus-visible:outline-none shadow-none">
        <div className="flex items-center justify-center w-full gap-16">
          <div
            className="outline-none cursor-pointer"
            onClick={handleChooseType(CreateType.CREATE_POST)}
          >
            <div className="bg-white w-[48px] h-[48px] rounded-[12px] flex items-center justify-center">
              <IconSelectCreatePost />
            </div>
            <p className="mt-4 text-center text-white">
              {t("news.upper_news")}
            </p>
          </div>
          <div
            className="outline-none cursor-pointer"
            onClick={handleChooseType(CreateType.CREATE_EVENT)}
          >
            <div className="bg-white w-[48px] h-[48px] rounded-[12px] flex items-center justify-center">
              <IconSelectCreateEvent />
            </div>
            <p className="mt-4 text-center text-white">
              {t("news.upper_event")}
            </p>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
