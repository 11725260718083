import React from "react"
interface Iprops {
  color?: string
}

export const IconNotice = ({ color = "#828282" }: Iprops) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="21"
      viewBox="0 0 19 21"
      fill="none"
    >
      <g clipPath="url(#clip0_7860_29809)">
        <path
          d="M10.5 1.3125V2.1C13.4941 2.70826 15.75 5.35664 15.75 8.53125V9.30234C15.75 11.2342 16.4596 13.0922 17.7392 14.5359L18.0428 14.8764C18.3873 15.266 18.4734 15.8156 18.2601 16.2873C18.0469 16.759 17.5793 17.0625 17.0625 17.0625H1.31249C0.795693 17.0625 0.326926 16.759 0.115203 16.2873C-0.0965196 15.8156 -0.0118346 15.266 0.33156 14.8764L0.635732 14.5359C1.91706 13.0922 2.62499 11.2342 2.62499 9.30234V8.53125C2.62499 5.35664 4.84394 2.70826 7.87499 2.1V1.3125C7.87499 0.587754 8.46151 0 9.18749 0C9.91347 0 10.5 0.587754 10.5 1.3125ZM9.18749 21C8.49022 21 7.82167 20.7252 7.32948 20.233C6.83729 19.7408 6.56249 19.0354 6.56249 18.375H11.8125C11.8125 19.0354 11.5377 19.7408 11.0455 20.233C10.5533 20.7252 9.84784 21 9.18749 21Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_7860_29809">
          <rect width="18.375" height="21" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
