import React from "react"
import { InputProps } from "./input"
import { cn } from "@/app/lib/utils"

type TFloatingInput = {
  postfixIcon?: React.ReactNode
  onFocus: () => void
  onBlur: any
  label: string
  labelClassName: string
} & InputProps

// eslint-disable-next-line react/display-name
export const FloatingInput = React.forwardRef<HTMLInputElement, TFloatingInput>(
  ({
    postfixIcon,
    onFocus,
    onBlur,
    label,
    placeholder,
    className,
    labelClassName,
    ...props
  }, ref) => {
    return (
      <div className="relative">
        <input
          type="text"
          id={`floating_outlined-${label}`}
          className={cn(
            "peer block w-full appearance-none rounded-lg border-[1px] border-stroke dark:border-neutral-03 bg-transparent dark:bg-transparent px-[24px] text-sm text-gray-900 focus:border-main-01 focus:outline-none focus:ring-0",
            className
          )}
          placeholder=" "
          onFocus={onFocus}
          onBlur={onBlur}
          {...props}
        />
        <label
          htmlFor={`floating_outlined-${label}`}
          className={cn(
            "ml-2 peer-focus:ml-0 absolute text-sm text-text-grey-03 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-bg-02 px-2 peer-focus:px-2 peer-focus:text-main-01  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 peer-focus:translate-x-4 rtl:peer-focus:left-auto start-1",
            labelClassName
          )}
        >
          {label}
        </label>
        {postfixIcon && (
          <div className="absolute top-1/2 right-[24px] transform -translate-y-1/2 cursor-pointer">
            {postfixIcon}
          </div>
        )}
      </div>
    )
  }
)
