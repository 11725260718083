import { QUERY_KEY, REPLY_LIMIT_SIZE } from "@/app/configs"
import { useInfiniteQuery } from "@tanstack/react-query"
import NotificationService from "../../services/NotificationService"
import { useContext } from "react"
import { AppContext } from "@/app/context"

export const getListNotificationFn = async (
  pageParam: number,
  setUnReadCount: any,
  noticeType?: string
) => {
  const data = await NotificationService.getNotices(pageParam, noticeType)
  setUnReadCount(data.data.data?.unreadCount)
  return data.data.data?.items
}

export const useQueryGetListNotification = (
  setUnReadCount: any,
  noticeType: string
) => {
  const { userInfo } = useContext(AppContext)
  return useInfiniteQuery({
    queryKey: [QUERY_KEY.NOTIFICATION_LIST, { noticeType }],
    queryFn: ({ pageParam }) =>
      getListNotificationFn(pageParam, setUnReadCount, noticeType),
    initialPageParam: 1,
    enabled: !!userInfo?.userid,
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.length >= REPLY_LIMIT_SIZE ? pages?.length + 1 : 0
    }
  })
}
