import { useMutation } from "@tanstack/react-query"
import { NewsService } from "../../services"
import { QUERY_KEY } from "@/app/configs"

const commentToPostFn = async (formData: FormData) => {
  return NewsService.commentToPost(formData)
}
const likePostFn = async (body: { id: string }) => NewsService.likePost(body)

const likeCommentFn = async (body: { id: string; comment_id: string }) =>
  NewsService.likeComment(body)

const replyToCommentFn = async (formData: FormData) => {
  return NewsService.replyToComment(formData)
}

const deleteCommentFn = async (body: { id: string; comment_id: string }) => {
  return NewsService.deleteComment(body)
}

const updateCommentFn = async (formData: FormData) => {
  return NewsService.updateComment(formData)
}

const deleteNews = async (id: string) => {
  return NewsService.deleteNews(id)
}

const storeNews = async (id: string) => {
  return NewsService.storeNews(id)
}
const removeStoreNews = async (id: string) => {
  return NewsService.removeStoreNews(id)
}

export const useMutationStoreNews = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.STORE_NEWS],
    mutationFn: storeNews
  })
}
export const useMutationRemoveStoreNews = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.REMOVE_STORE_NEWS],
    mutationFn: removeStoreNews
  })
}
export const useMutationDeleteNews = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.DELETE_NEWS],
    mutationFn: deleteNews
  })
}
export const useMutationCommentToPostFn = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.ADD_COMMENT],
    mutationFn: commentToPostFn
  })
}

export const useMutationReplyToComment = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.REPLY_COMMENT],
    mutationFn: replyToCommentFn
  })
}
export const useMutationLikePost = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.LIKE_POST],
    mutationFn: likePostFn
  })
}

export const useMutationLikeComment = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.LIKE_COMMENT],
    mutationFn: likeCommentFn
  })
}

export const useMutationDeleteComment = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.DELETE_COMMENT],
    mutationFn: deleteCommentFn
  })
}

export const useMutationUpdateComment = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.UPDATE_COMMENT],
    mutationFn: updateCommentFn
  })
}
