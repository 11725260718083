import { IAddNewsRequest } from "@/app/interfaces"
import { useMutation } from "@tanstack/react-query"
import { QUERY_KEY } from "@/app/configs"
import { EventService } from "../../services/EventServices"

const addEventFn = (body: IAddNewsRequest) => {
  return EventService.addEvent(body)
}

const editEventFn = (body: IAddNewsRequest) => {
  return EventService.editEvent(body)
}

const saveDraftFn = (body: IAddNewsRequest) => {
  return EventService.saveDraft(body)
}

const deletaDraftFn = (body: { id: string }) => {
  return EventService.deleteDraft(body)
}

export const useMutationEditEvent = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.EDIT_EVENT],
    mutationFn: editEventFn
  })
}
export const useMutationCreateEvent = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.CREATE_EVENT],
    mutationFn: addEventFn
  })
}

export const useMutationSaveDraftEvent = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.SAVE_DRAFT_EVENTS],
    mutationFn: saveDraftFn
  })
}

export const useMutationDeleteDraftEvent = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.DELETE_DRAFT_EVENT],
    mutationFn: deletaDraftFn
  })
}
