import React, { SetStateAction, useEffect } from "react"
import {
  enumTypeTransaction,
  enumTypeViewAll,
  enumViewDetailTransaction
} from "../../tab"
import { CardTransaction } from "../card/CardTransaction"
import { useRouter } from "next/router"
import { CardDetail } from "../card/CardDetail"
import { TopupTransaction } from "../topup/TopupTransaction"
import { MerchandiseTransaction } from "../merchandise/MerchandiseTransaction"
import { AllCardTransaction } from "../card/AllCardTransaction"
import { AllTopupTransaction } from "../topup/AllTopupTransaction"
import { TopupDetail } from "../topup/TopupDetail"
import { AllMerchandiseTransaction } from "../merchandise/AllMerchandiseTransaction"
import { MerchandiseDetail } from "../merchandise/MerchandiseDetail"

export const PurchaseContent = ({
  prevRef,
  nextRef,
  dataSelected,
  setDataSelected,
  onSelecteData
}: {
  prevRef: any
  nextRef: any
  dataSelected: any
  setDataSelected: React.Dispatch<SetStateAction<any>>
  onSelecteData: (value: enumTypeTransaction) => void
}) => {
  const router = useRouter()

  useEffect(() => {
    if (String(router?.query?.type).includes("detail")) {
      !dataSelected &&
        router.push({
          pathname: router.pathname,
          query: {
            ...router.query,
            type: String(router?.query?.type).replace("-detail", "")
          }
        })
    }
  }, [dataSelected, router])

  return (
    <div className="w-full">
      {/* Card */}
      {["undefined", String(enumTypeTransaction.CARD)].includes(
        String(router?.query?.type)
      ) && (
        <CardTransaction
          prevRef={prevRef}
          nextRef={nextRef}
          onSelecteData={onSelecteData}
        />
      )}
      {String(enumTypeViewAll.CARD_ALL) === String(router?.query?.type) && (
        <AllCardTransaction onSelecteData={onSelecteData} />
      )}
      {[String(enumViewDetailTransaction.CARD_DETAIL)].includes(
        String(router?.query?.type)
      ) && (
        <CardDetail
          dataSelected={dataSelected}
          setDataSelected={setDataSelected}
        />
      )}

      {/* topup */}
      {String(enumTypeTransaction.TOPUP) === String(router?.query?.type) && (
        <TopupTransaction
          prevRef={prevRef}
          nextRef={nextRef}
          onSelecteData={onSelecteData}
        />
      )}
      {String(enumTypeViewAll.TOPUP_ALL) === String(router?.query?.type) && (
        <AllTopupTransaction onSelecteData={onSelecteData} />
      )}

      {[String(enumViewDetailTransaction.TOPUP_DETAIL)].includes(
        String(router?.query?.type)
      ) && (
        <TopupDetail
          dataSelected={dataSelected}
          setDataSelected={setDataSelected}
        />
      )}

      {/* Merchandíse */}
      {String(enumTypeTransaction.MERCHANDISE) ===
        String(router?.query?.type) && (
        <MerchandiseTransaction
          prevRef={prevRef}
          nextRef={nextRef}
          onSelecteData={onSelecteData}
        />
      )}
      {String(enumTypeViewAll.MERCHANDISE_ALL) ===
        String(router?.query?.type) && (
        <AllMerchandiseTransaction onSelecteData={onSelecteData} />
      )}

      {[String(enumViewDetailTransaction.MERCHANDISE_DETAIL)].includes(
        String(router?.query?.type)
      ) && (
        <MerchandiseDetail
          dataSelected={dataSelected}
          setDataSelected={setDataSelected}
        />
      )}
    </div>
  )
}
