import { IconCopied } from "@/app/components/icon-tsx/IconCopied"
import { IconNonCopy } from "@/app/components/icon-tsx/IconNonCopy"
import { Button } from "@/app/components/ui/button"
import { OPEN_MODAL, isClient } from "@/app/configs"
import { AppContext, MobileContext } from "@/app/context"
import { jwtDecode } from "jwt-decode"
import { useRouter } from "next/router"
import React, { useContext, useEffect, useState } from "react"
import { animated, useSpring } from "@react-spring/web"
import { useTranslation } from "next-i18next"
import { MyMerchandiseAddress } from "../component/MyMerchandiseAddress"
import { useQueryGetListGame } from "@/app/services/query-fn/game"
import { IGame } from "@/app/interfaces"

const TIME_OUT = 3000
export const SettingUserInfomation = () => {
  const router = useRouter()
  const { t } = useTranslation()
  const { userInfo, themeApp, setOpenModal } = useContext(AppContext)
  const { isMobile } = useContext(MobileContext)
  const LIMIT_HASHTAG = isMobile ? 1 : 5
  const [copied, setIsCopied] = useState<boolean>(false)
  const { data: games } = useQueryGetListGame({
    category: "",
    type: "",
    limit: 100,
    page: 1
  })
  const onChangeShowModal = () => {
    setOpenModal(OPEN_MODAL.SHARE_REFER_CODE)
  }

  const onChangeShowEdit = () => {
    setOpenModal(OPEN_MODAL.EDIT_HASHTAG)
  }
  const onCopyInviteCode = () => {
    navigator.clipboard.writeText(userInfo?.referent_code)
    setIsCopied(true)
  }

  const renderListFavorite = () => {
    const listGameName =
      !!games &&
      games
        ?.filter((game) => userInfo?.games_interest?.includes(game.id))
        ?.map((game: IGame) => game?.game_name)
    if (!!listGameName)
      return [
        ...userInfo?.topic_interest?.map(
          (item: string) => `#${item?.replaceAll("#", "")}`
        ),
        ...listGameName
      ]
    return userInfo.topic_interest?.map(
      (item: string) => `#${item?.replaceAll("#", "")}`
    )
  }

  const onSubmitEdit = () => {
    if (isClient) {
      const token = localStorage.getItem("token")
      if (token) {
        const decodeToken: any = jwtDecode(token)
        router.push(
          `https://id.gamota.com/authorize?access_token=${decodeToken?.data?.id}&callback=/user/update_profile&redirect=${window.location.href}`
        )
      }
    }
  }

  const fade = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    delay: 100
  })

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (copied) setIsCopied(false)
    }, TIME_OUT)

    return () => {
      clearTimeout(timeout)
    }
  }, [copied])

  return (
    <animated.div style={fade}>
      <div className="w-full px-0 md:px-[32px] py-[24px] border-b dark:border-neutral-05">
        <div className="flex items-center justify-between w-full gap-[12px]">
          <p className="text-base Nunito-700 text-bg-01 dark:text-white min-w-fit">
            {t("profile.info")}
          </p>
          <Button
            variant="none"
            className="p-0 text-main-01"
            onClick={onSubmitEdit}
          >
            Edit
          </Button>
        </div>
        <div className="w-full">
          <div className="flex items-center justify-between w-full gap-[12px]">
            <p className="text-sm Nunito-400 text-neutral dark:text-white min-w-fit">
              {t("profile.fullname")}
            </p>
            <p className="text-sm Nunito-500 text-bg-01 dark:text-white">
              {userInfo?.fullname}
            </p>
          </div>
          <div className="flex items-center justify-between w-full mt-[16px] gap-[12px]">
            <p className="text-sm Nunito-400 text-neutral dark:text-white min-w-fit">
              Email
            </p>
            <p className="text-sm Nunito-500 text-bg-01 dark:text-white">
              {userInfo?.email}
            </p>
          </div>
          {!!userInfo?.phone && (
            <div className="flex items-center justify-between w-full mt-[16px] gap-[12px]">
              <p className="text-sm Nunito-400 text-neutral dark:text-white min-w-fit">
                {t("profile.phone")}
              </p>
              <p className="text-sm Nunito-500 text-bg-01 dark:text-white">
                {userInfo?.phone}
              </p>
            </div>
          )}

          {!!userInfo?.invite_code && (
            <div className="flex items-center justify-between w-full mt-[16px] gap-[12px]">
              <p className="text-sm Nunito-400 text-neutral dark:text-white min-w-fit">
                {t("profile.ref_code_title")}
              </p>
              <p className="text-sm text-bg-01 Nunito-500 dark:text-white">
                {userInfo?.invite_code}
              </p>
            </div>
          )}
          <div className="flex items-center justify-between w-full mt-[16px] gap-[12px]">
            <p className="text-sm Nunito-400 text-neutral dark:text-white min-w-fit">
              {t("profile.invite_code_title")}
            </p>
            <div className="flex gap-[12px] items-center">
              <p className="text-sm text-bg-01 Nunito-500 dark:text-white">
                {userInfo?.referent_code}
              </p>

              <div className="cursor-pointer w-fit" onClick={onCopyInviteCode}>
                {copied ? <IconCopied /> : <IconNonCopy stroke="#9F9EA9" />}
              </div>
              <div className="w-[1px] h-[17px] bg-black/10 dark:bg-neutral-03" />
              <div
                className="w-[24px] h-[24px] rounded-[12px] flex items-center justify-center cursor-pointer bg-bg-02-text-box dark:bg-white/10"
                onClick={onChangeShowModal}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.66602 7.33337L14.1327 1.8667"
                    stroke={themeApp === "dark" ? "#fff" : "#585669"}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14.6668 4.5335V1.3335H11.4668"
                    stroke={themeApp === "dark" ? "#fff" : "#585669"}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7.33398 1.3335H6.00065C2.66732 1.3335 1.33398 2.66683 1.33398 6.00016V10.0002C1.33398 13.3335 2.66732 14.6668 6.00065 14.6668H10.0007C13.334 14.6668 14.6673 13.3335 14.6673 10.0002V8.66683"
                    stroke={themeApp === "dark" ? "#fff" : "#585669"}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between w-full mt-[16px] gap-[12px]">
            <p className="text-sm Nunito-400 text-neutral dark:text-white min-w-fit">
              {t("merchandise.address_title")}
            </p>
            <MyMerchandiseAddress />
          </div>
          <div className="flex items-start justify-between w-full mt-[16px] gap-[12px]">
            <p className="text-sm Nunito-400 text-neutral dark:text-white min-w-fit">
              {t("profile.interest")}
            </p>
            <div className="flex items-start gap-[12px] max-w-[70%]">
              <>
                <div className="flex flex-wrap items-center justify-end gap-[12px] overflow-auto">
                  {renderListFavorite()
                    ?.slice(0, LIMIT_HASHTAG)
                    .map((item: string, index: number) => (
                      <Button
                        key={index}
                        variant="none"
                        className="h-[23px] px-[8px] py-[2px] flex items-center justify-center bg-button text-main-01 rounded-[8px] dark:bg-[#EFEFFF1A] min-w-fit overflow-hidden text-left truncate"
                      >
                        {item}
                      </Button>
                    ))}
                </div>

                <div className="h-[16px] w-[1px] bg-black/10 dark:bg-neutral-03" />
                <Button
                  variant="none"
                  className="flex items-start p-0 min-w-[24px] w-[24px] h-[24px]"
                  onClick={onChangeShowEdit}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.5 17.5H17.5M11.487 4.42643C11.487 4.42643 11.487 5.78861 12.8492 7.1508C14.2114 8.51299 15.5736 8.51299 15.5736 8.51299M6.09969 14.9901L8.96028 14.5814C9.37291 14.5225 9.7553 14.3313 10.05 14.0365L16.9358 7.1508C17.6881 6.39848 17.6881 5.17874 16.9358 4.42642L15.5736 3.06424C14.8213 2.31192 13.6015 2.31192 12.8492 3.06424L5.96347 9.94997C5.66873 10.2447 5.47754 10.6271 5.41859 11.0397L5.00994 13.9003C4.91913 14.536 5.464 15.0809 6.09969 14.9901Z"
                      stroke="#7673E6"
                      strokeLinecap="round"
                    />
                  </svg>
                </Button>
              </>
            </div>
          </div>
        </div>
      </div>
    </animated.div>
  )
}
