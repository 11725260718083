import { useMutation } from "@tanstack/react-query"
import { UserService } from "../../services"
import { QUERY_KEY } from "@/app/configs"

const updateLanguageFn = (body: { lang: string }) => {
  return UserService.updateLanguage(body)
}

const updateAvatarFn = (body: FormData) => {
  return UserService.uploadImage(body)
}

const updateProfileFn = (body: any) => {
  return UserService.updateProfile(body)
}
const updateInterestTopicFn = (body: {
  topic_interest?: string
  games_interest?: string
}) => {
  return UserService.updateInterestTopic(body)
}
const updateInviteCodeFn = (body: { invite_code: string }) => {
  return UserService.updateInviteCode(body)
}

export const useMutationUpdateProfile = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.UPDATE_PROFILE],
    mutationFn: updateProfileFn
  })
}

export const useMutationUploadAvatar = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.UPLOAD_AVATAR],
    mutationFn: updateAvatarFn
  })
}

export const useMutationUpdateLanguage = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.UPDATE_LANGUAGE],
    mutationFn: updateLanguageFn
  })
}
export const useMutationUpdateInterestTopic = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.UPDATE_INTEREST_TOPIC],
    mutationFn: updateInterestTopicFn
  })
}

export const useMutationUpdateInviteCode = () => {
  return useMutation({
    mutationFn: updateInviteCodeFn,
    mutationKey: [QUERY_KEY.UPDATE_INVITE_CODE]
  })
}
