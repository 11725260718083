import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger
} from "@/app/components/ui/tabs"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { MissionCenterContainer } from "./mission-center"
import { GoldShopContainer } from "./gold-shop"
import { PackageContainer } from "./package"
import { OPEN_MODAL, QUERY_KEY, isClient } from "@/app/configs"
import { AppContext } from "@/app/context"
import { useQueryClient } from "@tanstack/react-query"

export enum ENUM_NORMAL_TAB {
  PACKAGE = "package",
  GOLD_SHOP = "gold-shop",
  MISSION_CENTER = "mission-center"
}

export const RewardCenterContainer = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const { setOpenModal } = useContext(AppContext)
  const [activeTab, setActiveTab] = useState(
    router.query.tab || ENUM_NORMAL_TAB.PACKAGE
  )
  const queryClient = useQueryClient()
  const accessToken = isClient ? window.localStorage.getItem("token") : null

  const handleChangeTab = (value: ENUM_NORMAL_TAB) => {
    router.push({
      pathname: router.pathname,
      query: {
        tab: value
      }
    })
  }

  useEffect(() => {
    if (router.query.tab) {
      setActiveTab(router.query.tab)
      queryClient.refetchQueries({
        queryKey: [QUERY_KEY.LIST_MISSION]
      })
    }
  }, [router])

  useEffect(() => {
    if (!accessToken) {
      setOpenModal(OPEN_MODAL.LOGIN_MODAL)
    }
  }, [accessToken])

  return (
    <div className="w-full xs:mt-0 mt-[40px]">
      <Tabs
        defaultValue={
          ["undefined", ENUM_NORMAL_TAB.MISSION_CENTER].includes(
            String(activeTab)
          )
            ? ENUM_NORMAL_TAB.MISSION_CENTER
            : String(activeTab)
        }
        className="relative w-full"
        style={{
          zIndex: 2
        }}
        value={String(activeTab)}
      >
        <div className="max-w-full sm:overflow-auto">
          <TabsList className="flex px-0 border-0 w-fit xs:gap-0 xs:justify-between xs:w-full">
            <TabsTrigger
              value={ENUM_NORMAL_TAB.PACKAGE}
              className="xs:w-[31%] xs:text-[14px] xs:py-0 xs:h-[54px] flex items-center gap-[10px] text-neutral-05 dark:text-main-01 dark:data-[state=active]:text-main-01 dark:data-[state=active]:border-main-01 dark:data-[state=inactive]:text-neutral-08 w-fit"
              onClick={() => handleChangeTab(ENUM_NORMAL_TAB.PACKAGE)}
            >
              <span>{t("common.inventory")}</span>
            </TabsTrigger>
            <TabsTrigger
              value={ENUM_NORMAL_TAB.GOLD_SHOP}
              className="xs:w-[31%] xs:text-[14px] xs:py-0 xs:h-[54px] flex items-center gap-[10px] text-neutral-05 dark:text-main-01 dark:data-[state=active]:text-main-01 dark:data-[state=active]:border-main-01 dark:data-[state=inactive]:text-neutral-08 w-fit"
              onClick={() => handleChangeTab(ENUM_NORMAL_TAB.GOLD_SHOP)}
            >
              <span>Gold shop</span>
            </TabsTrigger>
            <TabsTrigger
              value={ENUM_NORMAL_TAB.MISSION_CENTER}
              className="xs:w-[31%] xs:text-[14px] break-word whitespace-normal xs:px-3 xs:py-0 xs:h-[54px] flex items-center gap-[10px] text-neutral-05 dark:text-main-01 dark:data-[state=active]:text-main-01 dark:data-[state=active]:border-main-01 dark:data-[state=inactive]:text-neutral-08 w-fit"
              onClick={() => handleChangeTab(ENUM_NORMAL_TAB.MISSION_CENTER)}
            >
              <span>{t("common.mission_center")}</span>
            </TabsTrigger>
          </TabsList>
        </div>

        <div className="mt-0 border-t-[1px] border-black/10 w-full dark:border-neutral-03">
          <TabsContent value={ENUM_NORMAL_TAB.PACKAGE} className="w-full">
            <PackageContainer />
          </TabsContent>
          <TabsContent value={ENUM_NORMAL_TAB.GOLD_SHOP} className="w-full">
            <GoldShopContainer />
          </TabsContent>
          <TabsContent
            value={ENUM_NORMAL_TAB.MISSION_CENTER}
            className="w-full"
          >
            <MissionCenterContainer />
          </TabsContent>
        </div>
      </Tabs>
    </div>
  )
}
