import React from "react"

import { StartTime } from "./StartTime"
import { EndTime } from "./EndTime"
import { useTranslation } from "next-i18next"
import { StartTimeMobile } from "./StartTimeMobile"
import { EndTimeMobile } from "./EndTimeMobile"

export const EventDate = () => {
  const { t } = useTranslation()
  return (
    <div className="relative w-full">
      <span className="text-bg-01 text-[18px] Nunito-700 dark:text-white">
        {t("news.time_happen")}
      </span>
      <div className="w-full hidden md:flex items-center justify-between flex-wrap">
        <StartTime />
        <EndTime />
      </div>
      <div className="w-full md:hidden flex items-center justify-between flex-wrap">
        <StartTimeMobile />
        <EndTimeMobile />
      </div>
    </div>
  )
}
