import { DATE_FORMAT_FULL, QUERY_KEY } from "@/app/configs"
import {
  IDeleteReplyRequest,
  IGame,
  IGameComment,
  IGameDetailRequest,
  ILikeReviewRequest,
  IRateGameRequest,
  IReplyReviewRequest
} from "@/app/interfaces"
import {
  InfiniteData,
  useMutation,
  useQueryClient
} from "@tanstack/react-query"
import { GameServices } from "../../services/GameServices"
import { customRound } from "@/app/utils"
import { useRouter } from "next/router"
import { useContext } from "react"
import { AppContext } from "@/app/context"
import moment from "moment"

const followGameFn = (body: IGameDetailRequest) => {
  return GameServices.followGame(body)
}

const registerGameFn = (body: IGameDetailRequest) => {
  return GameServices.registerGame(body)
}

const likeReviewFn = (body: ILikeReviewRequest) => {
  return GameServices.likeReviewGame(body)
}

const rateGameFn = (body: IRateGameRequest) => {
  return GameServices.rateGame(body)
}

const replyReviewFn = (body: IReplyReviewRequest) => {
  return GameServices.replyReviewGame(body)
}

const deleteReplyReviewFn = (body: IDeleteReplyRequest) => {
  return GameServices.deleteReplyReviewGame(body)
}

const deleteReviewFn = (body: IGameDetailRequest) => {
  return GameServices.deleteReviewGame(body)
}

export const useMutationFollowGame = () => {
  const queryClient = useQueryClient()
  const router = useRouter()

  return useMutation({
    mutationKey: [QUERY_KEY.FOLLOW_GAME],
    mutationFn: followGameFn,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.LIST_GAME_FOLLOW],
        refetchType: "active"
      })
      queryClient.setQueryData(
        [QUERY_KEY.GAME_DETAIL, { game_id: router.query.id }],
        (oldData: IGame) => {
          return {
            ...oldData,
            followed: !oldData.followed
          }
        }
      )
    }
  })
}

export const useMutationRegisterGame = () => {
  const queryClient = useQueryClient()
  const router = useRouter()

  return useMutation({
    mutationKey: [QUERY_KEY.REGISTER_GAME],
    mutationFn: registerGameFn,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.LIST_GAME_FOLLOW],
        refetchType: "active"
      })
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.GAME_DETAIL, { game_id: router.query.id }],
        refetchType: "active"
      })
    }
  })
}

export const useMutationLikeReview = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [QUERY_KEY.LIKE_REVIEW],
    mutationFn: likeReviewFn,
    onSuccess: (data, variables) => {
      queryClient.setQueryData(
        [QUERY_KEY.GAME_REVIEW_LIST, { game_id: variables.game_id }],
        (oldData: InfiniteData<IGameComment[]>) => {
          const updatedArray = oldData.pages.map((innerArray) => {
            // Duyệt qua các phần tử trong mảng con
            return innerArray.map((item) => {
              // Kiểm tra xem item có ID trùng khớp với targetId hay không
              if (item.review.id === variables.review_id) {
                // Nếu trùng khớp, chỉnh sửa trạng thái liked
                return {
                  ...item,
                  review: {
                    ...item.review,
                    liked: !item.review.liked,
                    total_like: item.review.liked
                      ? item.review.total_like - 1
                      : item.review.total_like + 1
                  }
                }
              }
              // Nếu không trùng khớp, giữ nguyên item
              return item
            })
          })

          return {
            ...oldData,
            pages: updatedArray
          }
        }
      )
    }
  })
}

export const useMutationDeleteReview = () => {
  const queryClient = useQueryClient()
  const { userInfo } = useContext(AppContext)

  return useMutation({
    mutationKey: [QUERY_KEY.DELETE_REVIEW_GAME],
    mutationFn: deleteReviewFn,
    onSuccess: (data, variables) => {
      queryClient.setQueryData(
        [QUERY_KEY.GAME_REVIEW_LIST, { game_id: variables.game_id }],
        (oldData: InfiniteData<IGameComment[]>) => {
          const updatedArray = oldData.pages.map((innerArray) => {
            // Duyệt qua các phần tử trong mảng con

            return innerArray.filter((item) => {
              return item?.author?.id !== userInfo?.userid
            })
          })

          return {
            ...oldData,
            pages: updatedArray
          }
        }
      )
    }
  })
}

export const useMutationReplyReview = () => {
  const queryClient = useQueryClient()
  const { userInfo } = useContext(AppContext)

  return useMutation({
    mutationKey: [QUERY_KEY.REPLY_REVIEW_GAME],
    mutationFn: replyReviewFn,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.GAME_REVIEW_LIST, { game_id: variables.game_id }],
        type: "active",
        exact: true
      })
    }
  })
}

export const useMutationDeleteReplyReview = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [QUERY_KEY.DELETE_REPLY_REVIEW_GAME],
    mutationFn: deleteReplyReviewFn,
    onSuccess: (data, variables) => {}
  })
}

export const useMutationRateGame = () => {
  const queryClient = useQueryClient()
  const router = useRouter()

  return useMutation({
    mutationKey: [QUERY_KEY.RATE_GAME],
    mutationFn: rateGameFn,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.GAME_DETAIL, { game_id: router.query.id }]
      })

      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.GAME_REVIEW_LIST, { game_id: variables.game_id }],
        type: "active",
        exact: true
      })
    }
  })
}
