import {
  IAddToCartRequest,
  ICreateAddress,
  ICreatePaymentMerchandise,
  ICreateQuickPaymentMerchandise,
  IMerchandiseAddress,
  IPramsDistroyOrder,
  IPramsPaymentFeeShip,
  IUpdateCartRequest,
  IUpdateMerchandiseAddress,
  IUpdateQuickCartRequest
} from "@/app/interfaces"
import { MerchandiseService } from "../../services/MerchandiseService"
import { useMutation } from "@tanstack/react-query"
import { QUERY_KEY } from "@/app/configs"
import { removeNullKeyObject } from "@/app/lib/utils"

const createAddress = (body: ICreateAddress) => {
  return MerchandiseService.createAddress(body)
}

const addToCartFn = (body: IAddToCartRequest) => {
  return MerchandiseService.addToCart(body)
}

const buyNowFn = (body: IAddToCartRequest) => {
  return MerchandiseService.buyNow(body)
}

const updateCartFn = (body: IUpdateCartRequest) => {
  return MerchandiseService.updateCart(body)
}

const updateQuickCartFn = (body: IUpdateQuickCartRequest) => {
  return MerchandiseService.updateQuickCart(body)
}

const deleteCartFn = ({ id }: { id: string }) => {
  return MerchandiseService.deleteCart(id)
}

const destroyOrderFn = (body: IPramsDistroyOrder) => {
  return MerchandiseService.destroyOrder(body)
}

const deleteAddressFn = ({ id }: { id: string }) => {
  return MerchandiseService.deleteAddress(id)
}

const updateAddressFn = (body: IUpdateMerchandiseAddress) => {
  return MerchandiseService.updateAddress(body)
}

const createPaymentFn = (body: ICreatePaymentMerchandise) => {
  const _body = removeNullKeyObject(body)
  return MerchandiseService.createPaymentMerchandise(_body)
}

const createQuickPaymentFn = (body: ICreateQuickPaymentMerchandise) => {
  const _body = removeNullKeyObject(body)
  return MerchandiseService.createQuickPaymentMerchandise(_body)
}

const getMerchandisePaymentFeeShip = async (body: IPramsPaymentFeeShip) => {
  const data = await MerchandiseService.getPaymentFeeShip(body)
  return data.data
}

export const useMutationGetMerchandisePaymentFeeShip = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.MERCHANDISE_PAYMENT_FEE_SHIP],
    mutationFn: getMerchandisePaymentFeeShip
  })
}

export const useMutationCreateAddress = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.CREATE_ADDRESS],
    mutationFn: createAddress
  })
}

export const useMutationAddToCart = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.ADD_TO_CART_MERCHANDISE],
    mutationFn: addToCartFn
  })
}

export const useMutationBuyNowMerchandise = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.BUY_NOW_MERCHANDISE],
    mutationFn: buyNowFn
  })
}

export const useMutationCreateQuickPaymentMerchandise = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.CREATE_NOW_PAYMENT_MERCHANDISE],
    mutationFn: createQuickPaymentFn
  })
}

export const useMutationUpdateQuickCart = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.UPDATE_QUICK_CART_MERCHANDISE],
    mutationFn: updateQuickCartFn
  })
}

export const useMutationUpdateCart = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.UPDATE_CART_MERCHANDISE],
    mutationFn: updateCartFn
  })
}

export const useMutationDeleteCart = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.DELETE_CART_MERCHANDISE],
    mutationFn: deleteCartFn
  })
}

export const useMutationDestroyOrder = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.DESTROY_ORDER],
    mutationFn: destroyOrderFn
  })
}

export const useMutationUpdateAddress = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.UPDATE_ADDRESS_MERCHANDISE],
    mutationFn: updateAddressFn
  })
}

export const useMutationDeleteAddress = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.DELETE_ADDRESS_MERCHANDISE],
    mutationFn: deleteAddressFn
  })
}

export const useMutationCreatePaymentMerchandise = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.CREATE_PAYMENT_MERCHANDISE],
    mutationFn: createPaymentFn
  })
}
