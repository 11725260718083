"use client";

import { useEffect, useState } from 'react';
import Script from 'next/script';

interface ThothanInputs {
  userToken: string;
  userName: string;
  isDev: boolean;
}

interface ThothanConfig {
  token: string;
  baseUrl: string;
  inputs: ThothanInputs;
}

// Extend Window interface to include Thothan config
declare global {
  interface Window {
    thothanChatbotConfig?: ThothanConfig;
  }
}

// Props interface for the component
interface ThothanChatbotProps {
  userName?: string;
  userToken?: string;
}


const ThothanChatbot: React.FC<ThothanChatbotProps> = ({ 
  userName = "", 
  userToken = "" 
}) => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
    // Initialize global config object
    
    if (typeof window !== 'undefined') {
      
      window.thothanChatbotConfig = {
        token: 'vmqV1XL3pJXffnaT',
        baseUrl: 'https://platform.thothan.ai',
        inputs: {
          userToken: userToken,
          userName: userName,
          isDev: true,
        }
      };

    }
  }, [userName,userToken]);

  if (!isClient) return null;

  return (
    <div id="thothan-chatbot-container">
      <style jsx global>{`
        #thothan-chatbot-bubble-button {
          background-color: #1C64F2 !important;
          z-index: 1000;
        }
      `}</style>

      <Script
        id="vmqV1XL3pJXffnaT"
        src="https://platform.thothan.ai/embed.min.js"
        
        onLoad={() => {
          console.log('Chatbot script loaded successfully');
        }}
        onError={(e) => {
          console.error('Error loading chatbot script:', e);
        }}
      />
    </div>
  );
};

export default ThothanChatbot;