import { DATE_FORMAT_FULL } from "@/app/configs"
import moment from "moment"
import { useTranslation } from "next-i18next"
import React from "react"

interface Props {
  dataSelected: any
  item: any
}

export const ItemDetailMerchandiseWeb: React.FC<Props> = ({
  dataSelected,
  item
}) => {
  const { t } = useTranslation()
  return (
    <div className="hidden grid-cols-12 md:grid">
      <div className="flex gap-[16px] items-center col-span-5">
        <img
          src={item?.images?.[0]}
          alt={item?.item_name}
          className="w-[48px] h-[48px] rounded-[12px] object-cover border border-[rgba(0, 0, 0, 0.10)] dark:border-border-light"
        />
        <div className="flex flex-col">
          <p className="text-[14px] Nunito-600">{item?.item_name}</p>
          <p className="text-[12px] Nunito-400 text-text-grey-03 dark:text-neutral-08">
            {item?.color?.name}
            {item?.size && `, ${item?.size}`}
          </p>
        </div>
      </div>
      <div className="flex items-center col-span-2">
        {!!item?.color?.length ? item?.color?.join(",") : ""}
      </div>

      <div className="flex items-center col-span-2">
        <p className="text-[14px] text-bg-01 Nunito-600 dark:text-white">
          <span>{item?.quantity}</span>{" "}
          <span className="text-neutral-06 Nunito-600 dark:text-white">x</span>{" "}
          <span>{`${item?.item_price?.toLocaleString()} đ`}</span>
        </p>
      </div>
      <div className="flex items-center justify-end col-span-3 gap-2">
        {!!item?.sale_percent && (
          <p className="text-[14px] line-through text-neutral-05 Nunito-400">
            {`${(
              Number(item?.item_price) * Number(item?.quantity)
            )?.toLocaleString()} đ`}
          </p>
        )}
        <p className="text-[14px] text-main-01 Nunito-600">{`${Number(
          item?.amount
        )?.toLocaleString()} đ`}</p>
      </div>
    </div>
  )
}

export const ItemDetailMerchandiseMobile: React.FC<Props> = ({
  dataSelected,
  item
}) => {
  const { t } = useTranslation()
  return (
    <div className="flex md:hidden items-center gap-[12px]">
      <img
        src={item?.images?.[0]}
        alt={item?.item_name}
        className="w-[65px] h-[65px] rounded-[12px] object-cover border border-[rgba(0, 0, 0, 0.10)] dark:border-border-light"
      />
      <div className="flex flex-col flex-1">
        <p className="text-[14px] Nunito-600">{item?.item_name}</p>
        <p className="">
          {!!item?.color?.length ? item?.color?.join(",") : ""}
        </p>
        <div className="flex items-center justify-between w-full">
          <p className="text-[12px] text-neutral-05 Nunito-600 dark:text-white">
            <div className="text-[12px] Nunito-400 text-neutral-05 Nunito-600 dark:text-white">
              {item?.color?.name}
              {item?.size && `, ${item?.size}`}
            </div>
            x{item?.quantity}
          </p>
          <div className="flex items-center gap-2">
            {Number(item?.item_price) * Number(item?.quantity) !==
              Number(item?.amount) && (
              <p className="text-[14px] line-through text-neutral-05 Nunito-400">
                {`${(
                  Number(item?.item_price) * Number(item?.quantity)
                )?.toLocaleString()} đ`}
              </p>
            )}
            <p className="text-[14px] text-main-01 Nunito-600">{`${Number(
              item?.amount
            )?.toLocaleString()} đ`}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
