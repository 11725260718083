import useGAEventsTracker from "@/app/hooks/useGAEventsTracker"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"

export const HelpCenter = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const GAEventsTrackerHelpCenter = useGAEventsTracker("HelpCenter")

  const onNavigateToHelpCenter = () => {
    GAEventsTrackerHelpCenter("Help_center_view_main")
    router.push("/help-center")
  }
  return (
    <div
      className={`px-[16px] py-[24px] rounded-l-[24px] fixed right-0 top-[50%] z-10 bg-bg-button dark:bg-[#2F2F39] cursor-pointer hover:px-[32px] user-help-center overflow-hidden hidden md:block`}
      onClick={onNavigateToHelpCenter}
    >
      <div className="relative flex items-center gap-[8px] text-[14px] Nunito-700 text-main-01 whitespace-nowrap">
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="ml-auto mr-0"
        >
          <path
            opacity="0.4"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.09766 22C10.3179 25.4956 13.6105 28 17.4807 28H22.9009H26.3696C28.006 28 29.3326 26.6568 29.3326 25V20.5C29.3326 16.2149 26.7973 12.5293 23.1627 10.897C23.3222 11.5717 23.4067 12.2758 23.4067 13C23.4067 17.9706 19.427 22 14.5178 22H9.09766Z"
            fill="#7673E6"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.0364 4H14.5179C18.7119 4 22.2274 6.94088 23.1628 10.897C23.3223 11.5717 23.4068 12.2758 23.4068 13C23.4068 17.9706 19.4271 22 14.5179 22H9.09774H5.62898C3.99258 22 2.66602 20.6569 2.66602 19V14.5C2.66602 8.70101 7.30899 4 13.0364 4ZM13.3327 9C12.4122 9 11.666 9.74619 11.666 10.6667C11.666 11.219 11.2183 11.6667 10.666 11.6667C10.1137 11.6667 9.66602 11.219 9.66602 10.6667C9.66602 8.64162 11.3076 7 13.3327 7C15.3577 7 16.9993 8.64162 16.9993 10.6667C16.9993 12.6917 15.3577 14.3333 13.3327 14.3333C12.7804 14.3333 12.3327 13.8856 12.3327 13.3333C12.3327 12.781 12.7804 12.3333 13.3327 12.3333C14.2532 12.3333 14.9993 11.5871 14.9993 10.6667C14.9993 9.74619 14.2532 9 13.3327 9ZM14.666 17.3333C14.666 18.0697 14.0691 18.6667 13.3327 18.6667C12.5963 18.6667 11.9993 18.0697 11.9993 17.3333C11.9993 16.597 12.5963 16 13.3327 16C14.0691 16 14.666 16.597 14.666 17.3333Z"
            fill="#7673E6"
          />
        </svg>
      </div>
    </div>
  )
}
