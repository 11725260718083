import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport
} from "@components/ui/toast"
import { useToast } from "@components/ui/use-toast"
import { IconToastError } from "../icon-tsx"
import { IconToastSuccess } from "../icon-tsx/IconToastSuccess"

const TIME_DURATION = 1000
export function ToasterContainer() {
  const { toasts } = useToast()

  return (
    <ToastProvider duration={TIME_DURATION}>
      {toasts.map(function ({ id, title, description, action, ...props }) {
        return (
          <Toast key={id} {...props} type="background">
            {!!props?.variant && props?.variant === "error" ? (
              <IconToastError />
            ) : props?.variant === "success" ? (
              <IconToastSuccess />
            ) : (
              <></>
            )}
            <div className="grid gap-1">
              {title && <ToastTitle>{title}</ToastTitle>}
              {/* {description && (
                <ToastDescription>{description}</ToastDescription>
              )} */}
            </div>
            {/* {action} */}
            <ToastClose />
          </Toast>
        )
      })}
      <ToastViewport />
    </ToastProvider>
  )
}
