import { useMutation } from "@tanstack/react-query"
import { EventService } from "../../services/EventServices"
import { QUERY_KEY } from "@/app/configs"

const deleteEvent = async (id: string) => {
  return EventService.deleteEvent(id)
}
const joinEvent = ({ id }: { id: string }) => {
  return EventService.joinEvent(id)
}

const regNotificationEvent = ({ id }: { id: string }) => {
  return EventService.regNotificationEvent(id)
}

export const useMutationDeleteEvent = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.DELETE_EVENT],
    mutationFn: deleteEvent
  })
}

export const useMutationJoinEvent = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.JOIN_EVENT],
    mutationFn: joinEvent
  })
}

export const useMutationRegNotificationEvent = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.REG_NOTIFICATION_EVENT],
    mutationFn: regNotificationEvent
  })
}
