import io from "socket.io-client"

export default class Socket {
  public socketIo: any
  getInstance = ({
    name,
    path,
    token
  }: {
    name?: string | null
    path?: string | null
    token?: string
  }) => {
    const publicBaseSocketURL = process.env.NEXT_PUBLIC_HOST_SOCKET_URL

    if (this.socketIo == null) {
      this.socketIo = io(publicBaseSocketURL as string, {
        path: "/socket.io",
        secure: true,
        reconnection: true,
        rejectUnauthorized: false,
        transports: ["websocket"],
        query: {
          token
        }
        // extraHeaders: {
        //   Authorization: Bearer ${token},
        // },
      })

      this.socketIo.on("connect", () => {
        // console.log("connect=", "connect", this.socketIo)
      })
      this.socketIo.on("disconnect", () => {
        // console.log("------------disconnect-socket------------")
      })
    }
    return this.socketIo
  }

  removeInstance = () => {
    this.socketIo = null
  }
}
