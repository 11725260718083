import React from "react"

export const IconToastError = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.02 3.38C16.54 2.9 15.58 2.5 14.9 2.5H9.1C8.42 2.5 7.46 2.9 6.98 3.38L2.88 7.48C2.4 7.96 2 8.92 2 9.6V15.4C2 16.08 2.4 17.04 2.88 17.52L6.98 21.62C7.46 22.1 8.42 22.5 9.1 22.5H14.9C15.58 22.5 16.54 22.1 17.02 21.62L21.12 17.52C21.6 17.04 22 16.08 22 15.4V9.6C22 8.92 21.6 7.96 21.12 7.48L17.02 3.38ZM16.03 16.53C15.88 16.68 15.69 16.75 15.5 16.75C15.31 16.75 15.12 16.68 14.97 16.53L12 13.56L9.03 16.53C8.88 16.68 8.69 16.75 8.5 16.75C8.31 16.75 8.12 16.68 7.97 16.53C7.68 16.24 7.68 15.76 7.97 15.47L10.94 12.5L7.97 9.53C7.68 9.24 7.68 8.76 7.97 8.47C8.26 8.18 8.74 8.18 9.03 8.47L12 11.44L14.97 8.47C15.26 8.18 15.74 8.18 16.03 8.47C16.32 8.76 16.32 9.24 16.03 9.53L13.06 12.5L16.03 15.47C16.32 15.76 16.32 16.24 16.03 16.53Z"
        fill="#EB5757"
      />
    </svg>
  )
}
