import React, { useMemo, useState } from "react"
import { useFormContext } from "react-hook-form"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger
} from "@/app/components/ui/dropdown-menu"
import { Button } from "@/app/components/ui/button"
import { IconCalendar } from "@/app/components/icon-tsx/IconCalendar"
import { ScrollArea } from "@/app/components/ui/scroll-area"
import { Calendar } from "@/app/components/ui/calendar"
import { useTranslation } from "next-i18next"
import { DATE_FORMAT_3, END_YEAR } from "@/app/configs"
import moment from "moment"
import { CustomSelectMonthYear } from "./CustomSelectMontYear"

export const EndTime = () => {
  const { t } = useTranslation()
  const { setValue, watch } = useFormContext()
  const [openEndTime, setOpenEndTime] = useState<boolean>(false)
  const [timeEnd, setTimeEnd] = useState<string>("00:00")

  const selectedStartTime = watch("start_time", undefined)
  const selectedEndTime = watch("end_time", undefined)

  const handleEndTimeChange: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    const time = e.target.value
    if (!selectedEndTime) {
      setTimeEnd(time)
      return
    }
    const [hours, minutes] = time.split(":").map((str) => parseInt(str, 10))
    const newSelectedDate = new Date(
      selectedEndTime.getFullYear(),
      selectedEndTime.getMonth(),
      selectedEndTime.getDate(),
      hours,
      minutes
    )
    setValue("end_time", newSelectedDate)
    setTimeEnd(time)
  }

  const handleDaySelectEndTime = (date: Date | undefined) => {
    if (!timeEnd || !date) {
      setValue("end_time", date)
      return
    }
    const [hours, minutes] = timeEnd.split(":").map((str) => parseInt(str, 10))
    const newDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      hours,
      minutes
    )
    setValue("end_time", newDate)
  }

  const onCloseSelectEndDate = () => {
    setOpenEndTime(false)
    setValue("end_time", undefined)
  }

  const onSubmitSelectTime = () => {
    setOpenEndTime(false)
  }
  const content = useMemo(() => {
    return (
      <div className="flex-1">
        <DropdownMenu open={openEndTime} onOpenChange={onCloseSelectEndDate}>
          <DropdownMenuTrigger asChild>
            <Button
              variant="none"
              className="bg-disable w-full text-neutral flex justify-between items-center py-[8px] dark:text-white"
              onClick={() => setOpenEndTime(true)}
            >
              <span>
                {selectedEndTime
                  ? moment(selectedEndTime).format(DATE_FORMAT_3)
                  : t("news.end")}
              </span>
              <IconCalendar />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-[276px]" align="center" side="left">
            <ScrollArea className="h-fit">
              <Calendar
                mode="single"
                selected={selectedEndTime}
                onSelect={handleDaySelectEndTime}
                className="p-0 rounded-md"
                showOutsideDays
                captionLayout="dropdown"
                fromYear={new Date().getFullYear()}
                toYear={END_YEAR}
                components={{
                  Head: () => <CustomSelectMonthYear />
                }}
                footer={
                  <div className="space-y-[24px]">
                    <div className="flex items-center justify-between mt-2">
                      <span className="text-base Nunito-600 text-main-01">
                        {t("news.post_time")}:
                      </span>
                      <input
                        type="time"
                        value={timeEnd}
                        onChange={handleEndTimeChange}
                        className="text-base"
                      />
                    </div>
                    <div className="flex items-center justify-end gap-4">
                      <Button
                        variant="none"
                        className="bg-disable text-neutral dark:text-white rounded-[12px] max-h-[34px] py-[6px] px-[24px]"
                        onClick={onCloseSelectEndDate}
                      >
                        {t("common.cancel")}
                      </Button>

                      <Button
                        variant="none"
                        disabled={
                          !selectedEndTime ||
                          selectedEndTime < new Date() ||
                          (!!selectedStartTime &&
                            selectedEndTime < selectedStartTime)
                        }
                        className="text-white bg-disable bg-main-01 rounded-[12px] max-h-[34px] py-[6px] px-[24px]"
                        onClick={onSubmitSelectTime}
                      >
                        {t("news.set_time")}
                      </Button>
                    </div>
                    {selectedEndTime < new Date() ? (
                      <p className="mt-1 text-xs Nunito-400 text-main-red">
                        {t("news.please_select_future_date")}
                      </p>
                    ) : !!selectedStartTime &&
                      selectedEndTime < selectedStartTime ? (
                      <p className="mt-1 text-xs Nunito-400 text-main-red">
                        {t("news.end_time_larger")}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                }
              />
            </ScrollArea>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    )
  }, [openEndTime, timeEnd, selectedStartTime, selectedEndTime])
  return <>{content}</>
}
