import { IOrderParams, IProduct } from "@/app/interfaces/IStore"
import { removeNullKeyObject } from "@/app/lib/utils"
import interceptor from "@api/interceptor"
import IApiResponse from "@interfaces/IApiResponse"
import axios, { AxiosResponse } from "axios"
const BASE_API_URL = process.env.NEXT_PUBLIC_SERVER_BASE_URL

export const $httpStore = axios.create({
  baseURL: `${BASE_API_URL}`,
  headers: {
    "Content-type": "application/json"
  }
})
interceptor($httpStore)
const resource = "/store"
export default class StoreService {
  static getListProduct(
    page: string = "1",
    limit: string = "100"
  ): Promise<AxiosResponse<IApiResponse<IProduct[]>>> {
    return $httpStore.get(`${resource}/items/product`, {
      params: { page, limit }
    })
  }

  static getListItem(
    product_code: string,
    page: string = "1",
    limit: string = "100"
  ): Promise<AxiosResponse<IApiResponse>> {
    return $httpStore.get(`${resource}/items/list`, {
      params: { page, limit, product_code }
    })
  }

  static getListItemDetail(
    item_code: string
  ): Promise<AxiosResponse<IApiResponse>> {
    return $httpStore.get(`${resource}/items/detail`, {
      params: { item_code }
    })
  }

  static getbyTransactionId(
    transaction_id: string
  ): Promise<AxiosResponse<IApiResponse>> {
    return $httpStore.get(`${resource}/payment/vaTransaction`, {
      params: { transaction_id }
    })
  }

  static createPaymentLink(
    method: string,
    item_code: string,
    quantity: string,
    voucher_code?: string
  ): Promise<AxiosResponse<IApiResponse>> {
    const _param = removeNullKeyObject({
      method,
      item_code,
      quantity,
      voucher_code
    })
    return $httpStore.post(`${resource}/payment/createPayment`, _param)
  }

  // static getPaymentCallback(params: any): Promise<AxiosResponse<IApiResponse>> {
  //   return $httpStore.get(`${resource}/payment/callback`, {
  //     params
  //   })
  // }

  static getPaymentCallback(params: any): Promise<AxiosResponse<IApiResponse>> {
    return $httpStore.get(`${resource}/payment/callbackV2`, {
      params
    })
  }

  static getOnepayPaymentCallback(
    params: any
  ): Promise<AxiosResponse<IApiResponse>> {
    return $httpStore.get(`${resource}/payment/onePayCallback`, {
      params
    })
  }

  static getPaymentHistory(
    params: IOrderParams
  ): Promise<AxiosResponse<IApiResponse<any>>> {
    return $httpStore.get(`${resource}/payment/history`, {
      params: params
    })
  }

  static suggestPaymentMethod(
    method_request: string
  ): Promise<AxiosResponse<IApiResponse>> {
    return $httpStore.post(`${resource}/payment/methodSugget`, {
      method_request
    })
  }

  // voucher

  static getListVoucherByProduct(
    item_code: string,
    page: string = "1",
    limit: string = "100"
  ): Promise<AxiosResponse<IApiResponse>> {
    return $httpStore.get(`${resource}/vouchers/listByItem`, {
      params: { page, limit, item_code }
    })
  }

  static checkoutVoucherInfo(
    item_code: string,
    code: string
  ): Promise<AxiosResponse<IApiResponse>> {
    return $httpStore.post(`${resource}/vouchers/check`, {
      item_code,
      code
    })
  }
}
