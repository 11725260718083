import ReactGA from "react-ga4"
import { removeNullKeyObject } from "../lib/utils"
const useGAEventsTracker = (category: string = "Event Category") => {
  const trackEvent = (
    action: string = "action",
    label?: string,
    value?: number
  ) => {
    ReactGA.event(removeNullKeyObject({ category, action, label, value }))
  }
  return trackEvent
}

export default useGAEventsTracker
