import React from "react"
import { RewardBanner } from "./RewardBanner"
import { DailyLogin } from "./DailyLogin"
import { ReferralMission } from "./ReferralMission"
import { InteractiveMission } from "./InteractiveMission"
import { useQueryGetListMission } from "@/app/services/query-fn/reward-center/mission-center/mission-query"

export const MissionCenterContainer = () => {
  const { data: dataReward } = useQueryGetListMission()
  return (
    <div className="mt-[16px] md:space-y-[40px]">
      <RewardBanner />
      <div className="flex gap-[56px] xs:gap-[40px] flex-col ld:flex-row">
        <DailyLogin dataReward={dataReward} />
        <ReferralMission dataReward={dataReward} />
      </div>
      <InteractiveMission dataReward={dataReward} />
    </div>
  )
}
