import { useToast } from "@/app/components/ui/use-toast"
import { GIFTCODE_TYPE, PACKAGE_TYPE, QUERY_KEY } from "@/app/configs"
import { AppContext } from "@/app/context"
import { PackageContext } from "@/app/context/PackageContext"
import { useMutationUseItem } from "@/app/services/query-fn/reward-center/package/package-mutation"
import { useQueryClient } from "@tanstack/react-query"
import { useTranslation } from "next-i18next"
import ImagePackage from "public/img/reward/package_default.png"
import { FC, useContext } from "react"
import { PackageCard } from "../PackageCard"
import useGAEventsTracker from "@/app/hooks/useGAEventsTracker"

interface AppotaCardItemprops {
  item: any
}

export const AppotaCardItem: FC<AppotaCardItemprops> = ({ item }) => {
  const { t } = useTranslation()
  const { userInfo: userProfile } = useContext(AppContext)
  const { visible, setVisible, setDataSelected } = useContext(PackageContext)
  const { mutate, isPending } = useMutationUseItem()
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const GAEventsTracker = useGAEventsTracker("Inventory")

  const textDetail = () => {
    let result = ""

    if (item.status === GIFTCODE_TYPE.USED) {
      result = t("reward.detail")
    }

    return result
  }

  const textAction = () => {
    let result = ""

    if (item.status === GIFTCODE_TYPE.PENDING) {
      result = t("reward.use_now")
    }

    return result
  }

  const onUse = async (item: any) => {
    GAEventsTracker("Inventory_used_ACard")
    mutate(
      { item_id: item.id },
      {
        onSuccess: () => {
          queryClient.refetchQueries({
            queryKey: [QUERY_KEY.PACKAGE_LIST]
          })

          toast({
            variant: "success",
            title: "Áp dụng thành công!"
          })
        },
        onError: () => {
          toast({
            variant: "error",
            title: t("message.out_of_giftcode")
          })
        }
      }
    )
  }

  return (
    <PackageCard
      cardItem={item as any}
      textDetail={textDetail()}
      textAction={textAction()}
      actionDetail={() => {
        GAEventsTracker("Inventory_view_ACard")
        setVisible({
          ...visible,
          appotaDetail: true
        })

        setDataSelected(item)
      }}
      action={() => onUse(item)}
      loading={isPending}
    />
  )
}
