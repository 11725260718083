import { QUERY_KEY, REPLY_LIMIT_SIZE } from "@/app/configs"
import { IGameDetailRequest, IGameReviewRequest } from "@/app/interfaces"
import { useInfiniteQuery, useQuery } from "@tanstack/react-query"
import { GameServices } from "../../services/GameServices"
import { UserService } from "../../services"

const getGameDetailFn = async (params: IGameDetailRequest) => {
  const data = await GameServices.getGameDetail(params)
  return data.data.data
}

export const getGameReviewFn = async (params: IGameReviewRequest) => {
  const data = await GameServices.getReviewByGame(params)
  return data.data.data
}

const getListSuggestFriendByGame = async (
  game_id: string,
  pageParam: number
) => {
  const data = await UserService.getListSuggestByGame(game_id, pageParam)
  return data?.data?.data
}

export const useQueryGetListSuggestFriendByGame = (game_id: string) => {
  return useInfiniteQuery({
    queryKey: [QUERY_KEY.LIST_SUGGEST_FRIEND_BY_GAME, game_id],
    queryFn: ({ pageParam }) => getListSuggestFriendByGame(game_id, pageParam),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.length > 9 ? pages?.length + 1 : undefined
    },
    enabled: !!game_id
  })
}
export const useQueryGetDetailGame = (params: IGameDetailRequest) => {
  return useQuery({
    queryKey: [QUERY_KEY.GAME_DETAIL, params],
    queryFn: () => getGameDetailFn(params),
    enabled: !!params
  })
}

export const useQueryGetListReviewGame = (game_id: string) => {
  return useInfiniteQuery({
    queryKey: [QUERY_KEY.GAME_REVIEW_LIST, { game_id: game_id }],
    queryFn: ({ pageParam }) =>
      getGameReviewFn({
        game_id: game_id,
        page: pageParam,
        limit: REPLY_LIMIT_SIZE
      }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.length >= REPLY_LIMIT_SIZE ? pages?.length + 1 : 0
    }
  })
}
