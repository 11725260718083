import { DATE_FORMAT_FULL } from "@/app/configs"
import moment from "moment"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"

export const ItemTopupTransaction = ({
  item,
  onSelecteData
}: {
  item: any
  onSelecteData: (item: any) => void
}) => {
  const { t } = useTranslation()
  const router = useRouter()
  const onNavigateToDetail = () => {
    const type = String(router?.query?.type)
    onSelecteData(item)
    router.push({
      pathname: router.pathname,
      query: {
        id: router?.query?.id,
        tab: router?.query?.tab,
        type: `${type?.replace("-all", "")}-detail`
      }
    })
  }

  return (
    <div
      className="p-[12px] bg-white dark:bg-white/10 border rounded-[12px] cursor-pointer border-black/10 hover:border-main-01 dark:border-0 space-y-[12px]"
      onClick={onNavigateToDetail}
    >
      <p className="text-[12px] Nunito-400 text-neutral-06 dark:text-neutral-08">
        {moment.unix(item?.created_at).format(DATE_FORMAT_FULL)}
      </p>
      <div className="gap-1 flex-row rounded-[8px]">
        <div className="flex">
          <img
            src={item?.game_icon}
            alt={item?.game_name}
            className="w-[55px] h-[55px] rounded-[8px] mr-[13px] object-cover"
          />
          <div className="flex-1">
            <div className="flex items-center justify-between">
              <p className="text-[14px] Nunito-600 dark:text-white">
                {item?.game_name}
              </p>
            </div>
            <p className="text-[12px] Nunito-400 text-neutral-06 dark:text-neutral-08 line-clamp-1">
              {t("profile.item_code_summary")}: {item?.transaction_id}
            </p>
            <p className="text-[12px] Nunito-400 text-neutral-06 dark:text-neutral-08 line-clamp-1">
              {t("topup.name_role")}: {item?.role_name}
            </p>
          </div>
        </div>
      </div>
      <div className="pt-[12px] border-t-[1px] border-dashed border-[rgba(0, 0, 0, 0.10)] flex flex-col gap-[8px]">
        <div className="flex items-center justify-between gap-2">
          <p className="text-xs text-neutral-06 dark:text-neutral-08">
            {t("store.total")}:
          </p>
          <span className="text-sm text-main-01 Nunito-700 dark:text-main-red-02">
            {item?.amount?.toLocaleString()}&nbsp;đ
          </span>
        </div>
        {/* <div className="flex items-center justify-between">
          <p className="text-[12px] Nunito-400 text-neutral-06 dark:text-neutral-08">
            {t("store.buy_date")}
          </p>
        </div> */}
      </div>
    </div>
  )
}
