import { IconClose } from "@/app/components/icon-tsx/IconClose"
import { IconCopied } from "@/app/components/icon-tsx/IconCopied"
import { IconNonCopy } from "@/app/components/icon-tsx/IconNonCopy"
import { Button } from "@/app/components/ui/button"
import { Dialog, DialogContent } from "@/app/components/ui/dialog"
import { useToast } from "@/app/components/ui/use-toast"
import { DATE_FORMAT, GIFTCODE_TYPE, QUERY_KEY } from "@/app/configs"
import { PackageContext } from "@/app/context/PackageContext"
import { useMutationUseItem } from "@/app/services/query-fn/reward-center/package/package-mutation"
import { useQueryClient } from "@tanstack/react-query"
import moment from "moment"
import { useTranslation } from "next-i18next"
import { FC, useContext, useEffect, useState } from "react"

interface ModalViewGiftcodeProps {
  open: boolean
  handleClose: () => void
}

export const ModalViewGiftcode: FC<ModalViewGiftcodeProps> = ({
  open,
  handleClose
}) => {
  const { visible, setVisible, dataSelected, setDataSelected } =
    useContext(PackageContext)
  const { t } = useTranslation()
  const { toast } = useToast()

  const onClose = () => {
    setVisible({
      ...visible,
      viewGiftcode: false
    })

    setDataSelected()
  }

  const onCopyCode = () => {
    navigator.clipboard.writeText(dataSelected?.item_received?.giftcode || "")
    toast({
      variant: "success",
      title: "Sao chép thành công!"
    })
    onClose()
  }

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className="max-w-[420px] border-0 shadow-[0px_4px_30px_0px_rgba(0,0,0,0.06)] rounded-[24px] p-[24px] sm:rounded-[24px] gap-0 space-y-[24px] xs:w-[90vw]">
        <div className="flex items-center justify-between">
          <p className="text-[16px] Nunito-700">Giftcode</p>
          <IconClose className="cursor-pointer" onClick={() => onClose()} />
        </div>

        <div>
          <p className="text-[14px] text-neutral-05 dark:text-neutral-07">
            {t("reward.exchange_package")}
          </p>
          <p className="text-[16px] Nunito-500">{dataSelected?.name}</p>
        </div>
        {!dataSelected?.is_used && (
          <div>
            <p className="text-[14px] text-neutral-05 dark:text-neutral-07">
              {t("reward.received_time")}
            </p>
            <p className="text-[16px] Nunito-500">
              {moment.unix(dataSelected?.created_at).format(DATE_FORMAT)}
            </p>
          </div>
        )}

        {(dataSelected?.item_received?.game_name ||
          dataSelected?.item_received?.gc_game_name) && (
          <div>
            <p className="text-[14px] text-neutral-05 dark:text-neutral-07">
              {t("game.range_use")}
            </p>
            <p className="text-[16px] Nunito-500">
              {dataSelected?.item_received?.game_name ||
                dataSelected?.item_received?.gc_game_name}
            </p>
          </div>
        )}

        {dataSelected?.is_used && dataSelected?.updated_at && (
          <div>
            <p className="text-[14px] text-neutral-05 dark:text-neutral-07">
              {t("giftcode.date_of_use")}
            </p>
            <p className="text-[16px] Nunito-500">
              {moment.unix(dataSelected?.updated_at).format(DATE_FORMAT)}
            </p>
          </div>
        )}

        {dataSelected?.is_used && (
          <div>
            <p className="text-[14px] text-neutral-05 dark:text-neutral-07">
              {t("giftcode.status")}
            </p>
            <p className="text-[16px] Nunito-500">{t("giftcode.used")}</p>
          </div>
        )}

        {dataSelected?.item_received?.giftcode && (
          <div className="border border-dashed border-black/10 dark:border-neutral-07 rounded-[12px] p-[12px] text-[24px] Nunito-700 text-main-01 text-center leading-[38px]">
            {dataSelected?.item_received?.giftcode}

            {dataSelected?.item_received?.expired_date && (
              <p className="text-[14px] text-neutral-05 dark:text-neutral-07 Nunito-400 leading-[19px]">
                HSD:{" "}
                {moment(dataSelected?.item_received?.expired_date).format(
                  DATE_FORMAT
                )}
              </p>
            )}
          </div>
        )}

        <div className="flex gap-[16px] justify-end items-center">
          <Button
            variant="grey"
            onClick={() => onClose()}
            className="text-neutral-05 dark:text-neutral-08 rounded-[16px] px-[24px] py-[12px] w-fit h-[46px]"
          >
            {t("merchandise.cancel")}
          </Button>
          <Button
            variant="main"
            onClick={() => {
              if (dataSelected?.status === GIFTCODE_TYPE.PENDING) {
                setVisible({
                  ...visible,
                  viewGiftcode: false,
                  confirmGiftcode: true
                })
              } else {
                onCopyCode()
              }
            }}
            className="rounded-[16px] px-[24px] py-[12px] w-fit h-[46px]"
          >
            {dataSelected?.status === GIFTCODE_TYPE.PENDING
              ? t("reward.use_now")
              : t("reward.copy")}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
