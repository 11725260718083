import { IGameBannerRequest, IGameRequest } from "@/app/interfaces"
import { useInfiniteQuery, useQuery } from "@tanstack/react-query"
import { QUERY_KEY } from "@/app/configs"
import { IShopParams } from "@/app/interfaces/IReward"
import RewardService from "@/app/services/services/RewardService"

const getListMissionFn = async () => {
  const data = await RewardService.getListTask()

  return data.data.data
}

export const useQueryGetListMission = () => {
  return useQuery({
    queryKey: [QUERY_KEY.LIST_MISSION],
    queryFn: () => getListMissionFn(),
    refetchOnWindowFocus: false,
    retry: 0
  })
}
