import React from "react"

export const IconTabEvents = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 2V5"
        stroke="#706E7E"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M8 2V5"
        stroke="#706E7E"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M3 7.5C3 5.29086 4.79086 3.5 7 3.5H17C19.2091 3.5 21 5.29086 21 7.5V18C21 20.2091 19.2091 22 17 22H7C4.79086 22 3 20.2091 3 18V7.5Z"
        stroke="#706E7E"
        strokeWidth="1.5"
      />
      <path
        d="M3 9H21"
        stroke="#706E7E"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}
