import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger
} from "@/app/components/ui/dropdown-menu"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@/app/components/ui/select"
import React, { FC, SetStateAction, useContext, useMemo, useState } from "react"
import { StartTimeFilter } from "../../users/common/filter-post/StartTime"
import { useTranslation } from "next-i18next"
import { EndTimeFilter } from "../../users/common/filter-post/EndTime"
import { Button } from "@/app/components/ui/button"
import { useFormContext } from "react-hook-form"
import { AppContext } from "@/app/context"
import { IconFilter } from "@/app/components/icon-tsx/IconFilter"
import { IGameGiftcode } from "@/app/interfaces/IGiftcode"
import { useQueryGetListGame } from "@/app/services/query-fn/game"
import { useRouter } from "next/router"

interface PackageFilterProps {
  params: any
  setParams: React.Dispatch<SetStateAction<any>>
}

export const PackageFilter: FC<PackageFilterProps> = ({
  params,
  setParams
}) => {
  const { t } = useTranslation()
  const [game, setGame] = useState<IGameGiftcode | undefined>({
    game_name: t("common.all"),
    game_id: "all",
    icon: ""
  })

  const { data: listGame } = useQueryGetListGame({
    category: "",
    type: "",
    limit: 100,
    page: 1
  })
  const { setValue, watch } = useFormContext()
  const router = useRouter()
  const { themeApp } = useContext(AppContext)
  const [openFilter, setOpenFilter] = useState<boolean>(false)
  const [openStartTime, setOpenStartTime] = useState<boolean>(false)
  const [openEndTime, setOpenEndTime] = useState<boolean>(false)

  const selectedStartTime = watch("start_date", undefined)
  const selectedEndTime = watch("end_date", undefined)
  const status = watch("status", "")
  const isEnable = useMemo(() => {
    let result = true

    if (
      selectedStartTime &&
      selectedEndTime &&
      selectedStartTime < selectedEndTime
    ) {
      result = true
    }

    if (
      (selectedStartTime && !selectedEndTime) ||
      (!selectedStartTime && selectedEndTime) ||
      selectedStartTime >= selectedEndTime
    ) {
      result = false
    }

    return result
  }, [status, selectedStartTime, selectedEndTime])
  const OptionGame = !!listGame ? listGame : []

  const OptionStatus = [
    {
      title: t("common.all"),
      value: "all"
    },
    {
      title: t("reward.not_use"),
      value: "pending"
    },
    {
      title: t("reward.used"),
      value: "done"
    }
  ]

  const onChangeStartTime = () => {
    setOpenStartTime(!openStartTime)
  }

  const onChangeEndTime = () => {
    setOpenEndTime(!openEndTime)
  }

  const clearFilter = () => {
    setValue("start_date", undefined)
    setValue("end_date", undefined)
    setValue("status", "")
    setParams({
      ...params,
      start_date: undefined,
      end_date: undefined,
      status: "",
      page: 1
    })
    setOpenFilter(false)
  }

  const onChangeType = (value: string) => {
    if (value !== "all") {
      setValue("status", value)
    } else {
      setValue("status", "")
    }
  }

  const onChangeGame = (value: string) => {
    const _game: any = OptionGame?.find((item: any) => item?.game_id === value)
    value === "all"
      ? setGame({ game_name: t("common.all"), game_id: "all", icon: "" })
      : setGame(_game)
    setParams({
      ...params,
      game_id: _game?.game_id
    })
  }

  return (
    <div className="flex items-center gap-[8px] justify-between md:justify-start">
      <Select
        value={game?.game_id || "all"}
        onValueChange={(value: string) => onChangeGame(value)}
      >
        <div
          className={`relative ${
            router?.query?.item !== "giftcode" && "hidden"
          }`}
        >
          <SelectTrigger
            asChild={false}
            className="px-[8px] h-[28px] border-main-01 dark:bg-main-disable rounded-[8px] bg-bg-disable dark:bg-bg-disable/10 flex gap-[4px] items-center text-main-01 dark:text-main-disable text-[12px]"
          >
            <SelectValue placeholder={t("common.chose_game")}>
              <div className="flex-1 line-clamp-1">{game?.game_name || ""}</div>
            </SelectValue>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M5.8335 8.3335L10.0002 11.6668L14.1668 8.3335"
                stroke="#7673E6"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </SelectTrigger>
        </div>

        <SelectContent align="end" className="p-1 rounded-[16px]">
          {OptionGame &&
            [
              {
                game_name: t("common.all"),
                game_id: "all",
                icon: ""
              },
              ...OptionGame.sort((a, b) =>
                a.game_name.localeCompare(b.game_name)
              )
            ]?.map((item: any) => (
              <SelectItem
                value={item?.game_id}
                key={item?.game_id}
                className="p-[6px] h-[36px] rounded-[12px] hover:!bg-bg-disable hover:!text-main-01 Nunito-500 focus:bg-bg-disable focus:text-main-01 dark:hover:!bg-[#F1F1FC]/[.1] dark:focus:!bg-[#F1F1FC]/[.1]"
              >
                <div
                  className="flex items-center gap-[6px]"
                  title={item?.game_name}
                >
                  {item?.icon && (
                    <img
                      src={item?.icon}
                      alt={item?.game_name}
                      className="w-[24px] h-[24px] rounded-[6px]"
                    />
                  )}
                  <p className="flex-1 line-clamp-1">{item?.game_name}</p>
                </div>
              </SelectItem>
            ))}
        </SelectContent>
      </Select>
      <DropdownMenu open={openFilter} onOpenChange={() => setOpenFilter(false)}>
        <DropdownMenuTrigger asChild className={`outline-0 mt-0`}>
          <div
            onClick={() => setOpenFilter(true)}
            className={`w-fit ml-auto md:mt-0 border border-main-01 cursor-pointer h-[28px] px-[8px] rounded-[8px] bg-bg-disable dark:bg-white/10 text-main-01 dark:text-main-white text-[14px] Nunito-600 flex items-center gap-[8px]`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M2.5 5.83325H5M5 5.83325C5 7.21396 6.11929 8.33325 7.5 8.33325C8.88071 8.33325 10 7.21396 10 5.83325C10 4.45254 8.88071 3.33325 7.5 3.33325C6.11929 3.33325 5 4.45254 5 5.83325ZM2.5 14.1666H7.5M15 14.1666H17.5M15 14.1666C15 15.5473 13.8807 16.6666 12.5 16.6666C11.1193 16.6666 10 15.5473 10 14.1666C10 12.7859 11.1193 11.6666 12.5 11.6666C13.8807 11.6666 15 12.7859 15 14.1666ZM12.5 5.83325H17.5"
                stroke="#7673E6"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          align="end"
          className="min-w-[343px] rounded-[24px] p-[16px] overflow-visible dark:bg-dark-mode-box"
        >
          <div className="w-full bg-white dark:bg-dark-mode-box">
            <div className="mb-[24px]">
              <p className="text-[16px] Nunito-600 text-neutral-03 dark:text-neutral-06">
                {t("reward.status")}
              </p>
              <Select
                value={status !== "" ? status : "all"}
                onValueChange={(value: string) => onChangeType(value)}
              >
                <SelectTrigger className="w-full rounded-none border-0 border-b-[1px] border-black/10 dark:border-neutral-07 text-sm Nunito-500 text-neutral-05 dark:text-main-white dark:bg-transparent dark:backdrop-blur-[20px] text-left">
                  <SelectValue
                    placeholder={t("reward.status_placeholder")}
                    defaultValue=""
                  />
                </SelectTrigger>
                <SelectContent className="p-1 rounded-[16px]">
                  {OptionStatus.map((item: Record<string, string>) => (
                    <SelectItem
                      value={item?.value}
                      key={item?.value}
                      className="p-3 rounded-[8px] hover:!bg-bg-disable hover:!text-main-01 text-sm Nunito-600 focus:bg-bg-disable focus:text-main-01 dark:hover:!bg-[#F1F1FC]/[.1] dark:focus:!bg-[#F1F1FC]/[.1]"
                    >
                      {item?.title}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div>
              <p className="text-base Nunito-600 text-neutral-03 dark:text-neutral-06">
                {t("profile.time_title")}
              </p>
              <div className="flex items-center gap-[12px]">
                <StartTimeFilter
                  open={openStartTime}
                  onChange={onChangeStartTime}
                />
                <span>-</span>
                <EndTimeFilter open={openEndTime} onChange={onChangeEndTime} />
              </div>
              {selectedStartTime >= selectedEndTime && (
                <p className="mt-2 text-xs text-main-red-02">
                  {t("profile.invalid_date")}
                </p>
              )}
            </div>

            <div className="w-full flex items-center justify-end gap-[16px] mt-[24px]">
              <Button
                variant="grey"
                className="text-[14px] bg-bg-button text-neutral-04 dark:text-neutral-08 Nunito-700 h-[30px] py-[6px] px-[24px] rounded-[12px]"
                autoFocus={false}
                onClick={clearFilter}
              >
                {t("merchandise.cancel")}
              </Button>

              <Button
                variant="main"
                disabled={!isEnable}
                className="text-[14px] h-[30px] Nunito-700 py-[6px] px-[24px] rounded-[12px]"
                onClick={() => {
                  setOpenFilter(false)
                  setParams({
                    ...params,
                    start_date: selectedStartTime,
                    end_date: selectedEndTime,
                    status: status,
                    page: 1
                  })
                }}
              >
                {t("profile.filter")}
              </Button>
            </div>
          </div>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}
