const onTrackPageView = (pixel: any, detailItem: any) => {
  if (!!pixel && !!detailItem) {
    pixel.trackEvent("S1_PageView", {
      content_ids: detailItem?.id,
      content_category: "merchandise",
      content_name: detailItem?.name
    })
  }
}

const onTrackTimeView = (pixel: any, detailItem: any) => {
  if (!!pixel && !!detailItem) {
    pixel.trackEvent("S1_Timeview", {
      content_ids: detailItem?.id,
      content_category: "merchandise",
      content_name: detailItem?.name
    })
  }
}

const onTrackBuyNow = (pixel: any, item: any) => {
  if (!!pixel && !!item)
    pixel?.trackEvent("S2_BuyNow", {
      content_ids: item?.item_id,
      content_name: JSON.stringify([
        {
          id: item?.item_id,
          quantity: item?.quantity
        }
      ]),
      value: item?.price ?? 0
    })
}

const onTrackNewAddress = (pixel: any) => {
  const listPayment = window.localStorage.getItem("listPayment")
  const parstListPayment = JSON.parse(String(listPayment))
  const listId = Array.isArray(parstListPayment)
    ? parstListPayment?.map((item: any) => item?.item_id)
    : []
  pixel?.trackEvent("S2_Address", {
    content_category: "merchandise",
    content_ids: listId
  })
}

const onTrackVoucher = (pixel: any) => {
  !!pixel && pixel?.trackEvent("S2_Voucher")
}

const onTrackApplyVoucher = (pixel: any) => {
  const listPayment = window.localStorage.getItem("listPayment")
  const parstListPayment = JSON.parse(String(listPayment))
  const listId = Array.isArray(parstListPayment)
    ? parstListPayment?.map((item: any) => item?.item_id)
    : []
  const listContent = Array.isArray(parstListPayment)
    ? parstListPayment.map((item: any) => ({
        id: item?.item_id,
        quantity: item?.quantity
      }))
    : []
  const result = {
    content_ids: listId,
    contents: listContent
  }
  pixel?.trackEvent("S2_ApplyVoucher", result)
}

const onTrackPaymentNow = (pixel: any, productList: any) => {
  const listId = productList.map((item: any) => item?.item_id)
  const listName = productList.map((item: any) => item?.item_name)
  const listContent = productList.map((item: any) => ({
    id: item?.item_id,
    quantity: item?.quantity
  }))
  const totalValue = productList.reduce((sum: number, currentItem: any) => {
    return sum + Number(currentItem?.quantity) * Number(currentItem?.price)
  }, 0)

  const result = {
    content_ids: listId,
    content_name: listName,
    contents: listContent,
    currency: "VND",
    value: totalValue
  }
  !!pixel && pixel?.trackEvent("S3_Pay", result)
}

const onTrackPaymentSuccess = (pixel: any, dataResult: any) => {
  if (!!dataResult && !!pixel && !!dataResult?.list_item) {
    const listId = dataResult?.list_item.map((item: any) => item?.card_id)
    const listName = dataResult?.list_item.map((item: any) => item?.item_name)
    const listContent = dataResult?.list_item.map((item: any) => ({
      id: item?.card_id,
      quantity: item?.quantity
    }))
    const result = {
      content_ids: listId,
      content_name: listName,
      contents: listContent,
      currency: "VND",
      value: dataResult?.amount
    }
    !!pixel && pixel?.trackEvent("S3_Success", result)
  }
}
const onTrackPaymentCancel = (pixel: any) => {
  !!pixel && pixel?.trackEvent("S3_Cancel")
}

const onTrackBackToHome = (pixel: any) => {
  !!pixel &&
    pixel?.trackEvent("S3_BackHome", {
      content_category: "merchandise"
    })
}

const onTrackBuyAgain = (pixel: any) => {
  !!pixel &&
    pixel?.trackEvent("S3_BuyAgain", {
      content_category: "merchandise"
    })
}

const onTrackBackMerchandise = (pixel: any) => {
  !!pixel &&
    pixel?.trackEvent("S3_BackMerchandise", {
      content_category: "merchandise",
      content_name: "inCardPage",
      content_type: "backMerchandise",
      currency: "VND"
    })
}

const onTrackAddToCart = (pixel: any, detailItem: any) => {
  if (!!pixel && !!detailItem) {
    pixel.trackEvent("S2_AddToCart", {
      content_ids: detailItem?.id,
      contents: detailItem?.name,
      currency: "VND",
      value: detailItem?.price
    })
  }
}

const onTrackShoppingCart = (pixel: any) => {
  if (!!pixel) {
    pixel.trackEvent("S2_ShoppingCart", {
      content_category: "merchandise",
      content_name: "ViewShoppingCart",
      currency: "VND"
    })
  }
}

const facebookPixel = {
  onTrackPageView,
  onTrackTimeView,
  onTrackBuyNow,
  onTrackNewAddress,
  onTrackVoucher,
  onTrackApplyVoucher,
  onTrackPaymentNow,
  onTrackPaymentSuccess,
  onTrackPaymentCancel,
  onTrackBackToHome,
  onTrackBuyAgain,
  onTrackBackMerchandise,
  onTrackAddToCart,
  onTrackShoppingCart
}

export { facebookPixel }
