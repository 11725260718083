import { AxiosResponse } from "axios"
import IApiResponse from "@interfaces/IApiResponse"
import { INoticesResult } from "@interfaces/INotice"
import { http } from "@/app/api"
import { removeNullKeyObject } from "@/app/lib/utils"

const resource = "/api/notification"
export default class NotificationService {
  static getNotices(
    page: number,
    noticeType?: string
  ): Promise<AxiosResponse<IApiResponse<any>>> {
    const params = removeNullKeyObject({
      page: page,
      is_read: noticeType === "all" ? "" : 0
    })
    return http.get(`${resource}/inbox`, { params: params })
  }
}
