import React, { useContext } from "react"
import { Button } from "../ui/button"
import Link from "next/link"
import Logo from "@/public/img/playee_full_text.png"
import LogoDark from "@/public/img/playee_full_text_dark.png"
import LogoBct from "@public/img/logo_btc.png"
import { AppContext } from "@/app/context"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import Image from "next/image"

export const Footer = () => {
  const { themeApp } = useContext(AppContext)
  const { t } = useTranslation()
  const router = useRouter()
  return (
    <div className="hidden sm:block w-full bg-[#F9F9F9] dark:bg-[#1A1925] border-t-[1px] border-black/10 dark:border-neutral-03">
      <div className="flex justify-center w-full py-[24px] border-b-[1px] border-black/10 dark:border-neutral-03">
        <div className="w-full pc:w-[1280px]">
          <div className="flex items-center justify-center w-full pc:justify-start gap-[20px]">
            <Image
              width={145}
              height={62}
              alt="Playee logo"
              src={themeApp === "dark" ? LogoDark.src : Logo.src}
              className="w-[120px] h-[51px] pc:w-[145px] pc:h-[62px] object-contain"
            />
            {router.pathname.includes("merchandise") && (
              <div className="flex pc:hidden">
                <Link
                  href="http://online.gov.vn/Home/WebDetails/115905"
                  target="_blank"
                >
                  <Image
                    width={100}
                    height={37}
                    alt="Merchandise Playee"
                    src={LogoBct.src}
                    className="w-[100px] h-[37px] object-contain max-w-[100px] max-h-[37px]"
                  />
                </Link>
              </div>
            )}
          </div>
          <div className="flex flex-col pc:flex-row w-full mt-[24px] pc:mt-[32px] justify-between gap-[24px]">
            {/* Company info */}
            <div className="w-full pc:w-[627px] space-y-[8px] pc:space-y-[16px]">
              <p className="text-base text-center Nunito-700 text-bg-01 dark:text-main-white pc:text-start">
                {t("footer.company_name")}
              </p>
              <div className="flex flex-col">
                <p className="text-sm text-center text-neutral dark:text-neutral-08 Nunito-400 pc:text-start">
                  {t("footer.mst")}
                </p>
                <p className="text-sm text-center text-neutral dark:text-neutral-08 Nunito-400 pc:text-start">
                  {t("footer.issued_by")}
                </p>
              </div>
              <div className="flex items-center justify-center gap-2 pc:justify-start">
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14 7.75893C14 11.0317 10.25 15.1663 8 15.1663C5.75 15.1663 2 11.0317 2 7.75893C2 4.48614 4.68629 1.83301 8 1.83301C11.3137 1.83301 14 4.48614 14 7.75893Z"
                    stroke="#7673E6"
                  />
                  <path
                    d="M10 7.83301C10 8.93758 9.10457 9.83301 8 9.83301C6.89543 9.83301 6 8.93758 6 7.83301C6 6.72844 6.89543 5.83301 8 5.83301C9.10457 5.83301 10 6.72844 10 7.83301Z"
                    stroke="#7673E6"
                  />
                </svg>
                <p className="text-sm text-neutral dark:text-neutral-08 Nunito-400">
                  {t("footer.address")}
                </p>
              </div>
              <div className="flex items-center justify-center gap-2 pc:justify-start">
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14 13.1667V12.0694C14 11.5242 13.6681 11.0339 13.1619 10.8314L11.8058 10.289C11.1619 10.0314 10.4281 10.3104 10.118 10.9307L10 11.1667C10 11.1667 8.33333 10.8333 7 9.5C5.66667 8.16667 5.33333 6.5 5.33333 6.5L5.56934 6.38199C6.18959 6.07187 6.46857 5.33809 6.21103 4.69424L5.66859 3.33815C5.46611 2.83194 4.97583 2.5 4.43062 2.5H3.33333C2.59695 2.5 2 3.09695 2 3.83333C2 9.72437 6.77563 14.5 12.6667 14.5C13.403 14.5 14 13.903 14 13.1667Z"
                    stroke="#7673E6"
                    strokeLinejoin="round"
                  />
                </svg>

                <p className="text-sm text-neutral dark:text-neutral-08 Nunito-400">
                  {t("footer.phone")}
                </p>
              </div>
            </div>

            {/* Link footer */}
            <div className="w-full pc:w-[519px] flex flex-col pc:flex-row items-center pc:items-start justify-between gap-[12px]">
              <ul className="flex flex-row pc:flex-col gap-[24px] pc:gap-[16px]">
                <li className="text-sm Nunito-400 text-bg-01 dark:text-main-white tracking-[0.56px] opacity-100 active:opacity-60">
                  <Link href={"/term-of-services"} target="_blank">
                    {t("footer.thoa_thuan_cung_cap")}
                  </Link>
                </li>
                <li className="text-sm Nunito-400 text-bg-01 dark:text-main-white tracking-[0.56px] opacity-100 active:opacity-60">
                  <Link href={"/privacy-policy"} target="_blank">
                    {t("footer.privacy")}
                  </Link>
                </li>
                {router.pathname.includes("merchandise") && (
                  <li className="hidden mt-2 pc:flex">
                    <Link
                      href="http://online.gov.vn/Home/WebDetails/115905"
                      target="_blank"
                    >
                      <img
                        src={LogoBct.src}
                        className="w-[124px] h-[46px] object-contain max-w-[124px] max-h-[46px]"
                      />
                    </Link>
                  </li>
                )}
              </ul>

              <ul className="flex flex-row pc:flex-col gap-[24px] pc:gap-[16px]">
                <li className="text-sm Nunito-400 text-bg-01 dark:text-main-white tracking-[0.56px] opacity-100 active:opacity-60">
                  <Link href={"/phuong-thuc-thanh-toan"} target="_blank">
                    {t("footer.phuong_thuc_thanh_toan")}
                  </Link>
                </li>
                <li className="text-sm Nunito-400 text-bg-01 dark:text-main-white tracking-[0.56px] opacity-100 active:opacity-60">
                  <Link href={"/chinh-sach-mua-hang"} target="_blank">
                    {t("footer.chinh_sach_mua_hang")}
                  </Link>
                </li>
                <li className="text-sm Nunito-400 text-bg-01 dark:text-main-white tracking-[0.56px] opacity-100 active:opacity-60">
                  <Link href={"/chinh-sach-giao-hang"} target="_blank">
                    {t("footer.chinh_sach_giao_hang")}
                  </Link>
                </li>
                <li className="text-sm Nunito-400 text-bg-01 dark:text-main-white tracking-[0.56px] opacity-100 active:opacity-60">
                  <Link href={"/chinh-sach-doi-tra"} target="_blank">
                    {t("footer.chinh_sach_doi_tra")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* copyright */}
      <div className="flex justify-center p-[8px]">
        <div className="flex items-center gap-[12px]">
          <p className="text-sm Nunito-400 text-neutral-06 dark:text-neutral-08 leading-[20px]">
            Copyright © 2023 Playee Game Portal
          </p>
          <div className="w-[1px] h-[10px] bg-neutral-06" />
          <Link
            href="https://www.facebook.com/PlayeeGamePortal"
            target="_blank"
          >
            <Button
              variant="none"
              className="p-0 text-sm text-main-01 Nunito-400 h-[20px] active:text-main-disable"
            >
              Facebook
            </Button>
          </Link>
          <div className="w-[1px] h-[10px] bg-neutral-06" />
          <Link href="mailto:contact@playee.vn" target="_blank">
            <Button
              variant="none"
              className="p-0 text-sm text-main-01 Nunito-400 h-[20px] active:text-main-disable"
            >
              contact@playee.vn
            </Button>
          </Link>
        </div>
      </div>
    </div>
  )
}
