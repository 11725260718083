import { http } from "@/app/api"
import {
  IBannerGame,
  ICategory,
  IDeleteReplyRequest,
  IGame,
  IGameBannerRequest,
  IGameComment,
  IGameDetailRequest,
  IGameRequest,
  IGameReviewRequest,
  ILikeReviewRequest,
  IRateGameRequest,
  IReplyReviewRequest
} from "@/app/interfaces"
import IApiResponse from "@/app/interfaces/IApiResponse"
import { AxiosResponse } from "axios"

const resource = "/api/games"

export class GameServices {
  static getAllGames(
    params: IGameRequest
  ): Promise<AxiosResponse<IApiResponse<IGame[]>>> {
    return http.get(`${resource}/list`, {
      params
    })
  }

  static getAllCategories(): Promise<AxiosResponse<IApiResponse<ICategory[]>>> {
    return http.get(`${resource}/category`)
  }

  //game detail
  static getGameDetail(
    params: IGameDetailRequest
  ): Promise<AxiosResponse<IApiResponse<IGame>>> {
    return http.get(`${resource}/view`, { params: { id: params.game_id } })
  }

  static followGame(
    params: IGameDetailRequest
  ): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`${resource}/follow`, { game_id: params.game_id })
  }

  static registerGame(
    params: IGameDetailRequest
  ): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`${resource}/preRegister`, { game_id: params.game_id })
  }

  static getReviewByGame(
    params: IGameReviewRequest
  ): Promise<AxiosResponse<IApiResponse<IGameComment[]>>> {
    return http.get(`${resource}/listReview`, {
      params
    })
  }

  static rateGame(
    params: IRateGameRequest
  ): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`${resource}/rate`, {
      ...params
    })
  }

  static likeReviewGame(
    params: ILikeReviewRequest
  ): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`${resource}/likeReview`, {
      ...params
    })
  }

  static replyReviewGame(
    params: IReplyReviewRequest
  ): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`${resource}/replyReview`, {
      ...params
    })
  }

  static deleteReviewGame(
    params: IGameDetailRequest
  ): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`${resource}/deleteRate`, {
      ...params
    })
  }

  static deleteReplyReviewGame(
    params: IDeleteReplyRequest
  ): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`${resource}/deleteReply`, {
      ...params
    })
  }

  static getListBanner(
    params: IGameBannerRequest
  ): Promise<AxiosResponse<IApiResponse<IBannerGame[]>>> {
    return http.get(`/api/banner/list`, { params })
  }

  static getListFollowGame(
    other_userid?: string
  ): Promise<AxiosResponse<IApiResponse<IGame[]> & { totalFollow: number }>> {
    return http.get(`${resource}/listFollow`, { params: { other_userid } })
  }
}
