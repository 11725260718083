export const LoadingInside = ({
  className,
  style
}: {
  className?: string
  style: any
}) => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        d="M11.75 6.5C11.75 3.60051 9.39949 1.25 6.5 1.25C3.60051 1.25 1.25 3.60051 1.25 6.5C1.25 9.39949 3.60051 11.75 6.5 11.75"
        stroke="#FFBFCC"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
