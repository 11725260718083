import { IAddNewsRequest } from "@/app/interfaces"
import { NewsService } from "../../services"
import { useMutation } from "@tanstack/react-query"
import { QUERY_KEY } from "@/app/configs"

const addNewsFn = (body: IAddNewsRequest) => {
  return NewsService.addnews(body)
}

const saveDraftFn = (body: IAddNewsRequest) => {
  return NewsService.saveDraft(body)
}

const deletaDraftFn = (body: { id: string }) => {
  return NewsService.deleteDraft(body)
}

const uploadMediaFn = (formData: any) => {
  return NewsService.uploadMedia(formData)
}
const editNewsFn = (body: IAddNewsRequest) => {
  return NewsService.editNews(body)
}

export const useMutationUploadMedia = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.UPLOAD_MEDIA],
    mutationFn: uploadMediaFn
  })
}

export const useMutationCreateNews = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.CREATE_NEWS],
    mutationFn: addNewsFn
  })
}

export const useMutationEditNews = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.EDIT_NEWS],
    mutationFn: editNewsFn
  })
}

export const useMutationSaveDraft = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.SAVE_DRAFT],
    mutationFn: saveDraftFn
  })
}

export const useMutationDeleteDraft = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.DELETE_DRAFT],
    mutationFn: deletaDraftFn
  })
}
