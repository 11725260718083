export const IconNotiIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      className={className}
    >
      <path
        opacity="0.4"
        d="M5.66536 13.6667C8.61088 13.6667 10.9987 11.2789 10.9987 8.33333C10.9987 7.86007 10.9371 7.40121 10.8214 6.96434C10.6617 6.98783 10.4983 7 10.332 7C8.49108 7 6.9987 5.50762 6.9987 3.66667C6.9987 3.50043 7.01087 3.33704 7.03436 3.17733C6.59749 3.06164 6.13862 3 5.66536 3C2.71985 3 0.332031 5.38781 0.332031 8.33333C0.332031 11.2789 2.71985 13.6667 5.66536 13.6667Z"
        fill="#7673E6"
      />
      <path
        d="M13.6667 3.66634C13.6667 5.34106 12.4316 6.72731 10.8227 6.96401C10.663 6.98751 10.4996 6.99967 10.3333 6.99967C8.49238 6.99967 7 5.50729 7 3.66634C7 3.50011 7.01217 3.33672 7.03566 3.17701C7.27236 1.56804 8.65862 0.333008 10.3333 0.333008C12.1743 0.333008 13.6667 1.82539 13.6667 3.66634Z"
        fill="#7673E6"
      />
    </svg>
  )
}
