import React, { useEffect, useRef, useState } from "react"
import { useToast } from "@/app/components/ui/use-toast"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "next-i18next"
import CreatableSelect from "react-select/creatable"
import { useQueryGetHotTopic } from "@/app/services/query-fn"
import { ActionMeta, MultiValue } from "react-select"
import { removeVietnameseTones } from "@/app/helpers/function"

const LIMIT_TAG = 10
const SPACE_KEYCODE = 32
type MyOptionType = { label: string; value: string }

export const TagMention = () => {
  const { toast } = useToast()
  const { t } = useTranslation()
  const { data: otherHashtag } = useQueryGetHotTopic()
  const tagRef = useRef<any>()
  const [valueInput, setValueInput] = useState<readonly MyOptionType[]>([])
  const { setValue, watch } = useFormContext()
  const tags = watch("tags", [])

  const options = otherHashtag?.length
    ? otherHashtag.map((hashtag: { hashtag: string; total: number }) => ({
        value: hashtag.hashtag,
        label: `#${hashtag.hashtag.replaceAll("#", "")}`
      }))
    : []

  useEffect(() => {
    if (
      tags.length > 0 &&
      !!tagRef &&
      !!tagRef?.current &&
      !valueInput?.length &&
      tags.every((element: any) => element !== null)
    ) {
      setValueInput(
        tags.map((hashtag: string) => ({
          value: hashtag,
          label: `#${hashtag?.replaceAll("#", "")}`
        }))
      )
    }
  }, [tags, tagRef, valueInput])

  const onChangeValue = (
    newValue: MultiValue<MyOptionType>,
    actionMeta: ActionMeta<MyOptionType>
  ) => {
    if (newValue?.length <= LIMIT_TAG) {
      const validValue = newValue.map((item: MyOptionType) => ({
        ...item,
        value: removeVietnameseTones(item?.value),
        label: removeVietnameseTones(`#${item?.label?.replaceAll("#", "")}`)
      }))
      setValue(
        "tags",
        validValue.map((item: MyOptionType) => item?.value)
      )
      setValueInput(validValue)
    } else {
      toast({
        variant: "error",
        title: t("news.limit_hashtag_alert")
      })
    }
  }

  const onKeyDown = (e: any) => {
    if (tagRef && tagRef?.current) {
      const selectInstance = tagRef?.current
      const focusedOption = selectInstance?.state?.focusedOption
      if (
        e.keyCode == SPACE_KEYCODE &&
        !e.shiftKey &&
        e?.target &&
        !!focusedOption
      ) {
        selectInstance?.selectOption(focusedOption)
      }
    }
  }

  return (
    <div>
      <div className="flex items-center gap-3 cursor-pointer">
        <span className="text-bg-01 text-[18px] Nunito-700 dark:text-white">
          Tag (
          {(tags ?? [])?.filter((element: any) => element !== null)?.length} /{" "}
          {LIMIT_TAG})
        </span>
      </div>
      <>
        <div className="relative w-full">
          <CreatableSelect
            isMulti
            ref={tagRef}
            options={options}
            value={valueInput}
            placeholder={t("news.input_content")}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null
            }}
            classNamePrefix="react-select"
            onChange={onChangeValue}
            onKeyDown={onKeyDown}
          />
        </div>
      </>
    </div>
  )
}
