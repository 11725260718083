import { Button } from "@/app/components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger
} from "@/app/components/ui/dropdown-menu"
import React, { useState } from "react"
import { useTranslation } from "next-i18next"
import { ProfileSortFilter } from "./ProfileSortFilter"

export const FilterPostEvent = () => {
  const { t } = useTranslation()
  const [openFilter, setOpenFilter] = useState<boolean>(false)
  return (
    <DropdownMenu open={openFilter} onOpenChange={() => setOpenFilter(false)}>
      <DropdownMenuTrigger asChild>
        <Button
          variant="none"
          className="flex items-center gap-[8px] bg-bg-disable dark:bg-white/10 text-main-01 outline-none focus:outline-none active:outline-none rounded-[12px]"
          onClick={() => setOpenFilter(true)}
        >
          <span className="hidden md:block">
            {t("profile.filter_by")}&nbsp;
          </span>
          <span>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 5H17.5M10 10H15M10 15H12.5M2.5 13.0208L3.75432 14.4319C4.4173 15.1778 5.5827 15.1778 6.24568 14.4319L7.5 13.0208M5 15V5"
                stroke="#7673E6"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
          </span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="end"
        className="w-[120px] rounded-[16px] p-2 overflow-visible dark:bg-[#30303A]"
      >
        <ProfileSortFilter />
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
