import { DataEmpty } from "@/app/components/data-empty"
import { IconClose } from "@/app/components/icon-tsx/IconClose"
import { Button } from "@/app/components/ui/button"
import { Dialog, DialogContent, DialogHeader } from "@/app/components/ui/dialog"
import { IMerchandiseAddress } from "@/app/interfaces"
import { useTranslation } from "next-i18next"
import React from "react"
import { OneItemAddress } from "../component/ItemAddress"

interface Props {
  open: boolean
  onClose: () => void
  onSelectAddNewAddress: () => void
  listAddress: IMerchandiseAddress[]
  onSetAddressDefault: (address: IMerchandiseAddress) => void
  onDeleteAddress: (id: string) => void
  onUpdateAddress: (address: IMerchandiseAddress) => void
}

export const ModalSelectAddressProfile: React.FC<Props> = ({
  open,
  onClose,
  onSelectAddNewAddress,
  listAddress,
  onDeleteAddress,
  onSetAddressDefault,
  onUpdateAddress
}) => {
  const { t } = useTranslation()

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="rounded-[16px] sm:rounded-[16px] w-[90vw] md:w-[480px] p-[24px] dark:bg-bg-02 dark:border-0 max-w-[90vw] md:max-w-[536px] overflow-auto outline-none">
        <DialogHeader className="flex flex-row items-center justify-between w-full">
          <p className="Nunito-700 text-[16px] text-bg-01 dark:text-main-white">
            {t("merchandise.address_title")}
          </p>
          <div className="cursor-pointer" onClick={onClose}>
            <IconClose />
          </div>
        </DialogHeader>
        {/* select address */}
        <div className="w-full">
          {listAddress?.length ? (
            <div className="w-full">
              {listAddress.map((item: IMerchandiseAddress, index: number) => (
                <div className="w-full space-y-[16px]" key={item.id}>
                  <OneItemAddress
                    border={index !== listAddress?.length - 1}
                    address={item}
                    onSetAddressDefault={onSetAddressDefault}
                    onDeleteAddress={onDeleteAddress}
                    onUpdateAddress={onUpdateAddress}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className="flex items-center justify-center min-h-[300px] xs:min-h-[200px]">
              <DataEmpty />
            </div>
          )}
        </div>
        <Button variant="main" onClick={onSelectAddNewAddress}>
          Thêm địa chỉ giao hàng mới
        </Button>
      </DialogContent>
    </Dialog>
  )
}
