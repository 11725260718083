import { AppContext } from "@/app/context"
import React, { useContext, useEffect, useState } from "react"

export const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false)
  const { themeApp } = useContext(AppContext)
  const onScrollToTop = () => {
    if (typeof window !== "undefined") {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      })
    }
  }

  const handleScroll = () => {
    if (window.pageYOffset > 200) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <>
      {isVisible && (
        <div
          className="fixed bottom-[80px] md:bottom-[64px] right-[12px] md:right-[48px] opacity-100 hover:opacity-60 active:opacity-60 flex items-center justify-center bg-bg-button min-w-[24px] md:min-w-[40px] lg:min-w-[52px] border border-black/10 dark:border-0 aspect-square rounded-[12px] cursor-pointer z-50 dark:bg-[rgba(236,236,238,0.1)]"
          onClick={onScrollToTop}
        >
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.832 16.3333L13.9987 11.6666L8.16536 16.3333"
              stroke={themeApp === "dark" ? "#fff" : "#706E7E"}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      )}
    </>
  )
}
