import { Button } from "@/app/components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger
} from "@/app/components/ui/dropdown-menu"
import React, { useState } from "react"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "next-i18next"
import { StartTimeFilter } from "../../../common/filter-post/StartTime"
import { EndTimeFilter } from "../../../common/filter-post/EndTime"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@/app/components/ui/select"

export const FilterGold = () => {
  const { setValue, watch } = useFormContext()
  const { t } = useTranslation()
  const [openFilter, setOpenFilter] = useState<boolean>(false)
  const [openStartTime, setOpenStartTime] = useState<boolean>(false)
  const selectedSource = watch("source", "")
  const selectedStartTime = watch("start_date", undefined)
  const selectedEndTime = watch("end_date", undefined)

  const onChangeStartTime = () => {
    setOpenStartTime(!openStartTime)
  }
  const [openEndTime, setOpenEndTime] = useState<boolean>(false)
  const onChangeEndTime = () => {
    setOpenEndTime(!openEndTime)
  }
  const listFilter = [
    { value: "task", label: "Nhiệm vụ" },
    { value: "spin", label: "Vòng quay" },
    { value: "shop", label: "Cửa hàng" }
  ]

  const clearFilter = () => {
    setValue("start_date", undefined)
    setValue("end_date", undefined)
    setValue("source", "")
    setOpenFilter(false)
  }

  const onChangeType = (value: string) => {
    setValue("source", value)
  }

  return (
    <div className="flex items-center justify-between">
      <DropdownMenu open={openFilter} onOpenChange={() => setOpenFilter(false)}>
        <DropdownMenuTrigger asChild>
          <Button
            variant="none"
            className="flex items-center gap-[8px] bg-button dark:bg-white/10 text-main-01 outline-0"
            onClick={() => setOpenFilter(true)}
          >
            <span className="hidden md:block">
              {t("profile.filter_by")}&nbsp;
            </span>
            <span>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 5H17.5M10 10H15M10 15H12.5M2.5 13.0208L3.75432 14.4319C4.4173 15.1778 5.5827 15.1778 6.24568 14.4319L7.5 13.0208M5 15V5"
                  stroke="#7673E6"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
            </span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          align="end"
          className="min-w-[343px] rounded-[24px] px-[16px] py-[24px] overflow-visible dark:bg-[#30303A]"
        >
          <div className="w-full bg-white dark:bg-[#30303A]">
            <div className="mb-[24px]">
              <p className="text-base Nunito-600 text-neutral-03 dark:text-white">
                Loại tiêu phí
              </p>
              <Select onValueChange={(value: string) => onChangeType(value)}>
                <SelectTrigger className="w-full rounded-none border-0 border-b-[1px] border-black/10 dark:border-neutral-03 text-sm Nunito-500 text-neutral-05 dark:text-white dark:bg-transparent dark:backdrop-blur-[20px] text-left">
                  <SelectValue
                    placeholder={
                      !!selectedSource
                        ? listFilter.find(
                            (item) => item.value === selectedSource
                          )?.label
                        : t("reward.choose_game_title")
                    }
                  />
                </SelectTrigger>
                <SelectContent className="p-1 rounded-[16px]">
                  {listFilter.map((item: Record<string, string>) => (
                    <SelectItem
                      value={item?.value}
                      key={item?.value}
                      className="p-3 rounded-[8px] hover:!bg-bg-disable hover:!text-main-01 text-sm Nunito-600 focus:bg-bg-disable focus:text-main-01 dark:hover:!bg-[#F1F1FC]/[.1] dark:focus:!bg-[#F1F1FC]/[.1]"
                    >
                      {item?.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div>
              <p className="text-base Nunito-600 text-neutral-03 dark:text-white">
                {t("profile.time_title")}
              </p>
              <div className="flex items-center gap-[12px]">
                <StartTimeFilter
                  open={openStartTime}
                  onChange={onChangeStartTime}
                />
                <span>-</span>
                <EndTimeFilter open={openEndTime} onChange={onChangeEndTime} />
              </div>
              {selectedStartTime >= selectedEndTime && (
                <p className="mt-2 text-xs text-main-red-02">
                  {t("profile.invalid_date")}
                </p>
              )}
            </div>

            <div className="w-full flex items-center justify-end gap-[16px] mt-[24px]">
              <Button
                variant="none"
                className="text-base bg-bg-button text-neutral Nunito-700 h-[30px] py-[6px] px-[24px] rounded-[12px]"
                autoFocus={false}
                onClick={clearFilter}
              >
                {t("merchandise.cancel")}
              </Button>
              <Button
                variant="none"
                className="text-base text-white bg-main-01 h-[30px] Nunito-700 py-[6px] px-[24px] rounded-[12px]"
                onClick={() => setOpenFilter(false)}
              >
                {t("profile.filter")}
              </Button>
            </div>
          </div>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}
