import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from "@/app/components/ui/dropdown-menu"
import { AppContext } from "@/app/context"
import { IMerchandiseAddress } from "@/app/interfaces"
import { useTranslation } from "next-i18next"
import React, { useContext } from "react"

interface Props {
  item: IMerchandiseAddress
  onSetAddressDefault: (address: IMerchandiseAddress) => void
  onDeleteAddress: (id: string) => void
  onUpdateAddress: (address: IMerchandiseAddress) => void
}
export const OptionMerchandiseAddress: React.FC<Props> = ({
  item,
  onSetAddressDefault,
  onDeleteAddress,
  onUpdateAddress
}) => {
  const { t } = useTranslation()
  const { themeApp } = useContext(AppContext)
  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        asChild
        className={`outline-0 mt-0`}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <div
          className={`cursor-pointer w-[22px] h-[22px] rounded-[8px] bg-bg-button dark:bg-white/10 flex justify-center items-center rotate-90`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="3"
            viewBox="0 0 13 3"
            fill="none"
            className="w-[10px] h-[3px]"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 1.25C0 1.94036 0.559644 2.5 1.25 2.5C1.94036 2.5 2.5 1.94036 2.5 1.25C2.5 0.559644 1.94036 0 1.25 0C0.559644 0 0 0.559644 0 1.25ZM6.25 2.5C5.55964 2.5 5 1.94036 5 1.25C5 0.559644 5.55964 0 6.25 0C6.94036 0 7.5 0.559644 7.5 1.25C7.5 1.94036 6.94036 2.5 6.25 2.5ZM11.25 2.5C10.5596 2.5 10 1.94036 10 1.25C10 0.559644 10.5596 0 11.25 0C11.9404 0 12.5 0.559644 12.5 1.25C12.5 1.94036 11.9404 2.5 11.25 2.5Z"
              fill={themeApp === "dark" ? "#fff" : "#28303F"}
            />
          </svg>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="text-left" align="end">
        {!item?.default && (
          <DropdownMenuItem
            className="justify-start focus:bg-bg-disable focus:text-main-01 Nunito-600 text-[16px] text-center flex px-4 py-3 rounded-[8px]"
            onClick={() => {
              onSetAddressDefault(item)
            }}
          >
            {t("merchandise.set_default_address")}
          </DropdownMenuItem>
        )}
        <DropdownMenuItem
          className="justify-start focus:bg-bg-disable focus:text-main-01 Nunito-600 text-[16px] text-center flex px-4 py-3 rounded-[8px]"
          onClick={() => onUpdateAddress(item)}
        >
          {t("common.edit_sth")}
        </DropdownMenuItem>

        <DropdownMenuItem
          className="items-start focus:bg-bg-disable focus:text-main-01 Nunito-600 text-[16px] text-center flex px-4 py-3 rounded-[8px]"
          onClick={() => onDeleteAddress(item.id)}
        >
          {t("merchandise.delete_address")}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
