import React from "react"
import { AnalyticChart } from "./AnalyticChart"
import { Statistical } from "./Statistical"
import { DetailStatistical } from "./DetailStatistical"

export const PartnerAnalytic = () => {
  return (
    <div className="grid grid-cols-12 w-full mt-[32px] gap-[24px] lg:gap-[32px] pb-[40px]">
      <AnalyticChart />
      <Statistical />
      <DetailStatistical />
    </div>
  )
}
