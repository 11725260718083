import {
  DATE_FORMAT_FULL,
  _15M_TIME_STAMP,
  _24H_TIME_STAMP
} from "@/app/configs"
import moment from "moment"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import React, { useCallback } from "react"
import { enumViewDetailTransaction } from "../../tab"
import { Button } from "@/app/components/ui/button"

export const ItemMerchandiseTransaction = ({
  item,
  onSelecteData
}: {
  item: any
  onSelecteData: (item: any) => void
}) => {
  const { t } = useTranslation()
  const router = useRouter()
  const status = {
    pending: {
      label: t("profile.pending_confirm"),
      value: "pending"
    },
    confirmed: {
      label: t("profile.transaction_confirmed"),
      value: "confirmed"
    },
    transfer: {
      label: t("profile.transaction_transfer"),
      value: "transfer"
    },
    done: {
      label: t("profile.transaction_done"),
      value: "done"
    },
    rejected: {
      label: t("profile.transaction_cancel"),
      value: "rejected"
    },
    cancel: {
      label: t("profile.transaction_cancel"),
      value: "cancel"
    },
    destroy: {
      label: t("profile.transaction_cancel"),
      value: "destroy"
    },
    refund: {
      label: t("profile.transaction_cancel"),
      value: "refund"
    }
  }

  const onNavigateToDetail = () => {
    const type = String(router?.query?.type)
    onSelecteData(item)
    router.push({
      pathname: router.pathname,
      query: {
        id: router?.query?.id,
        tab: router?.query?.tab,
        type:
          type === "undefined"
            ? enumViewDetailTransaction.CARD_DETAIL
            : `${type?.replace("-all", "")}-detail`
      }
    })
  }

  const totalItem = useCallback(() => {
    if (!item?.list_item?.length) return 0
    const list = item?.list_item
    const total = list?.reduce(
      (total: number, current: any) => total + Number(current?.quantity),
      0
    )
    return total ?? 0
  }, [item])

  const renderStatusTransaction = () => {
    switch (item.status) {
      case "cancel": {
        return (
          <p
            className={`text-[12px] Nunito-600 rounded-[8px] line-clamp-1 text-label-02 flex items-center min-w-fit`}
          >
            {t("profile.transaction_cancel")}
          </p>
        )
      }
      case "pending": {
        return (
          <p
            className={`text-[12px] Nunito-600 rounded-[8px] line-clamp-1 flex text-label-02 items-center min-w-fit`}
          >
            {t("profile.transaction_pending")}
          </p>
        )
      }
      default: {
        return (
          <p
            className={`text-[12px] text-label-02 Nunito-600 line-clamp-1 rounded-[8px] flex items-center`}
          >
            {status[item?.list_item[0]?.status as keyof typeof status]?.label}
          </p>
        )
      }
    }
  }
  return (
    <div
      className="p-[12px] space-y-[12px] bg-white dark:bg-white/10 rounded-[16px] w-full cursor-pointer border border-black/10 hover:border-main-01 dark:border-0"
      onClick={onNavigateToDetail}
    >
      <div className="flex justify-between items-center">
        <span className="text-xs text-neutral-06 dark:text-neutral-08">
          {moment.unix(item?.created_at).format(DATE_FORMAT_FULL)}
        </span>
        {renderStatusTransaction()}
      </div>
      <div className="flex gap-1 sm:flex-row rounded-[8px] w-full">
        <img
          src={item?.list_item[0]?.images[0]}
          alt={item?.list_item[0]?.item_name}
          className="w-[55px] h-[55px] rounded-[8px] mr-[13px] object-cover"
        />
        <div className="flex-1">
          <div className="flex items-center justify-between gap-2 mb-1">
            <p className="text-[14px] Nunito-600 line-clamp-1">
              {item?.list_item[0]?.item_name}
            </p>
          </div>

          <p className="text-xs break-all Nunito-400 text-neutral-06 dark:text-neutral-08 line-clamp-1">
            {t("profile.item_code_summary")}: {item?.transaction_id}
          </p>
          <div className="flex items-center justify-between w-full gap-3">
            <p className="text-[12px] dark:text-neutral-08">
              x{item?.list_item[0]?.quantity}
            </p>
            {totalItem() > 1 && (
              <p className="flex items-center text-xs text-main-01 Nunito-400">
                {totalItem()}&nbsp;
                <span className="lowercase">{t("merchandise.product")}</span>
                <span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 7L14 12L10 17"
                      stroke="#7673E6"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="border-t-[1px] border-dashed border-[rgba(0, 0, 0, 0.10)] flex flex-col gap-[8px] pt-[12px]">
        {item.status === "pending" &&
        (Number(item?.created_at) + _15M_TIME_STAMP) * 1000 >
          Number(moment().unix()) * 1000 ? (
          <div className="flex justify-between items-center">
            {(Number(item?.created_at) + _15M_TIME_STAMP) * 1000 >
              Number(moment().unix()) * 1000 && (
              <p className="text-xs text-main-red-02 Nunito-400">
                {t("profile.remind_pending_status", {
                  time: moment(
                    (Number(item?.created_at) + _15M_TIME_STAMP) * 1000
                  ).format(DATE_FORMAT_FULL)
                })}
              </p>
            )}
            {item?.payment_link &&
              (Number(item?.created_at) + _15M_TIME_STAMP) * 1000 >
                Number(moment().unix()) * 1000 && (
                <Button
                  variant="main"
                  className="text-[12px] Nunito-500 h-[24px] lg:w-fit px-[8px] rounded-[8px]"
                  onClick={(e) => {
                    e.stopPropagation()
                    window.location.href = item?.payment_link
                  }}
                >
                  {t("store.pay")}
                </Button>
              )}
          </div>
        ) : (
          <>
            <div className="flex items-center justify-between gap-2">
              <p className="text-xs text-neutral-06 dark:text-neutral-08">
                {t("store.total")}:
              </p>
              <span className="text-sm text-main-01 Nunito-700 dark:text-main-red-02">
                {item?.total_amount?.toLocaleString()}&nbsp;đ
              </span>
            </div>
            {/* <div className="flex items-center justify-between gap-2">
              <p className="text-xs Nunito-400 text-neutral-06 dark:text-neutral-08">
                {t("store.buy_date")}
              </p>
            </div> */}
          </>
        )}
      </div>
    </div>
  )
}
