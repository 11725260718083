import { QUERY_KEY } from "@/app/configs"
import { useInfiniteQuery, useQuery } from "@tanstack/react-query"
import { AnalyticService } from "../../services/AnalyticServices"

export const useQueryGetTotalLikeNews = (param?: {
  start_time?: string
  end_time?: string
}) => {
  return useQuery({
    queryKey: [QUERY_KEY.ANALYTIC_GET_TOTAL_LIKE_NEWS, param],
    queryFn: async () => {
      const res = await AnalyticService.getTotalLikeNews(param)
      return res?.data?.total
    },
    refetchOnMount: "always"
  })
}
export const useQueryGetTotalCommentNews = (param?: {
  start_time?: string
  end_time?: string
}) => {
  return useQuery({
    queryKey: [QUERY_KEY.ANALYTIC_GET_TOTAL_COMMENT_NEWS, param],
    queryFn: async () => {
      const res = await AnalyticService.getTotalCommentNews(param)
      return res?.data?.total
    },
    refetchOnMount: "always"
  })
}

export const useQueryGetTotalNews = (param?: {
  start_time?: string
  end_time?: string
}) => {
  return useQuery({
    queryKey: [QUERY_KEY.ANALYTIC_GET_TOTAL_NEWS, param],
    queryFn: async () => {
      const res = await AnalyticService.getTotalNews(param)
      return res?.data?.total
    },
    refetchOnMount: "always"
  })
}
export const useQueryGetTotalEvent = (param?: {
  start_time?: string
  end_time?: string
}) => {
  return useQuery({
    queryKey: [QUERY_KEY.ANALYTIC_GET_TOTAL_EVENT, param],
    queryFn: async () => {
      const res = await AnalyticService.getTotalEvent(param)
      return res?.data?.total
    },
    refetchOnMount: "always"
  })
}

export const useQueryGetTotalUserFollowPartner = (param?: {
  start_time?: string
  end_time?: string
}) => {
  return useQuery({
    queryKey: [QUERY_KEY.ANALYTIC_GET_TOTAL_USER_FOLLOW_PARTNER, param],
    queryFn: async () => {
      const res = await AnalyticService.getTotalUserFollowPartner(param)
      return res?.data?.total
    },
    refetchOnMount: "always"
  })
}
export const useQueryGetTotalUserJoinedEvent = (param?: {
  start_time?: string
  end_time?: string
}) => {
  return useQuery({
    queryKey: [QUERY_KEY.ANALYTIC_GET_TOTAL_USER_JOINED_EVENT, param],
    queryFn: async () => {
      const res = await AnalyticService.getTotalUserJoinedEvent(param)
      return res?.data?.total
    },
    refetchOnMount: "always"
  })
}

export const useQueryGetListDataNews = (params: {
  start_time?: string
  end_time?: string
  type: string
}) => {
  const { type, ...param } = params
  return useInfiniteQuery({
    queryKey: [QUERY_KEY.ANALYTIC_NEWS, param],
    queryFn: async ({ pageParam }) => {
      const res = await AnalyticService.getListDataNews({
        ...params,
        page: pageParam
      })
      return res?.data?.data
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.length > 9 ? pages?.length + 1 : undefined
    },
    enabled: type === "news",
    refetchOnMount: "always"
  })
}

export const useQueryGetListDataEvent = (params: {
  start_time?: string
  end_time?: string
  type: string
}) => {
  const { type, ...param } = params
  return useInfiniteQuery({
    queryKey: [QUERY_KEY.ANALYTIC_EVENT, param],
    queryFn: async ({ pageParam }) => {
      const res = await AnalyticService.getListDataEvent({
        ...params,
        page: pageParam
      })
      return res?.data?.data
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.length > 9 ? pages?.length + 1 : undefined
    },
    enabled: type === "event",
    refetchOnMount: "always"
  })
}

export const useQueryGetChartData = (param: {
  start_time?: string
  end_time?: string
}) => {
  return useQuery({
    queryKey: [QUERY_KEY.ANALYTIC_CHART_DATA, param],
    queryFn: async () => {
      const res = await AnalyticService.getChartData(param)
      return res?.data?.data
    },
    refetchOnMount: "always"
  })
}
