import { Button } from "@/app/components/ui/button"
import { useRouter } from "next/router"
import React, { useEffect, useRef, useState } from "react"
import { enumTypeTransaction, enumTypeViewAll } from "../../tab"
import { PurchaseHeader } from "./PurchaseHeader"
import { PurchaseContent } from "./PurchaseContent"
import { useTranslation } from "next-i18next"
import { FormProvider, useForm } from "react-hook-form"
import useGAEventsTracker from "@/app/hooks/useGAEventsTracker"

const initialFilter = {
  keyword: "",
  start_date: undefined as unknown as any,
  end_date: undefined as unknown as any
}

export const PurchaseTransaction = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const prevRef = useRef<any>()
  const nextRef = useRef<any>()
  const [dataSelected, setDataSelected] = useState<any>()

  const GAEventsTracker = useGAEventsTracker("Profile")

  const methods = useForm<typeof initialFilter>({
    defaultValues: initialFilter
  })

  const onSelecteData = (item: any) => {
    setDataSelected(item)
  }

  const handleChangeTab = (value: enumTypeTransaction) => {
    if (value === enumTypeTransaction.CARD) {
      GAEventsTracker("View_order_card")
    }
    if (value === enumTypeTransaction.TOPUP) {
      GAEventsTracker("View_order_topup")
    }
    if (value === enumTypeTransaction.MERCHANDISE) {
      GAEventsTracker("View_order_merchandise")
    }

    if (router?.query?.type?.includes("all")) {
      router.push({
        pathname: router.pathname,
        query: {
          id: router?.query?.id,
          tab: router?.query?.tab,
          type: `${value}-all`
        }
      })
    } else
      router.push({
        pathname: router.pathname,
        query: router?.query?.gold
          ? {
              id: router?.query?.id,
              tab: router?.query?.tab,
              gold: router?.query?.gold,
              type: value
            }
          : {
              id: router?.query?.id,
              tab: router?.query?.tab,
              type: value
            }
      })
  }

  const handleViewAll = () => {
    const momentType = String(router?.query?.type)
    router.push({
      pathname: router.pathname,
      query: {
        id: router?.query?.id,
        tab: router?.query?.tab,
        type:
          momentType === "undefined"
            ? enumTypeViewAll.CARD_ALL
            : `${momentType}-all`
      }
    })
  }

  return (
    <div className="px-0 lg:px-[24px] w-full space-y-[16px]">
      <FormProvider {...methods}>
        <PurchaseHeader
          prevRef={prevRef}
          nextRef={nextRef}
          handleChangeTab={handleChangeTab}
        />

        <PurchaseContent
          prevRef={prevRef}
          nextRef={nextRef}
          dataSelected={dataSelected}
          setDataSelected={setDataSelected}
          onSelecteData={onSelecteData}
        />
      </FormProvider>

      {!String(router?.query?.type).includes("all") &&
        !String(router?.query?.type).includes("detail") && (
          <Button
            variant="none"
            className="h-auto p-0 text-sm opacity-100 active:opacity-70 text-main-01 Nunito-700 "
            onClick={handleViewAll}
          >
            {t("profile.view_all")}&nbsp;
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.5303 12.5303C18.8232 12.2374 18.8232 11.7626 18.5303 11.4697L14.5303 7.46967C14.2374 7.17678 13.7626 7.17678 13.4697 7.46967C13.1768 7.76256 13.1768 8.23744 13.4697 8.53033L16.1893 11.25H6C5.58579 11.25 5.25 11.5858 5.25 12C5.25 12.4142 5.58579 12.75 6 12.75H16.1893L13.4697 15.4697C13.1768 15.7626 13.1768 16.2374 13.4697 16.5303C13.7626 16.8232 14.2374 16.8232 14.5303 16.5303L18.5303 12.5303Z"
                fill="#7673E6"
              />
            </svg>
          </Button>
        )}
    </div>
  )
}
