import { IGameBannerRequest, IGameRequest } from "@/app/interfaces"
import { useInfiniteQuery, useQuery } from "@tanstack/react-query"
import { QUERY_KEY } from "@/app/configs"
import {
  IBuyItemParams,
  IPackageParams,
  IShopParams
} from "@/app/interfaces/IReward"
import RewardService from "@/app/services/services/RewardService"

const getListPackageFn = async (params: IPackageParams) => {
  const data = await RewardService.getPackage(params)

  return data.data
}

const getListGameByGiftcodeFn = async (params: IBuyItemParams) => {
  const data = await RewardService.getGameByGiftcode(params)

  return data.data
}

export const useQueryGetListPackage = (params: IPackageParams) => {
  return useQuery({
    queryKey: [QUERY_KEY.PACKAGE_LIST, params],
    queryFn: () => getListPackageFn(params),
    enabled: !!params,
    refetchOnWindowFocus: false,
    retry: 0
  })
}

export const useQueryGetListGameByGiftcode = (params: IBuyItemParams) => {
  return useQuery({
    queryKey: [QUERY_KEY.LIST_GAME_BY_GIFTCODE, params],
    queryFn: () => getListGameByGiftcodeFn(params),
    enabled: !!params,
    refetchOnWindowFocus: false,
    retry: 0
  })
}
