import { useEffect, useState } from "react"
import { FacebookPixel } from "react-use-facebook-pixel"
import { FACEBOOK_PIXEL_ID } from "../configs"

export const useFacebookPixel = () => {
  const [facebookPixel, setFacebookPixel] = useState<FacebookPixel | null>(null)

  useEffect(() => {
    const initializeFacebookPixel = async () => {
      const pixel = new FacebookPixel({
        pixelID: FACEBOOK_PIXEL_ID,
        pageViewOnInit: false
      })

      pixel.init({})

      setFacebookPixel(pixel)
    }

    initializeFacebookPixel()
  }, [])

  return facebookPixel
}
