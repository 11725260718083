import React from "react"

export const IconInventory = ({ className }: { className?: string }) => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.87429 3.16699C4.87429 1.4411 6.2734 0.0419922 7.99929 0.0419922C9.72518 0.0419922 11.1243 1.4411 11.1243 3.16699V4.00847C12.7012 4.11844 13.9989 5.33021 14.1976 6.92021L15.0309 13.5869C15.2796 15.5764 13.7283 17.3337 11.7233 17.3337H4.27523C2.27024 17.3337 0.718949 15.5764 0.967638 13.5869L1.80097 6.92021C1.99972 5.33021 3.29737 4.11844 4.87429 4.00847V3.16699ZM9.87429 3.16699V4.00033H6.12429V3.16699C6.12429 2.13146 6.96376 1.29199 7.99929 1.29199C9.03482 1.29199 9.87429 2.13146 9.87429 3.16699ZM10.8063 12.8781C11.1069 12.7086 11.2133 12.3274 11.0437 12.0267C10.8742 11.726 10.493 11.6197 10.1924 11.7892C9.24464 12.3235 8.59859 12.5408 7.99926 12.542C7.40107 12.5431 6.75518 12.329 5.80835 11.7904C5.50832 11.6197 5.12674 11.7246 4.95606 12.0246C4.78538 12.3246 4.89024 12.7062 5.19027 12.8769C6.20689 13.4552 7.08082 13.7938 8.00168 13.792C8.9214 13.7902 9.79361 13.449 10.8063 12.8781Z"
        fill="#7673E6"
      />
    </svg>
  )
}
