import { HoverUserAvatar } from "@/app/components/app-avatar/HoverUserAvatar"
import { DataEmpty } from "@/app/components/data-empty"
import { IconSearch } from "@/app/components/icon-tsx"
import { IconClose } from "@/app/components/icon-tsx/IconClose"
import { Dialog, DialogContent, DialogHeader } from "@/app/components/ui/dialog"
import { AppContext } from "@/app/context"
import { useDebounceEffect } from "@/app/hooks/useDebounceEffect"
import { useQueryGetMyFollowing } from "@/app/services/query-fn"
import { ScrollArea } from "@radix-ui/react-scroll-area"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { useInView } from "react-intersection-observer"

interface ModalFollowProps {
  open: boolean
  onClose: () => void
}
const TIME_DEBOUNCE = 300
export const ModalFollower: React.FC<ModalFollowProps> = ({
  open,
  onClose
}) => {
  const { t } = useTranslation()
  const router = useRouter()
  const userid = useMemo(() => router?.query?.id, [router])
  const { themeApp } = useContext(AppContext)
  const [tempValue, setTempValue] = useState<string>("")
  const [searchValue, setSearchValue] = useState<string>("")
  const { data, fetchNextPage } = useQueryGetMyFollowing(
    String(userid),
    open,
    searchValue
  )
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTempValue(e?.target?.value)
  }
  const { ref, inView } = useInView()

  useEffect(() => {
    if (inView) {
      fetchNextPage()
    }
  }, [fetchNextPage, inView])

  useDebounceEffect(() => setSearchValue(tempValue), TIME_DEBOUNCE, [tempValue])

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:rounded-[24px] rounded-[24px] w-[90vw] md:max-w-[406px] p-[32px] dark:bg-bg-02 dark:border-0 ">
        <DialogHeader className="flex flex-row items-center justify-between w-full">
          <p className="Nunito-700 text-[16px] text-bg-01 dark:text-white">
            {t("profile.follower")}
          </p>
          <div className="cursor-pointer" onClick={onClose}>
            <IconClose />
          </div>
        </DialogHeader>
        <div className="flex items-center justify-between border-b-[1px] dark:border-0 gap-4 py-3">
          <IconSearch fill={themeApp === "dark" ? "#fff" : "#100D28"} />
          <input
            type="text"
            value={tempValue}
            className="flex-1 text-base border-0 outline-none dark:text-white dark:bg-transparent"
            placeholder={t("profile.find_follower_placeholder")}
            onChange={onChange}
          />
        </div>
        <div className="w-full">
          <div className="h-[412px] space-y-[24px] overflow-auto">
            {data?.pages?.flat()?.length ? (
              data?.pages?.flat().map((item, index) => (
                <div
                  className="flex items-center justify-between w-full"
                  key={index}
                >
                  <HoverUserAvatar
                    avatarUser={item}
                    size={40}
                    text={{
                      text: item?.fullname,
                      className:
                        "flex-1 text-base Nunito-700 text-bg-01 dark:text-white"
                    }}
                  />
                </div>
              ))
            ) : (
              <div className="w-full flex items-center justify-center h-[200px]">
                <DataEmpty title={t("profile.no_data")} />
              </div>
            )}
            {!!data && !!data?.pages?.flat()?.length && (
              <button ref={ref} className="opacity-0">
                Load more
              </button>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
