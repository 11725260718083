import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "@lib/utils"

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-60",
  {
    variants: {
      variant: {
        default: "bg-primary text-primary-foreground hover:bg-primary/90",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline:
          "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
        secondary: "bg-bg-disable text-main-01 active:opacity-70",
        ghost_calendar:
          "bg-disable hover:bg-bg-disable hover:text-accent-foreground dark:hover:text-bg-01",
        ghost:
          "bg-bg-button  rounded-[12px] text-neutral-03 text-[16px] Nunito-700 h-[38px] leading-[16px] active:bg-white",
        link: "text-primary underline-offset-4 hover:underline",
        none: "text-primary",
        main: "rounded-[12px] bg-main-01 text-[16px] Nunito-700 leading-[16px] h-[38px] py-[8px] px-[16px] text-main-white active:bg-main-disable dark:disabled:bg-white/10 dark:disabled:text-neutral-07 dark:disabled:opacity-100",
        grey: "rounded-[12px] bg-bg-button dark:bg-bg-button/10 text-[16px] Nunito-700 leading-[16px] h-[38px] py-[8px] px-[16px] text-main-01 active:bg-white dark:active:bg-transparent",
        light:
          "text-primary bg-button rounded-[12px] text-main-01 text-[16px] Nunito-700 leading-[16px]",
        primary:
          "border bg-transparent border-main-01 text-main-01 rounded-[8px] text-[14px] Nunito-500"
      },
      size: {
        default: "h-10 px-4 py-2",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md px-8",
        icon: "h-10 w-10"
      }
    },
    defaultVariants: {
      variant: "default",
      size: "default"
    }
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  loading?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, loading, ...props }, ref) => {
    const Comp = asChild ? Slot : "button"
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    )
  }
)
Button.displayName = "Button"

export { Button, buttonVariants }
