import React from "react"
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar"
import { IGameAuthor, IUser, IUserRequest } from "@/app/interfaces"
import { convertNameString } from "@/app/utils"
import { IAuthor } from "@/app/interfaces/IPost"

interface OnlyShowAvatarProps {
  size: number
  avatarUser: IUser | IUserRequest | IAuthor | IGameAuthor
}
export const OnlyShowAvatar: React.FC<OnlyShowAvatarProps> = ({
  size,
  avatarUser
}) => {
  return (
    <div className={`relative group`}>
      <Avatar className={`rounded-full w-[${size}px] h-[${size}px]`}>
        <AvatarImage
          src={
            !!avatarUser?.small_avatar
              ? avatarUser?.small_avatar
              : !!avatarUser?.avatar
              ? avatarUser?.avatar
              : ""
          }
          alt={avatarUser?.fullname}
          height={size}
          width={size}
          className={`object-cover aspect-square w-[${size}px] h-[${size}px] rounded-full`}
        />
        <AvatarFallback className="flex items-center justify-center w-full text-white bg-main-01">
          {convertNameString(avatarUser?.fullname ?? "P")}
        </AvatarFallback>
      </Avatar>
      {!!avatarUser?.frame_avatar && (
        <div
          className={`w-[${size}px] h-[${size}px] absolute top-0 left-0 rounded-full z-20`}
          style={{
            backgroundImage: `url(${avatarUser?.frame_avatar})`,
            backgroundSize: "100%"
          }}
        ></div>
      )}
    </div>
  )
}
